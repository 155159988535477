import { Component, OnInit, Input, HostListener } from '@angular/core';
import { LayoutService } from '../../shared/services/layout.service';
import { AuthenticationService, CommonService, StorageService } from '../../services/index';
import { Router } from '@angular/router';
import { IEvent } from 'src/app/models';

@Component({
  selector: 'app-content-section',
  templateUrl: './content-section.component.html',
  styleUrls: ['./content-section.component.scss']
})
export class ContentSectionComponent implements OnInit {
  screenTitle = '';
  contentHeight: number;
  @Input() navLayout: string;
  @Input() defaultNavbar: string;
  @Input() toggleNavbar: string;
  @Input() toggleStatus: boolean;
  @Input() navbarEffect: string;
  @Input() deviceType: string;
  @Input() headerColorTheme: string;
  @Input() navbarColorTheme: string;
  @Input() activeNavColorTheme: string;

  currentUser:string;
  documentInformation: string;
  offlineInfo: string;

  constructor(
    private storageService: StorageService,
    private commonService: CommonService,
    private layoutService: LayoutService, 
    private router: Router,
    private authenticationService: AuthenticationService) { 
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }


  ngOnInit() {
    
    this.layoutService.contentHeightCast.subscribe(setCtHeight => this.contentHeight = setCtHeight);
    this.commonService.hasDocument.subscribe(next => {
      this.documentInformation = next;
     
    });

    this.commonService.offlineInformation.subscribe(next => {
      this.documentInformation = next;
    });

    if (this.storageService.GetDocEntry() > 0) {
      this.commonService.hasDocument.next(this.storageService.GetBreadCrum());
    }
  }
  @HostListener('window:resize', ['$event'])
  onResizeHeight(event: any) {
    console.log(0);
      this.contentHeight = window.innerHeight - this.layoutService.headerHeight;
  }

  raiseEvent(_event: number): void {
    
    let event = null;
    switch (_event) {
      case 0:
        event = {
          FunctionName: "refreshCustomers",
          ViewName: ["/so", "/quotation"],
          Paramenters: null
        } as IEvent;
    
        
        break;
      case 1:
        event = {
          FunctionName: "redirectToSoAndSQ",
          ViewName: ["/so", "/quotation"],
          Paramenters: null
        } as IEvent;
        break;
    }

    this.commonService.eventManager.next(event);   
  }
}
