import { environment } from "src/environments/environment";

export class AppConstants {
  public static get padronInfoURl(): string {
    return "https://api.hacienda.go.cr/fe/";
  }
  // PRUEBAS DEBUG
  public static get modalAnswer(): string {
    return "https://npstest.clavisco.com/#/";
  }
  // PRUEBAS TEST
  public static get apiAnswer(): string {
    return "https://npsapitest.clavisco.com/";
  }

  // public static get RPTMANAGER_URL(): string { return 'http://localhost:54264/'; } return "https://rptmanager.clavisco.com/";
  public static get RPTMANAGER_URL(): string {
    return environment.ReportManager; //"https://rptmanagertest.clavisco.com/";
  }

  public static comoesta: boolean = true;

  public static get sOnline(): string {
    this.comoesta = true;
    return "a";
  }

  public static get sOffline(): string {
    this.comoesta = false;
    return "a";
  }

  public static get apiUrl(): string {
    return navigator.onLine ? this.getOnLine() : this.getOffLine();
  }

  public static get onlineUrl(): string {
    return this.getOnLine();
  }

  public static get offlineUrl(): string {
    return this.getOffLine();
  }

  public static get TokenPadron(): string {
    return this.getTokenPadron();
  }

  static getOffLine(): string {
    return "http://localhostt:56666/";
  }

  static getOnLine = (): string => environment.ApiUrl;

  static getTokenPadron(): string { return 'https://padronapi.clavisco.com/'; }
  public static get AppKey(): string {
    return "AppSuper";
  }

}

export const ExchangeRate = [
  {
    Id: "1",
    Name: "SAP",
  },
  {
    Id: "2",
    Name: "DB",
  },
];

export const HandleItem = [
  {
    Id: "1",
    Name: "Código",
  },
  {
    Id: "2",
    Name: "Barras",
  },
];

export const BillItem = [
  {
    Id: "1",
    Name: "Código",
  },
  {
    Id: "2",
    Name: "Series",
  },
];

export const ReportTypeList = [
  {
    Id: "5",
    Name: "Factura",
  },
  {
    Id: "1",
    Name: "Orden de Venta",
  },
  {
    Id: "2",
    Name: "Proforma",
  },
];

export const IdentificationType = [
  // {
  //     'Id': '00',
  //     'Name': '',
  // },
  {
    Id: "01",
    Name: "Cedula Fisica",
  },
  {
    Id: "02",
    Name: "Cedula Juridica",
  },
  {
    Id: "03",
    Name: "DIMEX",
  },
  {
    Id: "04",
    Name: "NITE",
  },
];

export const IdentificationBusinessPartner = [
  {
    Id: "1",
    Name: "Cliente",
    SapType: "C",
  },
  {
    Id: "2",
    Name: "Proveedor",
    SapType: "S",
  },
];

export enum PayTermsEnum {
  A30Dias = 1,
  Contado = 2,
}

export enum RequestDocumentType { // Usado para definir el tipo de peticion que se hara al banco. Por ejemplo cierre de tarjetas o precierre de tarjetas
  BALANCE,
  PRE_BALANCE,
}

export enum CurrencyPP {
  LOCAL_CURRENCY = 1,
  USD = 2,
}

export enum DocumentStatus {
  ERROR_ON_SYNCUP = "ERROR",
  PENDING_TO_SYNUP = "PEDIENTE_SINCRONIZAR",
  SYNCHRONIZED = "SINCRONIZADO",
}
export const TypeInvoice = [
  {
    Name: "FE",
    Description: "Factura Electrónica",
  },
  {
    Name: "TE",
    Description: "Tiquete Electrónico",
  },
];


export enum LocalStorageVariables {
  OpenedWindowsIdentifiers = 'OpenedWindowsIdentifiers',
  OpenedWindowsCounter = 'OpenedWindowsCounter'
}
