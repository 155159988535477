import { Pipe, PipeTransform } from '@angular/core';
import { CashflowReasonModel } from '../models';

@Pipe({
  name: 'cashflowReasons'
})
export class CashflowReasonsPipe implements PipeTransform {

  transform(reasons: CashflowReasonModel[], type: string): CashflowReasonModel[] {
    if (!reasons) return [];
    if (!type) return reasons;

    return reasons.filter(x => x.Type === Number(type));
  }

}
