import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Users, UserAssigns, Stores, Company, IServiceConnection } from '../../../../models/index';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PermsService, ItemService, UserService, SalesManService, AlertService, BranchOfficeService } from '../../../../services/index';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BranchOfficeModel2 } from 'src/app/models/branch-office';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-user-conf',
  templateUrl: './user-conf.component.html',
  styleUrls: ['./user-conf.component.scss']
})
export class UserConfComponent implements OnInit {
  //VARBOX
  isSubmitted: boolean;
  @BlockUI() blockUI: NgBlockUI;
  userId: number;
  userinfo: UserAssigns;
  userList: Users[] = [];
  userAssignsList: UserAssigns[] = [];
  storesList: Stores[] = [];
  companyList: Company[] = [];
  branchOffices: BranchOfficeModel2[];
  UsersConnService: IServiceConnection[];
  
  userGroup: FormGroup;
  disable: boolean;
  salida: boolean;
  btnSendInfo: string;
  PriceList: any[] = [];
  SalesPersonList: any[] = [];

  constructor(private activatedRoute: ActivatedRoute,
    private pService: PermsService,
    private uService: UserService,
    private fbg: FormBuilder,
    private router: Router,
    private itemService: ItemService,
    private smService: SalesManService,
    private alertService: AlertService,
    private branchOfficeService: BranchOfficeService) {
    this.userinfo = new UserAssigns();
  }

  ngOnInit() {
    this.userId = Number(this.activatedRoute.snapshot.paramMap.get('userId'));
    this.disable = false;
    this.salida = true;
    if (this.userId > 0) {
      this.disable = true;
      this.btnSendInfo = 'Actualizar';
    } else { this.btnSendInfo = 'Crear'; }

    this.userGroup = this.fbg.group({
      cUserName: ['', [Validators.required]],
      BranchOfficeId: ['', Validators.required],
      cSAPUser: ['',Validators.required],
      SAPPass: ['', Validators.required],
      StoreId: ['', [Validators.required]],
      CompanyId: [{ value: '', disabled: this.disable }, [Validators.required]],
      minDiscount: [0, [Validators.required, Validators.max(100), Validators.min(0)]],
      SlpCode: ['', [Validators.required]],
      CenterCost: [''],
      PriceListDef: ['', [Validators.required]],
      Active: ['S'],
      SuperUser: ['S'],
      // UserConnSer: ['', [Validators.required]],
    });

    this.isSubmitted = false;
    this.chargeUser();
    this.getGetCompanies();
    this.GetPriceList();
    this.GetSalesPersonList();
    this.getBranchOfficess();
    // this.getUsersConnService();
  }

  chargeUser() {
    this.blockUI.start('Cargando listas de usuarios...');
    this.pService.getUsers().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        this.userList = data.users;

        if (this.userId > 0) this.chargeUserAsing();
        // this.userGroup.patchValue({ cUserName: this.userList[0].Id });
      } else {
        this.alertService.errorAlert('Error al cargar la lista de usuarios - ' + data.errorInfo.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  getGetCompanies() {
    this.blockUI.start('Cargando listas de almacenes...');
    this.uService.getGetCompanies().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        this.companyList = data.companiesList;
        this.userGroup.patchValue({ CompanyId: this.companyList[0].Id });
        this.getStoresList(this.companyList[0].Id);
      } else {
        this.alertService.errorAlert('Error al cargar la lista de almacenes - ' + data.errorInfo.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });

  }

  getStoresList(company: number) {
    this.blockUI.start('Cargando listas de Almacenes...');
    this.uService.getStoresList(company).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        this.storesList.length = 0;
        this.storesList = data.Stores;
        this.userGroup.patchValue({ StoreId: this.storesList[0].Id });
      } else {
        this.alertService.errorAlert('Error al cargar la lista de Almacenes - ' + data.errorInfo.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });

  }

  get f() { return this.userGroup.controls; }

  onCompanyChange() {
    this.storesList = [];
    this.getStoresList(this.userGroup.controls.CompanyId.value);
  }

  chargeUserAsing() {
    this.blockUI.start('Cargando listas de usuarios...');
    this.uService.getUserList().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        this.userAssignsList = data.Users;
        if (this.userId > 0) this.chargeUserData(this.userId);
      } else {
        this.alertService.errorAlert('Error al cargar la lista de usuarios - ' + data.errorInfo.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  chargeUserData(Id: number) {
    const user = this.userAssignsList.find(x => x.Id === Id);
    if (!user) return;

    this.userGroup.reset({
      cUserName: user.UserId,
      cSAPUser: user.SAPUser,
      SAPPass: user.SAPPass,
      StoreId: user.StoreId,
      CompanyId: user.CompanyId,
      minDiscount: user.minDiscount,
      SlpCode: user.SlpCode,
      CenterCost: user.CenterCost,
      PriceListDef: user.PriceListDef,
      Active: user.Active ? "S" : "N",
      SuperUser: user.SuperUser ? "S" : "N",
      BranchOfficeId: user.BranchOfficeId,
      // UserConnSer: user.ConnectionServiceId
    });     
  }

  onSubmitCreate() {
    this.isSubmitted = true;
    if (this.userGroup.invalid) return;
    this.blockUI.start('Registrando Datos...');
    this.uService.sendUserInfo(this.userGroup, this.userGroup.controls.cUserName.value, this.userId);
  }

  GetPriceList() {
    this.blockUI.start('Obteniendo Listas de precios, Espere Por Favor...');
    this.itemService.GetPriceList()
      .subscribe((data: any) => {
        if (data.result) {
          this.PriceList = data.priceList;
          this.userGroup.patchValue({ PriceListDef: this.PriceList[0].ListNum });
        } else {
          this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
        }
        this.blockUI.stop();
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  GetSalesPersonList() {
    this.blockUI.start('Obteniendo vendedores, Espere Por Favor...');
    this.smService.getSalesMan()
      .subscribe((data: any) => {
        if (data.result) {
          this.SalesPersonList = data.salesManList;
          this.userGroup.patchValue({ SlpCode: this.SalesPersonList[0].SlpCode });
        } else {
          this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
        }
        this.blockUI.stop();
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  getBranchOfficess() {
    this.branchOffices = [];
    this.blockUI.start('Procesando...');

    this.branchOfficeService.GetBrachOfficess()
      .pipe(first())
      .subscribe(response => {
        this.blockUI.stop();

        if (response.result) this.branchOffices = response.BranchOfficess;
        else this.alertService.errorAlert(response.errorInfo.Message);
      }, err => {
        this.blockUI.stop();
        this.alertService.errorAlert(err);
      });
  }

  getUsersConnService() {
    this.UsersConnService = [];
    this.blockUI.start('Procesando...');

    this.uService.GetUsersConnService()
      .pipe(first())
      .subscribe(response => {
        this.blockUI.stop();

        if (response.result) this.UsersConnService = response.SapUsers;
        else this.alertService.errorAlert(response.errorInfo.Message);
      }, err => {
        this.blockUI.stop();
        this.alertService.errorAlert(err);
      });
  }

  cancel() {
    this.router.navigate(['/AssignsUsers']);
  }

}
