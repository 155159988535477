import { Component, OnInit } from '@angular/core';
import { IItemModel, IBarcode } from 'src/app/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';
import { TaxService, ItemService, AlertService, AuthenticationService, PermsService } from 'src/app/services';
import { Observable, Subscription, from } from 'rxjs';
import { debounceTime, map, isEmpty, filter, distinctUntilChanged, tap } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IPrice } from 'src/app/models/i-price';
import { listenToElementOutputs } from '@angular/core/src/view/element';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {
  // Native variables
  isUpdating: boolean; // Indica si se va a crear o actualizar un item
  isReading: boolean; // Variable para bloquear la interfaz
  isUpdatingBarcode: boolean; // Variable para ver si se actualiza el codigo de barras
  isSearchingBox: boolean; // Variable para bloquear la caja de busqueda en la edicion
  isOnUpdateTab: boolean; // Controla las tabs de navegacion
  permisos = true; // Para saber si el usuario puede acceder a la pagina
  searchInput: string; // Contiene la busqueda del usuario para el typeahead
  barcode_input: string; // Campo para editar un codigo de barras
  createTitle = 'Agregar un producto'; // Titulo de la pestana del navbar en crear
  editTitle = 'Búsqueda y edición de productos'; // Titulo de la pestana del navbar de la edicion
  closeResult = ''; // Almacena el valor de retorno de la modal al cerrarla
  barcodeModal: string;
  barcodeDescriptionModal: string;
  currentIndex: number; // Guarda el codigo de barras actual
  title = 'Items'; // Titulo de la pagina
  unitPriceBox: string;
  // Object variables
  @BlockUI() blockUI: NgBlockUI; // Usado para bloquear la interfaz de usuario
  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  barcodeForm = new FormGroup({
    barcodeModal: new FormControl(''),
    barcodeDescriptionModal: new FormControl('')
  });

  ItemInfo: FormControl = new FormControl();

  itemsTypeaheadList: string[] = []; // lista de la concatenacion del Código con el nombre del item
  
  searchItemCode = (text$: Observable<string>) =>
  text$.pipe(
    //debounceTime(5),
    distinctUntilChanged(),
    map(term => term.length < 1 ? []
      : this.itemsTypeaheadList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  
  
  addItems(item) {
    item.preventDefault();
    const ITEM_CODE = item.item.split(' ')[0];
    this.getItem(ITEM_CODE);
    this.ItemInfo.reset();
   }

  itemForm = new FormGroup({
    code: new FormControl(''),
    name: new FormControl(''),
    barcode: new FormControl(''),
    unitPrice: new FormControl(''),
    discount: new FormControl(''),
    firmName: new FormControl(''),
    tax: new FormControl(''),
    frozen: new FormControl(''),
    unitPriceSelectBox: new FormControl(''),
    barcode_input: new FormControl('')
  }); // Es el formulario para el item
  itemsList: IItemModel[] = []; // Contiene la lista de todos los items
  taxesList: { TaxCode: string, TaxRate: number }[]; // Lista usada para guardar el codigo y valor del impuesto
  priceList: IPrice[]; // Contiene la lista de precios del sistema, debe ser obtenida por request, por ahora se va quemada para mostrar funcionalidad
  priceListOld: IPrice[]; // Respaldo de la lista editada
  priceListSystem: IPrice[]; // Lista de precios del sistema por defecto
  barcodeList: IBarcode[] = []; // Contiene la lista de los codigo de barra
  model: IItemModel; // Representa el modelo para el item
  formatter = (x: { ItemName: string }) => x.ItemName; // Usado para formatear la salida del typeahead

  constructor(private modalService: NgbModal, private taxesService: TaxService, private itemsService: ItemService, private alertService: AlertService, private authenticationService: AuthenticationService, private sPerm: PermsService) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.isOnUpdateTab = true;
    this.isUpdating = true;
  }

  ngOnInit() {
    this.checkPermits(); // Verifica si el usuario tiene permisos para acceder a la pagina
    this.itemsTypeaheadList.length = 0;
    this.blockUI.start('Obteniendo los items, espere por favor...');
    this.itemForm.disable();
    this.isReading = true;    
    this.taxesService.GetTaxes().subscribe(response => {
      this.taxesList = response.Taxes;
    }); // Consume los impuesto y los setea a dicha lista desde el api
    this.isSearchingBox = false;
    this.itemsService.GetPriceList().subscribe(response => {
      this.priceListSystem = response.priceList;
    }); // Contiene la lista de precios que hay en el sistema
    this.currentIndex = 0;
    this.itemsService.GetItems().subscribe(response => { // Obtiene los items desde el api y mapea a objetos ya que el api solo retorna listas
      this.itemsTypeaheadList = response.ItemList.ItemCompleteName;
      for (let c = 0; c < response.ItemList.ItemCompleteName.length; c++) {
        const name = response.ItemList.ItemCompleteName[c];
        this.itemsList.push({
          PriceListId: this.priceList,
          ItemCode: response.ItemList.ItemCode[c],
          ItemName: response.ItemList.ItemCompleteName[c],
          ItemBarCode: name.substring(name.lastIndexOf('COD. ') + 1, name.lastIndexOf(' COD.')),
          Frozen: true,
          TaxCode: '',
          TaxRate: '',
          BarCode: '',
          Discount: 0,
          ForeingName: '',
          UnitPrice: 0,
          PriceList: [],
          Barcodes: []
        });
      }
      this.blockUI.stop();
    });
  }
  // Funcion para el typeahead
  onTypeaheadClick(event: { TaxCode: string, TaxRate: number }): void {
    // if (event.constructor.name.toString() === 'Object') {
    //   this.getItem();
    //   this.isUpdating = true;
    //   this.isReading = false;
    //   this.itemForm.enable();
    // }

    // if (!event) { // Controla la limpieza del campo de busqueda
    //   this.isUpdating = false;
    //   this.isReading = true;
    //   this.itemForm.disable();
    //   this.initVariables();
    // }
  }
  // Es el filtro del typeahead
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(20),
      map(term => term === '' ? []
        : this.itemsList.filter(v =>
          v.ItemName.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0,
            10))
    )
  // Inicializa los campos del formulario para la creacion de este y obtiene el codigo del proximo item
  createItem(): void {
    this.initVariables();
    this.itemForm.enable();
    this.isUpdating = false;
    this.isReading = false;
    this.isSearchingBox = true;
    this.itemsService.getNextCode().subscribe(response => {
      this.itemForm.patchValue({
        code: response.Item.ItemCode,
        frozen: 'Activo'
      });
    });
    this.itemsService.GetPriceList().subscribe(response => {
      this.priceListOld = response.priceList;
      this.priceList = response.priceList;
    });
    this.itemForm.patchValue({
      unitPriceSelectBox: 0,
      unitPrice: 0
    });
  }
  // Hace toggle al editar
  onTabChange(event: any): void {
    this.isUpdating = true;
    if (!(this.isOnUpdateTab = event.nextId === 'edition_item_tab')) {
      this.itemForm.patchValue({
        code: ''
      });
      this.barcodeList = [];
      this.itemForm.enable();
      this.isUpdating = false;
      this.isReading = false;
      this.itemsService.GetPriceList().subscribe(response => {
        if (response.result) {
          this.priceListOld = [];
          this.priceList = [];
          for (let c = 0; c < response.priceList.length; c++) {
            console.log('2')
            this.priceListOld.push({
              ListName: response.priceList[c].ListName,
              ListNum: response.priceList[c].ListNum,
              Price: 0,
            });
          }
          this.priceList = this.priceListOld;
        }
        // console.log(response.priceList);
      });
    } else {
      this.isReading = true;
      if (this.model) {
        if (this.model.ItemCode !== '') {
          this.itemForm.patchValue({
            code: this.model.ItemCode
          });
          this.barcodeForm.patchValue({
            barcodeDescriptionModal: '',
            barcodeModal: ''
          });
          this.barcodeList = this.model.Barcodes;
        }
      }
      this.initVariables();
    }
  }
  // Obtiene un item desde el api, el cual va a ser editado
  getItem(_itemCode: string): void {
    this.blockUI.start('Obteniendo la información del item');
    this.priceList = [];
    this.priceListOld = [];
    this.itemsService.GetBarcodesByItem(_itemCode).subscribe(response => {
      this.barcodeList = response.Item === null ? [] : response.Item.Barcodes;
      // this.model.Barcodes = response.Item === null ? [] : response.Item.Barcodes;
    });
    this.itemsService.GetItemByItemCode(_itemCode, 1).subscribe(
      response => {
        const name = response.Item.ItemName;
        const prefix = 'COD. ';
        const sufix = ' COD.';
        this.isUpdating = true;
        this.isReading = false;
        this.itemForm.enable();
        this.itemsService.getItemPriceList(response.Item.ItemCode).subscribe(res => {
          if (res.result) {
            this.priceList = res.Item.PriceList;
            // this.priceListOld = res.Item.PriceList;
            res.Item.PriceList.forEach((x: IPrice) => this.priceListOld.push({
              ListName: x.ListName,
              ListNum: x.ListNum,
              Price: x.Price
            }));
            this.itemForm.patchValue({
              unitPrice: this.priceList[0].Price
            });
          }
          this.blockUI.stop();
        });
        // Inicializa el formulario con los datos del item
        this.itemForm.setValue({
          code: response.Item.ItemCode,
          name: response.Item.ItemName,
          barcode: '',
          // barcode: name.match(new RegExp(prefix + '(.*)' + sufix))[1],
          unitPrice: response.Item.UnitPrice,
          tax: response.Item.TaxCode,
          frozen: !response.Item.Frozen ? 'Activo' : 'Inactivo',
          discount: response.Item.Discount,
          firmName: response.Item.ForeingName,
          unitPriceSelectBox: 0,
          barcode_input: ''
        });
      }
    );
  }
  // Updating price
  updatePrice(event: any): void {
    this.priceList[this.currentIndex].Price = this.itemForm.value.unitPrice;
    //this.alertService.infoInfoAlert('Presione guardar para actualizar la información');
  }

  updateIndex(event: any): void {
    this.currentIndex = +event;
    this.itemForm.patchValue({
      unitPrice: this.priceList[this.currentIndex].Price
    });
  }
  // Updating price
  updateBarcode(event: any): void {
    for (let c = 0; c < this.barcodeList.length; c++) {
      if (this.barcodeList[c].BcdCode === event) {
        this.currentIndex = c;
        this.itemForm.patchValue({
          barcode_input: event
        });
      }
    }
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  // Actualiza el valor de cada barcode
  updateBarcodeList(): void {
    if (this.currentIndex !== -1) {
      this.barcodeList[this.currentIndex].BcdCode = this.itemForm.value.barcode_input;
      this.itemForm.patchValue({
        barcode: this.itemForm.value.barcode_input
      });
    } else {

    }
  }
  // Agrega el codigo de barras de manera preeliminar
  addBarcode(): void {
    this.barcodeList.push({
      BcdCode: this.barcodeForm.value.barcodeModal,
      BcdName: this.barcodeForm.value.barcodeDescriptionModal,
      BcdEntry: -1,
      UomEntry: -1
    });
    let lookup = new Set();
    this.barcodeList = this.barcodeList.filter(obj => !lookup.has(obj['BcdCode']) && lookup.add(obj['BcdCode']));
    if (this.isUpdatingBarcode) {
      for (let c = 0; c < this.barcodeList.length; c++) {
        if (this.barcodeList[c].BcdCode === this.barcodeForm.value.barcodeModal) {
          this.barcodeList[c].BcdName = this.barcodeForm.value.barcodeDescriptionModal;
        }
      }
    }
    this.barcodeForm.patchValue({
      barcodeModal: '',
      barcodeDescriptionModal: ''
    });

    this.alertService.successInfoAlert(`Código ${this.isUpdatingBarcode ? 'actualizado' : ' agregado'}. Presione guardar para confirmar los cambios`);
    this.isUpdatingBarcode = false;
    // this.barcodeForm.value.barcodeModal.enable();
  }
  updateBarcodeDesctiption(_index: number): void {
    this.isUpdatingBarcode = true;
    // this.barcodeForm.value.barcodeModal.disabled();
    this.barcodeForm.patchValue({
      barcodeModal: this.barcodeList[_index].BcdCode,
      barcodeDescriptionModal: this.barcodeList[_index].BcdName
    });
  }

  clearBarcode(): void {
    this.barcodeForm.patchValue({
      barcodeModal: '',
      barcodeDescriptionModal: ''
    });
    this.isUpdating = false;
  }
  // Controla la limpieza del campo de busqueda
  resetSelection(event: any): void {
    if (!event) { this.currentIndex = -1; }
  }
  // Permite alternar entre la creacion o edicion de un item
  saveItem(): void {
    const mItem: IItemModel = {
      ItemCode: this.itemForm.value.code,
      ItemBarCode: this.itemForm.value.barcode,
      ItemName: this.itemForm.value.name,
      PriceListId: this.priceList,
      TaxCode: this.itemForm.value.tax,
      TaxRate: '',
      BarCode: this.itemForm.value.barcode,
      Frozen: !(this.itemForm.value.frozen === 'Activo'),
      Discount: this.itemForm.value.discount,
      ForeingName: this.itemForm.value.firmName,
      UnitPrice: this.itemForm.value.unitPrice,
      PriceList: this.priceList,
      Barcodes: this.barcodeList
    };
    console.log(mItem);

    if (this.isUpdating) { // Verifica si se va a actualizar el item
      this.blockUI.start('Actualizando el item, Espere Por Favor...');
      this.itemsService.UpdateItem(mItem).subscribe(response => {
        this.blockUI.stop();
        if (response.result) {
          this.alertService.successAlert('Se actualizado correctamente el item');
          this.itemsService.GetItems().subscribe(response => {
            for (let c = 0; c < response.ItemList.ItemCompleteName.length; c++) {
              const name = response.ItemList.ItemCompleteName[c];
              this.itemsTypeaheadList = response.ItemList.ItemCompleteName;
              this.itemsList.push({
                PriceListId: this.priceList,
                ItemCode: response.ItemList.ItemCode[c],
                ItemName: response.ItemList.ItemCompleteName[c],
                ItemBarCode: name.substring(name.lastIndexOf('COD. ') + 1, name.lastIndexOf(' COD.')),
                Frozen: true,
                TaxCode: '',
                TaxRate: '',
                BarCode: '',
                Discount: 0,
                ForeingName: '',
                UnitPrice: 0,
                PriceList: [],
                Barcodes: []
              });
            }
          });
        } else {
          this.alertService.errorAlert(`Error al actualizar el producto ${response.errorInfo.Message}`);
        }
      });
    } else {
      this.blockUI.start('Creando el item, Espere Por Favor...');
      this.itemsService.CrateItem(mItem).subscribe(response => {
        this.blockUI.stop();
        if (response.result) {
          this.alertService.successAlert('Se ha creado correctamente el producto');
          this.initVariables();
        } else {
          this.alertService.errorAlert(`Error al crear el producto ${response.errorInfo.Message}`);
        }
      });
    }
  }
  // Inicializa las variables a un estado inicial
  initVariables(): void {
    this.itemsList = [];
    this.priceList = [];
    this.priceListOld = [];
    this.barcodeList = [];
    this.itemForm.setValue({
      code: '',
      name: '',
      barcode: '',
      unitPrice: '',
      tax: 0,
      frozen: '',
      discount: '',
      firmName: '',
      unitPriceSelectBox: '',
      barcode_input: ''
    });

    this.itemsService.GetItems().subscribe(response => {
      for (let c = 0; c < response.ItemList.ItemCompleteName.length; c++) {
        const name = response.ItemList.ItemCompleteName[c];
        this.itemsTypeaheadList = response.ItemList.ItemCompleteName;
        this.itemsList.push({
          PriceListId: this.priceList,
          ItemCode: response.ItemList.ItemCode[c],
          ItemName: response.ItemList.ItemCompleteName[c],
          ItemBarCode: name.substring(name.lastIndexOf('COD. ') + 1, name.lastIndexOf(' COD.')),
          Frozen: true,
          TaxCode: '',
          TaxRate: '',
          BarCode: '',
          Discount: 0,
          ForeingName: '',
          UnitPrice: 0,
          PriceList: [],
          Barcodes: []
        });
      }
    });
    this.model = null;
    this.isSearchingBox = false;
    this.isReading = true;
    if (this.isOnUpdateTab) {
      this.itemForm.disable();
      this.isUpdating = true;
    } else {
      this.priceListSystem = [];
      this.itemsService.GetPriceList().subscribe(response => {
        this.priceListOld = [];
        this.priceList = [];
        for (let c = 0; c < response.priceList.length; c++) {
          this.priceListOld.push({
            ListName: response.priceList[c].ListName,
            ListNum: response.priceList[c].ListNum,
            Price: 0,
          });

          this.priceList.push({
            ListName: response.priceList[c].ListName,
            ListNum: response.priceList[c].ListNum,
            Price: 0,
          });
        }
      }); // Contiene la lista de precios que hay en el sistema
      this.isOnUpdateTab = false;
      this.itemForm.enable();
    }
  }
  // Verifica si el usuario tiene permisos para acceder a la pagina
  checkPermits() {
    this.sPerm.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        data.perms.forEach(Perm => {
          if (Perm.Name === 'V_Inventory_Entry') {
            this.permisos = Perm.Active;
          }
        });
      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }
}

