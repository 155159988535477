import { Injectable } from '@angular/core';
import { AppConstants, BaseResponse, Report, Parameter } from '../models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ParametersResponse, PrintResponse, ReportUsersResponse } from '../models/responses';
import { Email } from '../models/rpt-manager/i-email';

@Injectable({
  providedIn: 'root'
})
export class RptManagerService {

  constructor(private http: HttpClient) { }

  SaveReportFile(report: File,_companyId: number, _appKey: number) {
    const formData = new FormData();
    formData.append('report', report);

    return this.http.post<BaseResponse>(
      `${AppConstants.RPTMANAGER_URL}api/Reports/SaveReportFile?companyKey=${_companyId}&appKey=${_appKey}`,//#VALORPRUEBA
      formData
    );
  }

  HandlePostOrPutReport(report: Report) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    report.ApplicationId = 3; //#VALORPRUEBA

    if (report.Id > 0) {
      return this.http.put<any>(
        `${AppConstants.RPTMANAGER_URL}api/Reports/PutReport`,
        report,
        { headers }
      );
    } else {
      return this.http.post<any>(
        `${AppConstants.RPTMANAGER_URL}api/Reports/PostReport`,
        report,
        { headers }
      );
    }
  }

  GetReports(_companyId: number, _appKey: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<any>(
      `${AppConstants.RPTMANAGER_URL}api/Reports/GetReports?companyKey=${_companyId}&appKey=${_appKey}`,
      { headers }
    );
  }

  GetParameters(reportId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<any>(
      `${AppConstants.RPTMANAGER_URL}api/Parameter/GetParameters?reportId=${reportId}`,
      { headers }
    );
  }

  PrintReport(parameters: Parameter[], reportId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.post<any>(
      `${AppConstants.RPTMANAGER_URL}api/Reports/PrintReport?reportId=${reportId}`,
      parameters,
      { headers }
    );
  }

  SendEmail(emailInfo: Email, reportId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.post<any>(
      `${AppConstants.RPTMANAGER_URL}api/Reports/SendEmail?reportId=${reportId}`,
      emailInfo,
      { headers }
    );
  }

  DownloadFile(reportId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<any>(
      `${AppConstants.RPTMANAGER_URL}api/Reports/DownloadFile?reportId=${reportId}`,
      { headers }
    );
  }

  GetReportUsers() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<any>(
      `${AppConstants.RPTMANAGER_URL}api/ReportUser/GetReportUsers`,
      { headers }
    );
  }
}
