import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// MODELOS
import { AppConstants, IItemModel } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { ILine } from '../models/i-line';
import { Observable } from 'rxjs';
import { IIPriceResponse } from '../models/responses';

// PIPES

@Injectable({
  providedIn: 'root'
})
export class ItemService {

 // readonly apiUrl = 'http://localhost:50001/api/';

 hasLines: boolean;
  private lines: ILine[];

  constructor( private http: HttpClient,
               private storage: StorageService ) {
  }

  // funcion para obtener los items desde SAP
  // no recibe parametros
  GetItems() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetItemNames?whsCode=${token.WhCode}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }

  GetPayTerms() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetPayTermsList`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  GetPriceList() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetPriceList`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
  return this.http.get<any>(url, { headers });
  }

  GetPriceListDefault(_cardCode: string): Observable<IIPriceResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetDefaultPriceList?cardCode=${_cardCode}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IIPriceResponse>(url, { headers });
  }

  // funcion para obtener la informacion de un producto en  SAP
  // recibe como parametro el codigo del producto
  GetItemByItemCode(itemCode: string, priceList: number, _cardCode?: string) {
    const WHS_CODE = JSON.parse(this.storage.getCurrentSession()).WhCode;
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetInfoItem?ItemCode=${itemCode}&priceList=${priceList}&cardCode=${_cardCode}&whsCode=${WHS_CODE}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }

  // funcion para obtener la informacion de los disponibles de un item en los almacenes
  // recibe como parametro el codigo del producto
  GetWHAvailableItem(itemCode: string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetWHAvailableItem?ItemCode=${itemCode}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  // funcion para obtener la informacion de las series del item en un almacen
  // recibe como parametro el codigo del producto
  GetSeriesByItem(itemCode: string, WhsCode: string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetSeriesByItem?ItemCode=${itemCode}&WhsCode=${WhsCode}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  // Crea un item
  CrateItem(_item: IItemModel){
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/create`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<any>(url, _item, { headers });
  }
  // Edita un modelo
  UpdateItem(_item: IItemModel) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/updateItem`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<any>(url, _item, { headers });
  }
  // Obtiene el siguiente codigo para crear un item
  getNextCode() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/getNextCode`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }

  getItemPriceList(_itemCode: string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetItemPriceList?_itemCode=${_itemCode}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }

  GetBarcodesByItem(_itemCode: string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetBarcodesByItem?_itemCode=${_itemCode}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }

  GetItemsWithDetail() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Items/GetItemsWithDetail`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }

  GetLines(data: any): ILine[] {
    return this.lines;
  }

  UpdateLines(lines: ILine[]): void {
    this.lines = lines;
  }

}
