import { Component, OnInit, ElementRef, ViewChild, } from '@angular/core';
import { AlertService } from '../../../services/alert.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemService, PermsService, AuthenticationService } from 'src/app/services';
import { BusinessPartnerService } from '../../../services/business-partner.service';
import { debounceTime, map, distinctUntilChanged, filter } from 'rxjs/operators';
import { IItemModel } from '../../../models/i-item';
import { GoodsReciptService } from '../../../services/goods-recipt.service';
import { StoreService } from '../../../services/store.service';
import { ILine } from '../../../models/i-line';
import { TaxService } from '../../../services/tax.service';
import swal from 'sweetalert2';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-inventory-return',
  templateUrl: './inventory-return.component.html',
  styleUrls: ['./inventory-return.component.scss']
})
export class InventoryReturnComponent implements OnInit {

  @ViewChild('scrollMe') private myScrollContainer: ElementRef; // Se usa para que la tabla haga autoscroll
  @ViewChild('input1') inputEl: ElementRef; // Lo uso para mandarle el autofocus cuando se escanea el codigo de barras
  permisos = true; // Comprueba los permisos del usuario
  lines: ILine[]; // Representa la linea del producto que se ingresa
  itemsList: IItemModel[]; // Contiene el nombre de los productos, preformateados desde el api y el codigo de estos
  taxesList: any; // Contiene la lista de impuestos registrados en el sistema
  businessParters: any; // Contiene la lista de todos los proveedores
  @BlockUI() blockUI: NgBlockUI; // Usado para bloquear la interfaz de usuario
  currentUser: any; // variable para almacenar el usuario actual
  totalLines: number; // Guarda la sumatoria de todas las linea con (precio unitario * cantidad)
  itemsTypeaheadList: string[] = []; // lista de la concatenacion del Código con el nombre del item
  item: IItemModel; // Model para el typeahead, cuando se lecciona un item de la lista este lo almacena
  UnitPrice: number;
  Quantity: number;
  SubTotal: number; // Guarda
  WareHouse: string; // Guarda el id del almacen del dropdown global
  Stores: any; // Guarda todos los almacenes de la empresa
  DiscountTotal: number;
  TaxesTotal: number;
  USTotal: number; // Total en dolares
  CRCTotal: number; // Total en colones
  barcodeModel: any; // Usando para sacar el codigo de barras de un producto, de la lista de codigos que este tiene
  supplierModel: any; // Contiene el proveedor seleccionado
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  numAtCard: string; // Numero para la devolucion
  comments: string; // Comentarios para la nota de credito
  lockedButtton = false;
  ItemInfo: FormControl = new FormControl();

  searchItemCode = (text$: Observable<string>) =>
  text$.pipe(
    //debounceTime(5),
    distinctUntilChanged(),
    map(term => term.length < 1 ? []
      : this.itemsTypeaheadList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )
  
  fItemName = (x: { ItemName: string }) => x.ItemName; // Formateador para el nombre de los productos
  sItemName = (text$: Observable<string>) => text$.pipe( // Busca en el nombre de item la coincidencia por codigo de barras, nombre, codigo item
    debounceTime(5),
    distinctUntilChanged(),
    map(term => term === '' ? []
      : this.itemsList.filter(v => v.ItemName.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 3)))

  supplierNameformatter = (x: { CardName: string }) => x.CardName; // Formateador para el nombre de los proveedores
  supplierSearchName = (text$: Observable<string>) => text$.pipe( // Busca en el nombre del proveedor por cedula, codigo, nombre
    debounceTime(5),
    map(term => term === '' ? []
      : this.businessParters.filter(v => v.CardName.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))

  constructor(private modalService: NgbModal,
    private itemsService: ItemService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private sPerm: PermsService,
    private businessPartnerService: BusinessPartnerService,
    private goodsReciptService: GoodsReciptService,
    private storeService: StoreService,
    private taxesService: TaxService) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.lockedButtton = false;
  }

  ngOnInit() {
    this.blockUI.start();
    this.InitVariables();
    this.blockUI.start('Obteniendo información, espere por favor...');
    // Carga la lista de proveedores y la formatea para poder buscar codigo en este
    this.businessPartnerService.GetSuppliers().subscribe(response => {
      this.businessParters = response.BPS;
      for (let c = 0; c < this.businessParters.length; c++) {
        const bp = this.businessParters[c];
        this.businessParters[c].CardName = `${bp.CardCode} - ${bp.CardName} - ${bp.Cedula}`;
      }
    });
    // Obtiene la lista de almances de la compania, este lo hice nuevo porque el que estaba llamaba otro metodo, pero sigue exisitendo
    this.storeService.getStoresv2().subscribe(response => {
      this.Stores = response.Stores;
      this.WareHouse = this.Stores[0].StoreCode;
    });
    // Obtiene la lista de impuestos registrados en el sistema
    this.taxesService.GetTaxes().subscribe(response => {
      this.taxesList = response.Taxes;
    }); // Consume los impuesto y los setea a dicha lista desde el api

    this.checkPermits(); // Verifica si el usuario tiene permisos para acceder a la pagina
    // Obtiene los items para ser usados en el typeahead
    this.itemsService.GetItems().subscribe(response => {
      this.itemsList = [];
      this.itemsTypeaheadList = response.ItemList.ItemCompleteName;
      for (let c = 0; c < response.ItemList.ItemCompleteName.length; c++) {
        const NAME = response.ItemList.ItemCompleteName[c];
        const ITEM_CODE = response.ItemList.ItemCode[c];
        const tmpItem = {} as IItemModel;
        tmpItem.ItemName = NAME;
        tmpItem.ItemCode = ITEM_CODE;
        this.itemsList.push(tmpItem);
      }
      this.blockUI.stop();
    });

    if (this.itemsService.hasLines) {
      this.lines = this.itemsService.GetLines(this);
      this.recalculate();
    } else {
      console.log(this.itemsService.GetLines(this));
    }
    this.lockedButtton = false;
  }

  addItems(_item: any): void {
    const ITEM_CODE = _item.item.split(' ')[0];
    this.onThClick(ITEM_CODE);
    _item.preventDefault();
    this.ItemInfo.reset();
  }
  // Funcion que recalcula el precio total, subtotal, descuentos, impuestos, sobre la lista de lineas
  recalculate(): void {
    this.totalLines = this.lines.reduce((a, b) => a + ((b.UnitPrice * b.Quantity) || 0), 0);
    this.TaxesTotal = this.lines.reduce((a, b) => a + ((b.UnitPrice * b.Quantity) * (b.Tax_Rate / 100) || 0), 0);
    this.DiscountTotal = this.lines.reduce((a, b) => a + ((b.UnitPrice * b.Quantity) * (b.Discount / 100) || 0), 0);
    this.CRCTotal = this.lines.reduce((a, b) => a +
      (((b.UnitPrice * b.Quantity) - ((b.UnitPrice * b.Quantity) * (b.Discount / 100))) +
        (((b.UnitPrice * b.Quantity) - ((b.UnitPrice * b.Quantity) * (b.Discount / 100))) * (b.Tax_Rate / 100)) || 0), 0);
    if (this.lines.length > 0) {
      this.itemsService.hasLines = true;
      this.itemsService.UpdateLines(this.lines);
    } else {
      this.itemsService.hasLines = false;
      this.itemsService.UpdateLines([]);
    }
  }
  // Saca un item de la lista de lineas basado en su indice
  removeItem(index: number) {
    this.lines = this.lines.filter((x, i) => i !== index);
    this.recalculate();
  }
  // Evento para actualizar todos los almacenes con el dropdown global
  onSelectBoxChange(event: any): void {
    for (let c = 0; c < this.lines.length; c++) {
      this.lines[c].WareHouse = event.target.value;
    }
  }
  // Evento para actualizar el almacen de cada linea, basado en su indice
  onSelectBoxLineChange(event: any, index: number): void {
    this.lines[index].WareHouse = event.target.value;
  }
  // Evento para actulizar el impuesto de cada producto, basado en su indice
  onSelectBoxLineChangeTax(event: any, index: number): void {
    const TAX_CODE = event.target.value;
    const TAX = this.taxesList.filter(x => x.TaxCode === TAX_CODE)[0];
    this.lines[index].TaxCode = TAX.TaxCode;
    this.lines[index].Tax_Rate = +TAX.TaxRate;
    this.recalculate();
    // console.log(event.target.value, this.taxesList.filter(x=> {x.TaxCode === event.target.value}));
  }
  // Evento para seleccionar un item del type ahead y cargarlo en la lista de lineas
  onThClick(_itemCode: string): void {
      this.blockUI.start('Obteniendo información del item');
      this.itemsService.GetItemByItemCode(_itemCode, 1).subscribe(response => {
        if (response.result) {
          const LINE = {} as ILine;
          LINE.BarCode = response.Item.BarCode;
          LINE.ItemCode = response.Item.ItemCode;
          LINE.ItemName = response.Item.ItemName;
          LINE.Quantity = this.Quantity;
          LINE.TaxCode = response.Item.TaxCode;
          LINE.UnitPrice = response.Item.UnitPrice;
          LINE.Discount = response.Item.Discount;
          LINE.Tax_Rate = response.Item.TaxRate;
          LINE.WareHouse = this.WareHouse;
          this.lines.push(LINE);
          this.Quantity = 1;
          this.item = {} as IItemModel;
          setTimeout(() => this.inputEl.nativeElement.focus());
          this.recalculate();
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(response.Message);
        }
      });
  }
  // Evento para actualizar los diferentes datos de un item en la linea, segun su indice
  onKeyUp(i: number, event: any, field: string): void {
    if (event.target.value !== '') {
      switch (+field) {
        case 0:
          this.lines[i].Quantity = +event.target.value;
          break;
        case 1:
          this.lines[i].UnitPrice = +event.target.value;
          break;
        case 2:
          this.lines[i].Discount = +event.target.value;
          break;
        case 4:
          this.lines[i].UnitPrice = +event.target.value / this.lines[i].Quantity;
          break;
      }
      this.recalculate();
    }
  }
  // Crea la devolucion del inventario
  createGoodsReciptReturn(): void {
    if (this.lines.length === 0) {
      this.alertService.infoAlert('Debe ingregar un artículo al menos');
      return;
    }

    if (!this.supplierModel) {
      this.alertService.infoAlert('Debe Seleccionar el proveedor');
      return;
    }

    
    if (this.lockedButtton) {
      this.alertService.infoAlert('Ya se ha generado la entrada, por favor presione borrar campos para agregar una nueva entrada');
      return;
    }

    this.lockedButtton = true;

    this.blockUI.start('Realizando la devolucion de mercadería');
    this.goodsReciptService.CreateGoodsReciptReturn(
      this.lines, this.supplierModel.CardCode, this.supplierModel.CardName, this.supplierModel.Cedula, this.comments, this.numAtCard
    ).subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.alertService.successAlert('Se ha realizado correctamente la devolución de mercadería');
      } else {
        this.alertService.errorAlert('Error al realizar la devolución de mercadería: ' + response.errorInfo.Message);
      }
    });
  }

  // Resetea la intefaz usado por el boton de borrar campos
  resetGUI() {
    this.blockUI.start();
    this.InitVariables();
    this.blockUI.stop();
  }

  // Incializa las variables a un estado por defecto
  InitVariables() {
    this.lines = [];
    this.CRCTotal = 0.0;
    this.Quantity = 1;
    this.SubTotal = 0.0;
    this.totalLines = 0.0;
    this.DiscountTotal = 0.0;
    this.TaxesTotal = 0.0;
    this.USTotal = 0.0;
    this.CRCTotal = 0.0;
    this.itemsService.UpdateLines([]);
    this.itemsService.hasLines = false;
    this.comments = '';
    this.numAtCard = '';
    this.lockedButtton = false;
  }
  // Verifica si el usuario tiene permisos para acceder a la pagina
  checkPermits() {
    this.sPerm.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        data.perms.forEach(Perm => {
          if (Perm.Name === 'V_Comp') {
            this.permisos = Perm.Active;
          }
        });
      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }

}
