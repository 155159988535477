import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

// MODELOS
import { AppConstants, IDocumentLine, ISaleOrder, IFe, BaseResponse, IPaymentSearch } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import {IInvoiceResponse, IPaymentReponse, ICreateInvoiceFromSOResponse, PaymentSapResponse} from '../models/responses';
import { SOAndSQActions } from '../enum/enum';
import { IResponse } from '../models/i-api-response';
import { IQuotDocument, SLSaleOrderToARInvoice } from '../models/i-document';
import {IAutorizeOrRejectsOrder, SLDocument} from '../models/i-sl-document-model';
import { UDFModel2 } from '../models/i-udf';
import { ICreditMemo, IFieldCodeFerecence } from '../models/i-credit-memo';

// PIPES

@Injectable({
  providedIn: 'root'
})
export class DocumentService {



  constructor(private http: HttpClient,
    private storage: StorageService) {

  }


  CreateSaleOrder(_saleOrder : SLDocument): Observable<IResponse<SLDocument>>{
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/SLCreateSaleOrder`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<SLDocument>>(url,
      _saleOrder,
      { headers }
    );
  }
  // funcion para enviar la informacion de una orden de venta a SAP
  // recibe como parametro el formulario del cliente, la lista de items
  CreateSaleOrder2(header: FormGroup, itemsList: any, FeData: FormGroup, Comentario: string, baseLines: IDocumentLine[], _isFeDocument: string, _uniqueInvId: string) {

    const linesList: any[] = [];
    itemsList.forEach(element => {
      if (element.ItemCode !== '') {
        element.ItemCode = element.ItemCode.split('-')[0].trim();
        linesList.push(element);
      }
    });

    const SaleOrder = {
      'DocEntry': this.storage.GetDocEntry(),
      'BaseEntry': +localStorage.getItem("SOAndSQAction") === SOAndSQActions.DuplicateSaleOrder ? 0 :  this.storage.GetDocEntry(),
      'SaleOrderLinesList': linesList,
      'CardCode': header.value.cardCode,
      'CardName': header.value.cardName,
      'Currency': header.value.currency,
      'PayTerms': header.value.PayTerms,
      'SlpCode': header.value.SlpList,
      // 'Comment': Comentario,
      'Comment': header.value.Comment,
      'DocumentType': _isFeDocument,
      'U_FacturaVencida': header.value.BillOverdue == '0' ? 'SI' : 'NO',
      'U_Facturacion': header.value.PayTerms === '0' ? 'Contado' : 'Credito',
      'U_NVT_Medio_Pago': header.value.PaymentMethod,
      "IdType": (FeData.value.IdType != null) ? FeData.value.IdType : 99,
      "Identification": FeData.value.Identification,
      "Email": FeData.value.Email,
      'U_ObservacionFE': FeData.value.ObservacionFE,
      'BaseLines': baseLines,
      'U_Almacen': this.storage.getBranchOffice().Code,
      'NumAtCard': header.value.NumAtCard === '' ? 0: header.value.NumAtCard,
      'CLVS_POS_UniqueInvId': _uniqueInvId
    };
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/CreateSaleOrder`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url,
      SaleOrder,
      { headers }
    );
  }

  UpdateSaleOrder(_saleOrder : SLDocument): Observable<IResponse<SLDocument>>{
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/SLUpdateSaleOrder`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<SLDocument>>(url,
      _saleOrder,
      { headers }
    );
  }

  // UpdateCreateSaleOrder(header: FormGroup, itemsList: any, FeData: FormGroup, Comentario: string, baseLines: IDocumentLine[]) {
  UpdateSaleOrder2(header: FormGroup, itemsList: any, FeData: FormGroup, Comentario: string, baseLines: IDocumentLine[], _isCashCustomer: string) {

    const linesList: any[] = [];
    itemsList.forEach(element => {
      if (element.ItemCode !== '') {
        element.ItemCode = element.ItemCode.split('-')[0].trim();
        linesList.push(element);
      }
    });
    //alert(FeData.value.IdType);
    // const SaleOrder = {
    //   'BaseEntry': this.storage.GetDocEntry(),
    //   'DocEntry': this.storage.GetDocEntry(),
    //   'SaleOrderLinesList': linesList,
    //   'CardCode': header.value.cardCode,
    //   'CardName': header.value.cardName,
    //   'Currency': header.value.currency,
    //   'PayTerms': header.value.PayTerms,
    //   'SlpCode': header.value.SlpList,
    //   'Comment': Comentario,
    //   "IdType": (FeData.value.IdType != null) ? FeData.value.IdType : 99,
    //   "Identification": FeData.value.Identification,
    //   "Email": FeData.value.Email,
    //   'BaseLines': baseLines
    // };
    const SaleOrder = {
      'DocEntry': this.storage.GetDocEntry(),
      'BaseEntry': this.storage.GetDocEntry(),
      'SaleOrderLinesList': linesList,
      'CardCode': header.value.cardCode,
      'CardName': header.value.cardName,
      'Currency': header.value.currency,
      'PayTerms': header.value.PayTerms,
      'SlpCode': header.value.SlpList,
      'Comment': header.value.Comment,
      // 'Comment': Comentario,
      'DocumentType':_isCashCustomer,
      'U_FacturaVencida': header.value.BillOverdue === '0' ? 'SI' : 'NO',
      'U_Facturacion': header.value.PayTerms === '0' ? 'Contado' : 'Credito',
      'U_NVT_Medio_Pago': header.value.PaymentMethod,
      'U_Almacen': this.storage.getBranchOffice().Code,
      "IdType": (FeData.value.IdType != null) ? FeData.value.IdType : 99,
      "Identification": FeData.value.Identification,
      "Email": FeData.value.Email,
      'U_ObservacionFE': FeData.value.ObservacionFE,
      'BaseLines': baseLines,
      'NumAtCard': header.value.NumAtCard === '' ? 0: header.value.NumAtCard
    };

    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/UpdateSaleOrder`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url,
      SaleOrder,
      { headers }
    );
  }

  CancelSaleOrder(_docEntry: number): Observable<BaseResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/CancelSaleOrder`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<BaseResponse>(url,
      {'DocEntry': _docEntry},
      { headers }
    );
  }

  CancelSaleQuotation(_docEntry: number):Observable<IResponse<BaseResponse>>  {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/SlCancelSaleQuotation`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<BaseResponse>>(url,
      {'DocEntry': _docEntry},
      { headers }
    );
  }

  /**
   * method to create quotation
   * @param _quotation model to create
   * @constructor
   */
  CreateQuotation(_quotation : SLDocument): Observable<IResponse<SLDocument>>{
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/SLCreateQuotation`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<SLDocument>>(url,
      _quotation,
      { headers }
    );
  }

  UpdateQuotation(_quotation : SLDocument): Observable<IResponse<SLDocument>>{


    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/SLUpdateQuotation`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<SLDocument>>(url,
      _quotation,
      { headers }
    );
  }
  // UpdateQuotation(header: FormGroup, itemsList: any, _docEntry) {

  //   const linesList: any[] = [];
  //   itemsList.forEach(element => {
  //     if (element.ItemCode !== '') {
  //       element.ItemCode = element.ItemCode.split('-')[0].trim();
  //       linesList.push(element);
  //     }
  //   });

  //   const Quotation = {
  //     'QuotationsLinesList': linesList,
  //     'CardCode': header.value.cardCode,
  //     'CardName': header.value.cardName,
  //     'Currency': header.value.currency,
  //     'Comment': header.value.Comment,
  //     'SlpCode': header.value.SlpList,
  //     'DocEntry': _docEntry
  //   };

  //   const token = JSON.parse(this.storage.getCurrentSession());
  //   const url = `${AppConstants.apiUrl}api/Documents/UpdateQuotation`;
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${token.access_token}`
  //   });
  //   return this.http.post(url,
  //     Quotation,
  //     { headers }
  //   );
  // }

  GetInvList(inv: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/GetInvPrintList`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url, inv, { headers });
  }


  GetInvBalanceList(BalanceModel: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/GetBalanceInvoices_UsrOrTime`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url, BalanceModel, { headers });
  }


  CreateInvoice(Invo: FormGroup, Payment: any, InvoLines: any, feForm: FormGroup, uniqueInvCode: string) {

    console.log('uniqueInvCode', uniqueInvCode);
    /*IMPORTANTE
      El formato que vamos a usar para la creacion de uniqueInvCode es

      eeeddmmyyyyhhmmss

      en donde:

      eee = estacion
      dd = dia
      mm = mes
      yyyy = annio
      hh = hora
      mm = minuto
      ss = segundo
    */

    const linesList: any[] = [];
    InvoLines.forEach(element => {
      if (element.ItemCode !== '') {
        element.ItemCode = element.ItemCode.split('-')[0].trim();
        linesList.push(element);
      }
    });
    let FE = {
      'IdType': feForm.value.IdType,
      'Email': feForm.value.Email,
      // 'Provincia': feForm.value.Provincia,
      // 'Canton': feForm.value.Canton,
      // 'Distrito': feForm.value.Distrito,
      // 'Barrio': feForm.value.Barrio,
      // 'Direccion': feForm.value.Direccion,
      'Identification': feForm.value.Identification
    }
    const Invoice = {
      'InvoiceLinesList': linesList,
      'DocumentType': Invo.value.DocumentType,
      'CardCode': Invo.value.cardCode,
      'CardName': Invo.value.cardName,
      'Currency': Invo.value.currency,
      'PayTerms': Invo.value.PayTerms,
      'Comment': Invo.value.Comment,
      'SlpCode': Invo.value.SlpList,
      'FEInfo': FE,
      'CLVS_POS_UniqueInvId': uniqueInvCode
    };
    const createInvoice = {
      'Invoice': Invoice,
      'Payment': Payment
    };

    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/CreateInvoice`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url, createInvoice, { headers });
  }


  CreateapInvoice(Invo: FormGroup, Payment: any, InvoLines: any, feForm: FormGroup) {

    let invoiceId = new Date();
    let dateTime = new Date();
    let dateString = dateTime.toLocaleDateString();
    let hrs = dateTime.getHours();
    let min = dateTime.getMinutes();
    let milli = dateTime.getMilliseconds();
    let uniqueInvCode = `${Invo.value.cardCode}_${dateString}_${hrs}${min}_${milli}`;
    const linesList: any[] = [];
    InvoLines.forEach(element => {
      if (element.ItemCode !== '') {
        element.ItemCode = element.ItemCode.split('-')[0].trim();
        linesList.push(element);
      }
    });
    let FE = {
      'IdType': feForm.value.IdType,
      'Email': feForm.value.Email,
      'Identification': feForm.value.Identification
    }
    const Invoice = {
      'InvoiceLinesList': linesList,
      'DocumentType': Invo.value.DocumentType,
      'CardCode': Invo.value.cardCode,
      'CardName': Invo.value.cardName,
      'Currency': Invo.value.currency,
      'PayTerms': Invo.value.PayTerms,
      'Comment': Invo.value.Comment,
      'SlpCode': Invo.value.SlpList,
      'FEInfo': FE,
      'CLVS_POS_UniqueInvId': uniqueInvCode
    };
    const createInvoice = {
      'Invoice': Invoice,
      'Payment': Payment
    };


    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/CreateapInvoice`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url, createInvoice, { headers });
  }

  createInvOnline(Invo: FormGroup, Payment: any, InvoLines: any, feForm: FormGroup, Clave: string, Consecutivo: string) {

    const linesList: any[] = [];
    InvoLines.forEach(element => {
      if (element.ItemCode !== '') {
        element.ItemCode = element.ItemCode.split('-')[0].trim();
        linesList.push(element);
      }
    });
    let FE = {
      'IdType': feForm.value.IdType,
      'Email': feForm.value.Email,
      'Provincia': feForm.value.Provincia,
      'Canton': feForm.value.Canton,
      'Distrito': feForm.value.Distrito,
      'Barrio': feForm.value.Barrio,
      'Direccion': feForm.value.Direccion,
      'Identification': feForm.value.Identification
    }
    const Invoice = {
      'InvoiceLinesList': linesList,
      'CardCode': Invo.value.cardCode,
      'CardName': Invo.value.cardName,
      'Currency': Invo.value.currency,
      'PayTerms': Invo.value.PayTerms,
      'Comment': Invo.value.Comment,
      'SlpCode': Invo.value.SlpList,
      'U_ClaveFe': Clave,
      'U_NumFE': Consecutivo,
      'FEInfo': FE

    };
    const createInvoice = {
      'Invoice': Invoice,
      'Payment': Payment
    };

    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.onlineUrl}api/Documents/CreateInvoice`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url, createInvoice, { headers });
  }

  CreateInvoiceNc(Invo: FormGroup, Payment: any, InvoLines: any, feForm: FormGroup, _documentType: string) {

    const USER_PREFIXID = JSON.parse(this.storage.getCurrentSession()).PrefixId;

    const DATE = new Date();

    const DAYS = DATE.getDate() < 10 ? '0' + DATE.getDate() : DATE.getDate().toString();
    const MONTS = (DATE.getMonth() + 1) < 10 ? '0' + (DATE.getMonth() + 1) : (DATE.getMonth() + 1).toString();
    const YEAR = DATE.getFullYear().toString().slice(0, 2);

    const HOURS = DATE.getHours() < 10 ? '0' + DATE.getHours() : DATE.getHours();
    const MINUTES = DATE.getMinutes() < 10 ? '0' + DATE.getMinutes() : DATE.getMinutes();
    const SECONDS = DATE.getSeconds() < 10 ? '0' + DATE.getSeconds() : DATE.getSeconds();

    let uniqueInvCode = `${USER_PREFIXID + DAYS + MONTS + YEAR + HOURS + MINUTES + SECONDS}`;
    const linesList: any[] = [];
    InvoLines.forEach(element => {
      if (element.ItemCode !== '') {
        element.ItemCode = element.ItemCode.split('-')[0].trim();
        linesList.push(element);
      }
    });
    let FE = {
      'IdType': feForm.value.IdType,
      'Email': feForm.value.Email,
      'Identification': feForm.value.Identification,
      'Observacion': feForm.value.ObservacionFE
    }
    const Invoice = {
      'InvoiceLinesList': linesList,
      'CardCode': Invo.value.cardCode,
      'CardName': Invo.value.cardName,
      'Currency': Invo.value.currency,
      'PayTerms': Invo.value.PayTerms,
      'Comment': Invo.value.Comment,
      'SlpCode': Invo.value.SlpList,
      'U_NVT_Medio_Pago': Invo.value.PaymentMethod,
      'U_Almacen': this.storage.getBranchOffice().Code,
      'U_Online': '0',
      'FEInfo': FE,
      'CLVS_POS_UniqueInvId': uniqueInvCode,
      'NumAtCard': Invo.value.NumAtCard === '' ? 0: Invo.value.NumAtCard,
      'DocumentType': _documentType,
    };
    const createInvoice = {
      'Invoice': Invoice,
      'Payment': Payment
    };

    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/CreateInvoiceNc`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url, createInvoice, { headers });
  }


  /**
   * Method to create credit note
    * @param creditMemo -Model credit note
   * @constructor
   */
  SLCreateInvoiceNc(creditMemo: ICreditMemo):Observable<IResponse<ICreditMemo>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/SLCreateInvoiceNc`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<ICreditMemo>>(url, creditMemo, { headers });
  }



  getMaxDiscout() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/getDiscount`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  DeleteOffLineInvoice(Id: number) {
    const IdModel = {
      'Id': Id,
    };
    const token = JSON.parse(this.storage.getCurrentSessionOffline());
    const url = `${AppConstants.offlineUrl}api/Documents/DeleteOffLineInvoice`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url, IdModel, { headers });
  }

  CreateInvoiceFromSO2(_saleOrder: ISaleOrder, Payment: any, InvoLines: any, _fe: IFe, uniqueInvCode: string
    ,_saleOrders: ISaleOrder[] ):Observable<ICreateInvoiceFromSOResponse> {

    const linesList: any[] = [];
    InvoLines.forEach(element => {
      if (element.ItemCode !== '') {
        element.ItemCode = element.ItemCode.split('-')[0].trim();
        linesList.push(element);
      }
    });

    let FE = {
      'IdType': _fe.IdType,
      'Email': _fe.Email,
      'Identification': _fe.Identification
    }

    const Invoice = {
      // 'BaseEntry': _saleOrder.DocEntry,
      // 'SaleOrderLinesList': linesList,
      // 'DocumentType': _saleOrder.DocumentType,
      // 'CardCode': _saleOrder.CardCode,
      // 'CardName': _saleOrder.CardName,
      // 'Currency': _saleOrder.Currency,
      // 'PayTerms': _saleOrder.PayTerms,
      // 'Comment': _saleOrder.Comment,
      // 'SalesMan': _saleOrder.SlpCode,
      // 'IdType': FE.IdType,
      // 'SlpCode': _saleOrder.SlpCode,
      // 'Identification': FE.Identification,
      // 'Email': FE.Email,
      // 'CLVS_POS_UniqueInvId': uniqueInvCode,
      // 'Payment': Payment,
      // 'Payterms': _saleOrder.PayTerms,
      // 'U_Almacen': _saleOrder.U_Almacen,
      // 'U_Facturacion': _saleOrder.U_Facturacion,
      // 'U_FacturaVencida': _saleOrder.U_FacturaVencida,
      // 'U_Online': '0',
      // 'U_ObservacionFE': _saleOrder.U_ObservacionFE,
      // 'SaleOrders': _saleOrders,
      // 'BranchOfficeTransaction': this.storage.getBranchOffice().Code
    };

    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/CreateInvoiceFromSO`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<ICreateInvoiceFromSOResponse>(url, Invoice, { headers });
  }

  getInvoice(_docEntry: number): Observable<IInvoiceResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/GetInvoice?docEntry=${_docEntry}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.get<IInvoiceResponse>(url, { headers });
  }

  GetPayments(_paymentSearch: IPaymentSearch): Observable<IPaymentReponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/GetPayments`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IPaymentReponse>(url, _paymentSearch, { headers });
  }

  //#region SL
  /**
   * Method to cancel sale order
   * @param _docEntry
   * @constructor
   */
  SLCancelSaleOrder(_docEntry: number): Observable<IResponse<BaseResponse>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/SlCancelSaleOrder`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<BaseResponse>>(url,
      {'DocEntry': _docEntry},
      { headers }
    );
  }

  /**
   * Method to create invoices from sales orders
   * @param _slSaleOrderToARInvoice - Model to document
   * @constructor
   */
  CreateInvoiceFromSO(_slSaleOrderToARInvoice : SLSaleOrderToARInvoice ): Observable<IResponse<ICreateInvoiceFromSOResponse>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/SLCreateInvoiceFromSO`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<ICreateInvoiceFromSOResponse>>(url, _slSaleOrderToARInvoice, { headers });
  }
   //#endregion

  /**
   * Method to authorize or rejected orders
   * @param _order - Order to update
   * @constructor
   */
   AutorizeOrRejectsO(_order : IAutorizeOrRejectsOrder):Observable<IResponse<IAutorizeOrRejectsOrder>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/SLAutorizeOrRejectsO`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<IAutorizeOrRejectsOrder>>(url,_order, { headers });
  }

  GetFieldsCodeReference(): Observable<IResponse<IFieldCodeFerecence[]>>{
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/GetFieldsReferenceCode`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.get<IResponse<IFieldCodeFerecence[]>>(url, { headers });
  }



}
