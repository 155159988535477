import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  HostListener,
  Renderer2,
  DoCheck,
} from "@angular/core";
import {
  formatDate,
  DatePipe,
  CurrencyPipe,
  DecimalPipe,
} from "@angular/common";
import { SOAndSQService } from "src/app/services/soand-sq.service";
import {
  SLSaleOrderToARInvoice,
  ISaleQuotation,
  ISaleOrderSearch,
  Company,
  TypeInvoice,
  IdentificationType,
  IPPTransaction,
  IPrinter,
  IFe,
  KeyValue,
  IInvoiceFromSOResponse,
  IReportMerger,
  IDocumentLine,
  CreditCards,
  PaymentLines,
  BasePayment,
  ISaleOrder,
} from "src/app/models";
import { NgBlockUI, BlockUI } from "ng-block-ui";
import {
  FormGroup,
  Validators,
  FormControl,
  FormBuilder,
} from "@angular/forms";
import { Subscription, Observable, Subject } from "rxjs";
import { debounceTime, map, distinctUntilChanged } from "rxjs/operators";
import {
  SalesManService,
  AlertService,
  BusinessPartnerService,
  StoreService,
  StorageService,
  CardService,
  ExRateService,
  DocumentService,
  AccountService,
  ParamsService,
  PaymentService,
  ReportsService,
  BankService,
  CommonService,
} from "src/app/services";
import { Router } from "@angular/router";
import {
  NgbPaginationConfig,
  NgbModalOptions,
  NgbModal,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import {
  SOAndSQActions,
  ReportType,
  KEY_CODE,
  PRINTERS_PER_REPORT,
  BaseTypeLines,
  BoRcptInvTypes,
  BoRcptTypes,
} from "src/app/enum/enum";
import { BranchOfficeModel2 } from "src/app/models/branch-office";

import { timer, interval } from "rxjs";
import { ISaleOrderResponse } from "src/app/models/responses";
import swal from "sweetalert2";
import {IUdf, UDFModel2} from "src/app/models/i-udf";
import { IInvoiceDocument } from "src/app/models/i-document";
import { IBPAutorizedContacts } from "src/app/models/i-business-partner";
import { BPAutorizedContactsComponent } from "src/app/components/custom-modals/bpautorized-contacts/bpautorized-contacts.component";

const printJS = require("print-js");

@Component({
  selector: "app-cashier",
  templateUrl: "./cashier.component.html",
  styleUrls: ["./cashier.component.scss"],
  providers: [DecimalPipe, CurrencyPipe, DatePipe],
})
export class CashierComponent
  implements OnInit, AfterViewInit, OnDestroy, DoCheck {
  //VARBOX
  docEntries: number[];
  lastPaymentsResponse: IInvoiceFromSOResponse[];
  isDocumentCommited: false;
  isRaisedFromMergedEvent: boolean;
  documentsToMergeOrder: KeyValue[];
  isDocumentsMerged: boolean;
  isMerginDocuments: boolean;
  isRequestingDocument: boolean;
  globalModal: any;
  requiredReference: boolean;
  isFromCreditPay: boolean;
  branchOffice: BranchOfficeModel2;
  isLocked: boolean;
  titleInvoice: string;
  isCancelLocked: boolean;
  isCancellingPayment: boolean;
  selectedSaleOrder: number;
  // globalModal: any;
  returnedDocNum: string;
  returnedDocEntry: string;
  returnedNumFE: string;
  banksList: any[] = []; // lista de bancos
  Fe: IFe;
  uniqueInvCode: string;
  attemptsWhileBilling: number;
  lastInvoice: number;
  lastPayment: number;
  pinPadCard: IPPTransaction;
  pinPadCards: IPPTransaction[];
  userCurrency: string; // Usado para precargar el tipo de moneda que usara el usuario
  DEFAULT_BUSSINESS_PARTNER: string;
  bpCodeList: string[] = []; // lista de los Códigos de clientes
  bpNameList: string[] = []; // lista de los nombres de clientes
  setCurr: string; // tipo de moneda escogida en simbolo
  currencyList: any[] = []; // lista de tipos de cambio
  allCurrencyList: any[] = []; // lista de todos los tipos de cambio existentes en la aplicacion
  isBilling: boolean;
  isOnSubmit: boolean = false;
  accountList: any[] = []; // lista de cuentas
  maxDiscuont: any;
  DailyExRate: number;
  itemsList: IDocumentLine[] = []; // lista de items
  identificationTypeList: any[] = [];
  conta: number;
  total: number;
  totalUSD: number;
  tax: number;
  discount: number;
  totalWithoutTax: number;
  typesInvoice: any[] = [];
  isAllowedPriceListChange: boolean; // Controla el cambio de lista por parte del usuario
  COMPANY: Company; // Usada para guardar las configuraciones del a compania
  TO_FIXED_PRICE: string; // Contiene la cantidad de decimales a usar en el precio unitario
  TO_FIXED_TOTALLINE: string; // Contiene la cantidad de decimales a usar en el total de linea
  TO_FIXED_TOTALDOCUMENT: string; // Contiene la cantidad de decimales a usar en el total del documento
  @BlockUI() blockUI: NgBlockUI;
  saleOrders: ISaleOrder[];
  saleOrder: ISaleOrder;
  saleQuotations: ISaleQuotation[];
  saleQuotation: ISaleQuotation;
  searchForm: FormGroup;
  previewForm: FormGroup;
  nowDate: any;
  bpCodeNameList: string[] = []; // lista de los codigo con nombres de clientes
  bpList: string[];
  StatusFact: String;
  Formadate: string;

  viewParamTitles: any[] = []; // llena la lista con los titulos de las paginas parametrizados
  title: string; // titulo de la vista
  currentUser: any; // variable para almacenar el usuario actual
  SlpsList: string[]; //= []; // lista de los vendedores
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  permisos: boolean = true;
  status: boolean[] = [true, false];
  invoiceSelected = false; //Existe factura seleccionada
  page = 1;
  pageSize = 10;
  collectionSize: number;
  model: any;
  mDate: string;

  // modal de pagos
  cashForm: FormGroup; // formulario para el efectivo
  creditCardForm: FormGroup; // formulario para las tarjetas de credito
  transferForm: FormGroup; // formulario para la tranferencia
  checkForm: FormGroup; // formulario para el cheque
  cardsList: any[] = []; // lista de tarjetas
  salesManList: any[] = []; // lista de vendedores
  currencyPayment: string; // moneda selecionada al buscar los anticipos
  modalPay: any; // instancia de la modal de pagos
  modalChange: any; // instancia de la modal de vueltos
  modalBPContacts: any;
  TotalG: number; // monto total del pago
  ReceivedG: number; // monto total recibido del pago
  DifferenceG: number; // monto total de la diferencia, o salto restante
  ChangeG: number; // guarda el vuelto
  TotalCash: number; // monto total del pago en efectivo
  TotalCards: number; // monto total del pago en tarjetas
  TotalTransfer: number; // monto total del pago en transferencia
  TotalCheck: number; // monto total del pago en cheque
  V_CreditCards: CreditCards[] = []; // lista de pagos registrados con tarjetas de credito
  CardName: string; // nombre de la tarjeta seleccionada para el pago con tarjeta
  V_Checks: any[] = []; // lista de pagos registrados con cheques
  correctInvoice: boolean = false; //dice si el pago se realizo correctamente
  facturando: boolean = false;
  hasLines: boolean = false; //dice si el pago se realizo correctamente
  isPagoCuenta: boolean;
  currencyChange: number; // monto del tipo de cambio
  unamePattern = "^d{2}/d{2}$";
  btnVisibleBool: boolean;
  changeColones: number; // vuelto en colones
  changeDolares: number; // vuelto en dolares
  defaultGroupNum: any;
  defaultListNum: any;
  defaultContado: any;
  closeResult: string; // variable para saber porque se cerro la modal
  mInterval: any;

  UdfsInDocument: UDFModel2[];
  // -----------------------------
  TotalCol: FormControl = new FormControl();
  TotalUSD: FormControl = new FormControl();
  Cant: FormControl = new FormControl();
  ItemInfo: FormControl = new FormControl();
  dateTimeExitUI = "";
  // permisos: boolean = true;
  paymentTypes: any[] = [
    { id: "1", name: "A30Dias" },
    { id: "2", name: "Contado" },
  ];
  private searches$: Observable<ISaleOrderResponse>;
  //----------------------------
  @ViewChild("payContent") payContentModal: any;

  @HostListener("window:down", ["$event"])
  keyEvent(event: KeyboardEvent) {
    switch (event.ctrlKey && !event.altKey && !event.shiftKey) {
      case event.ctrlKey && event.keyCode === KEY_CODE.F2: {
        this.redirectToSO();
        break;
      }
      case event.ctrlKey && event.keyCode === KEY_CODE.F4: {
        this.redirectToQuotation();
        break;
      }
      case event.ctrlKey && event.keyCode === KEY_CODE.Enter: {
        // this.nameField.nativeElement.focus();
        break;
      }
    }
  }

  constructor(
    private storageService: StorageService,
    private commonService: CommonService,
    private bankService: BankService,
    private reportsService: ReportsService,
    private cp: CurrencyPipe,
    private paymentService: PaymentService,
    private paramsService: ParamsService,
    private accountService: AccountService,
    private documentService: DocumentService,
    private exrate: ExRateService,
    private cardService: CardService,
    private storage: StorageService,
    private fb: FormBuilder,
    private soAndSqService: SOAndSQService,
    private smService: SalesManService,
    private alertService: AlertService,
    private bpService: BusinessPartnerService,
    private router: Router,
    private storeService: StoreService,
    private config: NgbPaginationConfig,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private renderer: Renderer2
  ) {
    this.config.size = "sm";
  }

  ngOnDestroy(): void {
    this.isLocked = true;
  }

  private stopPolling = new Subject();

  ngOnInit() {
    this.isRequestingDocument = false;
    this.requiredReference = false;
    this.storeService.SaveBreadCrum(``);
    this.InitVariables();
    let SEARCH = this.searchForm.value as ISaleOrderSearch;

    SEARCH.docNum = this.searchForm.value.docNum;
    SEARCH.docStatus = this.searchForm.value.docStatus;
    SEARCH.doctype = "SO";
    if (SEARCH.slpCode !== 0) {
      const CODE = this.searchForm.value.slpCode + "";
      SEARCH.slpCode = +CODE.split(" ")[0];
    }

    if (SEARCH.cardCode !== "") {
      SEARCH.cardCode = SEARCH.cardCode.split(" ")[0];
    }

    this.mInterval = interval(1000 * this.COMPANY.RefreshDelay).subscribe((x) =>
      this.autoSearch()
    );
  }

  ngDoCheck(): void {
    const parent: HTMLElement = document.getElementById("main_body");
    if (parent && parent.style.cssText !== "padding-right: 0px;") {
      this.renderer.setStyle(parent, "padding-right", "0px"); //style="padding-right: 0 !important"
      console.log("Overriding styles");
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.commonService.hasDocument.next(``);
    });
  }

  searchSLP = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.SlpsList.filter(
            (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
          ).slice(0, 10)
      )
    );

  searchBP = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.bpList
            .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
            .slice(0, 10)
      )
    );

  search(): void {
    let SEARCH = this.searchForm.value as ISaleOrderSearch;

    SEARCH.docNum = this.searchForm.value.docNum;
    SEARCH.docStatus = this.searchForm.value.docStatus;
    SEARCH.doctype = "SO";
    SEARCH.U_EstadoOV = "01";
    SEARCH.ViewType = "INV";
    if (SEARCH.slpCode !== 0) {
      const CODE = this.searchForm.value.slpCode + "";
      SEARCH.slpCode = +CODE.split(" ")[0];
    }

    if (SEARCH.cardCode !== "") {
      SEARCH.cardCode = SEARCH.cardCode.split(" ")[0];
    }

    this.blockUI.start(`Obteniendo documentos`);
    this.saleOrders = [];
    this.isLocked = true;
    this.soAndSqService.GetSaleOrders(SEARCH).subscribe(
      (next) => {
        this.blockUI.stop();
        this.isLocked = false;

        if (next.result) {
          if (next.SaleOrders.length === 0) {
            this.alertService.infoInfoAlert("No se encontraron documentos dentro de los parámetros de búsqueda.");
          }

          this.collectionSize = next.SaleOrders.length;
          this.saleOrders = next.SaleOrders.map((so, i) => ({
            Id: i + 1,
            ...so,
          }));
        } else {
          this.alertService.errorAlert(
            `No se pudieron obtener las órdenes de venta, detalle: ${next.errorInfo.Message}`
          );
        }
      },
      (error) => {
        this.blockUI.stop();
        this.isLocked = false;
        console.log(error);
      }
    );
  }

  autoSearch(): void {
    if (!this.isLocked) {
      let SEARCH = this.searchForm.value as ISaleOrderSearch;

      SEARCH.docNum = this.searchForm.value.docNum;
      SEARCH.docStatus = this.searchForm.value.docStatus;
      SEARCH.doctype = "SO";
      SEARCH.ViewType = "INV";
      SEARCH.U_EstadoOV = "01";
      if (SEARCH.slpCode !== 0) {
        const CODE = this.searchForm.value.slpCode + "";
        SEARCH.slpCode = +CODE.split(" ")[0];
      }

      if (SEARCH.cardCode !== "") {
        SEARCH.cardCode = SEARCH.cardCode.split(" ")[0];
      }

      this.soAndSqService.GetSaleOrders(SEARCH).subscribe(
        (next) => {
          if (next.result) {
            if (!this.isLocked) {
              this.collectionSize = next.SaleOrders.length;
              this.saleOrders = next.SaleOrders.map((so, i) => ({
                Id: i + 1,
                ...so,
              }));
            }
          } else {
            console.log(next);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  edit(
    _docNum: number,
    _documentType: number,
    _currency: string,
    _cardCode: string,
    _slpCode: number
  ): void {
    this.saleOrder = null;
    this.saleOrders = [];
    this.saleQuotation = null;
    this.saleQuotations = [];
    this.SlpsList = [];
    this.bpList = [];
    let CustomerData: string = JSON.stringify({
      CardCode: _cardCode,
      Currency: _currency,
      SlpCode: _slpCode,
    });
    console.log(CustomerData);
    this.storeService.SaveCustomerData(CustomerData);
    console.log(_documentType);
    if (_documentType === 0) {
      // SALE ORDER
      this.storeService.saveDocEntry(_docNum);
      this.router.navigate(["/", "so"]);
    } else {
      this.storeService.saveDocEntry(_docNum);
      this.router.navigate(["/", "quotation"]);
    }
  }

  preview(_docNum: number): void {
    this.isLocked = true;
    this.isRaisedFromMergedEvent = false;
    this.blockUI.start("Obteniendo documento, espere por favor");
    this.soAndSqService.GetSaleOrder(_docNum).subscribe(
      (next) => {
        this.blockUI.stop();
        if (next.result) {
          this.saleOrder = next.Data;
          this.saleOrder.DocDate = formatDate(
            this.saleOrder.DocDate,
            "yyyy-MM-dd",
            "en"
          );
          this.previewForm.patchValue({ ...this.saleOrder });
          (<HTMLButtonElement>(
            document.getElementById("previewTrigger")
          )).click();
        } else {
          console.log(next);
          this.alertService.errorAlert(
            `No se pudo obtener el documento ${next.errorInfo.Message}`
          );
        }
      },
      (error) => {
        this.blockUI.stop();
        console.log(error);
        this.alertService.errorAlert(
          `No se pudo obtener el documento ${error}`
        );
      }
    );
  }

  copyTo(
    _docNum: number,
    _currency: string,
    _cardCode: string,
    _slpCode: number
  ): void {
    localStorage.setItem("DocEntry", _docNum.toString());
    let CustomerData: string = JSON.stringify({
      CardCode: _cardCode,
      Currency: _currency,
      SlpCode: _slpCode,
    });
    this.storeService.SaveCustomerData(CustomerData);
    if (this.searchForm.get("doctype").value === "SO") {
      localStorage.setItem(
        "SOAndSQAction",
        SOAndSQActions.CopyToInvoice.toString()
      );
    } else {
      localStorage.setItem(
        "SOAndSQAction",
        SOAndSQActions.CopyToOrder.toString()
      );
      this.router.navigateByUrl("so");
    }
  }

  getPurchaseorderList(): void { }

  changeDocumentType(): void {
    this.saleOrders = [];
    this.saleQuotations = [];
    this.page = 1;
    this.pageSize = 10;
  }

  GetSalesPersonList(): void {
    this.blockUI.start("Obteniendo vendedores, Espere Por Favor...");
    this.smService.getSalesMan().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.SlpsList = [];
          data.salesManList.forEach((x) => {
            this.SlpsList.push(x.SlpCode + " - " + x.SlpName);
          });
        } else {
          this.alertService.errorAlert(
            `Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
          );
        }
      },
      (error: any) => {
        this.blockUI.stop();
        console.log(error);
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  getCustomers(): void {
    this.blockUI.start("Obteniendo Clientes, Espere Por Favor...");
    this.bpService.GetCustomers().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data && data.Code === undefined && data.BPS != null) {
          this.bpList = [];
          data.BPS.forEach((x) => {
            this.bpList.push(x.CardCode + " " + x.CardName);
          });
        } else {
          console.log(data.errorInfo);
          this.alertService.errorAlert(
            `Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
          );
        }
      },
      (error: any) => {
        this.blockUI.stop();
        console.log(error);
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  InitVariables(): void {
    this.lastPaymentsResponse = [];
    this.isRaisedFromMergedEvent = false;
    this.documentsToMergeOrder = [];
    this.isDocumentsMerged = false;
    this.isMerginDocuments = false;
    this.requiredReference = false;
    this.isFromCreditPay = false;
    this.COMPANY = this.storage.getCompanyConfiguration();
    this.branchOffice = this.storage.getBranchOffice();
    this.UdfsInDocument = [];
    this.UdfsInDocument = this.GetDocumentUDFs();
    this.TO_FIXED_PRICE = `1.${this.COMPANY.DecimalAmountPrice}-${this.COMPANY.DecimalAmountPrice}`;
    this.TO_FIXED_TOTALLINE = `1.${this.COMPANY.DecimalAmountTotalLine}-${this.COMPANY.DecimalAmountTotalLine}`;
    this.TO_FIXED_TOTALDOCUMENT = `1.${this.COMPANY.DecimalAmountTotalDocument}-${this.COMPANY.DecimalAmountTotalDocument}`;
    this.conta = 0.0;
    this.total = 0.0;
    this.totalUSD = 0.0;
    this.tax = 0.0;
    this.discount = 0.0;
    this.DailyExRate = 0;
    this.ReceivedG = 0;
    this.TotalTransfer = 0;
    this.TotalCards = 0;
    this.TotalCheck = 0;
    this.TotalCash = 0;
    this.returnedDocNum = "0";
    this.returnedDocEntry = "0";
    this.returnedNumFE = "";
    this.attemptsWhileBilling = 0;
    this.totalWithoutTax = 0.0;
    this.TotalG = 0.0;
    this.identificationTypeList = IdentificationType;
    this.typesInvoice = TypeInvoice;
    this.isAllowedPriceListChange = false;
    this.isBilling = false;
    this.isLocked = false;
    this.isCancelLocked = false;
    this.pinPadCard = null;
    this.isCancellingPayment = false;
    const mDate = formatDate(new Date(), "yyyy-MM-dd", "en");
    this.saleOrders = [];
    this.saleQuotations = [];
    this.pinPadCards = [];
    this.saleQuotation = null;
    this.saleOrder = null;
    this.isBilling = false;
    this.searchForm = new FormGroup({
      slpCode: new FormControl(""),
      cardCode: new FormControl(""),
      fini: new FormControl("", [Validators.required]),
      ffin: new FormControl(mDate, [Validators.required]),
      doctype: new FormControl("SO", [Validators.required]),
      docStatus: new FormControl("O"),
      docNum: new FormControl(0),
    }); // La definicion del formularioFormGroup;

    this.cashForm = this.fb.group({
      AccountCash: ["", Validators.required],
      TotalCash: [0, Validators.required],
    });
    this.creditCardForm = this.fb.group({
      CreditCard: ["", Validators.required],
      CardNum: [""], // , [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      OwnerIdNum: ["", Validators.required],
      CardValid: [""], //, [Validators.required, Validators.minLength(3), Validators.maxLength(6)]
      CreditSum: [0, Validators.required],
      VoucherNum: ["", Validators.required],
    });

    this.mDate = formatDate(new Date(), "yyyy-MM-dd", "en");

    this.transferForm = this.fb.group({
      AccountTransf: ["", Validators.required],
      DateTrans: [this.mDate, Validators.required],
      Ref: [""],
      TransSum: [0, Validators.required],
    });

    this.checkForm = this.fb.group({
      AccountCheck: ["", Validators.required],
      BankNames: ["", Validators.required],
      DueDate: ["", Validators.required],
      CountryCod: [{ value: "CR", disabled: true }, Validators.required],
      AcctNum: ["", Validators.required],
      CheckNum: ["", Validators.required],
      CheckSum: [0, Validators.required],
    });
    this.creditCardForm.patchValue({ VoucherNum: "0" });
    this.creditCardForm.patchValue({ CardNum: "9999" });
    this.creditCardForm.patchValue({ CardValid: "01/99" });

    this.generateUniqueInvoiceId();
    this.searchForm.patchValue({ ffin: mDate });
    this.searchForm.patchValue({ fini: mDate });
    this.GetSalesPersonList();
    this.getCustomers();
    this.getCards();
    this.getAccount();
    this.GetCurrencyType();
    this.getMaxDiscout();
    this.getExRate();
    this.getAccountsBank();
    this.saleQuotations = [];
    this.resetPreviewForm();
    this.pageSize = 10;
    this.autoSearch();
  }

  CreateNew() {
    this.InitVariables();
  }

  invoiceCredit(_docNum: number) { }

  OverrideMainLines(): void {
    this.conta = 0;
    this.Fe = {
      Email: this.saleOrder.U_CorreoFE,
      IdType: this.saleOrder.U_TipoIdentificacion,
      Identification: this.saleOrder.U_NumIdentFE,
    } as IFe;

    this.total = 0;
    this.itemsList = [];

    this.saleOrder.DocumentLines.forEach((x) => {
      this.conta++;
      this.total += x.UnitPrice;
      const tot = x.UnitPrice * x.Quantity;
      let itemAux = {
        Item: `${x.ItemCode}`,
        ItemCode: `${x.ItemCode}`,
        ItemName: `${x.ItemName}`,
        CodeName: `${x.ItemCode} - ${x.ItemName}`,
        UnitPrice:
          this.saleOrder.DocCurrency === "COL"
            ? x.UnitPrice
            : parseFloat(Number(x.UnitPrice / this.DailyExRate).toString()),
        UnitPriceCol: x.UnitPrice,
        UnitPriceDol: x.UnitPrice,
        U_SugPrice: 0,
        TaxCode: x.TaxCode,
        Quantity: x.Quantity,
        Active: true,
        Id: this.conta,
        LinTot: tot,
        TaxRate: x.TaxRate != 0.0 ? x.TaxRate : 0.0,
        DiscountPercent: x.DiscountPercent,
        WarehouseCode: x.WarehouseCode,
        //'WhsName': this.whName,
        Serie: "",
        SysNumber: 0,
        OnHand: 0,
        BaseEntry: x.BaseEntry,
        BaseType: x.BaseType,
        BaseLine: x.BaseLine,
        LineNum: x.LineNum,
        U_NVT_ServicioMedico: x.U_NVT_ServicioMedico,
      } as IDocumentLine;

      this.itemsList.push(itemAux);

      this.LineTotalCalculate(this.itemsList.length - 1);
      this.getTotalOnLoad();
    });
  }

  onSubmit(_docNum: number, payContent) {
    this.globalModal = payContent;
    if (this.isMerginDocuments && this.isDocumentsMerged) {
      this.createDocument(this.globalModal, true);
    } else {
      this.isLocked = true;
      this.isFromCreditPay = false;
      this.selectedSaleOrder = _docNum;
      this.isRequestingDocument = true;
      this.blockUI.start(`Obteniendo documento, espere por favor`);
      this.soAndSqService.GetSaleOrder(_docNum).subscribe(
        (next) => {
          this.blockUI.stop();
          if (next.result) {
            this.saleOrder = next.Data;
            this.conta = 0;

            this.Fe = {
              Email: this.saleOrder.U_CorreoFE,
              IdType: this.saleOrder.U_TipoIdentificacion,
              Identification: this.saleOrder.U_NumIdentFE,
            } as IFe;

            this.total = 0;
            this.itemsList = [];
            this.getCurrencyByUser(next.Data.DocCurrency);
            next.Data.DocumentLines.forEach((x) => {
              this.conta++;
              this.total += x.UnitPrice;
              const tot = x.UnitPrice * x.Quantity;

              let itemAux = {
                Item: `${x.ItemCode}`,
                ItemCode: `${x.ItemCode}`,
                ItemName: `${x.ItemName}`,
                CodeName: `${x.ItemCode} - ${x.ItemName}`,
                UnitPrice:
                  this.saleOrder.DocCurrency === "COL"
                    ? x.UnitPrice
                    : parseFloat(
                      Number(x.UnitPrice / this.DailyExRate).toString()
                    ),
                UnitPriceCol: x.UnitPrice,
                UnitPriceDol: x.UnitPrice,
                U_SugPrice: 0,
                TaxCode: x.TaxCode,
                Quantity: x.Quantity,
                Active: true,
                Id: this.conta,
                LinTot: tot,
                TaxRate: x.TaxRate != 0.0 ? x.TaxRate : 0.0,
                DiscountPercent: x.DiscountPercent,
                WarehouseCode: x.WarehouseCode,
                //'WhsName': this.whName,
                Serie: "",
                SysNumber: 0,
                OnHand: 0,
                BaseEntry: this.saleOrder.DocEntry,
                BaseType: x.BaseType,
                BaseLine: x.BaseLine,
                LineNum: x.LineNum,
                U_NVT_ServicioMedico: x.U_NVT_ServicioMedico,
              } as IDocumentLine;

              this.itemsList.push(itemAux);

              this.LineTotalCalculate(this.itemsList.length - 1);
            });

            this.total = 0;
            this.totalUSD = 0;

            if (this.saleOrder.DocCurrency == "COL") {
              this.total = this.saleOrder.DocTotal;
              this.totalUSD = this.saleOrder.DocTotal / this.DailyExRate;
            } else {
              this.total = this.saleOrder.DocTotal;
              this.totalUSD = this.saleOrder.DocTotal;
            }

            this.total = +this.total.toFixed(
              this.COMPANY.DecimalAmountTotalDocument
            );
            this.totalUSD = +this.totalUSD.toFixed(
              this.COMPANY.DecimalAmountTotalDocument
            );
            this.DifferenceG = this.total;

            this.createDocument(payContent, true);
          } else {
            console.log(next);
            this.alertService.errorAlert(
              `No se pudo obtener el documento ${next.errorInfo.Message}`
            );
          }
        },
        (error) => {
          this.blockUI.stop();
          console.log(error);
          this.alertService.errorAlert(
            `No se pudo obtener el documento ${error}`
          );
        }
      );
    }
  }

  createDocument(payContent: any, _isRequestModal): void {
    this.isDocumentCommited = false;
    this.isLocked = true;

    if (this.saleOrder.U_Facturacion === "Credito") {
      let saleOrders = [] as ISaleOrder[];

      this.documentsToMergeOrder.forEach((x) => {
        saleOrders.push(JSON.parse(x.Value) as ISaleOrder);
      });

      this.blockUI.reset();
      this.blockUI.start(`Generando factura a crédito, espere por favor`);
      this.documentService
        .CreateInvoiceFromSO(this.CreateInvoiceDocument())
        .subscribe(callback => {
            this.blockUI.stop();
            if (callback && callback.Data) {
              this.dateTimeExitUI = formatDate(
                new Date(),
                "yyyy-MM-dd HH:mm:ss SSS a",
                "en"
              );
              this.isCancelLocked = true;
              this.isFromCreditPay = true;
              this.titleInvoice = "Factura creada exitosamente";

              this.returnedDocNum = "";
              this.returnedDocEntry = "";
              this.returnedNumFE = "";
              this.lastPaymentsResponse = [];
              this.lastPaymentsResponse = callback.Data.PaymentsResponse;

              this.returnedDocEntry = this.returnedDocEntry.slice(0, -2);
              this.returnedDocNum = this.returnedDocNum.slice(0, -2);
              this.returnedNumFE = this.returnedNumFE.slice(0, -2);

              this.btnVisibleBool = false;
              this.correctInvoice = true;
              this.facturando = false;
              this.attemptsWhileBilling = 0;
              this.isBilling = false;
              this.isLocked = true;
              this.isCancelLocked = true;

              (<HTMLButtonElement>(
                document.getElementById("triggerAfterPayModal")
              )).click();

              let docEntries: number[] = [];

              callback.Data.PaymentsResponse.forEach((x) => {
                this.returnedDocNum += x.DocNum + ", ";
                this.returnedDocEntry += x.DocEntry + ", ";
                this.returnedNumFE += x.NumDocFe + ", ";
                docEntries.push(x.DocEntry);
              });

              this.docEntries = docEntries;

              this.printARInvoice(docEntries);

              this.returnedDocNum = this.returnedDocNum.slice(0, -2);
              this.returnedDocEntry = this.returnedDocEntry.slice(0, -2);
              this.returnedNumFE = this.returnedNumFE.slice(0, -2);
            } else {
              this.alertService.errorAlert(
                `Error al generar la factura: Código: ${callback.Error.Code}, Mensaje: ${callback.Error.Message}`
              );
              this.attemptsWhileBilling = 0;
              this.isBilling = false;
            }
            this.facturando = false;
          },
          (error) => {
            console.log(error);
            this.blockUI.stop();
            let err = error && error.Code ? `Error al generar factura, Código: ${error.Code}, Mensaje: ${error.Message}`: '';
            this.alertService.errorAlert(err ? err :`Error al intentar conectar con el servidor, Error: ${error}`);

            this.attemptsWhileBilling = 0;
            this.facturando = false;
            this.isBilling = false;
          }
        );
    } else {
      const DOCUMENTS_TO_MERGE = this.documentsToMergeOrder.map(
        (x) => JSON.parse(x.Value) as ISaleOrder
      );

      const TOTAL_DOCUMENTS = +DOCUMENTS_TO_MERGE.reduce(
        (sum, order) => sum + order.DocTotal,
        0
      ).toFixed(this.COMPANY.DecimalAmountTotalDocument);

      this.isLocked = true;
      this.DifferenceG = this.total;
      this.resetModalData();
      this.TotalG = this.total;
      this.TotalG = TOTAL_DOCUMENTS;
      this.setTotalAmount();

      let modalOption: NgbModalOptions = {};
      modalOption.backdrop = "static";
      modalOption.keyboard = false;
      modalOption.ariaLabelledBy = "modal-basic-title";
      modalOption.size = "lg";
      modalOption.windowClass = "Modal-Xl";

      this.creditCardForm.patchValue({ VoucherNum: "0" });
      this.creditCardForm.patchValue({ CardNum: "9999" });
      this.creditCardForm.patchValue({ CardValid: "01/99" });

      this.commonService.setPaymentDefaultValues(
        this.branchOffice,
        this.cashForm,
        this.creditCardForm,
        this.transferForm,
        this.checkForm
      );

      this.modalPay = this.modalService.open(payContent, modalOption);

      this.modalPay.result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.facturando = false;
        },
        (reason) => {
          this.facturando = false;
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
  }

  CreatePaymentLines(): PaymentLines[] {
    const PaymentLines: PaymentLines[] = [];

    PaymentLines.push({
      AppliedFC: this.total,
      DocEntry: 0,
      SumApplied: this.total,
      InvoiceType: BoRcptInvTypes.it_Invoice,
    });
    return PaymentLines;
  }

  CreatePaymentObject() {
    // const PaymentLines = [];
    // let dT = 1;
    // PaymentLines.push({
    //   'InvTotal': this.total,
    //   'DocNum': 0,
    //   'PayTotal': this.TotalG,
    //   'DocEntry': 0,
    //   'InstId': 30, // plasos
    //   'Currency': this.saleOrder.DocCurrency,
    //   'Balance': this.TotalG,
    //   'DocType': dT,
    //   'PayBalanceChk': false, // Verifica el pago vs saldo
    //   'ReceivedAmount': this.ReceivedG,
    //   'Change': this.ChangeG
    // });
    let total = 0;
    total = this.total;
    let Payment = {
      CardCode: this.saleOrder.CardCode,
      CashAccount: this.cashForm.value.AccountCash,
      CashSum: this.cashForm.value.TotalCash,
      DocCurrency: this.currencyPayment,
      Remarks: "pago de fatura",
      // Currency: this.currencyPayment,
      DocRate: this.currencyChange,
      // SlpCode: this.saleOrder.SalesPersonCode,
      Total: total,
      PaymentChecks: this.V_Checks,
      PaymentCreditCards: this.V_CreditCards,
      PaymentInvoices: this.CreatePaymentLines(),
      TransferSum: this.transferForm.value.TransSum,
      TransferAccount: this.transferForm.value.AccountTransf,
      // trfsrCurr: this.currencyPayment,
      // trfsrDate: this.transferForm.value.DateTrans,
      TransferReference: this.transferForm.value.Ref,
      DocDate: this.datePipe.transform(new Date(), "yyyy-MM-dd"),
      DueDate: this.datePipe.transform(new Date(), "yyyy-MM-dd"),
      DocType: BoRcptTypes.rCustomer,
      U_MontoRecibido: this.ReceivedG,
      Change: this.ChangeG,
    } as BasePayment;
    return Payment;
  }

  CreateInvoiceDocument(): SLSaleOrderToARInvoice {
    this.isDocumentCommited = false;
    this.isLocked = true;

    this.SetValueUDF<string>("FacturaVencida", this.saleOrder.U_FacturaVencida);
    this.SetValueUDF<string>("Facturacion", this.saleOrder.U_Facturacion);
    this.SetValueUDF<string>("NVT_Medio_Pago", this.saleOrder.U_NVT_Medio_Pago);
    this.SetValueUDF<string>("Almacen", this.storage.getBranchOffice().Code);
    this.SetValueUDF<string>("Online", "0");
    this.SetValueUDF<string>("CLVS_POS_UniqueInvId", this.uniqueInvCode);
    //fe
    this.SetValueUDF<string>("TipoDocE", this.saleOrder.U_TipoDocE);
    this.SetValueUDF<string>(
      "TipoIdentificacion",
      this.saleOrder.U_TipoIdentificacion
    );
    this.SetValueUDF<string>("NumIdentFE", this.saleOrder.U_NumIdentFE);
    this.SetValueUDF<string>("CorreoFE", this.saleOrder.U_CorreoFE);
    this.SetValueUDF<string>("ObservacionFE", this.saleOrder.U_ObservacionFE);

    const mappedUdfs: IUdf[] = this.UdfsInDocument.filter(x=>x.Value).map(udf => {
      return {
        Name: udf.Description,
        Value: udf.Value,
        FieldType: udf.Type
      };
    });

    // if (this.saleOrder.U_Facturacion === 'Credito') {
    let saleOrders = [] as ISaleOrder[];

    this.documentsToMergeOrder.forEach((x) => {
      saleOrders.push(JSON.parse(x.Value) as ISaleOrder);
    });

    const Payments = this.CreatePaymentObject();

    let document = {
      DocumentsToPay: [],
      // DocumentLines: [],
      CardCode: this.saleOrder.CardCode,
      CardName: this.saleOrder.CardName,
      DocCurrency: this.saleOrder.DocCurrency,
      PaymentGroupCode: this.saleOrder.PaymentGroupCode,
      Comments: this.saleOrder.Comments,
      SalesPersonCode: this.saleOrder.SalesPersonCode,
      SaleOrders: saleOrders,
      Udfs: mappedUdfs,
      Payment: Payments,
      BranchOfficeTransaction: this.storage.getBranchOffice().Code,
    } as IInvoiceDocument;

    let createInvoiceFromSoReturn = {
      Document: document
    } as SLSaleOrderToARInvoice;
    createInvoiceFromSoReturn.StringedDocument = JSON.stringify(createInvoiceFromSoReturn);

    return createInvoiceFromSoReturn;
  }

  resetPreviewForm(): void {
    this.previewForm = new FormGroup({
      CardName: new FormControl(""),
      DocDate: new FormControl(""),
      DocNum: new FormControl(""),
      SalesMan: new FormControl(""),
      DocTotal: new FormControl(""),
      U_Facturacion: new FormControl(""),
    }); // La definicion del formularioFormGroup;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  // funcion para resetear los valores de la modal
  resetModalData() {
    this.currencyPayment = "COL";
    this.TotalCash = 0;
    this.TotalCards = 0;
    this.TotalCheck = 0;
    this.TotalTransfer = 0;
    this.TotalG = 0;
    this.ReceivedG = 0;
    this.DifferenceG = 0;
    this.ChangeG = 0;
    this.V_Checks.length = 0;
    this.V_CreditCards.length = 0;
    this.cashForm.patchValue({ AccountCash: this.accountList[0].Account });
    this.cashForm.patchValue({ TotalCash: 0 });
    this.creditCardForm.patchValue({
      CreditCard: this.cardsList[0].CreditCard,
    });
    this.creditCardForm.patchValue({ CardNum: "" });
    this.creditCardForm.patchValue({ OwnerIdNum: "" });
    this.creditCardForm.patchValue({ CardValid: "" });
    this.creditCardForm.patchValue({ CreditSum: 0 });
    this.creditCardForm.patchValue({ VoucherNum: "" });
    this.transferForm.patchValue({
      AccountTransf: this.accountList[0].Account,
    });

    this.transferForm.patchValue({
      DateTrans: this.datePipe.transform(new Date(), "y-MM-dd"),
    });
    this.transferForm.patchValue({ Ref: "" });
    this.transferForm.patchValue({ TransSum: 0 });
    this.checkForm.patchValue({ AccountCheck: this.accountList[0].Account });
    this.checkForm.patchValue({ BankNames: this.banksList[0].BankCode });
    this.checkForm.patchValue({ DueDate: "" });
    this.checkForm.patchValue({ CountryCod: "CR" });
    this.checkForm.patchValue({ AcctNum: "" });
    this.checkForm.patchValue({ CheckNum: "" });
    this.checkForm.patchValue({ CheckSum: 0 });
  }

  addAmounCreditCard() {
    let totalcard = 0;
    if (this.creditCardForm.valid) {
      if (parseFloat(this.creditCardForm.value.CreditSum) > 0) {
        if (this.V_CreditCards.length > 0) {
          this.V_CreditCards.forEach((vcc) => {
            totalcard += parseFloat(vcc.CreditSum.toString());
          });
          totalcard += parseFloat(this.creditCardForm.value.CreditSum);
        } else {
          totalcard = parseFloat(this.creditCardForm.value.CreditSum);
        }
        if (totalcard > 0) {
          if (totalcard + this.TotalTransfer + this.TotalCheck <= this.TotalG) {
            const CREDIT_CARD = this.cardsList.filter(
              (x) => x.CreditCard === this.creditCardForm.value.CreditCard
            )[0];
            this.V_CreditCards.push({
              CardValidUntil: this.creditCardForm.value.CardValid,
              CreditCardNumber: this.creditCardForm.value.CardNum,
              CreditCard: CREDIT_CARD.CardName.split(" ")[0],
              CreditSum: this.creditCardForm.value.CreditSum,
              VoucherNum: this.creditCardForm.value.VoucherNum,
              // Curr: this.currencyPayment,
              FormatCode: CREDIT_CARD.CardName,
              OwnerIdNum: this.creditCardForm.value.OwnerIdNum,
            });

            this.TotalCards = totalcard;
            this.setTotalAmount();

            this.creditCardForm.patchValue({ OwnerIdNum: "" });
            this.creditCardForm.patchValue({ CardNum: "9999" });
            this.creditCardForm.patchValue({ CardValid: "01/99" });
            this.creditCardForm.patchValue({ CreditSum: "0" });
          } else {
            this.creditCardForm.patchValue({ CreditSum: "0" });
            this.alertService.infoInfoAlert(
              "El monto ingresado en la tarjeta de crédito supera el total de la factura."
            );
          }
        } else {
          this.alertService.infoInfoAlert(
            "El monto ingresado en Total es incorrecto."
          );
          this.creditCardForm.patchValue({ CreditSum: 0 });
        }
      } else {
        this.creditCardForm.patchValue({ CreditSum: 0 });
      }
    } else {
      this.alertService.infoInfoAlert("Campos inválidos");
    }
  }

  setTotalAmount() {
    //decimalPipe
    this.ReceivedG = Number(
      (
        this.TotalCash +
        this.TotalCards +
        this.TotalCheck +
        this.TotalTransfer
      ).toString()
    );
    this.ReceivedG = +this.ReceivedG.toFixed(this.COMPANY.DecimalAmountTotalDocument);

    let diff = Number((this.TotalG - this.ReceivedG).toString());
    this.DifferenceG = Math.max(diff, 0.0);
    this.ChangeG = Math.max(0, -1 * diff);

    if (this.currencyPayment !== "COL") {
      this.changeDolares = this.ChangeG;
      this.changeColones = Number(
        (this.ChangeG * this.currencyChange).toString()
      );
    } else {
      this.changeDolares = Number(
        (this.ChangeG / this.currencyChange).toString()
      );
      this.changeColones = this.ChangeG;
    }
  }

  onCtrlBTarjeta() {
    this.patchZeroes();
    this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.ReceivedG = +this.ReceivedG.toFixed(
      this.COMPANY.DecimalAmountTotalDocument
    );
    this.creditCardForm.patchValue({
      CreditSum: this.DifferenceG.toFixed(
        this.COMPANY.DecimalAmountTotalDocument
      ),
    });
  }

  onCtrlBCash() {
    this.patchZeroes();
    this.cashForm.patchValue({
      TotalCash: this.DifferenceG.toFixed(
        this.COMPANY.DecimalAmountTotalDocument
      ),
    });
    this.addAmountCash();
  }

  patchZeroes() {
    /*
    this.checkForm.patchValue({CheckSum: 0 });
    this.cashForm.patchValue({TotalCash: 0 });
    this.transferForm.patchValue({TransSum: 0 });
    this.creditCardForm.patchValue({CreditSum: 0 });
    */
  }

  addAmountCash() {
    this.ReceivedG = +this.ReceivedG.toFixed(
      this.COMPANY.DecimalAmountTotalDocument
    );
    this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalCash = parseFloat(this.cashForm.value.TotalCash);

    if (this.TotalCash > 0) {
      this.setTotalAmount();
    } else {
      this.cashForm.patchValue({ TotalCash: 0 });
      this.TotalCash = 0;
      this.setTotalAmount();
    }
  }

  getCards() {
    this.blockUI.start("Obteniendo Tarjetas, Espere Por Favor...");
    this.cardService.getCards().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.cardsList = data.cardsList;
          this.creditCardForm.patchValue({
            CreditCard: data.cardsList[0].CreditCard,
          });
          this.CardName = data.cardsList[0].CardName;
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(
            "error al cargar tarjetas de credito - Error: " +
            data.errorInfo.Message
          );
        }
      },
      (error) => {
        this.blockUI.stop();
        this.alertService.errorAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  LineTotalCalculate(idx: number) {
    let disc = 0;
    this.total = 0;
    if (this.itemsList[idx].DiscountPercent <= this.maxDiscuont) {
      disc = this.itemsList[idx].DiscountPercent;
    } else {
      disc = this.maxDiscuont;
      this.itemsList[idx].DiscountPercent = this.maxDiscuont;
    }
    if (this.itemsList[idx].DiscountPercent == null) {
      this.itemsList[idx].DiscountPercent = 0;
    }
    const qty = this.itemsList[idx].Quantity;
    const price = this.itemsList[idx].UnitPrice;
    let lineTotal = +(qty * price).toFixed(
      this.COMPANY.DecimalAmountTotalDocument
    );
    const taxamount = +(
      lineTotal *
      (this.itemsList[idx].TaxRate / 100)
    ).toFixed(this.COMPANY.DecimalAmountTotalDocument);
    lineTotal = +(lineTotal + taxamount).toFixed(
      this.COMPANY.DecimalAmountTotalDocument
    );
    lineTotal = +(lineTotal - +(lineTotal * (disc / 100))).toFixed(
      this.COMPANY.DecimalAmountTotalDocument
    );
    this.itemsList[idx].LinTot = +lineTotal.toFixed(
      this.COMPANY.DecimalAmountTotalDocument
    );
    this.getTotalOnLoad();
    // if (this.itemsList[idx].Discount == null) { this.itemsList[idx].Discount = 0; }
    // const qty = this.itemsList[idx].Quantity;
    // const price = this.itemsList[idx].UnitPrice;
    // let lineTotal = Number((qty * price).toString());
    // const taxamount = Number(
    //   (lineTotal * (this.itemsList[idx].TaxRate / 100)).toString()
    // );
    // lineTotal = Number((lineTotal + taxamount).toString());
    // lineTotal = Number((lineTotal - (lineTotal * (disc / 100))).toString());
    // this.itemsList[idx].LinTot = lineTotal.toString();
    // this.getTotalOnLoad();
  }

  getTotalOnLoad() {
    this.total = 0;
    this.totalUSD = 0;
    this.tax = 0;
    this.discount = 0;
    this.totalWithoutTax = 0;
    this.itemsList.forEach((element) => {
      const lintot = +(element.UnitPrice * element.Quantity).toFixed(
        this.COMPANY.DecimalAmountTotalDocument
      );
      const disc = +(lintot * (element.DiscountPercent / 100)).toFixed(
        this.COMPANY.DecimalAmountTotalDocument
      );
      this.discount = +(disc + this.discount).toFixed(
        this.COMPANY.DecimalAmountTotalDocument
      );
      this.totalWithoutTax = lintot - disc + this.totalWithoutTax;
      this.tax = +(
        (lintot - disc) * (element.TaxRate / 100) +
        this.tax
      ).toFixed(this.COMPANY.DecimalAmountTotalDocument);
    });
    if (this.saleOrder.DocCurrency == "COL") {
      this.total += +(this.totalWithoutTax + this.tax).toFixed(
        this.COMPANY.DecimalAmountTotalDocument
      );
      this.totalUSD += +(
        (this.totalWithoutTax + this.tax) /
        this.DailyExRate
      ).toFixed(this.COMPANY.DecimalAmountTotalDocument);
    } else {
      this.total += +(
        (this.totalWithoutTax + this.tax) *
        this.DailyExRate
      ).toFixed(this.COMPANY.DecimalAmountTotalDocument);
      this.totalUSD += +(this.totalWithoutTax + this.tax).toFixed(
        this.COMPANY.DecimalAmountTotalDocument
      );
    }

    this.total = +this.total.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.totalUSD = +this.totalUSD.toFixed(
      this.COMPANY.DecimalAmountTotalDocument
    );
    this.DifferenceG = +this.total.toFixed(
      this.COMPANY.DecimalAmountTotalDocument
    );
  }

  getExRate() {
    this.blockUI.start("Obteniendo Tipos de Cambio, Espere Por Favor...");
    this.exrate.getExchangeRate().subscribe(
      (data: any) => {
        if (data.result) {
          this.blockUI.stop();
          this.DailyExRate = data.exRate;
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(
            `Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
          );
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error getCustomers!!!, Error: ${error}`
        );
      }
    );
  }

  getMaxDiscout() {
    this.blockUI.start("Obteniendo tipos de cambio, Espere Por Favor...");
    this.documentService.getMaxDiscout().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.maxDiscuont = data.discount;
        } else {
          this.alertService.errorAlert(
            `Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
          );
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error getCustomers!!!, Error: ${error.error.Message}`
        );
      }
    );
  }

  getAccount() {
    this.blockUI.start("Obteniendo Cuentas, Espere Por Favor...");
    this.accountService.getAccount().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.accountList = data.accountList;
          this.cashForm.patchValue({
            AccountCash: data.accountList[0].Account,
          });
          this.transferForm.patchValue({
            AccountTransf: data.accountList[0].Account,
          });
          this.checkForm.patchValue({
            AccountCheck: data.accountList[0].Account,
          });
          //this.GetPayTerms();
        } else {
          this.alertService.errorAlert(
            "Error al cargar Cuentas - Error: " + data.errorInfo.Message
          );
        }
      },
      (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  closePayModal(_isFromView = false) {
    if (this.V_CreditCards.length > 0 && !this.isBilling) {
      this.alertService.infoInfoAlert(`Elimine las tarjetas agregadas`);
      return;
    }

    if (_isFromView) {
      this.documentsToMergeOrder = [];
      this.saleOrders.forEach((x) => (x.IsSelected = false));
      this.isDocumentsMerged = false;
      this.isMerginDocuments = false;
      this.isDocumentCommited = false;
      this.isLocked = false;
      this.generateUniqueInvoiceId();
    }

    // console.log('payments', this.V_CreditCards);
    // console.log('payments', this.isBilling);

    this.modalPay.close();
    this.isOnSubmit = false;
    this.isLocked = this.isDocumentsMerged;
  }

  GetCurrencyType() {
    this.blockUI.start("Obteniendo los tipos de monedas...");
    this.paramsService.GetCurrencyType().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.length > 0) {
          this.currencyList = data;
          this.currencyList.sort();
          this.allCurrencyList = data;
          this.currencyPayment = "COL";
          this.funcion();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(
            "Error al cargar las monedas - " + data.errorInfo.Message
          );
        }
      },
      (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  funcion() {
    let inputValue = this.DEFAULT_BUSSINESS_PARTNER;
    let code = inputValue;
    let codePos = this.bpCodeList.indexOf(code);
    let cardName = this.bpNameList[codePos];

    // let customer = this.bpList.filter(cus => {
    //   return cus.CardCode === code;
    // });

    // this.userCurrency = customer[0].Currency;

    // this.getCurrencyByUser(customer[0].Currency);

    // if (cardName !== this.invForm.get('cardName').value) {
    //   this.cashForm.patchValue({ cardName: cardName });
    // }
  }

  getCurrencyByUser(_currency: string) {
    this.currencyList = this.allCurrencyList;
    if (_currency === "COL" || _currency === "USD") {
      this.currencyList = this.currencyList.filter(
        (cur) => cur.Id === _currency
      );

      this.currencyList.push({
        Id: "USD",
        Name: "Dólares",
        Symbol: "$",
      });
    }
    this.currencyList = this.currencyList.sort();
    this.currencyPayment = this.currencyList[0].Id;
    // if (this.currencyList.length === 1) {
    //   // this.invForm.patchValue({ currency: this.currencyList[0].Id });
    //   // this.currencyPayment = this.currencyList[0].Id;
    // }
    // else {
    //   this.currencyPayment = 'COL';
    //   this.cashForm.patchValue({ currency: "COL" }); //
    // }

    this.SetCurr(_currency);
  }

  SetCurr(_currency: string) {
    let cur = this.currencyList.filter((curr) => {
      return curr.Id === _currency;
    });

    this.setCurr = cur[0].Symbol;
    if (_currency === "COL") {
      this.setCurr = "₡";
      this.currencyPayment = "COL";
    } else {
      this.currencyPayment = "USD";
      this.setCurr = "$";
    }
    this.userCurrency = this.currencyPayment;
  }

  removeCreditCard(index, _voucherNumber: string) {
    console.log("voucher ->", _voucherNumber);
    let hasPinPadPayment = this.pinPadCards.some(
      (x) => x.ReferenceNumber === _voucherNumber
    );
    // this.pinPadCards.forEach(x => {
    //   if (x.ReferenceNumber === _voucherNumber) hasPinPadPayment = true;
    // });
    let iPPTrasaction = this.pinPadCards.find(
      (x) => x.ReferenceNumber === _voucherNumber
    );

    if (iPPTrasaction !== undefined) {
      this.blockUI.start(`Anulando tarjeta, espere por favor`);
      this.paymentService.cancelPinPadCard(iPPTrasaction).subscribe(
        (next) => {
          if (next.result) {
            this.pinPadCards = this.pinPadCards.filter(
              (x) => x.ReferenceNumber !== _voucherNumber
            );
            this.TotalCards -= this.V_CreditCards[index].CreditSum;
            this.V_CreditCards.splice(index, 1);
            this.setTotalAmount();
          } else {
            this.alertService.errorAlert(
              `No se puede anular la tarjeta seleccionada, Detalle: ${next.errorInfo.Message}`
            );
          }
          this.blockUI.stop();
        },
        (error) => {
          this.blockUI.stop();
          console.log(error);
        }
      );
    } else {
      this.TotalCards -= this.V_CreditCards[index].CreditSum;
      this.V_CreditCards.splice(index, 1);
      this.setTotalAmount();
    }
  }

  onCtrlBTransferencia() {
    this.patchZeroes();
    this.transferForm.patchValue({
      TransSum: this.DifferenceG.toFixed(
        this.COMPANY.DecimalAmountTotalDocument
      ),
    });
    this.addAmountTransfer();
  }

  addAmountTransfer() {
    console.log(2);
    this.TotalTransfer = parseFloat(this.transferForm.value.TransSum);
    const MSUM = +(
      this.TotalCards +
      this.TotalTransfer +
      this.TotalCheck
    ).toFixed(this.COMPANY.DecimalAmountTotalDocument);
    if (this.TotalTransfer > 0) {
      if (MSUM <= this.TotalG) {
        this.setTotalAmount();
      } else {
        this.alertService.infoInfoAlert(
          "El monto ingresado en transferencia supera el total de la factura."
        );
        this.transferForm.patchValue({ TransSum: 0 });
        this.TotalTransfer = 0;
        this.setTotalAmount();
      }
    } else {
      this.transferForm.patchValue({ TransSum: 0 });
      this.TotalTransfer = 0;
      this.setTotalAmount();
      // this.alertService.infoInfoAlert(
      //   "El monto ingresado en Total es incorrecto."
      // );
    }
  }

  CreatePay() {
    if (this.isBilling) {
      this.attemptsWhileBilling++;
      return;
    }

    let _totalCards = 0;
    if (this.V_CreditCards.length > 0) {
      this.V_CreditCards.forEach((vcc) => {
        _totalCards += parseFloat(vcc.CreditSum.toString());
      });
      _totalCards += parseFloat(this.creditCardForm.value.CreditSum);
    } else {
      _totalCards = parseFloat(this.creditCardForm.value.CreditSum);
    }
    let totalCash = +this.cashForm.value.TotalCash;
    let totalTransfers = +this.transferForm.value.TransSum;
    _totalCards = +_totalCards;

    this.requiredReference = false;
    if (totalTransfers > 0 && this.transferForm.value.Ref === "") {
      this.requiredReference = true;
      this.alertService.warningInfoAlert(
        "El campo de referencia es requerido en la transferencia"
      );
      return;
    }

    this.isBilling = true;

    this.facturando = true;
    let payOk = true;
    if (this.ReceivedG < this.TotalG) {
      //payOk = this.partialPayValidator();
    }
    if (payOk) {

      this.blockUI.start("Generando factura y pago, espere por favor");

      this.documentService
        .CreateInvoiceFromSO(this.CreateInvoiceDocument())
        .subscribe(
          (callback) => {
            this.blockUI.stop();
            if (
              callback && callback.Data &&
              callback.Data.PaymentsResponse &&
              callback.Data.PaymentsResponse.length > 0 &&
              callback.Data.PaymentsResponse[0].PaymentResponse
            ) {
              this.dateTimeExitUI = formatDate(
                new Date(),
                "yyyy-MM-dd HH:mm:ss SSS a",
                "en"
              );
              this.titleInvoice = "Pago exitoso";
              this.closePayModal();
              this.isLocked = true;
              this.lastPayment =
                callback.Data.PaymentsResponse[0].PaymentResponse.DocEntry;
              this.btnVisibleBool = false;
              this.correctInvoice = true;
              this.facturando = false;
              this.attemptsWhileBilling = 0;
              this.isBilling = false;

              this.returnedDocNum = "";
              this.returnedDocEntry = "";
              this.returnedNumFE = "";
              this.lastPaymentsResponse = [];
              this.lastPaymentsResponse = callback.Data.PaymentsResponse;


              (<HTMLButtonElement>(
                document.getElementById("triggerAfterPayModal")
              )).click();
              let docEntries: number[] = [];

              callback.Data.PaymentsResponse.forEach((x) => {
                this.returnedDocNum += x.DocNum + ", ";
                this.returnedDocEntry += x.DocEntry + ", ";
                this.returnedNumFE += x.NumDocFe + ", ";
                docEntries.push(x.DocEntry);
              });

              this.printARInvoice(docEntries);

              this.returnedDocNum = this.returnedDocNum.slice(0, -2);
              this.returnedDocEntry = this.returnedDocEntry.slice(0, -2);
              this.returnedNumFE = this.returnedNumFE.slice(0, -2);
            } else {
              console.log(callback.Data);
              if (callback && callback.Data) {
                let message = "";

                if (callback.Data && callback.Data.PaymentsResponse.length > 0) {
                  if (
                    callback.Data.PaymentsResponse[0].PaymentResponse &&
                    callback.Data.PaymentsResponse[0].PaymentResponse.errorInfo
                  ) {
                    message =
                      callback.Data.PaymentsResponse[0].PaymentResponse.errorInfo
                        .Message;
                  }
                }

                this.alertService.errorAlert(
                  `Error al aplicar pago ${message}`
                );
              } else {
                this.alertService.errorAlert(
                  `Error al generar factura y pago ${callback.Data && callback.Data.errorInfo ? callback.Data.errorInfo.Message : ""
                  }`
                );
              }

              this.isBilling = false;
              this.attemptsWhileBilling = 0;
            }
            this.facturando = false;
          },
          (error) => {
            console.log(error);
            this.facturando = false;
            this.isLocked = true;
            this.blockUI.stop();
            let err = error && error.Code ? `Error al generar factura y pago, Código: ${error.Code}, Mensaje: ${error.Message}`: '';
            this.alertService.errorAlert(err ? err :`Error al intentar conectar con el servidor, Error: ${error}`);

            this.attemptsWhileBilling = 0;
            this.isBilling = false;
          }
        );
    }
  }

  printARInvoice(_docEntries: number[]): void {
    this.docEntries = _docEntries;
    this.blockUI.start("Generando la impresión...");
    const REPORT_MERGER = {
      DocEntries: _docEntries,
      ReportType: this.isFromCreditPay ? ReportType.InvoiceCredit : ReportType.ArInvoice,
    } as IReportMerger;
    this.reportsService.MergeReportsToPrint(REPORT_MERGER).subscribe(
      (data: any) => {
        this.blockUI.stop();
        printJS({
          printable: data,
          type: "pdf",
          base64: true,
        });
      },
      (error: any) => {
        this.blockUI.stop();
        console.log("error");
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  generateUniqueInvoiceId(): void {
    const USER_PREFIXID = JSON.parse(this.storage.getCurrentSession()).PrefixId;

    const DATE = new Date();

    const DAYS =
      DATE.getDate() < 10 ? "0" + DATE.getDate() : DATE.getDate().toString();
    const MONTS =
      DATE.getMonth() + 1 < 10
        ? "0" + (DATE.getMonth() + 1)
        : (DATE.getMonth() + 1).toString();
    const YEAR = DATE.getFullYear()
      .toString()
      .slice(
        DATE.getFullYear().toString().length - 2,
        DATE.getFullYear().toString().length
      );

    const HOURS =
      DATE.getHours() < 10 ? "0" + DATE.getHours() : DATE.getHours();
    const MINUTES =
      DATE.getMinutes() < 10 ? "0" + DATE.getMinutes() : DATE.getMinutes();
    const SECONDS =
      DATE.getSeconds() < 10 ? "0" + DATE.getSeconds() : DATE.getSeconds();

    this.uniqueInvCode = `${USER_PREFIXID + DAYS + MONTS + YEAR + HOURS + MINUTES + SECONDS
      }`;

    console.log("UIC ", this.uniqueInvCode);
  }

  getAccountsBank() {
    this.blockUI.start("Obteniendo Bancos, Espere Por Favor...");
    this.bankService.getAccountsBank().subscribe(
      (data: any) => {
        if (data.result) {
          this.banksList = data.banksList;
          this.checkForm.patchValue({ BankNames: data.banksList[0].BankCode });
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(
            "error al obtener información de los Bancos - Error: " +
            data.errorInfo.Message
          );
        }
      },
      (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  closeAfterPayModal(): void {
    this.isLocked = false;
    this.changeColones = 0.0;
    this.isFromCreditPay = false;
    this.documentsToMergeOrder = [];
    this.isMerginDocuments = false;
    this.isDocumentsMerged = false;
    this.isRequestingDocument = false;
    this.search();
    this.generateUniqueInvoiceId();
  }

  printInvoice(): void {
    this.printARInvoice(this.docEntries);
  }

  async cancelPayment(): Promise<void> {
    let confirmationResult = await this.alertService.ConfirmationAlert(
      "Confirmación",
      `Va a cancelar el pago ¿Desea continuar?`,
      "Continuar"
    );
    if (!confirmationResult) return;

    this.blockUI.start(`Cancelando pago, espere por favor`);
    this.isCancellingPayment = true;
    this.paymentService.CancelPayment({ DocEntry: this.lastPayment }).subscribe(
      next => {
        this.isCancellingPayment = false;
        this.blockUI.stop();

          (<HTMLButtonElement>(
            document.getElementById("triggerCloseAuxiliar")
          )).click();
          this.onSubmit(this.selectedSaleOrder, this.payContentModal);
      },
      (error) => {
        console.log(error);
        this.blockUI.stop();
        let err = error && error.Code ? `No se pudo cancelar el pago, Código: ${error.Code}, Mensaje: ${error.Message}`: '';
        this.alertService.errorAlert(err ? err :`Error al intentar conectar con el servidor, Error: ${error}`);
      }
    );
  }

  resetRequest(): void {
    if (!this.saleOrders.some((x) => x.IsSelected)) {
      this.isLocked = false;
      console.log("Resettin request");
    }
  }

  redirectToSO() {
    this.router.navigate(["so"]);
  }
  redirectToQuotation() {
    this.router.navigate(["quotation"]);
  }

  changeCurrency(): void { }

  ToggleDocumentSelection(
    _id: number,
    _docEntry,
    _isNormalSelection = false,
    _modalContent: unknown
  ): void {
    this.isDocumentsMerged = false;
    let IS_CHECKED = (<HTMLInputElement>document.getElementById(`cb${_id}`))
      .checked;

    if (_isNormalSelection) IS_CHECKED = true;

    this.saleOrders.find((x) => x.Id === _id).IsSelected = IS_CHECKED;
    this.isLocked = this.saleOrders.some((x) => x.IsSelected);
    this.isMerginDocuments =
      this.saleOrders.filter((x) => x.IsSelected).length > 1;

    if (IS_CHECKED) {
      this.blockUI.start(`Obteniendo documento`);
      this.soAndSqService.GetSaleOrder(_docEntry).subscribe(
        (next) => {
          this.blockUI.stop();
          if (next.result) {
            next.Data.DocumentLines.forEach((x) => {
              (x.BaseEntry = next.Data.DocEntry),
                (x.BaseType = BaseTypeLines.INVOICE);
            });
            const IS_ADDED = this.documentsToMergeOrder.some(
              (x) =>
                (JSON.parse(x.Value) as ISaleOrder).DocNum === next.Data.DocNum
            );

            if (!IS_ADDED) {
              this.documentsToMergeOrder.push({
                Key: _id,
                Value: JSON.stringify(next.Data),
              });

              this.MergeDocuments();
            }

            if (_isNormalSelection) {
              this.onSubmit(_docEntry, _modalContent);
            }
          } else {
            console.log(next);
            this.saleOrders.find((x) => x.Id === _id).IsSelected = false;
            this.alertService.errorInfoAlert(
              `No se pudo obtener el documento ${next.errorInfo.Message}`
            );
          }
        },
        (error) => {
          console.log(error);
          this.saleOrders.find((x) => x.Id === _id).IsSelected = false;
          this.alertService.errorInfoAlert(
            `No se pudo obtener el documento: ${error}`
          );
          this.blockUI.stop();
        }
      );
    } else {
      this.documentsToMergeOrder = this.documentsToMergeOrder.filter(
        (x) => x.Key != _id
      );
      if (this.documentsToMergeOrder.length > 0) {
        this.MergeDocuments();
      } else {
        this.isDocumentsMerged = false;
      }
    }
  }

  createMeregedDocuments(_payContent): void {
    this.createDocument(_payContent, true);
  }

  MergeDocuments(): void {
    const DOCUMENTS_TO_MERGE = this.documentsToMergeOrder.map(
      (x) => JSON.parse(x.Value) as ISaleOrder
    );

    const TOTAL_DOCUMENTS = +DOCUMENTS_TO_MERGE.reduce(
      (sum, order) => sum + order.DocTotal,
      0
    ).toFixed(this.COMPANY.DecimalAmountTotalDocument);

    // check cardcode integrity
    if (
      !DOCUMENTS_TO_MERGE.every(
        (val, i, arr) => val.CardCode === arr[0].CardCode
      )
    ) {
      console.error("Cant merge documents with diferent cardcode");
      this.alertService.infoInfoAlert(
        `No se pueden agrupar documentos con diferente código de cliente`
      );
      const POPPED = DOCUMENTS_TO_MERGE.pop();
      this.documentsToMergeOrder.pop();
      this.saleOrders.find((x) => x.DocNum === POPPED.DocNum).IsSelected =
        false;
      return;
    }

    // check payment method integrity
    if (
      !DOCUMENTS_TO_MERGE.every(
        (val, i, arr) => val.U_Facturacion === arr[0].U_Facturacion
      )
    ) {
      console.error("Cant merge documents with diferent payment");
      this.alertService.errorInfoAlert(
        `No se pueden agrupar documentos con diferente método de pago`
      );
      const POPPED = DOCUMENTS_TO_MERGE.pop();
      this.saleOrders.find((x) => x.DocNum === POPPED.DocNum).IsSelected =
        false;
      this.documentsToMergeOrder.pop();
      return;
    }

    //check cardname integrity
    if (
      !DOCUMENTS_TO_MERGE.every(
        (val, i, arr) => val.CardName === arr[0].CardName
      ) &&
      DOCUMENTS_TO_MERGE.length == 2 &&
      !this.isDocumentsMerged
    ) {
      // this.alertService.infoInfoAlert(`La información del primer documento seleccionado será usada para la cabecera del documento principal`);
    }

    // get default header data
    let SALE_ORDER_BASE = JSON.parse(
      this.documentsToMergeOrder[0].Value
    ) as ISaleOrder;
    // const DOCUMENTS_TO_MERGE = this.documentsToMergeOrder.filter(x => (JSON.parse(x.Value) as ISaleOrder).DocEntry !== SALE_ORDER_BASE.DocEntry);

    //merge lines
    this.documentsToMergeOrder
      .filter(
        (x) =>
          (JSON.parse(x.Value) as ISaleOrder).DocEntry !==
          SALE_ORDER_BASE.DocEntry
      )
      .forEach((x) => {
        const CHILD = JSON.parse(x.Value) as ISaleOrder;
        CHILD.DocumentLines.forEach((y) =>
          SALE_ORDER_BASE.DocumentLines.push(y)
        );
      });

    //recalculate totals
    SALE_ORDER_BASE.DocTotal = TOTAL_DOCUMENTS;
    this.saleOrder = SALE_ORDER_BASE;
    this.OverrideMainLines();

    // display document
    this.saleOrder.DocDate = formatDate(
      this.saleOrder.DocDate,
      "yyyy-MM-dd",
      "en"
    );
    this.previewForm.patchValue({ ...this.saleOrder });
    this.isDocumentsMerged = true;
  }

  PreviewMergedDocuments(): void {
    this.isRaisedFromMergedEvent = true;
    this.MergeDocuments();
    (<HTMLButtonElement>document.getElementById("previewTrigger")).click();
  }

  SetValueUDF<V>(key: string, value: V): void {
    for (let i = 0; i < this.UdfsInDocument.length; i++) {
      if (this.UdfsInDocument[i].UDFName === key) {
        this.UdfsInDocument[i].Value = value;
        break;
      }
    }
  }
  GetDocumentUDFs(): UDFModel2[] {
    let UDFList: UDFModel2[] = [
      {
        Value: null,
        UDFName: "FacturaVencida",
        Description: "U_FacturaVencida",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "Facturacion",
        Description: "U_Facturacion",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "NVT_Medio_Pago",
        Description: "U_NVT_Medio_Pago",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "ObservacionFE",
        Description: "U_ObservacionFE",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "Almacen",
        Description: "U_Almacen",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "TipoDocE",
        Description: "U_TipoDocE",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "CorreoFE",
        Description: "U_CorreoFE",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "NumIdentFE",
        Description: "U_NumIdentFE",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "TipoIdentificacion",
        Description: "U_TipoIdentificacion",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "Online",
        Description: "U_Online",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "CLVS_POS_UniqueInvId",
        Description: "U_CLVS_POS_UniqueInvId",
        Type: 'String',
      },
    ];
    return UDFList;
  }

  RisePBAutorizeContacts(_show: boolean, _cardCode: string): void {
    if (_show) {

      let modalOption: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        ariaLabelledBy: 'modal-basic-title',
        size: 'sm',
        windowClass: 'Modal-Xl',
        centered: true
      };

      this.modalBPContacts = this.modalService.open(BPAutorizedContactsComponent, modalOption);
      this.modalBPContacts.componentInstance.cardCode = _cardCode;
    }
  }
}
