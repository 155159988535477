import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IBusinessPartner, Series } from 'src/app/models';
import { AlertService, BusinessPartnerService, AccountService, CardService, SeriesService, BranchOfficeService } from 'src/app/services';
import { DocType } from 'src/app/enum/enum';
import { first } from 'rxjs/operators';
import { BranchOfficeModel2 } from 'src/app/models/branch-office';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-branch-office',
  templateUrl: './branch-office.component.html',
  styleUrls: ['./branch-office.component.scss']
})
export class BranchOfficeComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  branchOfficeForm: FormGroup;
  numberingForm: FormGroup;
  customers: IBusinessPartner[];
  accounts: any[]; // AccountModel[]
  cards: any[]; //CardsModel[]
  series: Series[];
  branchOfficesSAP: { Key: string, Value: string }[];

  constructor(
    private router: Router,
    private activatedRotue: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private customerService: BusinessPartnerService,
    private accountService: AccountService,
    private cardService: CardService,
    private numberingSerieService: SeriesService,
    private branchOfficeService: BranchOfficeService) { }

  ngOnInit() {
    this.branchOfficeForm = this.formBuilder.group({
      Id: [0],
      Code: ['', Validators.required],
      Name: ['', Validators.required],
      CashBusinessPartnerCode: ['', Validators.required],
      CashAccount: ['', Validators.required],
      TransferAccount: ['', Validators.required],
      CreditCard: ['', Validators.required],
      VoucherNum: ['', Validators.compose([Validators.required, Validators.maxLength(20)])]
    });

    this.numberingForm = this.formBuilder.group({
      Invoice: [''],
      TEInvoice: [''],
      IncommingPayment: [''],
      Quotation: [''],
      SaleOrder: [''],
      Customer: [''],
      Supplier: [''],
      CreditNote: [''],
      DebitNote: [''],
      ApInvoice: [''],
      ApPayment: ['']
    });

    this.getCustomers();
    this.getAccounts();
    this.getCards();
    this.getSeries();
    this.getBranchOfficesSAP();

    this.checkIfEditingOrCreating();
  }

  checkIfEditingOrCreating() {
    let branchOfficeId = Number(this.activatedRotue.snapshot.paramMap.get('id'));
    if (branchOfficeId > 0) {
      this.blockUI.start('Procesando...');
      this.branchOfficeService.GetBranchOffice('', branchOfficeId)
        .pipe(first())
        .subscribe(response => {
          this.blockUI.stop();

          if (response.result) this.setBranchOfficeForm(response.BranchOffice);
          else this.alertService.errorAlert(response.errorInfo.Message);
        }, err => {
          this.blockUI.stop();
          this.alertService.errorAlert(err);
        });
    }
  }

  resetBranchOfficeForm() {
    this.branchOfficeForm.reset({
      Id: 0,
      Code: '',
      Name: '',
      CashBusinessPartnerCode: '',
      CashAccount: '',
      TransferAccount: '',
      CreditCard: '',
      VoucherNum: ''
    });
  }

  resetNumberingForm() {
    this.numberingForm.reset({
      Invoice: '',
      IncommingPayment: '',
      Quotation: '',
      SaleOrder: '',
      Customer: '',
      Supplier: '',
      CreditNote: '',
      DebitNote: '',
      ApInvoice: '',
      ApPayment: '',
      TEInvoice: ''
    });
  }

  setBranchOfficeForm(branchOffice: BranchOfficeModel2) {
    this.branchOfficeForm.reset({
      Id: branchOffice.Id,
      Code: branchOffice.Code,
      Name: branchOffice.Name,
      CashBusinessPartnerCode: branchOffice.CashBusinessPartnerCode,
      CashAccount: branchOffice.CashAccount,
      TransferAccount: branchOffice.TransferAccount,
      CreditCard: branchOffice.CreditCard,
      VoucherNum: branchOffice.VoucherNum
    });

    if (branchOffice.Series && branchOffice.Series.length > 0) {
      branchOffice.Series.forEach(x => {
        switch (x.NumType) {
          case DocType.Invoice:
            this.numberingForm.get('Invoice').setValue(x.Serie);
            break;
          case DocType.Quotation:
            this.numberingForm.get('Quotation').setValue(x.Serie);
            break;
          case DocType.IncommingPayment:
            this.numberingForm.get('IncommingPayment').setValue(x.Serie);
            break;
          case DocType.SaleOrder:
            this.numberingForm.get('SaleOrder').setValue(x.Serie);
            break;
          case DocType.BP:
            this.numberingForm.get('Customer').setValue(x.Serie);
            break;
          case DocType.Supplier:
            this.numberingForm.get('Supplier').setValue(x.Serie);
            break;
          case DocType.APInvoice:
            this.numberingForm.get('ApInvoice').setValue(x.Serie);
            break;
          case DocType.APPayment:
            this.numberingForm.get('ApPayment').setValue(x.Serie);
            break;
          case DocType.CreditNote:
            this.numberingForm.get('CreditNote').setValue(x.Serie);
            break;
          case DocType.TE_Invoice:
            this.numberingForm.get('TEInvoice').setValue(x.Serie);
            break;
          case DocType.DebitNote:
            this.numberingForm.get('DebitNote').setValue(x.Serie);
            break;
        }
      });
    }
  }

  get formControls() {
    return this.branchOfficeForm.controls;
  }

  onClickCancel() {
    this.router.navigateByUrl('branch-offices');
  }

  getSeriesAssigned() {
    let series: any[] = [];

    if (this.numberingForm.get("Invoice").value) series.push({ NumType: 1, Serie: Number(this.numberingForm.get("Invoice").value) });
    if (this.numberingForm.get("IncommingPayment").value) series.push({ NumType: 3, Serie: Number(this.numberingForm.get("IncommingPayment").value) });
    if (this.numberingForm.get("Quotation").value) series.push({ NumType: 2, Serie: Number(this.numberingForm.get("Quotation").value) });
    if (this.numberingForm.get("SaleOrder").value) series.push({ NumType: 4, Serie: Number(this.numberingForm.get("SaleOrder").value) });
    if (this.numberingForm.get("Customer").value) series.push({ NumType: 5, Serie: Number(this.numberingForm.get("Customer").value) });
    if (this.numberingForm.get("Supplier").value) series.push({ NumType: 6, Serie: Number(this.numberingForm.get("Supplier").value) });
    if (this.numberingForm.get("CreditNote").value) series.push({ NumType: 7, Serie: Number(this.numberingForm.get("CreditNote").value) });
    if (this.numberingForm.get("DebitNote").value) series.push({ NumType: 11, Serie: Number(this.numberingForm.get("DebitNote").value) });
    if (this.numberingForm.get("ApInvoice").value) series.push({ NumType: 8, Serie: Number(this.numberingForm.get("ApInvoice").value) });
    if (this.numberingForm.get("ApPayment").value) series.push({ NumType: 9, Serie: Number(this.numberingForm.get("ApPayment").value) });
    if (this.numberingForm.get("TEInvoice").value) series.push({ NumType: 10, Serie: Number(this.numberingForm.get("TEInvoice").value) });

    return series;
  }

  onClickSaveChanges() {
    let branchOffice = { ...this.branchOfficeForm.value, Series: this.getSeriesAssigned() };
    this.blockUI.start('Procesando...');

    const endPointName = branchOffice.Id === 0 ? 'PostBrachOfficess' : 'PutBrachOfficess';

    this.branchOfficeService[endPointName](branchOffice)
      .pipe(first()).
      subscribe(response => {
        this.blockUI.stop();

        if (response.result) {
          this.alertService.successInfoAlert('Proceso finalizado exitosamente');
          this.onClickCancel();
        } else {
          this.alertService.errorAlert(response.errorInfo.Message);
        }
      }, err => {
        this.blockUI.stop();
        this.alertService.errorAlert(err);
      });
  }

  getBranchOfficesSAP() {
    this.blockUI.start('Procesando...');
    this.branchOfficesSAP = [];

    this.branchOfficeService.GetBranchOfficesSAP().pipe(first()).subscribe(response => {
      this.blockUI.stop();

      if (response.result) this.branchOfficesSAP = response.BranchOffices;
      else this.alertService.errorAlert(response.errorInfo.Message);
    }, err => {
      this.blockUI.stop();
      this.alertService.errorAlert(err);
    });
  }

  getCustomers() {
    this.customers = [];

    this.blockUI.start('Procesando...');
    this.customerService.GetCustomers()
      .pipe(first())
      .subscribe((data: any) => {
        this.blockUI.stop();

        if (data.result && data.BPS) {
          this.customers = data.BPS.filter(x => x.QryGroup1 && x.QryGroup1 === 'Y');
        } else {
          this.alertService.errorAlert(data.errorInfo.Message);
        }

      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(error);
      });
  }

  getAccounts() {
    this.accounts = [];
    this.blockUI.start('Procesando...');
    this.accountService.getAccount()
      .pipe(first())
      .subscribe((data: any) => {
        this.blockUI.stop();

        if (data.result) {
          this.accounts = data.accountList;
        } else {
          this.alertService.errorAlert(data.errorInfo.Message);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(error);
      });
  }

  getCards() {
    this.cards = [];

    this.blockUI.start('Procesando...');
    this.cardService.getCards()
      .pipe(first())
      .subscribe((data: any) => {
        this.blockUI.stop();

        if (data.result) {
          this.cards = data.cardsList;
        } else {
          this.alertService.errorAlert(data.errorInfo.Message);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.errorAlert(error);
      });
  }

  getSeries() {
    this.series = [];
    this.blockUI.start('Procesando...');
    this.numberingSerieService.getSeriesList()
      .pipe(first()).
      subscribe((data: any) => {
        this.blockUI.stop();

        if (data.result) {
          this.series = data.Series;
        } else {
          this.alertService.errorAlert(data.errorInfo.Message);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(error);
      });
  }

}
