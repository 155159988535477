import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';

import { LayoutService } from '../../../shared/services/layout.service';
import { AuthenticationService, ExRateService, StorageService, AlertService, TokenService, PublisherService } from 'src/app/services';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { first, filter } from 'rxjs/operators';
import { IToken } from 'src/app/models';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  //varbox
  @Input() navLayout: string;
  @Input() defaultNavbar: string;
  @Input() toggleNavbar: string;
  @Input() toggleStatus: boolean;
  @Input() navbarEffect: string;
  @Input() deviceType: string;
  @Input() headerColorTheme: string;
  @Input() leftHeaderColorTheme: string;
  @Input() navbarColorTheme: string;
  @Input() activeNavColorTheme: string;
  @Input() headerHeight: number;
  @Input() collapsedLeftHeader: boolean;
  @Input() currentUser: string;
  @Input() currentOfflineUser: string;
  @Input() currencyChange: number;
  @Input() branchOffice: string;
  @Input() wareHouseName: string;

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  conectado: boolean;//= navigator.onLine;
  noconectado: boolean;// = !navigator.onLine;
  conectadoOffline = false;

  // monto del tipo de cambio
  successCurrencyChange: number;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private storage: StorageService,
    private exRateService: ExRateService,
    private alertService: AlertService,
    private tokenService: TokenService,
    private storageService: StorageService,
    private publisherService: PublisherService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.authenticationService.currentOfflineUser.subscribe(x => {
      if (x && !navigator.onLine) {
        this.connectionStatusMessage = "Conectado al servidor local";
        this.noconectado = false;
        this.conectadoOffline = true;
      }
      this.currentOfflineUser = x;
    });

    this.tokenService.iToken.subscribe(next => {
      if (next.IsRequiredToken && !next.IsSessionExpired) { // Sesion a punto de expirar
        const _SESSION = JSON.parse(this.storage.getCurrentSession());

        if (_SESSION === null || _SESSION["UserName"] === undefined || _SESSION['Password'] === undefined) {
          this.alertService.warningAlert('No se pudieron recuperar los crendeciales, la sesión expira en menos de 10 minutos, vuelva a iniciar sesión por favor');
        }
        else {
          this.authenticationService.login(_SESSION["UserName"], _SESSION["Password"])
            .pipe(first()).subscribe(next => {
              console.log('request token status on complete ', next);
            }, error => {
              console.log('request token status on error: ', error);
            }, () => {

            });
        }
        this.tokenService.iToken.next({
          IsRequiredToken: false,
          IsSessionExpired: false
        } as IToken);
      }

      if (next.IsSessionExpired && next.IsSessionExpired) { // Sesion expirada
        const _SESSION = JSON.parse(this.storage.getCurrentSession());

        this.alertService.warningAlert('Sesión expirada, intentando recuperar la sesión...');

        this.authenticationService.login(_SESSION["UserName"], _SESSION["Password"])
          .pipe(first()).subscribe(next => {
            this.alertService.successInfoAlert('Sesión recuperada satisfactoriamente');
            console.log('request token status on complete ', next);
          }, error => {
            this.alertService.errorAlert(`No se pudo recuperar la sesión, Error ${error}, vuelva a iniciar sesión nuevamente gracias`);
            console.log('request token status on error: ', error);
          }, () => {

          });

        this.tokenService.iToken.next({
          IsRequiredToken: false,
          IsSessionExpired: false
        } as IToken);
      }
    });

    this.tokenService.thirdPartyToken.subscribe(next => {
      if (next.IsRequiredToken && !next.IsSessionExpired) { // Sesion a punto de expirar
        const _SESSION = this.storageService.GetThirdPartySession();

        this.authenticationService.getTokenPadron().pipe(first()).subscribe(data => {},
            error => {
              this.alertService.errorAlert(error);
            });

        this.tokenService.thirdPartyToken.next({
          IsRequiredToken: false,
          IsSessionExpired: false
        } as IToken);
      }

      if (next.IsSessionExpired && next.IsSessionExpired) { // Sesion expirada
        this.authenticationService.getTokenPadron().pipe(first()).subscribe(data => {},
            error => {
              this.alertService.errorAlert(error);
            });

        this.tokenService.thirdPartyToken.next({
          IsRequiredToken: false,
          IsSessionExpired: false
        } as IToken);
      }
    });
  }

  ngOnInit() {
    this.conectado = navigator.onLine;
    this.noconectado = !navigator.onLine;
    this.wareHouseName = null;

    this.conectadoOffline = false;
    this.successCurrencyChange = 0;
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.alertService.successInfoAlert('Se ha recuperado la conexión a internet');
      this.connectionStatusMessage = ' Conectado';
      this.connectionStatus = 'online';
      this.conectado = true;
      this.noconectado = false;
      this.conectadoOffline = false;
      const _SESSION = JSON.parse(this.storage.getSession(false));
      if (_SESSION != null) {
        this.storage.setOnline();
        this.authenticationService.login(_SESSION.UserName, _SESSION.Password)
          .pipe(first())
          .subscribe(
            data => {
              this.storage.setOnline();
              this.connectionStatus = 'online';
              this.conectado = true;
              this.noconectado = false;

              this.alertService.infoInfoAlert('Conectado con el servidor en línea');
              this.authenticationService.getTokenPadron()
                .pipe(first())
                .subscribe(next => {
                },
                  error => {
                    this.connectionStatus = 'offline';
                    this.conectado = true;
                    this.noconectado = false;
                    this.conectadoOffline = false;
                    this.alertService.errorAlert(error);
                  });
            },
            error => {
              this.connectionStatus = 'offline';
              this.conectado = false;
              this.noconectado = false;
              this.conectadoOffline = false;
              this.alertService.warningInfoAlert(`Se ha dectectado una anomalía en el servidor: ${error}`);
            }, () => this.blockUI.stop());
      }
    }));

    if (navigator.onLine) {
      this.connectionStatusMessage = "Conectado";
    }
    else {
      if (this.currentOfflineUser !== null) {
        this.connectionStatusMessage = "Conectado al servidor local";
        this.noconectado = false;
        this.conectadoOffline = true;
      }
      else {
        this.connectionStatusMessage = "Sin conexión a internet";
        this.noconectado = true;
        this.conectadoOffline = false;
      }
    }

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.alertService.warningInfoAlert('Se ha perdido la conexión a internet');
        this.conectado = false;
        this.noconectado = false;
        this.connectionStatus = 'offline';
        this.connectionStatusMessage = "Conectando a servicios locales...";
        this.conectadoOffline = true;
        const _SESSION = JSON.parse(this.storage.getSession(true));
        if (_SESSION != null) {
          this.authenticationService.loginOffline(_SESSION.UserName, _SESSION.Password, true)
            .subscribe(
              data => {
                // this.conectadoOffline = true;
                this.connectionStatusMessage = 'Conectado al servidor local';

                this.alertService.infoInfoAlert('Se ha conectado con el servidor local');
                this.blockUI.stop();
              },
              error => {
                // this.conectadoOffline = false;
                this.connectionStatusMessage = 'Sin conexión a ningun servidor';
                console.log(error);
                this.storage.setCurrentSessionOffline(_SESSION);
                this.alertService.errorAlert(`Error en el servidor local, por favor contacte al administrador, Error: ${error}`);
                this.blockUI.stop();
              }, () => {
                this.blockUI.stop();
              });
        }
      // }
    }));
    this.authenticationService.getTokenPadron()
      .pipe(first())
      .subscribe(next => {
        this.conectadoOffline = false;
      }, error => {
      });

    this.publisherService.getObservable()
      .pipe(filter(x => x.Target === 'BrachOffice'))
      .subscribe(next => {
        this.branchOffice = next.Data.Name;
      });

      this.publisherService.getObservable()
      .pipe(filter(x => x.Target === 'WareHouseName'))
      .subscribe(next => {
        console.log(`some warehouse name`, next.Data);
        this.wareHouseName = next.Data;
      });
      this.GetWareHouseName();
    this.getBranchOffice();
  }

  disconnect() {
    this.connectionStatusMessage = ' No conectado';
    this.connectionStatus = 'offline';
    this.conectado = false;
    this.noconectado = false;
    this.conectadoOffline = false;
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  online() {
    this.storage.setOnline();
    alert('esta online');
  }

  offline() {
    this.storage.setOffline();
    alert('esta offline');
  }

  changeTheToggleStatus() {
    this.layoutService.getToggleStatus();

  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  /*
  getExchangeRate() {
    this.blockUI.start('Obteniendo tipo de cambio, Espere Por Favor...'); // Start blocking
      this.exRateService.getExchangeRate()
      .subscribe( (data: any) => {
        if (data.result) {
          this.currencyChange = data.exRate;
      this.successCurrencyChange = 2;
      this.blockUI.stop(); // Stop blocking
        } else {
          this.currencyChange = 0;
      this.successCurrencyChange += 1;
      this.blockUI.stop(); // Stop blocking
        }
      }, (error) => {
        this.currencyChange = 0;
    this.successCurrencyChange += 1;
    this.blockUI.stop(); // Stop blocking
      });
    }
*/


  imprimir() {
    /*
    printer
      .print("C:/Users/cl.juan.villacis/Desktop/b.pdf")
      .then(console.log)
      .catch(console.error);
      */

  }

  getBranchOffice() {
    let branchOffice = this.storageService.getBranchOffice();
    if (!branchOffice || Object.keys(branchOffice).length === 0) return;

    this.branchOffice = branchOffice.Name;
  }

  GetWareHouseName(): void {
    this.wareHouseName = this.storage.GetWareHouseName();
  }
}
