import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IPublication } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PublisherService {

  private publisher: Subject<IPublication>;

  constructor() {
    this.publisher = new Subject<IPublication>();
  }

  publish(publication: IPublication) {
    this.publisher.next(publication);
  }

  getObservable() {
    return this.publisher;
  }

}
