import { Component, OnInit, DoCheck, Renderer2 } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { debounceTime, distinctUntilChanged, map, first } from "rxjs/operators";
import {
  DecimalPipe,
  formatDate,
  CurrencyPipe,
  DatePipe,
} from "@angular/common";
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions,
  NgbPaginationConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { PRINTERS_PER_REPORT } from "src/app/enum/enum";

// MODELOS

import { Company, IInvoice, IPPTransaction, IPrinter } from "src/app/models";
// RUTAS

// COMPONENTES

// SERVICIOS
import {
  BusinessPartnerService,
  ParamsService,
  PaymentService,
  AccountService,
  CardService,
  BankService,
  ExRateService,
  AuthenticationService,
  PermsService,
  AlertService,
  ReportsService,
  CommonService,
  StorageService,
  UserService,
  DocumentService,
} from "../../../../services/index";

import { ReportType } from "src/app/enum/enum";
import { BranchOfficeModel2 } from "src/app/models/branch-office";
import {
  IncommingPayment,
  DocumentPayment,
} from "src/app/models/i-incomming-payment";
import {
  CreateIncomingPayment,
  IncomingPaymentLines,
  SLCreditCard,
  SLDebitNote,
} from "src/app/models/i-create-payment";
const printJS = require("print-js");
@Component({
  selector: "app-incoming-payment",
  templateUrl: "./incoming-payment.component.html",
  styleUrls: ["./incoming-payment.component.scss"],
  providers: [DecimalPipe, CurrencyPipe, DatePipe],
})
export class IncomingPaymentComponent implements OnInit, DoCheck {
  //VARBOX
  flagForm: boolean;
  interestToForgiveAmountDiff: number;
  interestToForgiveAmount: number;
  isPercentageSymbol: boolean;
  invoice: IInvoice;
  previewForm: FormGroup;
  titleInvoice: string;
  returnedDocNum: number;
  returnedDocEntry: number;
  @BlockUI() blockUI: NgBlockUI;
  branchOffice: BranchOfficeModel2;
  COMPANY: Company; // Usada para guardar las configuraciones del a compania
  fecha: Date; // fecha actual
  //searchForm: FormGroup; // formulario para la busqueda de pagos
  //incomingPaymentForm: FormGroup; // formulario para los pagos
  cashForm: FormGroup; // formulario para el efectivo
  creditCardForm: FormGroup; // formulario para las tarjetas de credito
  transferForm: FormGroup; // formulario para la tranferencia
  checkForm: FormGroup; // formulario para el cheque

  serieList: any[] = []; // lista de las sedes
  bpList: any[] = []; // lista de clientes
  bpCodeList: string[] = []; // lista de los codigos de clientes
  bpNameList: string[] = []; // lista de los nombres de clientes
  bpCodeNameList: string[] = []; // lista de los codigo con nombres de clientes
  incomingPaymentList: any[] = []; // lista con los pagos
  currencyList: any[] = []; // lista de tipos de cambio
  currency: string; // moneda selecionada al buscar los anticipos
  totalCOL: number; // monto total en colones
  totalUSD: number; // monto total en dolares
  currencyChange: number; // monto del tipo de cambio
  accountList: any[] = []; // lista de cuentas
  cardsList: any[] = []; // lista de tarjetas
  banksList: any[] = []; // lista de bancos
  salesManList: any[] = []; // lista de vendedores
  currencyPayment: string; // moneda selecionada al buscar los anticipos
  ChangeG: number; // guarda el vuelto
  // modal de pagos
  closeResult: string;
  modalPay: any; // instancia de la modal de pagos

  TotalG: number; // monto total del pago
  ReceivedG: number; // monto total recibido del pago
  DifferenceG: number; // monto total de la diferencia, o salto restante

  TotalCash: number; // monto total del pago en efectivo
  TotalCards: number; // monto total del pago en tarjetas
  TotalTransfer: number; // monto total del pago en transferencia
  TotalCheck: number; // monto total del pago en cheque

  V_CreditCards: any[] = []; // lista de pagos registrados con tarjetas de credito
  CardName: string; // nombre de la tarjeta seleccionada para el pago con tarjeta
  V_Checks: any[] = []; // lista de pagos registrados con cheques
  DocEntry: number;
  isPagoCuenta: boolean;

  unamePattern = "^d{2}/d{2}$";
  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  permisos: boolean = true;
  canPrint = false;
  viewParamTitles: any[] = []; // llena la lista con los titulos de las paginas parametrizados
  title: string; // titulo de la vista
  hasInvoice = false;
  /**Pagination */
  page = 1;
  pageSize = 10;
  collectionSize: number;

  /*Fecha Transferencia*/
  mDate: string;

  //*/
  //  Fe: IFe;
  uniqueInvCode: string;
  attemptsWhileBilling: number;
  lastInvoice: number;
  pinPadCard: IPPTransaction;
  pinPadCards: IPPTransaction[];
  userCurrency: string; // Usado para precargar el tipo de moneda que usara el usuario
  DEFAULT_BUSSINESS_PARTNER: string;
  setCurr: string; // tipo de moneda escogida en simbolo
  allCurrencyList: any[] = []; // lista de todos los tipos de cambio existentes en la aplicacion
  isBilling: boolean;
  isOnSubmit: boolean = false;
  maxDiscuont: any;
  DailyExRate: number;
  itemsList: any[] = []; // lista de items
  identificationTypeList: any[] = [];
  conta: number;
  total: number;
  tax: number;
  discount: number;
  totalWithoutTax: number;
  typesInvoice: any[] = [];
  isAllowedPriceListChange: boolean; // Controla el cambio de lista por parte del usuario
  TO_FIXED_PRICE: string; // Contiene la cantidad de decimales a usar en el precio unitario
  TO_FIXED_TOTALLINE: string; // Contiene la cantidad de decimales a usar en el total de linea
  TO_FIXED_TOTALDOCUMENT: string; // Contiene la cantidad de decimales a usar en el total del documento
  nowDate: any;
  StatusFact: String;
  Formadate: string;
  dateTimeExitUI = "";
  SlpsList: string[]; //= []; // lista de los vendedores
  status: boolean[] = [true, false];
  invoiceSelected = false; //Existe factura seleccionada

  // modal de pagos
  modalChange: any; // instancia de la modal de vueltos
  correctInvoice: boolean = false; //dice si el pago se realizo correctamente
  facturando: boolean = false;
  hasLines: boolean = false; //dice si el pago se realizo correctamente

  btnVisibleBool: boolean;
  changeColones: number; // vuelto en colones
  changeDolares: number; // vuelto en dolares
  defaultGroupNum: any;
  defaultListNum: any;
  defaultContado: any;

  // permisos: boolean = true;
  paymentTypes: any[] = [
    { id: "1", name: "A30Dias" },
    { id: "2", name: "Contado" },
  ];

  incommingPaymentForm: FormGroup;
  availableAmount: number;
  documentsPayment: DocumentPayment[];
  receivedAmount: number;
  authForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private businessPartnerService: BusinessPartnerService,
    private commonService: CommonService,
    private paramsService: ParamsService,
    private sPerm: PermsService,
    private authenticationService: AuthenticationService,
    private paymentService: PaymentService,
    private modalService: NgbModal,
    private accountService: AccountService,
    private cardService: CardService,
    private bankService: BankService,
    private exRateService: ExRateService,
    private alertService: AlertService,
    private reportsService: ReportsService,
    private config: NgbPaginationConfig,
    private storage: StorageService,
    private cp: CurrencyPipe,
    private datePipe: DatePipe,
    private userService: UserService,
    private documentService: DocumentService,
    private renderer: Renderer2
  ) {
    // obtiene al anno para el footer
    this.currentUserSubscription =
      this.authenticationService.currentUser.subscribe((user) => {
        this.currentUser = user;
      });
    this.fecha = new Date();
    this.config.size = "sm";
  }

  ngDoCheck(): void {
    const parent: HTMLElement = document.getElementById("main_body");
    if (parent && parent.style.cssText !== "padding-right: 0px;") {
      this.renderer.setStyle(parent, "padding-right", "0px"); //style="padding-right: 0 !important"
      console.log("Overriding styles");
    }
  }

  ngOnInit() {
    this.checkPermits();
    this.InitVariables();
  }

  onChangeForgiveInterest() {
    if (this.isPercentageSymbol) {
      if (
        !this.incommingPaymentForm.get("ForgiveInterest").value ||
        this.incommingPaymentForm.get("ForgiveInterest").value < 0 ||
        this.incommingPaymentForm.get("ForgiveInterest").value > 100
      )
        this.incommingPaymentForm.get("ForgiveInterest").setValue(0);
      this.resetTotals();
    } else {
      if (
        !this.incommingPaymentForm.get("ForgiveInterest").value ||
        this.incommingPaymentForm.get("ForgiveInterest").value < 0
      ) {
        this.resetTotals();
        this.incommingPaymentForm.patchValue({ ForgiveInterest: 0 });
      } else {
        this.resetTotals();
        this.interestToForgiveAmount =
          +this.incommingPaymentForm.get("ForgiveInterest").value;
        this.interestToForgiveAmountDiff = this.interestToForgiveAmount;
      }
    }
  }

  resetTotals() {
    this.receivedAmount = Number(
      this.incommingPaymentForm.get("ReceivedAmount").value
        ? this.incommingPaymentForm.get("ReceivedAmount").value
        : 0
    );
    this.availableAmount = this.receivedAmount;
    this.totalCOL = 0;
    this.totalUSD = 0;
    this.interestToForgiveAmountDiff = 0;
    this.interestToForgiveAmount = 0;
    this.hasInvoice = false;
    this.documentsPayment = [];
    if (this.incomingPaymentList)
      this.incomingPaymentList.forEach((x) => {
        x.Selected = false;
        x.Pago = 0;
        x.docBalanceToPay = 0;
        x.Interest2 = 0;
        x.InterestToPay = 0;
      });
  }

  checkPermits() {
    this.sPerm.getPerms(this.currentUser.userId).subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {
          let permListtable: any = data.perms;
          data.perms.forEach((Perm) => {
            if (Perm.Name === "V_Ipay") {
              this.permisos = Perm.Active;
            }
          });
        } else {
          this.permisos = false;
        }
      },
      (error) => {
        this.permisos = false;
        this.blockUI.stop();
      }
    );
  }

  getExchangeRate() {
    this.blockUI.start("Obteniendo Tipo de Cambio, Espere Por Favor...");
    this.exRateService.getExchangeRate().subscribe(
      (data: any) => {
        if (data.result) {
          this.DailyExRate = data.exRate;
          this.currencyChange = data.exRate;

          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(
            "Error al obtener tipo de cambio - " + data.errorInfo.Message
          );
        }
      },
      (error) => {
        this.blockUI.stop();
        this.alertService.errorAlert(
          "Error al obtener tipo de cambio - " + error
        );
      }
    );
  }

  // funcion para obtener una lista de clientes segun la compañía seleccionada
  getCustomers() {
    this.blockUI.start("Obteniendo Clientes, Espere Por Favor..."); // Start blocking
    this.businessPartnerService.GetCustomers().subscribe(
      (data: any) => {
        if (data.result) {
          this.bpList.length = 0;
          this.bpCodeList.length = 0;
          this.bpNameList.length = 0;
          this.bpCodeNameList.length = 0;
          this.bpList = data.BPS;
          for (let item of data.BPS) {
            this.bpCodeList.push(item.CardCode);
            this.bpNameList.push(item.CardName);
            this.bpCodeNameList.push(`${item.CardCode} - ${item.CardName}`);
          }
          this.blockUI.stop(); // Stop blocking
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert(
            "Error al obtener la lista de clientes - " + data.errorInfo.Message
          );
        }
      },
      (error) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  // funcion para obtener una lista de cuentas segun la compañía seleccionada
  getAccount() {
    this.blockUI.start("Obteniendo Cuentas, Espere Por Favor..."); // Start blocking
    this.accountService.getAccount().subscribe(
      (data: any) => {
        if (data.result) {
          this.accountList = data.accountList;
          this.cashForm.patchValue({
            AccountCash: data.accountList[0].Account,
          });
          this.transferForm.patchValue({
            AccountTransf: data.accountList[0].Account,
          });
          this.checkForm.patchValue({
            AccountCheck: data.accountList[0].Account,
          });
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert(
            "Error al obtener las cuentas - " + data.errorInfo.Message
          );
        }
      },
      (error) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  // funcion para obtener una lista de tarjetas segun la compañía seleccionada
  getCards() {
    this.blockUI.start("Obteniendo Tarjetas, Espere Por Favor..."); // Start blocking
    this.cardService.getCards().subscribe(
      (data: any) => {
        if (data.result) {
          this.cardsList = data.cardsList;
          this.creditCardForm.patchValue({
            CreditCard: data.cardsList[0].CreditCard,
          });
          this.CardName = data.cardsList[0].CardName;
          this.blockUI.stop(); // Stop blocking
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert(
            "Error al obtener las tarjetas - " + data.errorInfo.Message
          );
        }
      },
      (error) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  // funcion para obtener una lista de bancos segun la compañía seleccionada
  getAccountsBank() {
    this.blockUI.start("Obteniendo Bancos, Espere Por Favor..."); // Start blocking
    this.bankService.getAccountsBank().subscribe(
      (data: any) => {
        if (data.result) {
          this.banksList = data.banksList;
          this.checkForm.patchValue({ BankNames: data.banksList[0].BankCode });
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(
            "Error al obtener cuentas de bancos - " + data.errorInfo.Message
          );
        }
        // Stop blocking
      },
      (error) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  // funcion para el typeahead
  searchBP = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.bpCodeNameList
              .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
              .slice(0, 10)
      )
    );

  getInvoiceList() {
    if (this.isPagoCuenta) {
      this.alertService.infoInfoAlert(
        "Para la nueva búsqueda, desmarque pago a cuenta."
      );
      return;
    }

    if (!this.incommingPaymentForm.get("BusinessPartner").value) {
      this.alertService.infoAlert("Debe seleccionar el cliente.");
      return;
    }

    this.incomingPaymentList = [];
    this.collectionSize = 0;
    this.page = 1;
    this.currency = this.incommingPaymentForm.value.Currency;
    this.blockUI.start("Obteniendo Facturas, Espere Por Favor..."); // Start blocking

    this.paymentService
      .getPayInvoices(this.incommingPaymentForm)
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data.result) {
            this.collectionSize = data.InvoicesList.length;
            this.incomingPaymentList = data.InvoicesList.map((so, i) => ({
              id: i + 1,
              ...so,
            }));
          } else {
            this.alertService.errorInfoAlert(data.errorInfo.Message);
          }
          this.blockUI.stop();
        },
        (error) => {
          this.blockUI.stop();
          this.alertService.errorInfoAlert(error);
        }
      );
  }

  activePagocuenta() {
    this.isPagoCuenta = this.incommingPaymentForm.get("AccountPayment").value;
    this.onChangeReceivedAmount();
  }

  // funcion para colocar los totales en pago a cuenta segun la moneda seleccionada
  onChangeReceivedAmount() {
    this.resetTotals();

    if (this.isPagoCuenta) {
      this.incomingPaymentList = [];
      this.collectionSize = 0;
      this.incommingPaymentForm.get("ForgiveInterest").setValue(0);

      if (this.incommingPaymentForm.get("Currency").value === "COL") {
        this.totalCOL = this.receivedAmount;
        this.totalUSD = this.totalCOL / this.currencyChange;
      } else {
        this.totalUSD = this.receivedAmount;
        this.totalCOL = this.totalUSD * this.currencyChange;
      }

      this.totalCOL = this.commonService.roudToTwo(this.totalCOL);
      this.totalUSD = this.commonService.roudToTwo(this.totalUSD);
    }
  }

  setTotals() {
    this.totalCOL = 0;
    this.totalUSD = 0;

    this.incomingPaymentList.forEach((element) => {
      if (element.Selected) {
        if (this.currency === "COL") this.totalCOL += element.Pago;
        else this.totalUSD += element.Pago;
      }
    });

    if (this.currency === "COL") {
      this.totalUSD = this.totalCOL / this.currencyChange;
    } else {
      this.totalCOL = this.totalUSD * this.currencyChange;
    }

    this.totalCOL = this.commonService.roudToTwo(this.totalCOL);
    this.totalUSD = this.commonService.roudToTwo(this.totalUSD);

    this.hasInvoice = this.totalUSD > 0 || this.totalCOL > 0;
  }

  onDocumentSelected(document: any, selected: boolean) {
    document.Selected = selected;

    if (!document.Selected) {
      if (document.Pago && document.Pago > 0 && this.receivedAmount)
        this.availableAmount += document.Pago;

      if (document.Pago && document.Pago > 0 && this.interestToForgiveAmount) {
        this.interestToForgiveAmountDiff += document.Interest2;
      }
      document.Pago = 0;
      document.docBalanceToPay = 0;
      document.Interest2 = 0;
      document.InterestToPay = 0;
      document.Selected = false;
    } else {
      let interest2 = this.commonService.roudToTwo(
        this.calculateDocumentInterest(document.Interest)
      );
      let docBalanceToPay = document.DocBalance;
      let interestToPay = interest2;

      if (this.receivedAmount && this.receivedAmount > 0) {
        if (this.availableAmount <= 0) {
          document.Selected = false;
          return;
        }

        interestToPay =
          this.availableAmount >= interest2 ? interest2 : this.availableAmount;
        interestToPay = this.commonService.roudToTwo(interestToPay);
        this.availableAmount -= interestToPay;

        docBalanceToPay =
          this.availableAmount >= document.DocBalance
            ? document.DocBalance
            : this.availableAmount;
        docBalanceToPay = this.commonService.roudToTwo(docBalanceToPay);
        this.availableAmount -= docBalanceToPay;

        this.availableAmount = this.commonService.roudToTwo(
          this.availableAmount
        );
      }

      // if (docBalanceToPay > 0) {
      document.docBalanceToPay = docBalanceToPay;
      // }

      // if (interestToPay > 0) {
      document.Interest2 = interest2;
      document.InterestToPay = interestToPay;
      // }

      document.Pago = this.commonService.roudToTwo(
        interestToPay + docBalanceToPay
      );
    }

    this.setTotals();
  }

  async CheckPayBalance(content) {
    let paymentTotal = 0;
    if (this.isPagoCuenta) paymentTotal = this.receivedAmount;
    else {
      this.incomingPaymentList.forEach((x) => {
        if (x.Selected) paymentTotal += x.Pago;
      });

      paymentTotal = this.commonService.roudToTwo(paymentTotal);

      if (!this.incommingPaymentForm.get("ReceivedAmount").value) {
        this.receivedAmount = paymentTotal;
        this.availableAmount = 0;
        this.incommingPaymentForm.get("ReceivedAmount").setValue(paymentTotal);
      }
    }

    if (this.incommingPaymentForm.invalid) {
      this.alertService.infoInfoAlert("Campos requeridos con formato inválido");
      return;
    }

    if (
      paymentTotal < (this.currency === "COL" ? this.totalCOL : this.totalUSD)
    ) {
      this.alertService.infoInfoAlert(
        "El total a pagar es mayor al monto recibido"
      );
      return;
    }

    if (this.totalCOL === 0 && this.totalUSD === 0) {
      this.alertService.infoInfoAlert("Monto de pago inválido");
      return;
    }

    let confirmationResult = await this.alertService.ConfirmationAlert(
      "Confirmación",
      `El total del pago es ₡${paymentTotal}. ¿Desea continuar?`,
      "Continuar"
    );
    if (!confirmationResult) return;

    this.DifferenceG = this.totalCOL;
    this.resetModalData();
    this.TotalG = this.totalCOL;
    this.setTotalAmount();
    let modalOption: NgbModalOptions = {};
    modalOption.backdrop = "static";
    modalOption.keyboard = false;
    modalOption.ariaLabelledBy = "modal-basic-title";
    modalOption.size = "lg";
    modalOption.windowClass = "Modal-Xl";

    this.creditCardForm.patchValue({ VoucherNum: "0" });
    this.creditCardForm.patchValue({ CardNum: "9999" });
    this.creditCardForm.patchValue({ CardValid: "01/99" });

    this.commonService.setPaymentDefaultValues(
      this.branchOffice,
      this.cashForm,
      this.creditCardForm,
      this.transferForm,
      this.checkForm
    );

    this.modalPay = this.modalService.open(content, modalOption);

    this.modalPay.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  // funcion para cerrar la modal de pagos
  close() {
    this.modalPay.close();
  }

  printInvoice() {
    this.printReport(this.DocEntry);
  }

  printReport(DocEntry: number): void {
    this.blockUI.start("Generando la impresión...");
    this.reportsService.printReport(DocEntry, ReportType.PaidRecived).subscribe(
      (data: any) => {
        this.blockUI.stop();
        console.log("break");
        printJS({
          printable: data,
          type: "pdf",
          base64: true,
        });
      },
      (error: any) => {
        this.blockUI.stop();
        console.log("error");
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  /**
   * Method to create incomming payment
   * @constructor
   */
  CreatePay() {
    if (!this.flagForm) {
      this.alertService.infoAlert(
        `Se ha detectado una segunda petición, espere a que se complete la petición actual por favor`
      );
      return;
    }

    this.flagForm = false;

    let totalTransfer = this.transferForm.value.TransSum;
    if (totalTransfer && totalTransfer > 0 && !this.transferForm.value.Ref) {
      this.alertService.warningInfoAlert(
        "El campo de referencia es requerido en la transferencia"
      );
      return;
    }



    let incomingPaymentDocument = this.GenerateIncomingPaymentDocument();

    this.blockUI.start("Procesando, espere por favor");
    this.paymentService
      .CreateIncommingPaymentSL(incomingPaymentDocument)
      .subscribe(callback => {
          this.blockUI.stop();
          if (callback && callback.Data) {
            this.dateTimeExitUI = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss SSS a", "en");
            this.titleInvoice = "Pago aplicado correctamente";
            this.V_CreditCards = [];
            this.closePayModal();
            this.hasInvoice = false;
            this.DocEntry = callback.Data.DocEntry;
            this.returnedDocEntry = callback.Data.DocEntry;
            this.returnedDocNum = callback.Data.DocNum;
            this.canPrint = true;
            this.incomingPaymentList = [];
            this.documentsPayment = [];
            this.currency = this.incommingPaymentForm.value.Currency;
            this.isPagoCuenta = false;
            this.totalCOL = 0;
            this.totalUSD = 0;
            this.receivedAmount = 0;
            this.collectionSize = 0;
            this.incommingPaymentForm.patchValue({
              ReceivedAmount: "",
              AccountPayment: false,
              ForgiveInterest: 0,
            });
            (<HTMLButtonElement>(
              document.getElementById("triggerAfterPayModal")
            )).click();
            this.incommingPaymentForm.get("ForgiveInterest").disable();
            this.isPercentageSymbol = true;
            this.printInvoice();
          } else {
            this.flagForm = true;

            this.alertService.errorAlert(
              `Error al intentar crear el pago, Código: ${callback.Error.Code}, Mensaje: ${callback.Error.Message}`
            );
          }
        },
        (error) => {
          this.flagForm = true;
          this.blockUI.stop();
          let err = error && error.Code ? `Error al intentar crear pago, Código: ${error.Code}, Mensaje: ${error.Message}`: '';
          this.alertService.errorAlert(err ? err :`Error al intentar conectar con el servidor, Error: ${error}`);

        }
      );
  }

  GenerateIncomingPaymentDocument(): CreateIncomingPayment {
    const Payments: CreateIncomingPayment = {
      CardCode: this.incommingPaymentForm.value.BusinessPartner.split(" - ")[0],
      CardName: this.incommingPaymentForm.value.BusinessPartner.split(" - ")[1],
      ReceivedAmount: this.receivedAmount,
      DocType: "rCustomer",
      PaymentLines: this.GenerateIncomingPaymentLines(),
      CashAccount: this.cashForm.value.AccountCash,
      CashSum: this.cashForm.value.TotalCash,
      Remarks: this.incommingPaymentForm.value.Comments,
      AccountPayment: this.incommingPaymentForm.value.AccountPayment,
      DocCurrency: this.currencyPayment,
      DocRate: this.currencyChange,
      CounterReference: this.incommingPaymentForm.value.Reference,
      SalesPersonCode: "-1",
      PaymentCreditCards: this.V_CreditCards,
      TransferSum: this.transferForm.value.TransSum,
      TransferAccount: this.transferForm.value.AccountTransf,
      TransferDate: this.transferForm.value.DateTrans,
      TransferReference: this.transferForm.value.Ref,
      Total:this.TotalCards+this.TotalCash+this.TotalTransfer,
      U_MontoRecibido: (this.transferForm.value.TransSum +this.cashForm.value.TotalCash +this.TotalCards),
      Udfs: [
        {
          Value: this.uniqueInvCode,
          FieldType: 'String',
          Name: "U_DocumentKey",
        }
      ],
    } as CreateIncomingPayment;

    return Payments;
  }

  GenerateIncomingPaymentLines(): IncomingPaymentLines[] {
    let documentsPayment: IncomingPaymentLines[] = [];

    if (!this.isPagoCuenta && this.incomingPaymentList) {
      this.incomingPaymentList.forEach((x, index) => {
        if (x.Selected) {
          let documentPayment: IncomingPaymentLines = {
            DocEntry: x.DocEntry,
            DocNum: x.DocNum,
            Balance: 0,
            DebitMemo: null,
            DocType: x.DocType,
            PaidBalance: 0,
          } as IncomingPaymentLines;

          if (x.docBalanceToPay) {
            documentPayment.Balance = x.DocBalance;
            documentPayment.PaidBalance = x.docBalanceToPay;
          }

          if (x.InterestToPay) {
            documentPayment.DebitMemo = {
              DocEntry: 0,
              DocNum: 0,
              Interest: x.Interest2,
              PaidInterest: x.InterestToPay,
              Udfs: [
                {
                  Value: `DN${this.uniqueInvCode}_${index}`,
                  FieldType: 'String',
                  Name: "U_DocumentKey",
                },
              ],
            } as SLDebitNote;

            console.log(`DN${this.uniqueInvCode}_${index}`);
          }

          documentsPayment.push(documentPayment);
        }
      });
    }

    return documentsPayment;
  }

  closePayModal() {
    if (this.V_CreditCards.length > 0) {
      this.alertService.infoInfoAlert(`Elimine las tarjetas agregadas`);
      return;
    }

    this.resetModalData();
    this.InitVariables();

    this.modalPay.close();
    this.isOnSubmit = false;
  }

  resetModalData() {
    this.currencyPayment = this.currencyList[0].Id;
    this.TotalCash = 0;
    this.TotalCards = 0;
    this.TotalCheck = 0;
    this.TotalTransfer = 0;
    this.TotalG = 0;
    this.ReceivedG = 0;
    this.DifferenceG = 0;
    this.ChangeG = 0;
    this.V_Checks.length = 0;
    this.V_CreditCards.length = 0;
    this.cashForm.patchValue({ AccountCash: this.accountList[0].Account });
    this.cashForm.patchValue({ TotalCash: 0 });
    this.resetCreditCardForm();
    this.transferForm.patchValue({
      AccountTransf: this.accountList[0].Account,
    });

    this.transferForm.patchValue({
      DateTrans: this.datePipe.transform(new Date(), "y-MM-dd"),
    });
    this.transferForm.patchValue({ Ref: "" });
    // this.transferForm.patchValue({ Ref: '' });

    // this.transferForm.patchValue({ DateTrans: '' });

    this.transferForm.patchValue({ TransSum: 0 });
    this.checkForm.patchValue({ AccountCheck: this.accountList[0].Account });
    this.checkForm.patchValue({ BankNames: this.banksList[0].BankCode });
    this.checkForm.patchValue({ DueDate: "" });
    this.checkForm.patchValue({ CountryCod: "CR" });
    this.checkForm.patchValue({ AcctNum: "" });
    this.checkForm.patchValue({ CheckNum: "" });
    this.checkForm.patchValue({ CheckSum: 0 });

    /**REF CAJAS*/

    this.creditCardForm.patchValue({
      CreditCard: this.cardsList[0].CreditCard,
    });
    this.creditCardForm.patchValue({ CardNum: "" });
    this.creditCardForm.patchValue({ OwnerIdNum: "" });
    this.creditCardForm.patchValue({ CardValid: "" });
    this.creditCardForm.patchValue({ CreditSum: 0 });
    this.creditCardForm.patchValue({ VoucherNum: "" });
    this.transferForm.patchValue({
      AccountTransf: this.accountList[0].Account,
    });
  }

  addAmountCash() {
    this.ReceivedG = +this.ReceivedG.toFixed(
      this.COMPANY.DecimalAmountTotalDocument
    );
    this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalCash = parseFloat(this.cashForm.value.TotalCash);

    if (this.TotalCash > 0) {
      this.setTotalAmount();
    } else {
      this.cashForm.patchValue({ TotalCash: 0 });
      this.TotalCash = 0;
      this.setTotalAmount();
    }
  }

  addAmountTransfer() {
    this.TotalTransfer = parseFloat(this.transferForm.value.TransSum);
    const MSUM = +(
      this.TotalCards +
      this.TotalTransfer +
      this.TotalCheck
    ).toFixed(this.COMPANY.DecimalAmountTotalDocument);
    if (this.TotalTransfer > 0) {
      if (MSUM <= this.TotalG) {
        this.setTotalAmount();
      } else {
        this.alertService.infoInfoAlert(
          "El monto ingresado en transferencia supera el total de la factura."
        );
        this.transferForm.patchValue({ TransSum: 0 });
        this.TotalTransfer = 0;
        this.setTotalAmount();
      }
    } else {
      this.transferForm.patchValue({ TransSum: 0 });
      this.TotalTransfer = 0;
      this.setTotalAmount();
    }
  }

  addAmounCreditCard() {
    let totalcard = 0;
    if (this.creditCardForm.valid) {
      if (parseFloat(this.creditCardForm.value.CreditSum) > 0) {
        if (this.V_CreditCards.length > 0) {
          this.V_CreditCards.forEach((vcc) => {
            totalcard += parseFloat(vcc.CreditSum);
          });
          totalcard += parseFloat(this.creditCardForm.value.CreditSum);
        } else {
          totalcard = parseFloat(this.creditCardForm.value.CreditSum);
        }
        if (totalcard > 0) {
          if (totalcard + this.TotalTransfer + this.TotalCheck <= this.TotalG) {
            const CREDIT_CARD = this.cardsList.filter(
              (x) => x.CreditCard === this.creditCardForm.value.CreditCard
            )[0];

            this.V_CreditCards.push({
              CardValidUntil: this.creditCardForm.value.CardValid,
              CreditCardNumber: this.creditCardForm.value.CardNum,
              CrTypeCode: CREDIT_CARD.CardName.split(" ")[0],
              CreditCard: CREDIT_CARD.CardName.split(" ")[0],
              CardAccount:this.creditCardForm.value.CreditCard,
              CreditSum: this.creditCardForm.value.CreditSum,
              Curr: this.currencyPayment,
              FormatCode: CREDIT_CARD.CardName,
              OwnerIdNum: this.creditCardForm.value.OwnerIdNum,
              VoucherNum: this.creditCardForm.value.VoucherNum,
            });


            this.TotalCards = totalcard;
            this.setTotalAmount();

            this.creditCardForm.patchValue({ OwnerIdNum: "" });
            this.creditCardForm.patchValue({ CardNum: "9999" });
            this.creditCardForm.patchValue({ CardValid: "01/99" });
            this.creditCardForm.patchValue({ CreditSum: "0" });
          } else {
            this.creditCardForm.patchValue({ CreditSum: "0" });
            this.alertService.infoInfoAlert(
              "El monto ingresado en la tarjeta de crédito supera el total de la factura."
            );
          }
        } else {
          this.alertService.infoInfoAlert(
            "El monto ingresado en Total es incorrecto."
          );
          this.creditCardForm.patchValue({ CreditSum: 0 });
        }
      } else {
        this.creditCardForm.patchValue({ CreditSum: 0 });
      }
    } else {
      this.alertService.infoInfoAlert("Campos inválidos");
    }
  }

  addAmountCheck() {
    let totalcheck = 0;

    if (this.checkForm.valid) {
      if (parseFloat(this.checkForm.value.CheckSum) > 0) {
        if (this.V_Checks.length > 0) {
          this.V_Checks.forEach((vcc) => {
            totalcheck += parseFloat(vcc.CheckSum);
          });
          totalcheck += parseFloat(this.checkForm.value.CheckSum);
        } else {
          totalcheck = parseFloat(this.checkForm.value.CheckSum);
        }
        if (totalcheck > 0) {
          if (
            this.TotalCash +
              this.TotalCards +
              this.TotalTransfer +
              totalcheck <=
            this.TotalG
          ) {
            this.V_Checks.push({
              AcctNum: this.checkForm.value.AcctNum,
              BankCode: this.checkForm.value.BankNames,
              CheckAct: this.checkForm.value.AccountCheck,
              CheckNum: this.checkForm.value.CheckNum,
              CheckSum: this.checkForm.value.CheckSum,
              CountryCod: this.checkForm.controls.CountryCod.value,
              Curr: this.currencyPayment,
              DueDate: this.checkForm.value.DueDate,
            });
            this.TotalCheck = totalcheck;
            this.setTotalAmount();
          } else {
            this.alertService.infoInfoAlert(
              "El monto ingresado en el cheque supera el total de la factura."
            );
            this.TotalCards = 0;
            this.setTotalAmount();
          }
        } else {
          this.alertService.infoInfoAlert(
            "El monto ingresado en Total es incorrecto."
          );
        }
      } else {
        this.alertService.infoInfoAlert(
          "El monto total debe ser superior a cero."
        );
      }
    } else {
      this.alertService.infoInfoAlert("Campos inválidos");
    }
  }

  setTotalAmount() {
    this.ReceivedG = Number(
      (
        this.TotalCash +
        this.TotalCards +
        this.TotalCheck +
        this.TotalTransfer
      ).toString()
    );
    let diff = Number((this.TotalG - this.ReceivedG).toString());
    this.DifferenceG = Math.max(diff, 0.0);
    this.ChangeG = Math.max(0, -1 * diff);

    if (this.currencyPayment !== "COL") {
      this.changeDolares = this.ChangeG;
      this.changeColones = Number(
        (this.ChangeG * this.currencyChange).toString()
      );
    } else {
      this.changeDolares = Number(
        (this.ChangeG / this.currencyChange).toString()
      );
      this.changeColones = this.ChangeG;
    }
  }

  getSelectedOptionText(event: Event) {
    let selectedOptions = event.target["options"];
    let selectedIndex = selectedOptions.selectedIndex;
    this.CardName = selectedOptions[selectedIndex].text;
  }

  // funcion para eliminar el item de la lista de tarjetas de credito
  // recibe como parametro el item a eliminar
  removeCreditCard(index, _voucherNumber: string) {
    let iPPTrasaction = this.pinPadCards.find(
      (x) => x.ReferenceNumber === _voucherNumber
    );

    if (iPPTrasaction) {
      this.blockUI.start(`Anulando tarjeta, espere por favor`);
      this.paymentService.cancelPinPadCard(iPPTrasaction).subscribe(
        (next) => {
          if (next.result) {
            this.pinPadCards = this.pinPadCards.filter(
              (x) => x.ReferenceNumber !== _voucherNumber
            );
            this.TotalCards -= this.V_CreditCards[index].CreditSum;
            this.V_CreditCards.splice(index, 1);
            this.setTotalAmount();
          } else {
            this.alertService.errorAlert(
              `No se puede anular la tarjeta seleccionada, Detalle: ${next.errorInfo.Message}`
            );
          }
          this.blockUI.stop();
        },
        (error) => {
          this.blockUI.stop();
          console.log(error);
        },
        () => this.blockUI.stop()
      );
    } else {
      this.TotalCards -= this.V_CreditCards[index].CreditSum;
      this.V_CreditCards.splice(index, 1);
      this.setTotalAmount();
    }
  }

  removeCheck(index) {
    this.TotalCheck -= this.V_Checks[index].CheckSum;
    this.V_Checks.splice(index, 1);
    this.setTotalAmount();
  }

  // funcion para cambiar el tipo de moneda en la modal de pagos
  changeCurrency() {
    if (this.currencyPayment === "COL") {
      this.TotalG = this.totalCOL;
      this.ReceivedG = this.totalCOL;
    } else {
      this.TotalG = this.totalUSD;
      this.ReceivedG = this.totalUSD;
    }
    this.ReceivedG = 0;
    this.setTotalAmount();
  }

  GetParamsViewList() {
    this.blockUI.update("Cargando, espere por favor");
    this.paramsService.getParasmView().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.viewParamTitles = data.Params.filter((param) => {
            return param.type === 6;
          });
          this.ChargeParamstoView();
        } else {
          this.alertService.errorAlert(
            "Error al cargar los parámetros de la pagina - " +
              data.errorInfo.Message
          );
        }
      },
      (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  ChargeParamstoView() {
    let obj = this.viewParamTitles.filter((param) => {
      return param.Name === "T_incomingPayment";
    });
    this.title = obj[0].Text;
  }

  GetCurrencyType() {
    this.blockUI.update("Obteniendo las monedas.");
    this.paramsService.GetCurrencyType().subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.currencyList = data;
          this.currencyList = this.currencyList.reverse();
          this.incommingPaymentForm.patchValue({
            Currency: "COL",
          });
          this.GetParamsViewList();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(
            "Error al cargar las monedas - " + data.errorInfo.Message
          );
        }
      },
      (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  onCtrlBCash() {
    this.cashForm.patchValue({
      TotalCash: this.DifferenceG.toFixed(
        this.COMPANY.DecimalAmountTotalDocument
      ),
    });
    this.addAmountCash();
  }

  onCtrlBTarjeta() {
    this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.ReceivedG = +this.ReceivedG.toFixed(
      this.COMPANY.DecimalAmountTotalDocument
    );
    this.creditCardForm.patchValue({
      CreditSum: this.DifferenceG.toFixed(
        this.COMPANY.DecimalAmountTotalDocument
      ),
    });
  }

  onCtrlBTransferencia() {
    this.transferForm.patchValue({ TransSum: this.DifferenceG });
    this.addAmountTransfer();
  }

  onCtrlBCheque() {
    this.checkForm.patchValue({ CheckSum: this.DifferenceG });
  }

  resetCreditCardForm() {
    this.creditCardForm.reset({
      CreditCard: this.cardsList ? this.cardsList[0].CreditCard : "",
      CardNum: "9999",
      OwnerIdNum: "",
      CardValid: "01/99",
      CreditSum: 0,
      VoucherNum: "",
    });
  }

  CreateNew() {
    this.InitVariables();
  }

  InitVariables() {
    this.flagForm = true;
    this.GenerateUniqueInvoiceId();
    this.interestToForgiveAmountDiff = 0;
    this.interestToForgiveAmount = 0;
    this.isPercentageSymbol = true;
    this.COMPANY = this.storage.getCompanyConfiguration();
    this.branchOffice = this.storage.getBranchOffice();
    this.documentsPayment = [];
    this.TO_FIXED_PRICE = `1.${this.COMPANY.DecimalAmountPrice}-${this.COMPANY.DecimalAmountPrice}`;
    this.TO_FIXED_TOTALLINE = `1.${this.COMPANY.DecimalAmountTotalLine}-${this.COMPANY.DecimalAmountTotalLine}`;
    this.TO_FIXED_TOTALDOCUMENT = `1.${this.COMPANY.DecimalAmountTotalDocument}-${this.COMPANY.DecimalAmountTotalDocument}`;

    this.totalCOL = 0;
    this.totalUSD = 0;
    this.receivedAmount = 0;
    this.availableAmount = 0;
    this.currencyChange = 0;
    this.isPagoCuenta = false;
    this.conta = 0.0;
    this.total = 0.0;
    this.totalUSD = 0.0;
    this.tax = 0.0;
    this.discount = 0.0;
    this.DailyExRate = 0;
    this.ReceivedG = 0;
    this.TotalTransfer = 0;
    this.TotalCards = 0;
    this.TotalCheck = 0;
    this.TotalCash = 0;
    this.attemptsWhileBilling = 0;
    this.totalWithoutTax = 0.0;
    this.TotalG = 0.0;
    this.mDate = formatDate(new Date(), "yyyy-MM-dd", "en");
    this.V_CreditCards = [];

    this.hasInvoice = false;
    this.canPrint = false;
    this.incomingPaymentList = [];
    this.collectionSize = 0;
    this.pageSize = 10;
    this.ReceivedG = 0;
    this.pinPadCards = [];
    this.invoice = null;

    this.authForm = this.fb.group({
      Email: ["", Validators.required],
      Password: ["", Validators.required],
    });

    this.incommingPaymentForm = this.fb.group({
      BusinessPartner: ["", Validators.required],
      Currency: [""],
      ReceivedAmount: ["", Validators.required],
      AccountPayment: [false],
      Reference: [""],
      ForgiveInterest: [{ value: 0, disabled: true }], // #pr
      Comments: [""],
    });

    this.resetPreviewForm();

    this.cashForm = this.fb.group({
      AccountCash: ["", Validators.required],
      TotalCash: [0, Validators.required],
    });

    this.creditCardForm = this.fb.group({
      CreditCard: ["", Validators.required],
      CardNum: ["9999"],
      OwnerIdNum: ["", Validators.required],
      CardValid: ["01/99"],
      CreditSum: [0, Validators.required],
      VoucherNum: ["0", Validators.required],
    });

    this.transferForm = this.fb.group({
      AccountTransf: ["", Validators.required],
      DateTrans: [this.mDate, Validators.required],
      Ref: [""],
      TransSum: [0, Validators.required],
    });

    this.checkForm = this.fb.group({
      AccountCheck: ["", Validators.required],
      BankNames: ["", Validators.required],
      DueDate: ["", Validators.required],
      CountryCod: [{ value: "CR", disabled: true }, Validators.required],
      AcctNum: ["", Validators.required],
      CheckNum: ["", Validators.required],
      CheckSum: [0, Validators.required],
    });

    this.getExchangeRate();
    this.getCustomers();
    this.GetCurrencyType();
    this.GetParamsViewList();
    this.getAccount();
    this.getCards();
    this.getAccountsBank();
    this.canPrint = false;
  }

  changeCurr() {
    this.currencyPayment = this.incommingPaymentForm.get("Currency").value;
    this.onChangeReceivedAmount();
  }

  get formControls() {
    return this.incommingPaymentForm.controls;
  }

  calculateDocumentInterest(interest: number) {
    if (!this.incomingPaymentList) return;

    let forgiveInterest = Number(
      this.incommingPaymentForm.get("ForgiveInterest").value
    );
    if (
      (!forgiveInterest || forgiveInterest > 100 || forgiveInterest < 0) &&
      this.isPercentageSymbol
    ) {
      this.incommingPaymentForm.get("ForgiveInterest").setValue(0);
      forgiveInterest = 0;
    }

    let forgivedInterest = 0;
    if (this.isPercentageSymbol) {
      forgivedInterest = (interest * forgiveInterest) / 100;
    } else {
      if (interest - this.interestToForgiveAmountDiff <= 0) {
        this.interestToForgiveAmountDiff =
          (interest - this.interestToForgiveAmountDiff) * -1;
        forgivedInterest = interest;
      } else {
        forgivedInterest = this.interestToForgiveAmountDiff;
        this.interestToForgiveAmountDiff = 0;
      }
    }

    return interest - Number(forgivedInterest);
  }

  getOverdueTotal() {
    if (!this.incomingPaymentList) return 0;

    let total = 0;
    this.incomingPaymentList.forEach((x) => {
      if (x.Interest && x.Interest > 0) total += x.DocBalance;
    });
    return total;
  }

  getTotalBalance() {
    if (!this.incomingPaymentList) return 0;

    let total = 0;
    this.incomingPaymentList.forEach((x) => (total += x.DocBalance));
    return total;
  }

  getTotalDocBalanceToPay() {
    if (!this.incomingPaymentList) return 0;

    let total = 0;
    this.incomingPaymentList.forEach((x) => {
      if (x.docBalanceToPay) total += x.docBalanceToPay;
    });
    return total;
  }

  getTotalInterest() {
    if (!this.incomingPaymentList) return 0;

    let total = 0;
    this.incomingPaymentList.forEach((x) => (total += x.Interest));
    return total;
  }

  getTotalInterestToPay() {
    if (!this.incomingPaymentList) return 0;

    let total = 0;
    this.incomingPaymentList.forEach((x) => {
      if (x.Interest2) total += x.Interest2;
    });
    return total;
  }

  getAccountTotal() {
    if (!this.incomingPaymentList) return 0;

    let total = 0;
    this.incomingPaymentList.forEach((x) => {
      total += x.DocBalance + x.Interest;
    });
    return total;
  }

  getTotalPayment() {
    if (!this.incomingPaymentList) return 0;

    let total = 0;
    this.incomingPaymentList.forEach((x) => {
      if (x.Pago) total += x.Pago;
    });
    return total;
  }

  onClickEditInterest(authModal: any) {
    let options: any = { size: "md" };
    this.modalService.open(authModal, options);
  }

  onClickCanEditInterest() {
    let user: any = this.authForm.value;
    this.authForm.reset();
    this.modalService.dismissAll();

    this.blockUI.start("Procesando...");

    this.userService
      .CanEditInterest(user)
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.stop();
          if (response.result) {
            this.incommingPaymentForm.get("ForgiveInterest").enable();
          } else {
            this.alertService.errorAlert(response.errorInfo.Message);
          }
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.errorAlert(err);
        }
      );
  }

  isDocumentOverdue(docDueDate: Date) {
    if (!docDueDate) return false;

    return new Date(docDueDate) < new Date();
  }

  onDocBalanceToPayChange(document: any, $event: any) {
    const enteredAmount = Number($event.target.value);
    let newValue = enteredAmount;
    const oldValue = document.docBalanceToPay;

    if (enteredAmount < 0 || enteredAmount > document.DocBalance) {
      this.alertService.infoInfoAlert("Monto ingresado inválido");
      newValue = 0;
    }

    if (this.receivedAmount) {
      this.availableAmount += oldValue;

      if (enteredAmount > this.availableAmount) {
        this.alertService.infoInfoAlert(
          "El monto ingresado supera el total disponible"
        );
        newValue = 0;
      }

      this.availableAmount -= newValue;
    }

    document.docBalanceToPay = newValue;
    document.Pago = this.commonService.roudToTwo(
      document.docBalanceToPay + document.InterestToPay
    );

    this.setTotals();
  }

  getDocumentsForPayment() {
    let documentsPayment = [];

    if (!this.isPagoCuenta && this.incomingPaymentList) {
      this.incomingPaymentList.forEach((x, index) => {
        if (x.Selected) {
          let documentPayment: DocumentPayment = {
            DocEntry: x.DocEntry,
            DocNum: x.DocNum,
            Balance: 0,
            DebitMemo: null,
            DocType: x.DocType,
            PaidBalance: 0,
          };

          if (x.docBalanceToPay) {
            documentPayment.Balance = x.DocBalance;
            documentPayment.PaidBalance = x.docBalanceToPay;
          }

          if (x.InterestToPay) {
            documentPayment.DebitMemo = {
              DocEntry: 0,
              DocNum: 0,
              Interest: x.Interest2,
              PaidInterest: x.InterestToPay,
              DocumentKey: `DN${this.uniqueInvCode}_${index}`,
            };

            console.log(`DN${this.uniqueInvCode}_${index}`);
          }

          documentsPayment.push(documentPayment);
        }
      });
    }

    return documentsPayment;
  }

  preview(_docEntry: number, pay: any): void {
    this.blockUI.start("Obteniendo documento, espere por favor");
    this.invoice = null;
    this.documentService.getInvoice(_docEntry).subscribe(
      (next) => {
        this.blockUI.stop();
        if (next.result) {
          next.Invoice.DocDate = formatDate(next.Invoice.DocDate, 'yyyy-MM-dd', 'en');
          this.invoice = next.Invoice;
          this.previewForm.patchValue({ ...this.invoice });
          (<HTMLButtonElement>(
            document.getElementById("previewTrigger")
          )).click();
        } else {
          this.alertService.errorInfoAlert(
            `No se pudo obtener el documento ${next.errorInfo.Message}`
          );
        }
      },
      (error) => {
        this.blockUI.stop();
        console.log(error);
      }
    );
  }

  resetPreviewForm(): void {
    this.previewForm = new FormGroup({
      CardName: new FormControl(""),
      DocDate: new FormControl(""),
      DocNum: new FormControl(""),
      SalesMan: new FormControl(""),
      DocTotal: new FormControl(""),
    }); // La definicion del formularioFormGroup;
  }

  async toggleSymbol(): Promise<void> {
    if (
      this.incommingPaymentForm.get("ForgiveInterest").status !== "DISABLED"
    ) {
      if (this.incomingPaymentList.some((x) => x.Selected)) {
        let confirmationResult = await this.alertService.ConfirmationAlert(
          "Confirmación",
          `Se reestableceran todos los cambios realizados. ¿Desea continuar?`,
          "Continuar"
        );
        if (!confirmationResult) return;
      }

      this.resetTotals();
      this.page = 1;
      this.incommingPaymentForm.patchValue({ ForgiveInterest: 0 });
      this.isPercentageSymbol = !this.isPercentageSymbol;
    }
  }
  closeAfterPayModal(): void { }

  GenerateUniqueInvoiceId(): void {
    const USER_PREFIXID = JSON.parse(this.storage.getCurrentSession()).PrefixId;

    const DATE = new Date();

    const DAYS =
      DATE.getDate() < 10 ? "0" + DATE.getDate() : DATE.getDate().toString();
    const MONTS =
      DATE.getMonth() + 1 < 10
        ? "0" + (DATE.getMonth() + 1)
        : (DATE.getMonth() + 1).toString();
    const YEAR = DATE.getFullYear().toString().slice(0, 2);

    const HOURS =
      DATE.getHours() < 10 ? "0" + DATE.getHours() : DATE.getHours();
    const MINUTES =
      DATE.getMinutes() < 10 ? "0" + DATE.getMinutes() : DATE.getMinutes();
    const SECONDS =
      DATE.getSeconds() < 10 ? "0" + DATE.getSeconds() : DATE.getSeconds();

    this.uniqueInvCode = `${
      USER_PREFIXID + DAYS + MONTS + YEAR + HOURS + MINUTES + SECONDS
    }`;
  }
}

