import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

// MODELOS
import { ExchangeRate, HandleItem, BillItem, Company, IViewGroup, Mail, KeyValue, IPrice, IPrinterPerReport } from '../../../../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { CompanyService, SapService, AlertService, ReportsService, CommonService } from '../../../../services/index';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../../../../services/storage.service';
import { IPrinter } from 'src/app/models/i-printer';
import { SynchronizationService } from 'src/app/services/synchronization.service';
import { first } from 'rxjs/operators';
import { PRINTERS_PER_REPORT, Reports } from 'src/app/enum/enum';

// PIPES

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit, AfterViewChecked {
  printerPerReport: IPrinterPerReport;
  printersPerReport: IPrinterPerReport[];
  //VARBOX

  companyId: number; // id de la compañia, si es cero es creacion, si es diferente de cero es edicion
  mailDataId: number; // id del correo, si es cero es creacion, si es diferente de cero es edicion

  companyMailForm: FormGroup; // formulario de compañia y correo
  @BlockUI() blockUI: NgBlockUI;
  title: string; // titulo del componente
  create: boolean; // variable para reconcer si se esta creando o modificando una compannia
  isDecimalsFormValid: boolean; // Controla la validez del formulario de los decimales
  exchangeRateList: any[] = []; // lista de tipos de cambio
  handleItemList: any[] = []; // lista de tipos de manejo de items
  billItemList: any[] = []; // lista de tipos de facturacion de items
  sapConnectionList: any[] = []; // lista con las conexiones de SAP de la DBLocal
  isLockedPrintersRequest: boolean;

  // adjunto
  selectedFile: File = null; // archivo para  guardar el logo

  PrintInvFile: File = null; // Arcivo de imprecion de las facturas
  PrintQuoFile: File = null; // Archivo de imprecion para cotizaciones
  fileInvenFile: File = null; // Archivo de imprecion para inventario
  PrintCopyFile: File = null; // Archivo de imprecion para reimpreciones
  PrintRecivedPaidFile: File = null; // Archivo de imprecion para reimpreciones
  PrintRecivedPaidCopyFile: File = null; // Archivo de reimpresion de pago recibido
  PrintNCFile: File = null; // Archivo de impresion para la nota de credito
  PrintInvoiceCreditFile: File = null; // Archivo de impresion para facturas credito
  PrintInvoiceCreditCopyFile: File = null; // Archivo de impresion para facturas credito copia
  PrintSOFile: File = null; // Archivo de imprecion para reimpreciones
  PrintBalanceFile = null; // Archivo de imprecion para reimpreciones
  modalPay: any; // instancia de la modal de envio de documentos
  groupForm: FormGroup; // Formulario de Agrupacion de lineas
  decimalsForm: FormGroup; // Formulario de Agrupacion de lineas
  viewGroupList: IViewGroup[] = []; //contiene lista de agrupaciones en linea
  viewLineAgrupationList: IViewGroup[] = []; //contiene lista de agrupaciones en linea
  printersList: IPrinter[];
  defaultPrinter: IPrinter;
  // modal de envio de Docuemntos
  closeResult: string;

  // nombre de los files

  imgFileName: string;
  InvFileName: string;
  QuoFileName: string;
  InvenFileName: string;
  CopyFileName: string;
  RecivedPaidFileName: string;
  RecivedPaidCopyFileName: string;
  NcFileName: string;
  SOFileName: string;
  BalanceFileName: string;
  InvoiceCreditFileName: string
  InvoiceCreditCopyFileName: string

  reports: KeyValue[];

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private sapService: SapService,
    private router: Router,
    private alertService: AlertService,
    private modalService: NgbModal,
    private storageService: StorageService,
    private synchronizationService: SynchronizationService,
    private reportService: ReportsService,
    private commonService: CommonService
  ) {
    this.isLockedPrintersRequest = false;
  }

  ngOnInit() {
    this.printerPerReport = null;
    this.printersPerReport = [];

    // tslint:disable-next-line:radix
    this.defaultPrinter = null;
    this.isLockedPrintersRequest = false;
    this.companyId = parseInt(this.activatedRoute.snapshot.paramMap.get('companyId'));
    this.mailDataId = 0;
    this.exchangeRateList = ExchangeRate;
    this.handleItemList = HandleItem;
    this.billItemList = BillItem;
    this.setNewFormData();
    this.getSapConnection();
    this.groupForm = this.fb.group({
      CodNum: ['', Validators.required],
      NameView: ['', Validators.required],
      isGroup: ['', Validators.required],
    });

    this.decimalsForm = new FormGroup({
      price: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(6)]),
      totalLine: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(6)]),
      totalDocument: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(6)])
    });

    this.getViewGroup();
    this.getReports();

  }
  ngAfterViewChecked() {
  }

  raisePrintersModal(): void {
    this.printersList = [];
    this.printerPerReport = this.printersPerReport[0];
    this.cleanPrinters();
    // this.printersList.push({
    //   Description:"",
    //   DisplayName:"OneNote for Windows 10",
    //   IsDefault:false,
    //   Name:"OneNote for Windows 10",
    //   Status:0,
    //   Options:''
    // });

    // this.printersList.push({
    //   Description: '',
    //   DisplayName: 'Microsoft XPS Document Writer',
    //   IsDefault: false,
    //   Name: 'Microsoft XPS Document Writer',
    //   Options: '',
    //   Status: 0
    // });
    // this.printersList.push({
    //   Description: '',
    //   DisplayName: 'Epson l61',
    //   IsDefault: false,
    //   Name: 'tempo',
    //   Options: '',
    //   Status: 0
    // });

    // console.log(2);
    this.setupPrintersPerReport();


    this.printersPerReport.forEach((x, i) => {
      x.Printer = this.storageService.GetPrinterConfiguration(i);
    });

    let isLocked = false;
    this.printersPerReport.forEach(x => {
      if (x.Printer && !isLocked) {
        this.printersList.forEach(y => {
          if (x.Printer.DisplayName === y.DisplayName) {
            y.IsDefault = true;
            isLocked = true;
          }
        });
      }
    });


    if (this.printersPerReport[0].Printer === null) {
      // this.printersList.forEach(x => x.IsDefault = x.DisplayName === this.printersPerReport[0].Printer.DisplayName);
      this.printerPerReport = this.printersPerReport[0];
      if (this.printersList.length > 0) this.printersList[0].IsDefault = true;
    }
    else {
    }

    // console.log(JSON.stringify(this.printersList));

    // if (this.defaultPrinter !== null) {
    //   this.printersList.forEach(x => {
    //     x.IsDefault = false;

    //     if (x.DisplayName === this.defaultPrinter.DisplayName) {
    //       x.IsDefault = true;
    //     }
    //   });
    // }
    (<HTMLButtonElement>document.getElementById('triggerModalPrintersList')).click();
  }
  setDefaultPrinter(_index: number): void {
    this.cleanPrinters();
    this.printersList[_index].IsDefault = true;

    if (this.printerPerReport === undefined || this.printerPerReport === null) this.printerPerReport = this.printersPerReport[0];

    if (this.printerPerReport !== undefined) {
      this.printersPerReport.filter(x => {
        if (x.Id === this.printerPerReport.Id) {
          return true;
        }
      })[0].Printer = this.printersList[_index];
    }
    // if (this.printersList[_index].IsDefault) return;

    // this.printersList.forEach(x => { x.IsDefault = false });
    // this.printersList[_index].IsDefault = true;
    // this.defaultPrinter = this.printersList[_index];
  }
  CheckGroup(status: any, lineAgrupation: any) {
    // this.invoiceSelected = status.target.checked;
    this.viewLineAgrupationList.forEach(vgl => {
      if (vgl.Id === lineAgrupation) {
        vgl.isGroup = status.target.checked;

      }
    });
  }
  getViewGroup() {
    this.companyService.GetViewGroupList().subscribe(response => {
      if (response.result) {
        this.viewGroupList = [];
        response.ViewGroupList.forEach(vg => {
          this.viewGroupList.push({
            'Id': vg.Id,
            'CodNum': vg.CodNum,
            'NameView': vg.NameView,
            'isGroup': vg.isGroup
          });
        })
        this.viewLineAgrupationList = this.viewGroupList;

      }
    });
  }
  updateViewGroup(_index: number): void {
    // this.isUpdatingBarcode = true;
    this.groupForm.patchValue({
      CodNum: this.viewGroupList[_index].CodNum,
      NameView: this.viewGroupList[_index].NameView,
      isGroup: this.viewGroupList[_index].isGroup
    });
  }
  clearGroup(): void {
    this.groupForm.patchValue({
      NameView: '',
      isGroup: ''
    });
    // this.isUpdating = false;
  }
  SaveAgrupation(): void {
    const ViewGroupList = {
      viewLineAgrupationList: this.viewLineAgrupationList
    };

    this.blockUI.start('Actualizando Agrupaciones, Espere Por Favor...'); // Start blocking
    this.companyService.UpdateViewLineAgrupation(ViewGroupList)
      .subscribe(result => {
        if (result) {
          this.alertService.successAlertHtml(`Agrupaciones actualizadas correctamente`);
          this.close();
          this.getViewGroup();

        } else {
          this.alertService.errorAlert('Error al actualizar la información - Error: ' + result);
        }
        this.blockUI.stop(); // Stop blocking
      });
  }
  close() {
    this.modalPay.close();
    this.getViewGroup();
  }
  openModal(content) {
    this.modalPay = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'Modal-sm' });
    this.modalPay.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
    });
  }

  cancel() {
    this.router.navigate(['/companies']);
  }

  // funcion para obtener las conexiones de SAP de la DBLocal
  // no recibe parametros
  getSapConnection() {
    this.blockUI.start('Obteniendo Conexiones de SAP, Espere Por Favor...'); // Start blocking
    this.sapService.GetSapConnection()
      .subscribe((data: any) => {
        if (data.result) {
          this.sapConnectionList = data.SAPConnections;
          this.setData();
        } else {
          this.alertService.errorAlert('Error al Establecer conexión con SAP - Error: ' + data.errorInfo.Message);
        }
        this.blockUI.stop(); // Stop blocking
      }, (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para colocar cierta informacion de acuerdo al tipo de accion, ya sea creacion o edicion
  setData() {
    if (this.companyId === 0) {
      this.create = true;
      this.title = 'Crear';
    } else {
      this.create = false;
      this.title = 'Actualizar';
      this.getCompanyById();
    }
  }

  // funcion para obtener la info de la compannia  de la DBLocal para su modificacion
  // no recibe parametros
  getCompanyById() {
    this.blockUI.start('Obteniendo información de la compañía, Espere Por Favor...'); // Start blocking
    this.companyService.GetCompanyById(this.companyId)
      .subscribe((data: any) => {
        if (data.result) {
          this.setUpdateFormData(data.companyAndMail.company, data.companyAndMail.mail);
          if (this.storageService.getCompanyConfiguration() !== null && this.storageService.getCompanyConfiguration().PrinterConfiguration !== '') {
            this.defaultPrinter = JSON.parse(this.storageService.getCompanyConfiguration().PrinterConfiguration) as IPrinter;
            // console.log(this.storageService.getCompanyConfiguration().PrinterConfiguration);
          }
        } else {
          this.alertService.errorAlert('Error al cargar la información de las compañias - Error: ' + data.errorInfo.Message);
        }
        this.blockUI.stop(); // Stop blocking
      }, (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para colocar la informacion de la companni y correo al actualizar
  setUpdateFormData(company: Company, mail: Mail) {
    this.companyMailForm.patchValue({ DBName: company.DBName });
    this.companyMailForm.patchValue({ DBCode: company.DBCode });
    this.companyMailForm.patchValue({ SAPConnectionId: company.SAPConnectionId });
    this.companyMailForm.patchValue({ ExchangeRate: company.ExchangeRate });
    this.companyMailForm.patchValue({ ExchangeRateValue: company.ExchangeRateValue });
    this.companyMailForm.patchValue({ HandleItem: company.HandleItem });
    this.companyMailForm.patchValue({ BillItem: company.BillItem });
    this.companyMailForm.patchValue({ Active: company.Active });
    this.companyMailForm.patchValue({ Editable: company.IsLinePriceEditable });
    this.companyMailForm.patchValue({ maxAs0: company.ScaleMaxWeightToTreatAsZero });
    this.companyMailForm.patchValue({ ignoreWeight: company.ScaleWeightToSubstract });

    this.decimalsForm.patchValue({
      price: company.DecimalAmountPrice,
      totalLine: company.DecimalAmountTotalLine,
      totalDocument: company.DecimalAmountTotalDocument
    });

    if (mail != null) {
      this.companyMailForm.patchValue({ subject: mail.subject });
      this.companyMailForm.patchValue({ from: mail.from });
      this.companyMailForm.patchValue({ user: mail.user });
      this.companyMailForm.patchValue({ pass: mail.pass });
      this.companyMailForm.patchValue({ port: mail.port });
      this.companyMailForm.patchValue({ Host: mail.Host });
      this.companyMailForm.patchValue({ SSL: mail.SSL });
    }
    this.companyMailForm.patchValue({ SP_ItemInfo: company.SP_ItemInfo });
    this.companyMailForm.patchValue({ SP_InvoiceInfoPrint: company.SP_InvoiceInfoPrint });
    this.companyMailForm.patchValue({ SP_WHAvailableItem: company.SP_WHAvailableItem });
    this.companyMailForm.patchValue({ SP_SeriesByItem: company.SP_SeriesByItem });
    this.companyMailForm.patchValue({ SP_PayDocuments: company.SP_PayDocuments });
    this.companyMailForm.patchValue({ SP_CancelPayment: company.SP_CancelPayment });

    this.companyMailForm.patchValue({ V_BPS: company.V_BPS });
    this.companyMailForm.patchValue({ V_Items: company.V_Items });
    this.companyMailForm.patchValue({ V_ExRate: company.V_ExRate });
    this.companyMailForm.patchValue({ V_Taxes: company.V_Taxes });
    this.companyMailForm.patchValue({ V_GetAccounts: company.V_GetAccounts });
    this.companyMailForm.patchValue({ V_GetCards: company.V_GetCards });
    this.companyMailForm.patchValue({ V_GetBanks: company.V_GetBanks });
    this.companyMailForm.patchValue({ V_GetSalesMan: company.V_GetSalesMan });
    this.companyMailForm.patchValue({ hasOfflineMode: company.HasOfflineMode });
    this.companyMailForm.patchValue({ hasZeroBilling: company.HasZeroBilling });
    this.companyMailForm.patchValue({ RefreshDelay: company.RefreshDelay });
  }

  // funcion para colocar inicializar el form de compannia y correo
  setNewFormData() {
    this.companyMailForm = this.fb.group({
      DBName: ['', [Validators.required, Validators.minLength(1)]],
      DBCode: ['', [Validators.required, Validators.minLength(1)]],
      SAPConnectionId: ['', Validators.required],
      ExchangeRate: ['', Validators.required],
      ExchangeRateValue: ['', Validators.required],
      HandleItem: ['', Validators.required],
      RefreshDelay: ['', Validators.required],
      BillItem: ['', Validators.required],
      Active: [false],
      subject: [''],
      from: [''],
      user: [''],
      pass: [''],
      port: [''],
      Host: [''],
      SSL: [false],
      SP_ItemInfo: [''],
      SP_InvoiceInfoPrint: [''],
      SP_WHAvailableItem: [''],
      SP_SeriesByItem: [''],
      SP_PayDocuments: [''],
      SP_CancelPayment: [''],
      V_BPS: [''],
      V_Items: [''],
      V_ExRate: [''],
      V_Taxes: [''],
      V_GetAccounts: [''],
      V_GetCards: [''],
      V_GetBanks: [''],
      V_GetSalesMan: [''],
      maxAs0: [''],
      ignoreWeight: [''],
      Editable: [''],
      hasOfflineMode: [''],
      hasZeroBilling: ['']
    });
    this.decimalsForm = new FormGroup({
      price: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(6)]),
      totalLine: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(6)]),
      totalDocument: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(6)])
    });
    this.printersList = [];
  }

  // convenience getter for easy access to company and maildata form fields
  get fCM() { return this.companyMailForm.controls; }

  // funcion de creacion de compannia
  onSubmit() {

    // stop here if form is invalid
    if (this.companyMailForm.invalid) {
      this.alertService.warningInfoAlert('El formulario de compañía contiene errores');
      // this.alertService.error('El formato del usuario debe ser ejemplo@ejemplo.com');
      return;
    }

    this.storageService.setHasOfflineMode(this.companyMailForm.value.hasOfflineMode);

    if (this.companyId === 0) {
      this.blockUI.start('Creando Compañías, Espere Por Favor...');

      this.companyService.CreateCompanyAndMail(this.companyMailForm, this.companyId, this.mailDataId,
        this.selectedFile, this.PrintInvFile, this.PrintQuoFile, this.PrintSOFile, this.PrintBalanceFile,
        this.fileInvenFile, this.router, this.alertService, this.decimalsForm, this.defaultPrinter, this.PrintCopyFile, this.PrintRecivedPaidFile,
        this.PrintRecivedPaidCopyFile);


      this.storageService.setCompanyConfiguration(
        this.decimalsForm.value.price,
        this.decimalsForm.value.totalLine,
        this.decimalsForm.value.totalDocument,
        null, this.companyMailForm.value.hasZeroBilling,
        this.companyMailForm.value.RefreshDelay);
    } else {
      this.blockUI.start('Actualizando compañias, espere por favor');
      this.companyService.UpdateCompanyAndMail(this.companyMailForm, this.companyId, this.mailDataId,
        this.selectedFile, this.PrintInvFile, this.PrintQuoFile, this.PrintSOFile, this.PrintBalanceFile,
        this.fileInvenFile, this.router, this.alertService, this.decimalsForm, this.defaultPrinter, this.PrintCopyFile, this.PrintRecivedPaidFile
        , this.PrintRecivedPaidCopyFile, this.PrintNCFile,this.PrintInvoiceCreditFile, this.PrintInvoiceCreditCopyFile);
      this.storageService.setCompanyConfiguration(
        this.decimalsForm.value.price,
        this.decimalsForm.value.totalLine,
        this.decimalsForm.value.totalDocument,
        this.defaultPrinter, this.companyMailForm.value.hasZeroBilling,
        this.companyMailForm.value.RefreshDelay);
      //this.router.navigate(['/companies']);
    }
  }

  // funcion para seleccionar un archivo a guardar
  onFileSelected(event, n: number) {
    switch (n) {
      case 1:
        this.selectedFile = <File>event.target.files[0]; // guarda el logo
        this.imgFileName = this.selectedFile.name;
        break;
      case 2:
        this.PrintInvFile = <File>event.target.files[0]; // imprimir facturas
        this.InvFileName = this.PrintInvFile.name;
        break;
      case 3:
        this.PrintQuoFile = <File>event.target.files[0]; // imprimir Cotizacion
        this.QuoFileName = this.PrintQuoFile.name;
        break;
      case 4:
        this.fileInvenFile = <File>event.target.files[0]; // imprimir Orden de venta
        this.InvenFileName = this.fileInvenFile.name;
        break;
      case 5:
        this.PrintBalanceFile = <File>event.target.files[0]; // imprimir reimprecion
        this.BalanceFileName = this.PrintBalanceFile.name;
        break;
      case 6:
        this.PrintSOFile = <File>event.target.files[0]; // imprimir reimprecion
        this.SOFileName = this.PrintSOFile.name;
        break;
      case 7:
        this.PrintCopyFile = <File>event.target.files[0]; // imprimir reimpresión
        this.CopyFileName = this.PrintCopyFile.name;
        break;
      case 8:
        this.PrintRecivedPaidFile = <File>event.target.files[0]; // imprimir reimpresión
        this.RecivedPaidFileName = this.PrintRecivedPaidFile.name;
        break;
      case 9:
        this.PrintRecivedPaidCopyFile = <File>event.target.files[0]; // imprimir reimpresión
        this.RecivedPaidCopyFileName = this.PrintRecivedPaidCopyFile.name;
        break;
      case 10:
        this.PrintNCFile = <File>event.target.files[0]; // imprimir nc
        this.NcFileName = this.PrintNCFile.name;
        break;
      case 11:
        this.PrintInvoiceCreditFile = <File>event.target.files[0]; // imprimir factura credito
        this.InvoiceCreditFileName = this.PrintInvoiceCreditFile.name;
        break;
      case 12:
        this.PrintInvoiceCreditCopyFile = <File>event.target.files[0]; // imprimir factura credito copia
        this.InvoiceCreditCopyFileName = this.PrintInvoiceCreditCopyFile.name;
        break;
    }
  }

  raiseAlertToSave(): void {
    this.alertService.warningInfoAlert('Recuerde presionar el boton Actualizar, para guardar sus cambios');
    this.storageService.SavePrinters(this.printersPerReport);
  }

  downloadData(): void {
    this.synchronizationService.ItemsDownload().subscribe(x => {
      console.log(x);
    });
  }

  uploadData(): void {

  }

  getReports() {
    this.reports = [];
    this.reportService.getReports()
      .pipe(first())
      .subscribe(response => {
        if (response.result) {
          this.reports = response.Reports;
          this.setFilesNames();
        } else {
          this.alertService.errorAlert(response.errorInfo.Message);
        }
      }, err => {
        this.alertService.errorAlert(err);
      });
  }

  downloadReportFile(reportKey: number, reportName: string) {
    this.blockUI.start(`Descargando reporte, espere por favor`);

    this.reportService.downloadReportFile(reportKey)
      .pipe(first())
      .subscribe(response => {
        this.blockUI.stop();

        if (response.result) {
          this.commonService.downloadFile(
            response.File,
            reportName.substring(0, reportName.length - 4),
            'application/octet-stream',
            'rpt');
        } else {
          this.alertService.errorAlert(response.errorInfo.Message);
        }
      }, err => {
        this.blockUI.stop();
        this.alertService.errorAlert(err);
      });
  }

  setFilesNames() {
    this.reports.forEach(x => {
      switch (x.Key) {
        case Reports.Balance:
          this.BalanceFileName = x.Value;
          break;
        case Reports.Inventory:
          this.InvenFileName = x.Value;
          break;
        case Reports.Invoice:
          this.InvFileName = x.Value;
          break;
        case Reports.Quotation:
          this.QuoFileName = x.Value;
          break;
        case Reports.SaleOrder:
          this.SOFileName = x.Value;
          break;
        case Reports.CopyInvoice:
          this.CopyFileName = x.Value;
          break;
        case Reports.IncomingPayment:
          this.RecivedPaidFileName = x.Value;
          break;
        case Reports.IncomingPaymentCopy:
          this.RecivedPaidCopyFileName = x.Value;
          break;
        case Reports.CREDIT_NOTE:
          this.NcFileName = x.Value;
          break;
        case Reports.InvoiceCredit:
          this.InvoiceCreditFileName = x.Value;
          break;
        case Reports.InvoiceCreditCopy:
          this.InvoiceCreditCopyFileName = x.Value;
          break;
      }
    });
  }

  preSelectPrinter(_printerPerReport: IPrinterPerReport): void {
    if (this.printersList.length === 0) return;
    this.cleanPrinters();
    if (_printerPerReport.Printer !== null) {
      this.printersList.forEach(x => { x.IsDefault = x.DisplayName === _printerPerReport.Printer.DisplayName });
    }
    else this.printersList[0].IsDefault = true;
    this.printerPerReport = _printerPerReport;

    this.printersList.forEach(y => {
      if (this.printerPerReport.Printer && this.printerPerReport.Printer.DisplayName === y.DisplayName) {
        y.IsDefault = true;
      }
    });

    // this.printersPerReport.forEach(x => {
    //   if (x.Printer) {
    //     this.printersList.forEach(y => {
    //       if (x.Printer.DisplayName === y.DisplayName) {
    //         y.IsDefault = true;
    //       }
    //     });
    //   }
    // });
    // console.log(_printerPerReport);
  }

  cleanPrinters(): void {
    this.printersList.forEach(x => x.IsDefault = false);
  }

  setupPrintersPerReport(): void {
    this.printersPerReport = [];
    this.printersPerReport.push({
      Id: PRINTERS_PER_REPORT.DEFAULT_PRINTER,
      Printer: null,
      ReportName: 'Impresora por defecto'
    });
    this.printersPerReport.push({
      Id: PRINTERS_PER_REPORT.INVOICE,
      Printer: null,
      ReportName: 'Facturacion'
    });
    this.printersPerReport.push({
      Id: PRINTERS_PER_REPORT.SALE_ORDER,
      Printer: null,
      ReportName: 'Orden de venta'
    });
    this.printersPerReport.push({
      Id: PRINTERS_PER_REPORT.SALE_QUOTATION,
      Printer: null,
      ReportName: 'Proforma'
    });
    this.printersPerReport.push({
      Id: PRINTERS_PER_REPORT.BALANCE,
      Printer: null,
      ReportName: 'Cierres de caja'
    });
    this.printersPerReport.push({
      Id: PRINTERS_PER_REPORT.ACCOUNT_BALANCE,
      Printer: null,
      ReportName: 'Estados de cuenta'
    });
    this.printersPerReport.push({
      Id: PRINTERS_PER_REPORT.INCOMMING_PAYMENT,
      Printer: null,
      ReportName: 'Pago recibido'
    });

    // this.printersPerReportHot = [];

    // this.printersPerReportHot.push({
    //   Id: PRINTERS_PER_REPORT.DEFAULT_PRINTER,
    //   Printer: null,
    //   ReportName: 'Impresora por defecto'
    // });
    // this.printersPerReportHot.push({
    //   Id: PRINTERS_PER_REPORT.INVOICE_CASH,
    //   Printer: null,
    //   ReportName: 'Facturacion contado'
    // });
    // this.printersPerReportHot.push({
    //   Id: PRINTERS_PER_REPORT.INVOICE_CREDIT,
    //   Printer: null,
    //   ReportName: 'Facturacion credito'
    // });
    // this.printersPerReportHot.push({
    //   Id: PRINTERS_PER_REPORT.SALE_ORDER,
    //   Printer: null,
    //   ReportName: 'Orden de venta'
    // });
    // this.printersPerReportHot.push({
    //   Id: PRINTERS_PER_REPORT.SALE_QUOTATION,
    //   Printer: null,
    //   ReportName: 'Proforma'
    // });
  }
}
