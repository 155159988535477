import { Pipe, PipeTransform } from '@angular/core';
import { Series } from '../models';

@Pipe({
  name: 'numberingSeries'
})
export class NumberingSeriesPipe implements PipeTransform {

  transform(series: Series[], docType: number): any {
    if (!series) return [];

    return series.filter(x => x.DocType === docType);
  }

}
