import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BlockUI } from 'ng-block-ui';
import { NgBlockUI } from 'ng-block-ui/models/block-ui.model';
import { Subscription } from 'rxjs/internal/Subscription';
import { IPPBalance, ITerminal } from 'src/app/models';
import { RequestDocumentType } from 'src/app/models/constantes';
import { IPPBalanceRequest } from 'src/app/models/i-ppbalance-request';
import { AlertService, BankService } from 'src/app/services';
import swal from 'sweetalert2';

@Component({
  selector: 'app-terminals-balance',
  templateUrl: './terminals-balance.component.html',
  styleUrls: ['./terminals-balance.component.scss']
})
export class TerminalsBalanceComponent implements OnInit {

  constructor(private banksService: BankService, private alertService: AlertService) { }
  terminals: ITerminal[];
  balances: IPPBalance[];
  balanceRequest: IPPBalanceRequest;
  @BlockUI() blockUI: NgBlockUI;
  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  permisos: boolean = true;
  terminalModalTitle: string;
  TRANSFER_DATE = new Date(Date.now() - 1);
  terminalTarget: ITerminal;
  terminalForm = new FormGroup({
    TerminalId: new FormControl(''),
    From: new FormControl(''),
    To: new FormControl('')
  });

  requestTypeForm: FormControl = new FormControl();

  ngOnInit() {
    this.initVariables();
    this.getTerminals();
  }

  GetBalance(): void {

  }

  Balance(): void {
    this.balances = [];
    this.balanceRequest = this.terminalForm.value as IPPBalanceRequest;
    if (this.balanceRequest.TerminalId != -1) {
      this.blockUI.start(`Generando cierre de tarjetas, esto puede tomar tiempo. Espere por favor.`);
      this.banksService.Balance(this.balanceRequest.TerminalId).subscribe(next => {
        this.blockUI.stop();
        if (next.result) {
          this.balances = next.PPBalance;
          this.alertService.infoInfoAlert(`Cierre de tarjetas terminado exitosamente`);
          if (this.balances.length > 0) {
            this.mapRequest();
          }
          else {
            this.alertService.infoAlert(`No hay pre cierres realizados en el sistema`);
          }
        }
        else {
          this.alertService.errorAlert(`No se pudo realizar el cierre de tarjetas, error: ${next.errorInfo.Message}`);
        }
      }, error => {
        this.blockUI.stop();
        console.log(error);
        this.alertService.errorAlert(`No se pudo realizar el cierre de tarjetas, error: ${error}`);
      });
    }
  }

  PreBalance(): void {
    this.balances = [];
    this.balanceRequest = this.terminalForm.value as IPPBalanceRequest;
    if (this.balanceRequest.TerminalId != -1) {
      this.blockUI.start(`Obteniendo pre cierre, esto puede tomar tiempo. Espere por favor.`);

      this.banksService.PreBalance(this.balanceRequest).subscribe(next => {
        this.blockUI.stop();
        if (next.result) {
          this.balances = next.PPBalance;
          this.alertService.successInfoAlert(`Se ha obtenido el pre cierre exitosamente`);
          if (this.balances.length > 0) {
            this.mapRequest();
          }
          else {
            this.alertService.infoAlert(`No hay pre cierres realizados en el sistema`);
          }
        }
        else {
          this.alertService.infoAlert(`No se encontraron transacciones de tarjetas pedientes de cerrar`);
        }
      }, error => {
        this.blockUI.stop();
        console.log(error);
        this.alertService.errorAlert(`No se pudo obtener el pre cierre, error: ${error}`);
      });
    }
  }

  GetRequestsFromRegisters(_requestType: string, _documentType: string): void {
    this.balances = [];
    this.balanceRequest = this.terminalForm.value as IPPBalanceRequest;
    if (this.balanceRequest.TerminalId != -1) {
      this.blockUI.start(`Obteniendo ${_documentType} guardados en el sistema. Espere por favor.`);
      this.balanceRequest.DocumentType = _requestType;
      this.banksService.GetRequestsFromRegisters(this.balanceRequest).subscribe(next => {
        this.blockUI.stop();
        if (next.result) {
          this.balances = next.PPBalance;
          this.alertService.successInfoAlert(`Operación terminada exitosamente`);
          if (this.balances.length > 0) {
            this.mapRequest();
          }
          else {
            this.alertService.infoAlert(`No hay  ${_documentType} realizados en el sistema`);
          }
        }
        else {
          this.alertService.infoAlert(`No hay  ${_documentType} realizados en el sistema entre las fechas seleccionadas`);
        }
      }, error => {
        this.blockUI.stop();
        console.log(error);
        this.alertService.errorAlert(`No se pudo obtener el  ${_documentType}, error: ${error}`);
      });
    }
  }

  generate(): void {
    const REQUEST_TYPE = +this.requestTypeForm.value === 1 ? 'cierre' : 'pre cierre';
    swal({
      type: 'warning',
      title: 'Esta solicitud no puede ser cancelada',
      text: `¿ Desea generar el ${REQUEST_TYPE} ?`,
      showCancelButton: true,
      confirmButtonColor: '#049F0C',
      cancelButtonColor: '#ff0000',
      confirmButtonText: 'Sí',
      cancelButtonText: 'No'
    }).then(next => {
      console.log(Object.keys(next));
      if (!(Object.keys(next)[0] === 'dismiss')) {
        if (+this.requestTypeForm.value === 1) {
          this.Balance();
        }
        else {
          this.PreBalance();
        }
      }
    }, (dismiss) => { });
  }

  getRequest(): void {
    let documentType = 'pre cierres';
    let requestType = RequestDocumentType[RequestDocumentType.PRE_BALANCE];
    
    if (+this.requestTypeForm.value === 1) {
      documentType = 'cierres';
      requestType = RequestDocumentType[RequestDocumentType.BALANCE];
    }

    this.GetRequestsFromRegisters(requestType, documentType);
    
  }

  mapRequest(): void {
    this.balances.forEach(x => {
      if (parseInt(x.SalesAmount) !== 0) {
        const salesAmount = x.SalesAmount;
        const LEFT_OFFSET = salesAmount.slice(0, x.SalesAmount.length - 2);
        const RIGHT_OFFSET = salesAmount.slice(x.SalesAmount.length - 2, x.SalesAmount.length);
        x.SalesAmount = `${LEFT_OFFSET}.${RIGHT_OFFSET}`;
        x.SalesTransactions = parseInt(x.SalesTransactions).toString();
      }
      else {
        x.SalesAmount = parseInt(x.SalesAmount).toString();
      }

      if (parseInt(x.RefundsAmount) !== 0) {
        x.RefundsAmount = parseInt(x.RefundsAmount).toString();
        const salesAmount = x.RefundsAmount;
        const LEFT_OFFSET = salesAmount.slice(0, x.RefundsAmount.length - 2);
        const RIGHT_OFFSET = salesAmount.slice(x.RefundsAmount.length - 2, x.RefundsAmount.length);
        x.RefundsAmount = `${LEFT_OFFSET}.${RIGHT_OFFSET}`;
      }
      else {
        x.RefundsAmount = parseInt(x.RefundsAmount).toString();
      }

      x.RefundsTransactions = parseInt(x.RefundsTransactions).toString();
      try {
        x.HostDate = this.convertDate(x.HostDate);
      } catch (error) {
        this.alertService.infoAlert(`No se pudo convertir la fecha del servidor`);
      }
    });
  }

  getTerminals(): void {
    this.blockUI.start(`Obteniendo terminales, espere por favor.`);
    this.banksService.getTerminals().subscribe(next => {
      if (next.result) {
        this.terminals = next.PPTerminals.filter(x => x.Status);
        if (this.terminals.length > 0) {
          this.terminalForm.patchValue({
            TerminalId: this.terminals[0].Id
          });
        }
      }
      else {
        this.alertService.errorAlert(`No se pudieron obtener los terminales, detalle ${next.errorInfo.Message}`)
      }
      this.blockUI.stop();
    }, error => {
      console.log(error);
      this.blockUI.stop();
      this.alertService.errorAlert(`No se pudieron obtener los terminales, detalle ${error}`);
    });
  }

  convertDate(strA: string) {
    return strA.slice(0, 2) + '/' + strA.slice(2, 4) + '/' + strA.slice(4);
  }

  initVariables(): void {
    this.resetTerminalForm();
    this.terminalTarget = null;
    this.balances = [];
    this.getTerminals();
  }

  resetTerminalForm(): void {
    const DATE = new Date();
    this.terminalForm.patchValue({
      TerminalId: -1,
      From: DATE.getFullYear() + '-' + (DATE.getMonth() + 1) + '-' + DATE.getDate(),
      To: DATE.getFullYear() + '-' + (DATE.getMonth() + 1) + '-' + DATE.getDate()
    });

    this.requestTypeForm.setValue('0');
  }
}
