import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, Observable, Subject } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

// MODELOS
import { Company, PasswordValidation, User } from '../../../models/index';
// SERVICIOS
import { AuthenticationService, AlertService, StorageService, CompanyService, BranchOfficeService, PublisherService } from '../../../services/index';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  usersCredentials: User[];

  model: User;

  @ViewChild('instance') instance: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(100), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.usersCredentials
        : this.usersCredentials.filter(v => v.Email.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  formatter = (result: User) => result.Email;

  annio: number;
  /**
   * variable del formulario, tanto de login como de signin
   */
  loginForm: FormGroup;
  /**
   * variable que comprueba si esta cargando o no
   */
  loading = false;
  /**
   * variable para saber si esta activo el form de login (true) o de signin (false)
  */
  isLogin: boolean;
  /**
   * variable para saber si esta recuperando la contrasenna, activo el form de forgot (true) o se oculta (false)
  */
  forgotPwsd: boolean;
  /**
   * variable para reconcer si se hizo el envio del formulario o no
   */
  submitted = false;
  /**
   * variable que contiene la url de retorno para ver a donde desplazarse
   */
  returnUrl: string;



  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private storage: StorageService,
    private auth: AuthenticationService,
    private alertService: AlertService,
    private companyService: CompanyService,
    private branchOfficeService: BranchOfficeService,
    private publisherService: PublisherService
  ) {
    if (this.auth.currentUserValue) {
      this.router.navigate(['/']);
    }
    this.annio = new Date().getFullYear();
  }

  ngOnInit() {
    this.isLogin = true;
    this.forgotPwsd = false;
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(2)]],
      password: ['', Validators.required]
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.usersCredentials = this.storage.getUserCredentials();
  }

  /**
   * Funcion para tener mejor acceso a los campos del form
   */
  get f() { return this.loginForm.controls; }

  /**
   * Funcion para el envio del formulario, de login o de registro
   */
  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      if (this.loginForm.controls.email.invalid) {
        this.alertService.warningInfoAlert('El formato del usuario debe ser ejemplo@ejemplo.com')
        return;
      }
      if (this.loginForm.controls.confirmPassword.invalid) {
        this.alertService.warningInfoAlert('Datos Incorrectos, Las contraseñas no coinciden!');
        return;
      }
      this.alertService.warningInfoAlert('Contraseña no es valida!');
      return;
    }
    this.loading = true;
    if (this.isLogin && !this.forgotPwsd) {
      if (navigator.onLine) {
        this.storage.setOnline();

        const USERNAME = this.f.email.value.Email || this.f.email.value;
        const PASSWORD = this.f.password.value.Password || this.f.password.value;

        this.auth.login(USERNAME, PASSWORD)
          .pipe(first())
          .subscribe(
            data => {
              this.UpdateWareHouseName(data.WhName);
              this.getBranchOffice(data.userId);
              this.storage.addUserCredentials(USERNAME, ""); // Se guardan en blanco las claves hasta que se defina un flujo para guardar estas
              this.router.navigate(['/home']);
              this.companyService.GetDefaultCompany().subscribe(next => {
                if (next.result) {
                  this.companyService.GetCompanyById(next.Company.Id)
                    .subscribe((data: any) => {
                      if (data.result) {
                        const COMPANY = data.companyAndMail.company as Company;
                        this.storage.setCompanyConfiguration(
                          COMPANY.DecimalAmountPrice,
                          COMPANY.DecimalAmountTotalLine,
                          COMPANY.DecimalAmountTotalDocument,
                          null,
                          COMPANY.HasZeroBilling,
                          COMPANY.RefreshDelay);
                        this.storage.setHasOfflineMode(data.companyAndMail.company.HasOfflineMode);
                        if (data.companyAndMail.company.HasOfflineMode) {
                          this.auth.loginOffline(USERNAME, PASSWORD, true)
                            .pipe(first())
                            .subscribe(
                              data => {
                              },
                              error => {
                                console.log(error);
                                // this.alertService.errorInfoAlert(`No se pudo conectar con el servidor local, ` + error);
                                this.loading = false;
                              });
                        }
                        else {
                          this.auth.authPinPadCredentials(USERNAME, PASSWORD).subscribe(next => {
                            next.Password = this.f.password.value;
                            if (next && next.access_token) {
                              this.storage.setCurrentSessionOffline(next);
                            }
                          }, error => {
                            // this.alertService.infoInfoAlert(`Servicios pin pad no disponibles, no se pudo conectar con el servidor`);
                            console.warn('Servicios pin pad no disponibles', error);
                          });
                        }

                      } else {
                        this.alertService.errorAlert('Error al cargar la información de las compañias - Error: ' + data.errorInfo.Message);
                      }
                    }, (error: any) => {
                      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
                    });
                }
                else {
                  this.alertService.errorInfoAlert(`Error al obtener la información de la compañia, Error: ${next.errorInfo.Message}`);
                }
              }, error => {
                this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error.error}`);
              });
            },
            error => {
              // error.error.error_description
              this.alertService.errorAlert(error);
              this.loading = false;
            });
        // this.auth.getTokenPadron()
        //   .pipe(first())
        //   .subscribe(
        //     data => {

        //     },
        //     error => {
        //       // error.error.error_description
        //       this.alertService.errorAlert(error);
        //       this.loading = false;
        //     });
      }
      else {
        // Login offline
        const USERNAME = this.f.email.value.Email || this.f.email.value;
        const PASSWORD = this.f.password.value.Password || this.f.password.value;
        this.auth.loginOffline(USERNAME, PASSWORD, true)
          .pipe(first())
          .subscribe(
            next => {
              this.storage.setOffline();
              console.log(next);
              this.storage.setHasOfflineMode(true);
              this.getBranchOffice(next.userId);
              this.storage.addUserCredentials(USERNAME, "");
              this.router.navigate(['/home']);
              this.companyService.GetDefaultCompany().subscribe(next => {
                if (next.result) {
                  this.companyService.GetCompanyById(next.Company.Id)
                    .subscribe((data: any) => {
                      if (data.result) {
                        const COMPANY = data.companyAndMail.company as Company;
                        this.storage.setCompanyConfiguration(
                          COMPANY.DecimalAmountPrice,
                          COMPANY.DecimalAmountTotalLine,
                          COMPANY.DecimalAmountTotalDocument,
                          null,
                          COMPANY.HasZeroBilling,
                          COMPANY.RefreshDelay);
                      } else {
                        this.alertService.errorAlert('Error al cargar la información de las compañias - Error: ' + data.errorInfo.Message);
                      }
                    }, (error: any) => {
                      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
                    });
                }
                else {
                  this.alertService.errorInfoAlert(`Error al obtener la información de la compañia, Error: ${next.errorInfo.Message}`);
                }
              }, error => {
                this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error.error}`);
              });
            },
            error => {
              this.alertService.errorAlert(error);
              this.loading = false;
            });
      }
    } else if (!this.isLogin && !this.forgotPwsd) {
      if (this.checkPasswords(this.loginForm)) {
        this.auth.register(this.loginForm)
          .subscribe((data: any) => {
            this.loading = false;
            if (data.result) {
              this.alertService.successInfoAlert('Usuario Registrado Correctamente!!!');
            } else {
              this.alertService.errorInfoAlert(`No se Pudo Registrar el Usuario Correctamente!!!, Codigo: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`)
            }
          }, error => {
            this.loading = false;
            this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
          });
      } else {
        this.loading = false;
        this.alertService.warningInfoAlert('Datos Incorrectos, Las contraseñas no coinciden!!!');
      }
    } else if (this.forgotPwsd) {
      this.auth.sendRecoverPswdEmail(this.loginForm)
        .subscribe((data: any) => {
          this.loading = false;
          if (data.result) {
            this.alertService.successInfoAlert('Usuario Registrado Correctamente!!!');
          } else {
            this.alertService.errorInfoAlert(`No se Pudo Registrar el Usuario Correctamente!!!, Codigo: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`)
          }
        }, error => {
          this.loading = false;
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
        });
    }
  }

  /**
   * Funcion para cambiar entre el formulario de login y de sigin
   */
  clickEvent(islogin: boolean, forgotPwsd: boolean) {
    this.isLogin = islogin;
    this.forgotPwsd = forgotPwsd;
    // se activa el form de login
    if (this.isLogin && !this.forgotPwsd) {
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
        password: ['', Validators.required]
      });
      // se activa el form de registrarse
    } else if (!this.isLogin && !this.forgotPwsd) {
      this.loginForm = this.fb.group({
        fullName: ['', Validators.required],
        email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required]
      }, {
        validator: PasswordValidation.MatchPassword // your validation method
      });
      // se activa el form de recuperar la contrasenna
    } else if (this.forgotPwsd) {
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]]
      });
    }
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  /**
   * Funcion para validar el que las contraseñas sean iguales al registrar un usuario nuevo
   *
   * @param {FormGroup} group Formulario de registro
   */
  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? true : false;
  }

  getBranchOffice(userId: string) {
    this.branchOfficeService.GetBranchOffice(userId)
      .pipe(first()).
      subscribe(response => {
        if (response.result) {
          this.storage.setBranchOffice(response.BranchOffice);
          this.publisherService.publish({ Target: 'BrachOffice', Data: response.BranchOffice });
        } else {
          this.alertService.infoInfoAlert(response.errorInfo.Message);
        }
      }, err => {
        this.alertService.infoInfoAlert(err);
      });
  }

  UpdateWareHouseName(_wareHouseName: string): void {
    try {
      this.publisherService.publish({ Target: 'WareHouseName', Data: _wareHouseName });
    }
    catch(error) {
      console.info(error);
    }
    // this.branchOfficeService.GetBranchOffice(userId)
    //   .pipe(first()).
    //   subscribe(response => {
    //     if (response.result) {
    //       this.storage.setBranchOffice(response.BranchOffice);
    //       this.publisherService.publish({ Target: 'BrachOffice', Data: response.BranchOffice });
    //     } else {
    //       this.alertService.infoInfoAlert(response.errorInfo.Message);
    //     }
    //   }, err => {
    //     this.alertService.infoInfoAlert(err);
    //   });
  }
}
