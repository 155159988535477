import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import { AuthenticationService, ExRateService, StorageService } from './services/index';
import {ApplicationTabsService} from "./services/application-tabs.service";
import {Observable} from "rxjs";
import {environment} from "../environments/environment";
import * as LogRocket from 'logrocket';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Cavisco Supermercado';
  currentUser: any;
  shouldLockTab: Observable<boolean>;
  constructor(
      private router: Router,
      private authenticationService: AuthenticationService,
      private exRateService: ExRateService,
      private storageService: StorageService,
      private applicationTabsService: ApplicationTabsService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    if (environment.production) {
      LogRocket.init(environment.LogRocketKey);
    }
  }

  ngOnInit(): void {
    this.shouldLockTab = this.applicationTabsService.ShouldLockWindow;

    if (!this.storageService.getCurrentSession() !&& this.storageService.getCurrentSessionOffline()) {
      this.logout();
    }

  }

  logout(): void {
    this.storageService.logout();
    this.router.navigate(['/login']);
  }
}
