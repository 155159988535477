import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConstants, IToken } from '../models';
import { StorageService } from './storage.service';
import { TokenService } from './token.service';
import { constants } from 'os';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor{

  constructor(
    private storageService: StorageService, private tokenService: TokenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let today = new Date();
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds() + ":" + today.getMilliseconds();

    let dateTime = date + ' ' + time;

    const USER_SESSION = JSON.parse(this.storageService.getCurrentSession());

    const THIRDPARTY_SESSION = this.storageService.GetThirdPartySession();

    if (request.url === 'assets/data/Provinces.Json' || request.url === 'assets/data/Country.Json') return next.handle(request);
    
    const URL_ = new URL(request.url);

    if(AppConstants.RPTMANAGER_URL.includes(URL_.hostname)) {
      return next.handle(request);
    }
    // if (AppConstants.RPTMANAGER_URL)
    
    // if (USER_SESSION === null) this.storageService.logout();
    if ( USER_SESSION !== null && USER_SESSION['.expires'] !== undefined) {
      const EXPIRATION_DATE = new Date(USER_SESSION['.expires']);
      const CURRENT_DATE = new Date();
      
      const MINUTES_TO_EXPIRE = 10;
      
      let MINUTES_LEFT = ((EXPIRATION_DATE.getTime() - CURRENT_DATE.getTime()) / 60000);
      
      if (MINUTES_LEFT <= MINUTES_TO_EXPIRE && MINUTES_LEFT > 0) { // La sesion esta a punto de terminar
        this.tokenService.iToken.next({
            IsSessionExpired: false,
            IsRequiredToken: true
          } as IToken
        );
      }
    }

    if (THIRDPARTY_SESSION !== null && THIRDPARTY_SESSION['.expires'] !== undefined) {
      const EXPIRATION_DATE = new Date(THIRDPARTY_SESSION['.expires']);
      const CURRENT_DATE = new Date();
      
      const MINUTES_TO_EXPIRE = 10;
      
      let MINUTES_LEFT = ((EXPIRATION_DATE.getTime() - CURRENT_DATE.getTime()) / 60000);
      
      if (MINUTES_LEFT <= MINUTES_TO_EXPIRE && MINUTES_LEFT > 0) { // La sesion esta a punto de terminar
        this.tokenService.thirdPartyToken.next({
            IsSessionExpired: false,
            IsRequiredToken: true
          } as IToken
        );
      }
    }
    // if (MINUTES_LEFT <= 0) { // Se sesion expiro, intento de recuperarla
    //   this.tokenService.iToken.next({
    //       IsSessionExpired: true,
    //       IsRequiredToken: true
    //     } as IToken
    //   );
    //   const BODY = { errorInfo: { Code: 500, Message: 'La sesión ha expirado'} };
    //   return of(new HttpResponse({ status: 405, body: BODY }));
    // }

    if (URL_.pathname === '/api/Payment/UpdatePinPadPayment'
      || URL_.pathname === '/api/Payment/RequestPinPadPayment'
      || URL_.pathname === '/api/Payment/CancelPinPadCards'
      || URL_.pathname === '/api/Banks/GetTerminals'
      || URL_.pathname === '/api/Banks/GetTerminal'
      || URL_.pathname === '/api/Banks/UpdateTerminal'
      || URL_.pathname === '/api/Sync/GetDocuments'
      || URL_.pathname === '/api/Sync/DownloadData'
      || URL_.pathname === '/api/Sync/SyncUpDocuments'
      || URL_.pathname === '/api/Sync/UpdateDocumentsToSync') {
      const OFFLINE_SESSION = JSON.parse(this.storageService.getCurrentSessionOffline());
      let CLONED_REQUEST = null;
      if (OFFLINE_SESSION && OFFLINE_SESSION.access_token && JSON.parse(this.storageService.getSession(false))) {
        CLONED_REQUEST = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${OFFLINE_SESSION.access_token}`)
        });
      }
      else {
        const BODY = {
          errorInfo: {
            Code: 401,
            Message: 'No existen credenciales guardados, intentando recuperarlos...'
          }
        };
        return of(new HttpResponse(
          { status: 500, body: BODY }
        ));
      }
      
      return next.handle(CLONED_REQUEST);
    }

    if (URL_.pathname === '/token') return next.handle(request);
    if (!this.storageService.getHasOfflineMode() && !navigator.onLine) {
      const BODY = { errorInfo: { Code: 404, Message: 'No se pudo conectar con el servidor, verifique su conexión'} };
      return of(new HttpResponse({ status: 404, body: BODY }));
    }
    else {
      if (this.storageService.getHasOfflineMode()) {
        if (USER_SESSION != null && URL_.host !== 'padronapi.clavisco.com') {
      
          const TARGET = `${(navigator.onLine?AppConstants.getOnLine():AppConstants.getOffLine()).slice(0, -1)}${URL_.pathname}${URL_.search}`;
          
          const CLONED_REQUEST = request.clone({
            url: TARGET,
            headers: request.headers.set('Authorization', `Bearer ${USER_SESSION.access_token}`)
          });
          
          return next.handle(CLONED_REQUEST);
        }
      }

      request = request.clone({
        headers: request.headers.set('UIRequestTimestamp', dateTime)
      });
      return next.handle(request);
    }
  }
}
