import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

// MODELOS
import { AppConstants, IReportMerger } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { ReportsResponse, FileResponse } from '../models/responses';
import { Observable } from 'rxjs';

// PIPES

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient,
    private storage: StorageService) {
    // console.log(0);
  }
  // funcion para obtener el reporte del inventario
  // recive el for group que tiene la informacion de los parametros que ocupa el reporte.
  printInventory(PrintInventory: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Report/PrintInventory`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url,
      PrintInventory,
      { headers }
    );

  }
  // funcion para obtener el reporte impresion
  printReport(DocEntry: number, reportType: number) {
    console.log('imprimir');
    console.log(DocEntry);
    console.log(reportType);
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Report/PrintReport?DocEntry=${DocEntry}&ReportType=${reportType}`;
    const headers = new HttpHeaders({
      //'responseType': 'blob',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  MergeReportsToPrint(_rerportMerger: IReportMerger) {

    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Report/PrintMergedReports`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.post(url, _rerportMerger, { headers });
  }

  printBalanceReport(BalanceModel: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Report/GetBalanceReport`;
    const headers = new HttpHeaders({
      'responseType': 'blob',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url, BalanceModel, { headers });
  }


  sendDocument(DocumentModel: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Mails/CreatePDFToSendMail`;
    const headers = new HttpHeaders({
      'responseType': 'blob',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url, DocumentModel, { headers });
  }
  SendWhatsappDocument(DocumentModel: any) {
    console.log(DocumentModel);
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Mails/CreatePDFToSendWhatsapp`;
    const headers = new HttpHeaders({
      'responseType': 'blob',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url, DocumentModel, { headers });
  }

  getnamePdf(DocumentModel: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Mails/DownloadPDF`;
    const headers = new HttpHeaders({
      'responseType': 'blob',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url, DocumentModel, { headers });
  }

  getReports() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'responseType': 'blob',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.get<ReportsResponse>(`${AppConstants.apiUrl}api/Report/GetReports`, { headers });
  }

  downloadReportFile(reportKey: number) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'responseType': 'blob',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.get<FileResponse>(`${AppConstants.apiUrl}api/Report/downloadReportFile?reportKey=${reportKey}`, { headers });
  }


}
