import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// MODELOS
import { UserAssigns } from '../../../../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { UserService, ParamsService, AlertService, AuthenticationService, PermsService, BranchOfficeService } from '../../../../services/index';
import { BranchOfficeModel2 } from 'src/app/models/branch-office';
import { first } from 'rxjs/operators';

// PIPES

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  userList: UserAssigns[] = []; // lista para los usuarios
  viewParamTitles: any[] = []; // llena la lista con los titulos de las paginas parametrizados
  title: string; // titulo de la vista
  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  permisos: boolean = true;
  branchOffices: BranchOfficeModel2[];

  columns = [
    { prop: 'Id' },
    { prop: 'UserName' },
    { prop: 'SAPUser' },
    { prop: 'StoreName' },
    { prop: 'CompanyName' },
    { prop: 'Active' },
    { prop: 'SuperUser' }
  ];

  filteredData = [];

  constructor(private sUsers: UserService,
    private router: Router,
    private paramsService: ParamsService,
    private sPerm: PermsService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private branchOfficeService: BranchOfficeService) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.chargeUser();
  }

  ngOnInit() {
    this.checkPermits();
    this.chargeUser();
    this.GetParamsViewList();
    this.getBranchOffices();
  }
  // chequea que se tengan los permisos para acceder a la pagina
  checkPermits() {

    this.sPerm.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        // console.log(data);
        let permListtable: any = data.perms;
        data.perms.forEach(Perm => {
          if (Perm.Name === "V_User") {
            this.permisos = Perm.Active;
          }
        });

      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
    // console.log(this.permisos);
  }

  filterDatatable(event) {
    let val = event.target.value.toLowerCase();
    let colsAmt = this.columns.length;
    if (this.userList.length == 0 && event.key === 'Backspace') {
      this.userList = this.filteredData;
    }

    if (!this.userList[0]) return;
    let keys = Object.keys(this.userList[0]);

    this.userList = this.filteredData.filter(function (item) {
      for (let i = 0; i < colsAmt; i++) {
        if (keys[i] && item[keys[i]] && item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val) {
          return true;
        }
      }
    });
  }

  // carga los usuarios en la tabla de informacion
  // sin parametros
  chargeUser() {
    this.blockUI.start('Cargando listas de usuarios...');
    this.sUsers.getUserList().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        this.userList = data.Users;
        this.filteredData = this.userList;
      } else {
        this.alertService.errorAlert('Error al cargar la lista de usuarios - ' + data.errorInfo.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  // redirecciona a la pagina de configuracion
  // envia parametro del ID del usuario que se quiere enviar
  confPage(id) {
    this.router.navigate(['userConf/' + id]);
  }
  // redirecciona a la pagina para ver la informacion
  // envia parametro del ID del usuario que se quiere enviar
  viewPage(id) {
    this.router.navigate(['userView/' + id]);
  }


  // llena los campos de la tabla de items con los campos parametriados
  GetParamsViewList() {
    this.paramsService.getParasmView()
      .subscribe((data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.viewParamTitles = data.Params.filter(param => {
            return param.type === 6;
          });
          this.ChargeParamstoView();
        } else {
          this.alertService.errorAlert('Error al cargar los parámetros de la pagina - ' + data.errorInfo.Message);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  getBranchOffices() {
    this.blockUI.start('Procesando...');
    this.branchOffices = [];

    this.branchOfficeService.GetBrachOfficess()
      .pipe(first())
      .subscribe(response => {
        this.blockUI.stop();

        if (response.result) this.branchOffices = response.BranchOfficess;
        else this.alertService.errorAlert(response.errorInfo.Message);
      }, err => {
        this.blockUI.stop();
        this.alertService.errorAlert(err);
      });
  }

  getBranchOfficeName(id: number) {
    if (!id) return 'Sin asignación de sucursal';
    if (!this.branchOffices) return 'Sin asignación de sucursal';

    let branchOffice = this.branchOffices.find(x => x.Id === id);
    return branchOffice ? branchOffice.Name : '';
  }


  // Carga los datos parametrizados en las variables
  ChargeParamstoView() {
    // parametrizacion del titulo
    let obj = this.viewParamTitles.filter(param => {
      return param.Name === 'T_users';
    });
    this.title = obj[0].Text;
  }

}
