import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { AppConstants, ISaleQuotation, ISaleOrderSearch, IPrinter, IDocument, ISaleOrder } from '../models';
import { Observable, Subject, timer } from 'rxjs';
import { ISaleOrdersResponse, ISaleOrderResponse, ISaleQuotationsResponse, ISaleQuotationResponse } from '../models/responses';
import { retry, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { IResponse } from '../models/i-api-response';
import { InvoicesOpen, IQuotDocument, SLSaleOrderToARInvoice } from '../models/i-document';

@Injectable({
  providedIn: 'root'
})
export class SOAndSQService {

  //VARBOX
  @BlockUI() blockUI: NgBlockUI;

  private allCurrencies$: Observable<ISaleOrdersResponse>

  private stopPolling = new Subject();

  constructor(
    private httpClient: HttpClient,
    private storage: StorageService) {
      // this.allCurrencies$ = timer(0, 3000).pipe(
      //   switchMap(() => httpClient.get<ISaleOrdersResponse[]>('http://localhost:8000/currencyInfo')),
      //   retry(),
      //   tap(console.log),
      //   share(),
      //   takeUntil(this.stopPolling)
      // );
     }
  // getAllCurrencies(): Observable<ISaleOrdersResponse> {
  //   console.log(7);
  //     return this.allCurrencies$.pipe(
  //       tap(() => console.log('data sent to subscriber')));
  //   }

  GetSaleOrders(searchModel_: ISaleOrderSearch): Observable<ISaleOrdersResponse> {
    searchModel_.U_Almacen = this.storage.getBranchOffice().Code;
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/GetSaleOrders`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.httpClient.post<ISaleOrdersResponse>(url, searchModel_, { headers });
  }

  
  GetSaleOrder(_docEntry: number):  Observable<IResponse<ISaleOrder>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/GetSaleOrder?DocEntry=${_docEntry}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.httpClient.get<IResponse<ISaleOrder>>(url, { headers });
  }
  
  

  GetSaleQuotations(searchModel_: ISaleOrderSearch): Observable<ISaleQuotationsResponse> {
    searchModel_.U_Almacen = this.storage.getBranchOffice().Code;
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/GetQuotations`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.httpClient.post<ISaleQuotationsResponse>(url, searchModel_, { headers });
  }
 
  GetSaleQuotationToCopy2(_docEntry: number): Observable<ISaleQuotationResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/GetQuotation?DocEntry=${_docEntry}&allLines=false`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.httpClient.get<ISaleQuotationResponse>(url, { headers });
  }


  GetSaleQuotationToCopy(_docEntry: number): Observable<IResponse<ISaleOrder>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/GetQuotation?DocEntry=${_docEntry}&allLines=false`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.httpClient.get<IResponse<ISaleOrder>>(url, { headers });
  }

  GetSaleQuotation(_docEntry: number):  Observable<IResponse<IQuotDocument>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/GetQuotation?DocEntry=${_docEntry}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.httpClient.get<IResponse<IQuotDocument>>(url, { headers });
  }

  GetOpenInvoices(_almacen: string): Observable<IResponse<InvoicesOpen[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/GetOpenInvoices?almacen=${_almacen}`;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.access_token}`,
    });
    return this.httpClient.get<IResponse<InvoicesOpen[]>>(url, { headers });
  }

  
  getLocalInvoice(_uniqueInvId: string): Observable<IResponse<SLSaleOrderToARInvoice>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Documents/GetLocalInvoice?uniqueInvId=${_uniqueInvId}`;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.access_token}`,
    });
    return this.httpClient.get<IResponse<SLSaleOrderToARInvoice>>(url, { headers });
  }
 


}