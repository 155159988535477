import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

// MODELOS
import { AppConstants, IPPTransaction, ICard } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { Payments } from '../models/payments';
import { Observable } from 'rxjs';
import { BaseResponse, IPPTransactionResponse, IInvoicePaymentDetailResponse, PaymentSapResponse } from '../models/responses';
import { IInvoicePaymentDetail } from '../models/i-invoice-payment-detail';
import { CurrencyPP } from '../models/constantes';
import { IncommingPayment } from '../models/i-incomming-payment';
import { CreateIncomingPayment } from '../models/i-create-payment';
import {IResponse} from "../models/i-api-response";
import {SLDocument} from "../models/i-sl-document-model";

// PIPES

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient,
    private storage: StorageService) {
  }

  // funcion para obtener los parametros de la vista de facturacion
  // no recibe parametros
  getPayInvoices(searchForm: FormGroup) {

    const code = searchForm.value.BusinessPartner.split(' - ')[0];
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.get(`${AppConstants.apiUrl}api/Payment/GetPayInvoices?CardCode=${code}&Sede=${''}&Currency=${searchForm.value.Currency}`,
      { headers });
  }
  // funcion para obtener los parametros de la vista de facturacion
  // no recibe parametros
  getPayApInvoices(searchForm: FormGroup) {
    const code = searchForm.value.supplier.split(' - ')[0];
    const view = 1;
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(`${AppConstants.apiUrl}api/Payment/GetPayApInvoices?CardCode=${code}&Sede=${searchForm.value.sede}&Currency=${searchForm.value.currency}`,
      { headers });
  }
  // funcion para crear un pago
  // parametros el modelo de pago
  createPayInvoices(Payments: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `${AppConstants.apiUrl}api/Documents/CreatePaymentRecived`;

    return this.http.post(url,
      {
        ...Payments
      },
      { headers }
    );
  }

  // funcion para crear un pago
  // parametros el modelo de pago
  createPayApInvoices(Payments: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    console.log(Payments);
    const url = `${AppConstants.apiUrl}api/Documents/CreatePayApInvoices`;
    return this.http.post(url, Payments, { headers });
  }
  // obtiene la lista de facturas
  // parametros modelo con al informacion que se necesita para buscar los documentos
  getInvList(InfoSearch: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Payment/GetPaymentList`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url,
      InfoSearch,
      { headers });
  }
  // envia el pago a anular
  // parametros modelo de pago con la informacion de la factura a cancelar
  CancelPayment(Payments: any):Observable<IResponse<PaymentSapResponse>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    const url = `${AppConstants.apiUrl}api/Payment/CancelPayment`;

    return this.http.post<IResponse<PaymentSapResponse>>(url,
      Payments,
      { headers }
    );
  }

  requestPinPadPayment(_amount: number, _uniqueInvCode: string, _currencyPayment: string, invoiceDocument: string): Observable<IPPTransactionResponse> {
    let token = JSON.parse(this.storage.getCurrentSessionOffline());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `${AppConstants.offlineUrl}api/Payment/RequestPinPadPayment`;

    return this.http.post<IPPTransactionResponse>(url,
      {
        'Amount': _amount,
        'InvoiceNumber': _uniqueInvCode,
        'Currency': _currencyPayment == 'COL' ? CurrencyPP.LOCAL_CURRENCY : CurrencyPP.USD,
        'InvoiceDocument': invoiceDocument
      },
      { headers }
    );
  }

  updatePinPadPayment(_pinPadCards: IPPTransaction[]): Observable<IPPTransactionResponse> {
    const token = JSON.parse(this.storage.getCurrentSessionOffline());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `${AppConstants.offlineUrl}api/Payment/UpdatePinPadPayment`;

    return this.http.post<IPPTransactionResponse>(url,
      _pinPadCards,
      { headers }
    );
  }

  cancelPinPadCards(_paymentDetail: IInvoicePaymentDetail): Observable<BaseResponse> {
    const token = JSON.parse(this.storage.getCurrentSessionOffline());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `${AppConstants.offlineUrl}api/Payment/CancelPinPadCards`;

    return this.http.post<BaseResponse>(url, _paymentDetail, { headers }
    );
  }

  cancelPinPadCard(_pPTransaction: IPPTransaction): Observable<BaseResponse> {
    const token = JSON.parse(this.storage.getCurrentSessionOffline());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `${AppConstants.offlineUrl}api/Payment/CancelCard`;

    return this.http.post<BaseResponse>(url, _pPTransaction, { headers }
    );
  }

  getPaymentDetail(_docNum: number): Observable<IInvoicePaymentDetailResponse> {
    console.log(_docNum);
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `${AppConstants.apiUrl}api/Payment/GetInvoicePaymentDetail?_docEntry=${_docNum}`;

    return this.http.get<IInvoicePaymentDetailResponse>(url, { headers });
  }

  CreateIncommingPayment(incommingPayment: IncommingPayment) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.post<PaymentSapResponse>(`${AppConstants.apiUrl}api/Payment/SLCreateIncommingPayment`, incommingPayment, { headers });
  }

  /**
   * Method to create incomming payment
   * @param incommingPayment
   * @constructor
   */
  CreateIncommingPaymentSL(incommingPayment: CreateIncomingPayment): Observable<IResponse<PaymentSapResponse>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.post<IResponse<PaymentSapResponse>>(`${AppConstants.apiUrl}api/Payment/SLCreateIncommingPayment`, incommingPayment, { headers });
  }




}
