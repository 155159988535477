import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// MODELOS
import { AppConstants, BaseResponse, PaydeskBalance } from './../models/index';
import { StorageService } from './storage.service';
import { MoneyOutModel } from '../models/i-money-out';
import { CashflowReasonsResponse, FileResponse } from '../models/responses';
import { CashflowModel } from '../models/i-cashflow';

// RUTAS

// COMPONENTES

// SERVICIOS

// PIPES

@Injectable({
  providedIn: 'root'
})
export class dailybalanceService {

  constructor(private http: HttpClient,
    private storage: StorageService) {
  }

  PostCashflow(moneyOut: CashflowModel) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.post<BaseResponse>(`${AppConstants.apiUrl}api/Paydesk/PostCashflow`,
      moneyOut,
      { headers }
    );
  }

  GetCashflowReasons() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.get<CashflowReasonsResponse>(`${AppConstants.apiUrl}api/Paydesk/GetCashflowReasons`,
      { headers }
    );
  }

  PostPaydeskBalance(paydeskBalance: PaydeskBalance) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.post<FileResponse>(`${AppConstants.apiUrl}api/Paydesk/PostPaydeskBalance`, paydeskBalance,
      { headers }
    );
  }

  GetPaydeskBalance(balanceDate: Date | string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.get<FileResponse>(`${AppConstants.apiUrl}api/Paydesk/GetPaydeskBalance?creationDate=${balanceDate}`,
      { headers }
    );
  }

}
