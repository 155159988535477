import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'menu',
  pure: false
})
export class MenuPipe implements PipeTransform {
  constructor() { }

  transform(menu: any[], perms: any[]): any {
    if (!perms) return [];

    return menu.filter(x => !x.perm || perms.some(y => y.Name === x.perm && y.Active));
  }

}
