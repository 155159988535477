import { Component, ElementRef, ViewChild, OnInit, OnDestroy, HostListener, Renderer2, DoCheck } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {Observable, Subscription, Subject, forkJoin, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap, finalize, catchError} from 'rxjs/operators';
import { DatePipe, DecimalPipe, formatDate } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
const printJS = require('print-js');
// MODELOS
import { Params, UserAssigns, ISaleQuotation, IPrinter, IDocumentLine, IDocument } from './../../../models/index';
import {ReportType, KEY_CODE, PRINTERS_PER_REPORT, BoDocumentTypes, SOAndSQActions} from '../../../enum/enum';

// RUTAS

// COMPONENTES

// SERVICIOS
import { Router } from '@angular/router';
import {
  CompanyService, UserService, ItemService, BusinessPartnerService, DocumentService, TaxService, AuthenticationService, PermsService,
  ParamsService, ReportsService, AlertService, SalesManService, ExRateService, StorageService, CommonService
} from '../../../services/index';

// Electron renderer service
import { SOAndSQService } from 'src/app/services/soand-sq.service';
import { SLDocument } from 'src/app/models/i-sl-document-model';
import {IUdf, UDFModel2} from 'src/app/models/i-udf';
import {IBusinessPartnerModel, ISaleMan} from 'src/app/models/i-business-partner';

// PIPES


@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss'],
  providers: [DecimalPipe, DatePipe]
})
export class QuotationComponent implements OnInit, OnDestroy, DoCheck {
  //VARBOX
  slpTypeaheadList: ISaleMan[] = [];
  isDocumentSubmitted: boolean;
  isPriceEditable: boolean;
  previusAmount: number;
  isSalesManSelected: boolean;
  $requestViewer: Subject<number>;
  //requestViewerSubscription: Subscription;
  eventManager: Subscription;
  currentRequest: number;
  requestsToAwait: number;
  cardNameSearchTerm: string;
  returnedDocEntry: number;
  returnedDocNum: number;
  titleQuotation: string;
  @BlockUI() blockUI: NgBlockUI;

  viewParamList: any[] = []; // llena la lista con los componentes parametrizados
  viewParamListHeader: any[] = []; // llena la lista con los componentes parametrizados
  viewParamListTotales: any[] = []; // llena la lista con los componentes parametrizados
  viewParamTitles: any[] = []; // llena la lista con los titulos de las paginas parametrizados

  SlpsList: any[] = []; // lista de los vendedores

  // --------Campos Parametrizados
  lbCardCode: Params = new Params(); // etiqueta para CardCode
  txtCardCode: Params = new Params(); // campo para CardCode
  lbCardName: Params = new Params(); // etiqueta para CardName
  txtCardName: Params = new Params(); // campo para CardName
  lbCurrency: Params = new Params(); // etiqueta para CardName
  txtCurrency: Params = new Params(); // campo para CardName
  txtComments: Params = new Params(); // campo para el comentario
  lbComments: Params = new Params(); // etiqueta para el comentario
  lbSLP: Params = new Params(); // etiqueta para el vendedor
  txtSLP: Params = new Params(); // campo para el vendedor
  // -----------------------------
  // -------- Campos para metrizables de totales
  lbTotalExe: Params = new Params(); // etiqueta para Total sin impuestos
  txtTotalExe: Params = new Params(); // campo para Total sin impuestos
  lbDiscount: Params = new Params(); // etiqueta para descuentos
  txtDiscount: Params = new Params(); // campo para descuentos
  lbTaxes: Params = new Params(); // etiqueta para Impuestos
  txtTaxes: Params = new Params(); // campo para Impuestos
  lbTotal: Params = new Params(); // etiqueta para Total
  txtTotal: Params = new Params(); // campo para Total
  // -----------------------------
  TotalCol: FormControl = new FormControl();
  TotalUSD: FormControl = new FormControl();
  Cant: FormControl = new FormControl();
  ItemInfo: FormControl = new FormControl();
  saleQuotation: ISaleQuotation;

  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual

  public model: any;

  QouForm: FormGroup; // formulario para la cotizacion
  // totalForm: FormGroup; // formulario para el total de la cotizacion
  submitted = false; // variable para reconcer si se envio una peticion o no
  correctQuotation: boolean = false;  //dice si el pago se realizo correctamente
  isCreatingQuotation: boolean;
  setCurr: string;
  currencyList: any[] = []; // lista de tipos de cambio por cliente
  allCurrencyList: any[] = []; // lista de todos los tipos de cambio existentes en la aplicacion
  itemsList: IDocumentLine[] = []; // lista de items
  itemsTypeaheadList: string[] = []; // lista de la concatenacion del Código con el nombre del item
  itemsCodeList: string[] = []; // lista de los Códigos de items
  itemsNameList: string[] = []; // lista de los nombres de items
  itemsStock: string[] = [];
  itemsInventory: string[] = [];
  businessPartners: IBusinessPartnerModel[] = []; // lista de clientes
  companiesList: any[] = []; // lista de las compannias

  conta: number; // variable contador para colocar un 'id' a la lista de items
  total: number; // variable para almacenar el total de la factura
  totalUSD: number;
  tax: number; // variable para almacenar el total de impuestos
  discount: number; // variable para almacenar el total de descuento
  totalWithoutTax: number; // variable para almacenar el total sin impuesto
  DailyExRate: number;
  DEFAULT_BUSINESS_PARTNER: string;

  taxesList: any[] = []; // lista de los impuestos

  closeResult: string; // variable para saber porque se cerro la modal
  modalReference: any; // instancia de la modal de terminal y sucursal

  WHAvailableItemList: any[] = []; // lista de los items disponibles por almacen
  indexAvaItem: number; // indice de la lista del item seleccionado para la disponibilidad
  itemCode: string; // variable para almacenar el Código del ite seleccionado para buscar la disponibilidad
  seriesList: any[] = []; // lista de las series de un item po almacen
  btnVisibleBool: boolean; // activa y desactiva los botones de envio y nuevo
  hasLines: boolean = false;  //dice si hay elementos a cotizar
  public expandedIndex: number; // variable para manejar el collapse de los items y reconocer sobre cual se va a trabajar
  title: string; // titulo de la vista
  lastQuotation: number;
  @ViewChild("name") nameField: ElementRef;

  userAssignsList: UserAssigns[] = [];
  defaultSlpCode: number = -1;
  pesoBolsa: number = 0.020;
  priceEditable: boolean = false;
  maxWeightTo0: number = 0.01;

  Comments: FormControl = new FormControl();
  maxDiscuont: any;
  MapWidth: any;
  tableLength: number;
  permisos: boolean = true;

  isOnSubmit: boolean = false;

  whCode: string;
  whName: string;

  priceList: number;
  IndexMaxUpdate: number;
  IndexMinDocument: number;
  udfs: IUdf[] = [];
  uniqueId: string;
  dateTimeExitUI = "";
  DOC_ENTRY = -1;
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.ctrlKey && !event.altKey && !event.shiftKey && event.keyCode === KEY_CODE.Enter) {
      this.isOnSubmit = true;
      this.onSubmit();
    }
    if (event.ctrlKey && !event.altKey && !event.shiftKey && event.keyCode === KEY_CODE.F7) {
      this.redirectToSO();
    }
    if (event.ctrlKey && !event.altKey && !event.shiftKey && event.keyCode === KEY_CODE.F8) {
      this.redirectToSOandSQ();
    }
  };

  constructor(
    private storageService: StorageService,
    private renderer: Renderer2,
    private commonService: CommonService,
    private fb: FormBuilder,
    private itemService: ItemService,
    private bpService: BusinessPartnerService,
    private decimalPipe: DecimalPipe,
    private documentService: DocumentService,
    private sPerm: PermsService,
    private taxService: TaxService,
    private companyService: CompanyService,
    private uService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private exrate: ExRateService,
    private paramsService: ParamsService,
    private reportsService: ReportsService,
    private router: Router,
    private alertService: AlertService,
    private smService: SalesManService,
    private storage: StorageService,
    private soAndSqService: SOAndSQService,
    private datePipe: DatePipe) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.expandedIndex = -1;

  }

  ngDoCheck(): void {
    const parent: HTMLElement = document.getElementById('scrollable-dropdown-menu');
    if (parent && parent.children.length > 0) {
      const child = parent.children[1];
      if (child) {
        this.renderer.setStyle(child, 'max-height', ' 400px');
        this.renderer.setStyle(child, 'overflow-y', 'auto');
      }
    }
  }

  expandRow(index: number): void {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }
  OnLoad(): void {
    this.uniqueId = this.commonService.GenerateDocumentUniqueID();
    this.InitForm();
    this.LoadInitialData();
    this.InitDocument();
  }
  private InitForm(): void {
    this.QouForm = this.fb.group({
      cardCode: ['', Validators.required],
      cardName: ['', Validators.required],
      currency: ['', Validators.required],
      SlpList: ['', Validators.required],
      Comment: '',
      Discount: [0]
    });
  }
  private LoadInitialData():void {
    //items
    this.itemsTypeaheadList =[];
    this.itemsCodeList =[];
    this.itemsNameList =[];
    this.itemsStock = [];
    this.itemsInventory = [];
    this.itemsList = [];
    this.Cant.setValue(1);
    this.ItemInfo.setValue('');
    this.hasLines = false;
    //Customer
    this.businessPartners = [];
    //SaleMan
    this.SlpsList = [];
    this.slpTypeaheadList = [];
    this.taxesList = [];
    this.IndexMinDocument = 0;
    this.IndexMaxUpdate = 0;
    this.udfs = [];
    this.lastQuotation = 0;
    this.isDocumentSubmitted = false;
    this.isSalesManSelected = true;
    this.cardNameSearchTerm = '';
    this.currentRequest = 0;
    this.$requestViewer = new Subject();
    this.DOC_ENTRY = this.storage.GetDocEntry();
    this.setWarehouseInfo();
    this.tableLength = 1000;
    this.MapWidth = {};
    this.MapWidth["Id"] = 80;
    this.MapWidth["ItemCode"] = 350;
    this.MapWidth["UnitPrice"] = 200;
    this.MapWidth["Marca"] = 200;
    this.MapWidth["Group"] = 200;
    this.MapWidth["Quantity"] = 80;
    this.MapWidth["SubGroup"] = 100;
    this.MapWidth["ItemName"] = 350;
    this.MapWidth["Discount"] = 80;
    this.MapWidth["TaxRate"] = 80;
    this.MapWidth["TaxCode"] = 80;
    this.MapWidth["WhsCode"] = 60;
    this.MapWidth["WhsName"] = 100;
    this.MapWidth["UPWT"] = 100;
    this.MapWidth["LastDate"] = 100;
    this.MapWidth["LinTot"] = 100;
    this.MapWidth["Serie"] = 100;
    this.MapWidth["Opc"] = 100;
    this.btnVisibleBool = true;
    this.saleQuotation = null;
    this.conta = 0;
    this.total = 0;
    this.totalUSD = 0;
    this.DailyExRate = 0;
    this.tax = 0;
    this.discount = 0;
    this.totalWithoutTax = 0;
    this.correctQuotation = false;

  }

  /**
   * Method obtains initial requests
   * @constructor
   * @private
   */
  private InitDocument(): void {

    this.blockUI.start("Procesando, espere por favor...");
    let messageError = '';

    const observables: { [key: string]: Observable<any> } = {
      Customer: this.bpService.GetCustomers(),
      Items: this.itemService.GetItems(),
      Perms: this.sPerm.getPerms(this.currentUser.userId),
      ExchangeRate: this.exrate.getExchangeRate(),
      MaxDiscount: this.documentService.getMaxDiscout(),
      ParamView: this.paramsService.getParasmView(),
      UserList: this.uService.getUserList(),
      SalesMan: this.smService.getSalesMan(),
      Companies: this.companyService.GetCompanies(),
      Currency: this.paramsService.GetCurrencyType(),
      Taxes: this.taxService.GetTaxes(),
    };

    const observablesArray: Observable<any>[] = Object.values(observables);

    forkJoin(observablesArray).pipe(
      switchMap(([CustomerResponse, ItemsResponse, PermsResponse,
                   ExchangeRateResponse, MaxDiscountResponse, ParamViewResponse, UserListResponse,
                   SalesManResponse, CompaniesResponse, CurrencyResponse,
                   TaxesResponse]) => {
        //#region LoadData

        //#region Parametrizaciones
        if(ParamViewResponse.result){

          this.viewParamList = ParamViewResponse.Params.filter(param => {
            return param.type === 1 && param.Visibility;
          });
          this.viewParamList.splice(7, 0, {
            CompanyId: 4,
            Descrip: "Precio unitario con impuesto",
            Display: true,
            Id: 144,
            Name: "UPWT",
            Order: 15,
            ParamsId: 11,
            Text: "Precio+Imp",
            ViewsId: 1,
            Visibility: true,
            type: 1
          });
          this.tableLength = 0;
          for (var i = 0; i < this.viewParamList.length; i++) {
            this.tableLength += this.MapWidth[this.viewParamList[i].Name];
          }
          this.viewParamListHeader = ParamViewResponse.Params.filter(param => {
            return param.type === 2;
          });
          this.viewParamListTotales = ParamViewResponse.Params.filter(param => {
            return param.type === 3;
          });
          this.viewParamTitles = ParamViewResponse.Params.filter(param => {
            return param.type === 6;
          });

          this.ChargeParamstoView();
        }else {
          messageError += `Error: Código: ${ParamViewResponse.errorInfo.Code}, Mensaje: ${ParamViewResponse.errorInfo.Message}`
        }
        // endregion
        //#region Permisos
        if(PermsResponse.result){

          this.isPriceEditable = PermsResponse.perms.find(
            (x) => x.Name === "P_EditPrice"
          ).Active;

          PermsResponse.perms.forEach((Perm) => {
            if (Perm.Name === "V_Quo") {
              this.permisos = Perm.Active;
            }
          });

        } else {
          messageError += `Error: Código: ${PermsResponse.errorInfo.Code}, Mensaje: ${PermsResponse.errorInfo.Message}`
        }
        // endregion
        //#region Customer
        if(CustomerResponse.result){
          this.businessPartners = CustomerResponse.BPS;

        } else {
          messageError += `Error: Código: ${CustomerResponse.errorInfo.Code}, Mensaje: ${CustomerResponse.errorInfo.Message}`
        }
        // endregion
        //#region Items
        if(ItemsResponse.result){

          this.itemsTypeaheadList = ItemsResponse.ItemList.ItemCompleteName;
          this.itemsCodeList = ItemsResponse.ItemList.ItemCode;
          this.itemsNameList = ItemsResponse.ItemList.ItemName;
          this.itemsStock = ItemsResponse.ItemList.ItemStock;
          this.itemsInventory = ItemsResponse.ItemList.ItemInventoried;

        } else {
          messageError += `Error: Código: ${ItemsResponse.errorInfo.Code}, Mensaje: ${ItemsResponse.errorInfo.Message}`
        }
        // endregion

        if(ExchangeRateResponse.result){
          this.DailyExRate = ExchangeRateResponse.exRate;

        }else {
          messageError += `Error: Código: ${ExchangeRateResponse.errorInfo.Code}, Mensaje: ${ExchangeRateResponse.errorInfo.Message}`
        }
        if(MaxDiscountResponse.result){
          this.maxDiscuont = MaxDiscountResponse.discount;

        }else {
          messageError += `Error: Código: ${MaxDiscountResponse.errorInfo.Code}, Mensaje: ${MaxDiscountResponse.errorInfo.Message}`
        }
        //#region User
        if(UserListResponse.result){
          this.userAssignsList = UserListResponse.Users;
          this.userAssignsList.forEach((user) => {
            if (this.currentUser.userId.toString() === user.UserId.toString()) {
              this.defaultSlpCode = user.SlpCode;
            }
          });
        }else {
          messageError += `Error: Código: ${UserListResponse.errorInfo.Code}, Mensaje: ${UserListResponse.errorInfo.Message}`
        }
        //endregion
        //#region Vendedores
        if(SalesManResponse.result){
          this.slpTypeaheadList = SalesManResponse.salesManList.map(element => ({
            SlpCode: element.SlpCode,
            SlpName: element.SlpName,
            TypeAheadFormatt: `${element.SlpCode} ${element.SlpName}`
          }));
        }else {
          messageError += `Error: Código: ${SalesManResponse.errorInfo.Code}, Mensaje: ${SalesManResponse.errorInfo.Message}`
        }
        //endregion
        //#region companias
        if(CompaniesResponse.result){
          this.companiesList = CompaniesResponse.companiesList;
          this.companiesList.forEach((comp) => {
            this.pesoBolsa = comp.ScaleWeightToSubstract;
            this.priceEditable = comp.IsLinePriceEditable;
            this.maxWeightTo0 = comp.ScaleMaxWeightToTreatAsZero;
          });
        }else {
          messageError += `Error: Código: ${CompaniesResponse.errorInfo.Code}, Mensaje: ${CompaniesResponse.errorInfo.Message}`
        }
        //endregion
        //#region Currency
        if(CurrencyResponse.length > 0){
          this.currencyList = CurrencyResponse;
          this.allCurrencyList = CurrencyResponse;

        }else {
          messageError += `Error: Código: ${CurrencyResponse.errorInfo.Code}, Mensaje: ${CurrencyResponse.errorInfo.Message}`
        }
        //endregion
        //#region Taxes
        this.taxesList = TaxesResponse.Taxes;
        this.taxesList.push({
          TaxCode: "EXE",
          TaxRate: "0.00",
        });
        //endregion
        //#endregion

        this.SetInitialData();

        if (this.DOC_ENTRY > 0) {
          this.isCreatingQuotation = false;
          return this.soAndSqService.GetSaleQuotation(this.DOC_ENTRY);
        } else {
          return of(null);
        }
      }),
      finalize(() => {
        this.blockUI.stop();
        if(messageError.length > 0){
          this.alertService.errorAlert(
            `${messageError}`
          );
        }}),
      catchError(err => {
        this.alertService.errorInfoAlert(
          `Error: ${err}`
        );
        return of(null);
      })
    ).subscribe({
      next: res => {
        if (res) {
          if(res.result) {
            this.saleQuotation = res.Data;
            this.SetDocument();
            this.SetDocumentLines();
          }else {
            messageError += `Error: Código: ${res.errorInfo.Code}, Mensaje: ${res.errorInfo.Message}`
          }
        }

        this.getTotals();
      },
      error: err => {
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${err}`
        );
      }
    });
  }

  private SetInitialData():void {
    if (this.permisos) {
      this.nameField.nativeElement.focus();
    }

    this.DEFAULT_BUSINESS_PARTNER = this.storage.getBranchOffice().CashBusinessPartnerCode;

    if (this.DOC_ENTRY <= 0) {
      this.storage.SaveUIAction(-1);
      this.isCreatingQuotation = true;
    }

    const selectedSLP = this.slpTypeaheadList.find(x => x.SlpCode === this.defaultSlpCode.toString());

    if (selectedSLP) {
      this.QouForm.patchValue({ SlpList: selectedSLP });
    }


    let customer = this.businessPartners.find(x => x.CardCode === this.DEFAULT_BUSINESS_PARTNER);

    if (customer) {

      this.QouForm.patchValue({
        cardCode: this.DEFAULT_BUSINESS_PARTNER,
        cardName: customer.CardName });

      this.priceList = customer.ListNum;

      this.getCurrencyByUser(customer.Currency);

    }

  }

  private SetDocument():void {

    this.saleQuotation.DocDate = formatDate(this.saleQuotation.DocDate, 'yyyy-MM-dd', 'en');
    this.DEFAULT_BUSINESS_PARTNER = this.saleQuotation.CardCode;

    //#region DocumentForm
    const selectedSLP = this.slpTypeaheadList.find(
      x => x.SlpCode === this.saleQuotation.SalesPersonCode.toString()
    );

    this.QouForm.patchValue({
      cardCode: this.saleQuotation.CardCode,
      cardName: this.saleQuotation.CardName,
      currency: this.saleQuotation.DocCurrency,
      SlpList: selectedSLP,
      Comment: this.saleQuotation.Comments
    });

    this.QouForm.controls['cardCode'].disable();
    this.QouForm.controls['cardName'].disable();
    //endregion

    const customer = this.businessPartners.find(x => x.CardCode === this.saleQuotation.CardCode);

    if (customer) {
      this.priceList = customer.ListNum;
      this.getCurrencyByUser(customer.Currency);

    }

  }

  private SetDocumentLines():void
  {
    this.IndexMaxUpdate =  this.saleQuotation.DocumentLines.reduce((acc, i) => (i.LineNum > acc.LineNum ? i : acc)).LineNum || 0;
    this.IndexMinDocument =  this.saleQuotation.DocumentLines.reduce((acc, i) => (i.LineNum < acc.LineNum ? i : acc)).LineNum || 0;

    this.saleQuotation.DocumentLines.forEach(x => {
      this.conta++;
      this.total += x.Quantity * x.UnitPrice;
      let tot = (x.UnitPrice * x.UnitPrice);
      let itemAux = {
        Id: this.conta,
        Item: x.ItemCode,
        ItemCode: x.ItemCode,
        ItemName: x.ItemName,
        CodeName: `${x.ItemCode} - ${x.ItemName}`,
        UnitPrice: JSON.parse(this.storage.GetCustomerData()).Currency === 'COL' ? x.UnitPrice : (parseFloat(Number(x.UnitPrice / this.DailyExRate).toString())),
        UnitPriceCol: x.UnitPrice,
        UnitPriceDol: x.UnitPrice,
        U_SugPrice: 0,
        TaxCode: x.TaxMAGRate ? x.TaxMAGCode : x.TaxCode,
        TaxRate: x.TaxMAGRate ? x.TaxMAGRate : x.TaxRate,
        Quantity: x.Quantity,
        Active: true,
        LinTot: tot,
        DiscountPercent: x.DiscountPercent,
        WarehouseCode: x.WarehouseCode,
        WhsName: x.WhsName,
        Serie: '',
        SysNumber: 0,
        OnHand: x.OnHand,
        InvntItem: x.InvntItem,
        LineStatus: x.LineStatus,
        LineNum: x.LineNum,
        BaseType: x.BaseType,
        BaseLine: x.BaseLine == -1 ? null : x.BaseLine,
      } as IDocumentLine;

      this.itemsList.push(itemAux);
      this.hasLines = true;
      this.LineTotalCalculate(this.itemsList.length - 1);
    });

  }

  ngOnInit() {

    this.eventManager = this.commonService.eventManager.subscribe(async next => {
      if (next) {
        if (next.ViewName.filter(x => x === this.router.url).length > 0) {
          switch (next.FunctionName) {
            case 'refreshCustomers':
              this.GetCustomers();
              break;
            case 'redirectToSoAndSQ':
              if (!this.isDocumentSubmitted && this.itemsList.length > 0) {
                let confirmationResult = await this.alertService.ConfirmationAlert('Confirmación', `No ha guardado el documento ¿Desea continuar?`, 'Continuar');
                if (!confirmationResult) return;
                this.router.navigate(['SOandSQ']);
              }
              else {
                this.router.navigate(['SOandSQ']);
              }
              break;
          }
        }
      }
    });

    this.OnLoad();

  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.commonService.hasDocument.next(``);
    this.storage.SaveBreadCrum(``);
    this.currentUserSubscription.unsubscribe();
    //if (this.requestViewerSubscription) this.requestViewerSubscription.unsubscribe();
    this.eventManager.unsubscribe();
    this.storage.SaveDocEntry(-1);
    this.storage.SaveCustomerData('{}');
  }

  checkPermits() {
    this.sPerm.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        this.isPriceEditable = data.perms.find(x => x.Name === 'P_EditPrice').Active;
        data.perms.forEach(Perm => {
          if (Perm.Name === "V_Quo") {
            this.permisos = Perm.Active;
            if (this.permisos) {
              this.nameField.nativeElement.focus();
            }
          }
        });
      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }
  //chequea si hay existencias del item a agregar
  CheckAvailableItem(ItemCode) {
    if (ItemCode !== '') {
      this.blockUI.start('Obteniendo Disponibilidad del Artículo, Espere Por Favor...'); // Start blocking
      this.itemService.GetWHAvailableItem(ItemCode)
        .subscribe((data: any) => {
          if (data.result) {
            this.WHAvailableItemList.length = 0;
            this.itemCode = ItemCode;
            this.WHAvailableItemList = data.whInfo;
            if (data.whInfo.length <= 0) {
              this.blockUI.stop(); // Stop blocking
              this.alertService.infoInfoAlert('Este artículo no posee disponibles en ningún almacén.');
            }
            this.blockUI.stop(); // Stop blocking
          } else {
            this.blockUI.stop(); // Stop blocking
            this.alertService.errorAlert('Error al Obtener disponibilidad el artículo - ' + data.errorInfo.Message);
          }
        }, (error: any) => {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
        });
    }
  }

  setSalesPerson() {
    this.blockUI.start('Cargando listas de usuarios...');
    this.uService.getUserList().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        this.userAssignsList = data.Users;
        this.userAssignsList.forEach(user => {
          if (this.currentUser.userId.toString() === user.UserId.toString()) {
            this.defaultSlpCode = user.SlpCode;
          }
        });
        this.GetSalesPersonList();
      } else {
        this.blockUI.stop();
        this.alertService.errorAlert('Error al cargar la lista de usuarios - ' + data.errorInfo.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  getExRate() {
    this.blockUI.start('Obteniendo tipo de cambio, Espere Por Favor...');
    this.exrate.getExchangeRate().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        this.DailyExRate = data.exRate
        this.currentRequest++;
        this.$requestViewer.next(this.currentRequest);
      } else {
        this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
      }

    }, (error: any) => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error getCustomers!!!, Error: ${error.error.Message}`);
    });
  }

  getMaxDiscout() {
    this.documentService.getMaxDiscout().subscribe((data: any) => {
      if (data.result) {
        this.maxDiscuont = data.discount
        this.currentRequest++;
        this.$requestViewer.next(this.currentRequest);
      }
      else {
        this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
      }
    }, (error: any) => {
      this.alertService.errorInfoAlert(`Error getCustomers!!!, Error: ${error.error.Message}`);
    });

  }

  /**
   * Function to obtain customers from SAP
   * @constructor
   */
  GetCustomers() {
    this.businessPartners = [];

    this.blockUI.start('Obteniendo Clientes, Espere Por Favor...');
    this.bpService.GetCustomers()
      .subscribe((data: any) => {
        if (data.result) {
          this.businessPartners = data.BPS;
          this.GetCurrencyType();
          this.ChangeCode({ item: this.DEFAULT_BUSINESS_PARTNER });
          this.blockUI.stop(); // Stop blocking
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert('Error al cargar clientes - Error: ' + data.errorInfo.Message);
        }
      }, (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  /**
   * Sets the default values
   * @constructor
   */
  DefaultConfigurationBusinessPartner() {
    let customer = this.businessPartners.find(customer => customer.CardCode === this.DEFAULT_BUSINESS_PARTNER);

    if (customer) {
      this.getCurrencyByUser(customer.Currency);
      this.QouForm.patchValue({ cardName: this.saleQuotation ? this.saleQuotation.CardName : customer.CardName });
    }
  }

  // funcion para obtener los items desde SAP
  getItems() {
    this.blockUI.start('Obteniendo Items, Espere Por Favor...'); // Start blocking
    this.itemService.GetItems()
      .subscribe((data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.itemsTypeaheadList.length = 0;
          this.itemsCodeList.length = 0;
          this.itemsNameList.length = 0;
          this.itemsStock = [];
          this.itemsInventory = [];
          this.itemsTypeaheadList = data.ItemList.ItemCompleteName;
          this.itemsCodeList = data.ItemList.ItemCode;
          this.itemsNameList = data.ItemList.ItemName;
          this.itemsStock = data.ItemList.ItemStock;
          this.itemsInventory = data.ItemList.ItemInventoried;

        } else {
          this.alertService.errorAlert('Error al cargar Los Items - Error: ' + data.errorInfo.Message);
        }
      }, (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error.error.Message}`);
      });
  }
  // agrega un item a la linea de cotizacion
  addItems(item) {
    if (this.ItemInfo.value !== '') {
      let mobileNavigatorObject: any = window.navigator;
      if (mobileNavigatorObject && mobileNavigatorObject.clipboard) {
        mobileNavigatorObject.clipboard.readText()
          .then(text => {
            if (!isNaN(parseInt(text))) {
              if (Number(text) > this.maxWeightTo0) {
                // this.Cant.setValue(Math.max(Number(text) - this.pesoBolsa, 0.0001));
                //this.invForm.patchValue({cardName: cardName});
                mobileNavigatorObject.clipboard.writeText("*")
                  .then(text => {
                    //this.Cant.setValue(Number(text));
                    //this.invForm.patchValue({cardName: cardName});
                  })
                  .catch(err => {
                    // console.error('Failed to clear clipboard contents: ', err);
                  });
              }

            }
            let cant = Number(this.Cant.value);
            let code = item.item.split(' COD. ')[0];
            let searchitem = true;
            this.blockUI.start('Obteniendo Información del Artículo, Espere Por Favor...'); // Start blocking
            //this.itemsList.find(x => x.ItemCode == code).
            this.CheckAvailableItem(code);
            var index: number = this.itemsList.indexOf(this.itemsList.find(x => x.Item == code));
            // this.itemsList.forEach(x => {
            if (index != -1) {
              this.itemsList[index].Quantity += cant;
              this.itemsList[index].LinTot = this.itemsList[index].Quantity * this.itemsList[index].UnitPrice;
              searchitem = false;
              this.LineTotalCalculate(index)
              this.getTotals();
              this.blockUI.stop();
            }

            this.itemService.GetItemByItemCode(item.item.split(' COD. ')[0], this.priceList, this.QouForm.controls.cardCode.value) // #PR
              .subscribe((data: any) => {
                if (data.result) {
                  if (searchitem) {
                    this.conta++;
                    this.total += data.Item.UnitPrice;
                    let tot = this.QouForm.controls.currency.value == 'COL' ? (data.Item.UnitPrice * cant) : (cant * (parseFloat(Number(data.Item.UnitPrice / this.DailyExRate).toFixed(2))));

                    let itemAux = {
                      Item: data.Item.ItemCode,
                      ItemCode: `${data.Item.ItemCode} - ${data.Item.ItemName}`,
                      ItemName: `${data.Item.ItemName}`,
                      CodeName: `${data.Item.ItemCode} - ${data.Item.ItemName}`,
                      UnitPrice: this.QouForm.controls.currency.value == 'COL' ? data.Item.UnitPrice : (parseFloat(Number(data.Item.UnitPrice / this.DailyExRate).toFixed(2))),
                      UnitPriceCol: data.Item.UnitPrice,
                      UnitPriceDol: (parseFloat(Number(data.Item.UnitPrice / this.DailyExRate).toFixed(2))),
                      U_SugPrice: 0,
                      TaxCode: data.Item.TaxMAGRate > 0 ? data.Item.TaxMAGCode : data.Item.TaxCode,
                      TaxRate: data.Item.TaxMAGRate > 0 ? data.Item.TaxMAGRate : data.Item.TaxRate,
                      Quantity: cant,
                      Active: true,
                      Id: this.conta,
                      LinTot: tot,
                      DiscountPercent: data.Item.Discount,
                      WarehouseCode: this.whCode,
                      WhsName: this.whName,
                      Serie: '',
                      SysNumber: 0,
                      OnHand: data.Item.OnHand,
                      InvntItem: data.Item.InvntItem,
                      LineStatus: 'O',
                      LineNum:-1
                    } as IDocumentLine;

                    this.itemsList.push(itemAux);

                    this.hasLines = true;
                    this.LineTotalCalculate(this.itemsList.length - 1);
                    this.getTotals();
                  }
                  this.Cant.setValue(1);
                  this.ItemInfo.setValue('');
                  this.blockUI.stop(); // Stop blocking
                } else {
                  this.blockUI.stop(); // Stop blocking
                  this.alertService.errorAlert(`Error: no se pudo obtener la información del item solicitado: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
                }

              }, (error: any) => {
                this.blockUI.stop(); // Stop blocking
                this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
              });
          })
          .catch(err => {
            // console.error('Failed to read clipboard contents: ', err);
          });
      }
    }
  }


  cantChange() {
    if (this.Cant.value < 1) {
      this.Cant.setValue(1);
    }
  }

  // funcion para obtener los impuestos desde SAP
  // no recibe parametros
  getTaxes() {
    this.blockUI.start('Obteniendo Impuestos, Espere Por Favor...'); // Start blocking
    this.taxService.GetTaxes()
      .subscribe((data: any) => {
        if (data.result) {
          this.taxesList.length = 0;
          this.taxesList = data.Taxes;

          this.blockUI.stop(); // Stop blocking
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert('Error al cargar Impuestos - Error: ' + data.errorInfo.Message);
        }

      }, (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para obtener los clientes desde SAP
  // recibe como parametros el item y el index
  selectedItem(item, idx) {
    if (item.item !== '') {
      this.blockUI.start('Obteniendo Información del Artículo, Espere Por Favor...'); // Start blocking

      // #REV# revisar de cual lista de precios obtener la cotizacion
      this.itemService.GetItemByItemCode(item.item.split(' COD. ')[0], Number(this.priceList ? this.priceList : 1), this.QouForm.controls.cardCode.value) // #PR

        .subscribe((data: any) => {
          if (data.result) {
            this.itemsList[idx].ItemCode = data.Item.ItemCode;
            this.itemsList[idx].ItemName = data.Item.ItemName;
            this.itemsList[idx].Item = `${data.Item.ItemCode} - ${data.Item.ItemName}`;
            this.itemsList[idx].UnitPrice = data.Item.UnitPrice;
            this.itemsList[idx].U_SugPrice = data.Item.UnitPrice;
            this.itemsList[idx].TaxCode = data.Item.TaxMAGRate > 0 ? data.Item.TaxMAGCode : data.Item.TaxCode;
            this.itemsList[idx].TaxRate = data.Item.TaxMAGRate > 0 ? data.Item.TaxMAGRate : data.Item.TaxRate;
            this.itemsList[idx].DiscountPercent = data.Item.Discount;
            this.itemsList[idx].LinTot = parseFloat(data.Item.UnitPrice);
            this.itemsList[idx].Active = false;
            this.conta++;
            this.total += data.Item.UnitPrice;
            let itemAux = {
              ItemCode: '',
              ItemName: '',
              UnitPrice: 0,
              U_SugPrice: 0,
              TaxCode: 'EXE',
              Quantity: 1,
              Active: true,
              Id: this.conta,
              LinTot: 0,
              TaxRate: 0.00,
              DiscountPercent: 0,
              WarehouseCode: this.whCode,
              WhsName: 'Almacén general',
              Serie: ''
            } as IDocumentLine;
            this.itemsList.push(itemAux)

            this.getTotals();
          } else {
            this.alertService.errorAlert('Error al obtener Información del artículo - Error: ' + data.errorInfo.Message);
          }
          this.blockUI.stop(); // Stop blocking
        }, (error: any) => {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
        });
    }
  }

  // funcion para eliminar el item de la lista
  // recibe como parametro el item a eliminar
  removeItem(item) {
    const index = this.itemsList.indexOf(item);
    this.itemsList.splice(index, 1);
    if (this.itemsList.length > 0) this.hasLines = true;
    else this.hasLines = false;
    this.getTotals();
  }

  // convenience getter for easy access to form fields
  get f() { return this.QouForm.controls; }

  /**
   * Mothod to create quotation
   */
  onSubmit() {
    if (this.itemsList.length === 0) {
      this.alertService.errorInfoAlert(`Por favor agregue al menos un producto`);
      return;
    }

    if (!this.QouForm.value.cardName) {
      let cardName = '';

      if (this.storage.GetDocEntry() > 0) {
        let customer = this.businessPartners.find(x => x.CardCode === this.DEFAULT_BUSINESS_PARTNER);
        if (customer) {
          cardName = customer.CardName;
        }
      } else {
        cardName = this.cardNameSearchTerm;
      }

      this.QouForm.patchValue({
        cardName: cardName
      });
    }

    let SLP = this.QouForm.controls.SlpList.value as ISaleMan;
    let SLPList = this.slpTypeaheadList.find(x => x.SlpCode === SLP.SlpCode);
    if (SLPList) {
      if (SLP && SLP.SlpCode === "-1") {
        this.isSalesManSelected = false;
        return;
      }
    } else {
      this.isSalesManSelected = false;
      return;
    }

    if (this.QouForm.invalid) {
      this.isOnSubmit = false;
      return;
    }

    this.submitted = true;

    if (this.correctQuotation) {
      this.alertService.infoInfoAlert(`Presione el boton de Limpiar Campos para poder crear una cotización`);
      return;
    }

    this.SetValueUdfs();

    if ((this.itemsList.length - 1) < this.IndexMinDocument) {
      this.IndexMaxUpdate = -1;
    }

    let linesList: IDocumentLine[] = this.itemsList.map(line => {
      line.LineNum == -1 ? this.IndexMaxUpdate = this.IndexMaxUpdate + 1 : this.IndexMaxUpdate;

      let item = {
        ItemCode: line.ItemCode.split('-')[0].trim(),
        DiscountPercent: line.DiscountPercent,
        Quantity: line.Quantity,
        TaxCode: line.TaxCode,
        TaxRate: line.TaxRate,
        UnitPrice: line.UnitPrice,
        WarehouseCode: line.WarehouseCode,
        LineNum: line.LineNum == -1 ? this.IndexMaxUpdate : line.LineNum,
        BaseEntry: null,
        BaseLine: null,
        BaseType: -1,
        LineStatus: line.LineStatus,
        TaxCode_BCK: line.TaxCode

      } as IDocumentLine;

      return item;

    });

    let docEntry = this.storage.GetDocEntry();

    let quotation = {
      DocEntry: docEntry,
      CardCode: this.QouForm.controls.cardCode.value,
      CardName: this.QouForm.controls.cardName.value,
      Comments: this.QouForm.controls.Comment.value,
      DocCurrency: this.QouForm.controls.currency.value,
      DocDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      DocType: BoDocumentTypes.dDocument_Items,
      SalesPersonCode: Number(SLP.SlpCode),
      Udfs: this.udfs,
      DocumentLines: linesList
    } as SLDocument;
    quotation.StringedDocument = JSON.stringify(quotation);

    if (this.isCreatingQuotation) {



      this.blockUI.start('Creando proforma, espere por favor...');
      this.documentService.CreateQuotation(quotation)
        .subscribe(callback => {
          this.blockUI.stop();
          if (callback && callback.Data) {
            this.dateTimeExitUI = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss SSS a", "en");

            this.isDocumentSubmitted = true;
            this.returnedDocNum = callback.Data.DocNum;
            this.returnedDocEntry = callback.Data.DocEntry;
            this.lastQuotation = callback.Data.DocEntry;
            this.titleQuotation = `Proforma creada correctamente`;
            (<HTMLButtonElement>document.getElementById('triggerAfterPayModal')).click();
            this.correctQuotation = true;
          } else {
            this.isDocumentSubmitted = false;
            this.alertService.errorAlert(`Error al intentar crear la proforma, Código: ${callback.Error.Code}, Mensaje: ${callback.Error.Message}`);
          }
          this.isOnSubmit = false;
        }, (error: any) => {
          this.isDocumentSubmitted = false;
          this.isOnSubmit = false;
          this.blockUI.stop();
          let err = error && error.Code ? `Error al intentar crear la proforma, Código: ${error.Code}, Mensaje: ${error.Message}`: '';
          this.alertService.errorAlert(err ? err :`Error al intentar conectar con el servidor, Error: ${error}`);
        });
    }
    else {
      this.blockUI.start('Actualizando proforma, espere por favor...'); // Start blocking
      const DOC_ENTRY = this.storage.GetDocEntry();
      this.documentService.UpdateQuotation(quotation)
        .subscribe(callback => {
          this.blockUI.stop();
            this.dateTimeExitUI = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss SSS a", "en");

            this.isDocumentSubmitted = true;
            this.returnedDocNum = this.saleQuotation.DocNum;
            this.returnedDocEntry = this.saleQuotation.DocEntry;
            this.titleQuotation = `Proforma actualizada correctamente`;
            this.lastQuotation = this.saleQuotation.DocEntry;
            this.correctQuotation = true;
            (<HTMLButtonElement>document.getElementById('triggerAfterPayModal')).click();

          this.isOnSubmit = false;
        }, (error: any) => {
          this.isOnSubmit = false;
          this.correctQuotation = false;
          this.isDocumentSubmitted = false;
          this.blockUI.stop();
          let err = error && error.Code ? `Error al intentar actualizar la proforma, Código: ${error.Code}, Mensaje: ${error.Message}`: '';
          this.alertService.errorAlert(err ? err :`Error al intentar conectar con el servidor, Error: ${error}`);
        });
    }
  }

  formatter = (slp: any) => slp.TypeAheadFormatt || slp;

  formatterBusinessPartner = (businessPartner: IBusinessPartnerModel) => businessPartner.CardName || businessPartner;

  /**
   * Formatter business partner Code
   * @param businessPartner
   */
  FormatterCardCodeBusinessPartner = (businessPartner: IBusinessPartnerModel) => businessPartner.CardCode || businessPartner;

  searchSlpName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.slpTypeaheadList
            .filter(
              (v) =>
                v.SlpCode.toLowerCase().includes(term.toLowerCase()) ||
                v.SlpName.toLowerCase().includes(term.toLowerCase())
            )
            .slice(0, 10)
      )
    );

  searchBPCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.businessPartners.filter(v => v.CardCode.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  searchBPName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.businessPartners
            .filter((businessPartner) =>{
              return (businessPartner.CardName.toLowerCase().includes(term.toLowerCase()) ||
                businessPartner.Cedula.toLowerCase().includes(term.toLowerCase()))
            }).slice(0, 10)
      )
    );

  searchItemCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(0),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.itemsTypeaheadList.filter(v => {

          let a = v.toLowerCase();

          const stringSize = a.length;

          const t = term.toLowerCase();

          const b = t.split('*').filter(x => x !== '');

          const size = b.length;

          let isSorted = true;

          if (size > 1) {

            let indexes = [];

            for (let c = 0; c < size; c++) {
              // b[c] = b[c].replace(' ', '');
              // const invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
              // b[c] = b[c] .replace(invalid, '');
              const ii = a.indexOf(b[c]);
              if (ii > -1) {
                a = a.slice(ii, stringSize);
                if (indexes.length > 0) indexes.push(indexes[indexes.length - 1] + ii);
                else indexes.push(ii);
              }
            }

            let sizeIndexes = indexes.length;

            if (sizeIndexes === size) {
              for (let c = 0; c < sizeIndexes - 1; c++) {
                if (indexes[c] > indexes[c + 1]) {
                  isSorted = false;
                  c = sizeIndexes;
                }
              }
              return isSorted;
            }
          }

          return v.toLowerCase().indexOf(term.toLowerCase()) > -1;
        }).sort((x, y) => x.toLowerCase().indexOf(term.toLowerCase()) - y.toLowerCase().indexOf(term.toLowerCase())).slice(0, 50))
    )

  /**
   * Funcion para detectar el cambio en el input de Código
   * @param $event -Recibe como parametro el Código del item
   * @constructor
   */
  ChangeCode($event: any) {
    if ($event != null) {
      let businessPartner = $event.item as IBusinessPartnerModel;
      $event.preventDefault();

      if (businessPartner) {
        this.priceList = businessPartner.ListNum;
        if (this.itemsList.length > 0 && !(this.storage.GetDocEntry() > 0)) this.setupRecalculate();
        this.getCurrencyByUser(businessPartner.Currency);
        this.QouForm.patchValue({ cardName: businessPartner.CardName });
        this.QouForm.controls['cardCode'].setValue(businessPartner.CardCode);
      }
    }
  }

  setupRecalculate(): void {

    this.conta = 0;
    this.total = 0;
    this.totalUSD = 0;
    this.tax = 0;
    this.discount = 0;
    this.totalWithoutTax = 0;
    let itemsToCalculate: any[] = []; // lista de items

    this.itemsList.forEach(x => itemsToCalculate.push(x));
    this.blockUI.start('Recalculando impuestos, espere por favor');
    setTimeout(() => {
      this.pollItemsData(itemsToCalculate);
    }, 1000);
  }

  pollItemsData(_itemsList: IDocumentLine[]): void {
    if (_itemsList.length === 0) {
      this.blockUI.stop();
      return;
    }
    const ITEM_INDEX = _itemsList.length - 1;
    const ITEM = _itemsList[ITEM_INDEX];

    this.itemService.GetItemByItemCode(ITEM.Item, this.priceList, this.QouForm.controls.cardCode.value)
      .subscribe((data: any) => {
        if (data.result) {
          this.total += ITEM.UnitPrice;
          this.itemsList[ITEM_INDEX].DiscountPercent = data.Item.Discount;
          this.itemsList[ITEM_INDEX].TaxCode = data.Item.TaxCode;
          this.itemsList[ITEM_INDEX].TaxRate = data.Item.TaxRate;
          this.itemsList[ITEM_INDEX].TaxCode_BCK = data.Item.TaxCode;
          this.itemsList[ITEM_INDEX].TaxRate_BCK = data.Item.TaxRate;

          this.LineTotalCalculateExt(_itemsList.length - 1);
          this.getTotals();
          _itemsList.pop();
          this.pollItemsData(_itemsList);
        } else {
          this.pollItemsData([]);
          this.alertService.errorAlert(`Error no se pudo recalcular los impuestos, motivo: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
        }
      }, (error: any) => {
        this.pollItemsData([]);
        this.alertService.errorInfoAlert(`Error no se pudo recalcular los impuestos, motivo: ${error}`);
      });
  }

  /**
  * Funcion que permite llenar el select de moneda segun las monedas que posee el cliente
  * @param {string} currency Variable que contiene el tipo de moneda del cliente
  */
  getCurrencyByUser(currency: string) {
    if (!this.allCurrencyList) return;

    this.currencyList = this.allCurrencyList;

    if (currency === 'COL' || currency === 'USD') {
      this.currencyList = this.currencyList.filter(cur => cur.Id === currency);
    }

    if (this.currencyList.length === 1) {
      this.QouForm.patchValue({ currency: this.currencyList[0].Id }); //this.currencyList[0].Id
    }
    else {
      this.QouForm.patchValue({ currency: "COL" }); //
    }
    this.SetCurr();
  }

  /**
   * Funcion para selecionar socio del typeahead
   * @param $event - Recibe como parametro el nombre cliente
   * @constructor
   */
  ChangeDescription($event: any) {
    if ($event.item) {
      let businessPartner: IBusinessPartnerModel = $event.item;
      $event.preventDefault();

      this.QouForm.controls['cardCode'].setValue(businessPartner.CardCode);
      this.QouForm.controls['cardName'].setValue(businessPartner.CardName);

       this.priceList = businessPartner.ListNum;
       if (this.itemsList.length > 0) this.setupRecalculate();
       this.getCurrencyByUser(businessPartner.Currency);
       this.QouForm.patchValue({ cardCode: businessPartner.CardCode });

    }
  }
  /**
   * Funcion para detectar el cambio en el input de nombre cliente
   * @param $event
   * @constructor
   */
  OnChangeNameBusinessPartner($event: any) {
    if ($event != null) {
      let cardName = $event.target.value;
      let cardCode = this.QouForm.get("cardCode").value;

      let businessPartner = this.businessPartners.find((x) => x.CardCode == cardCode);

      if (businessPartner && businessPartner.CardName != cardName) {
        this.QouForm.controls['cardName'].setValue(cardName);
      }
    }
  }

  // funcion para calcular los totales de la SO
  // calcula por separado los precios en dolares y colones para poder evitar inconsistencias en
  // las conversiones
  getTotals() {
    this.total = 0;
    this.totalUSD = 0;
    this.tax = 0;
    this.discount = 0;
    this.totalWithoutTax = 0;
    this.itemsList.forEach(element => {
      const lintot = parseFloat(Number(element.UnitPrice * element.Quantity).toFixed(2));
      const disc = parseFloat(Number(lintot * (element.DiscountPercent / 100)).toFixed(2));
      this.discount = parseFloat(Number(disc + this.discount).toFixed(2));
      this.totalWithoutTax = parseFloat(Number((lintot - disc) + this.totalWithoutTax).toFixed(2));
      this.tax = parseFloat(Number(((lintot - disc) * (element.TaxRate / 100)) + this.tax).toFixed(2));
    });
    //this.total += parseFloat(Number(this.totalWithoutTax + this.tax).toFixed(2));
    if (this.saleQuotation) {
      if (this.saleQuotation.DocCurrency == 'COL') {
        this.total += (parseFloat(Number(this.totalWithoutTax + this.tax).toFixed(2)));
        this.totalUSD += parseFloat(Number((this.totalWithoutTax + this.tax) / this.DailyExRate).toFixed(2));
      }
      else {
        this.total += (parseFloat(Number((this.totalWithoutTax + this.tax) * this.DailyExRate).toFixed(2)));
        this.totalUSD += parseFloat(Number(this.totalWithoutTax + this.tax).toFixed(2));
      }
    }
    else {
      if (this.QouForm.controls.currency.value == 'COL') {
        this.total += (parseFloat(Number(this.totalWithoutTax + this.tax).toFixed(2)));
        this.totalUSD += parseFloat(Number((this.totalWithoutTax + this.tax) / this.DailyExRate).toFixed(2));
      }
      else {
        this.total += (parseFloat(Number((this.totalWithoutTax + this.tax) * this.DailyExRate).toFixed(2)));
        this.totalUSD += parseFloat(Number(this.totalWithoutTax + this.tax).toFixed(2));
      }
    }
    // this.total = this.total.toFixed(2);
    //	this.totalUSD = this.totalUSD.toFixed(2);
  }

  // funcion al cambiar el tipo de taxcode
  // recibe como parametro el taxxode y el indice de la lista
  changeTaxCode(TaxCode: string, idx: number) {
    const rate = this.taxesList.find(i => i.TaxCode === TaxCode.toUpperCase());
    if (rate) {
      this.itemsList[idx].TaxCode = rate.TaxCode.toUpperCase();
      this.itemsList[idx].TaxRate = parseFloat(this.decimalPipe.transform(rate.TaxRate, '.2'));
      this.LineTotalCalculate(idx);
    }
  }

  // funcion para calcular el total de la linea
  // recibe como parametro el index de la lista de items
  LineTotalCalculate(idx: number) {
    if (!this.maxDiscuont) this.maxDiscuont = 10;
    if (!this.itemsList[idx].DiscountPercent || this.itemsList[idx].DiscountPercent < 0) this.itemsList[idx].DiscountPercent = 0;

    if (this.itemsList[idx].DiscountPercent > this.maxDiscuont) {
      this.alertService.infoInfoAlert('El descuento no puede ser mayor a ' + this.maxDiscuont + '% que es lo permitido para este usuario');
      this.itemsList[idx].DiscountPercent = this.maxDiscuont;
    }

    const qty = this.itemsList[idx].Quantity;
    const price = this.itemsList[idx].UnitPrice;
    let lineTotal = Number((qty * price).toFixed(2));
    const taxamount = Number((lineTotal * (this.itemsList[idx].TaxRate / 100)).toFixed(2));
    lineTotal = Number((lineTotal + taxamount).toFixed(2));
    lineTotal = Number((lineTotal - (lineTotal * (this.itemsList[idx].DiscountPercent / 100))).toFixed(2));
    this.itemsList[idx].LinTot = lineTotal;
    this.getTotals();
  }

  printQuo() {
    this.printQuotation(this.lastQuotation);
  }

  // funcion para imprimir la proforma
  printQuotation(DocEntry: number) {
    this.blockUI.start('Generando la impresión...');
    this.reportsService.printReport(DocEntry, ReportType.Quotation)
      .subscribe((data: any) => {
        this.blockUI.stop();
        printJS({
          printable: data,
          type: 'pdf',
          base64: true
        });
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  openModal(content) {
    this.modalReference = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  GetParamsViewList() {
    this.blockUI.start('Obteniendo parámetros...'); // Start blocking
    this.paramsService.getParasmView()
      .subscribe((data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.viewParamList = data.Params.filter(param => {
            return param.type === 1 && param.Visibility;
          });
          this.viewParamList.splice(7, 0, {
            CompanyId: 4,
            Descrip: "Precio unitario con impuesto",
            Display: true,
            Id: 144,
            Name: "UPWT",
            Order: 15,
            ParamsId: 11,
            Text: "Precio+Imp",
            ViewsId: 1,
            Visibility: true,
            type: 1
          });
          this.tableLength = 0;
          for (var i = 0; i < this.viewParamList.length; i++) {
            this.tableLength += this.MapWidth[this.viewParamList[i].Name];
          }
          this.viewParamListHeader = data.Params.filter(param => {
            return param.type === 2;
          });
          this.viewParamListTotales = data.Params.filter(param => {
            return param.type === 3;
          });
          this.viewParamTitles = data.Params.filter(param => {
            return param.type === 6;
          });
          this.ChargeParamstoView();
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorInfoAlert('Error al cargar los parámetros de la página - ' + data.errorInfo.Message);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });

  }

  // funcion para obtener la informacion de los disponibles de un item en los almacenes
  // recibe como parametros el item y el index
  GetWHAvailableItem(event, content, ItemCode, idx) {
    if (ItemCode !== '') {
      if (event.type === 'dblclick') {
        this.blockUI.start('Obteniendo Disponibilidad del Artículo, Espere Por Favor...'); // Start blocking
        this.itemService.GetWHAvailableItem(ItemCode)
          .subscribe((data: any) => {
            if (data.result) {
              this.itemCode = ItemCode;
              this.indexAvaItem = idx;
              this.WHAvailableItemList = data.whInfo;
              if (data.whInfo.length > 0) {
                // this.openModal(content);
                (<HTMLButtonElement>document.getElementById('triggerWhsPreview')).click();
              } else {
                this.alertService.infoInfoAlert('Este artículo no posee disponibles en ningún almacén.');
              }
            } else {
              this.alertService.errorAlert('Error al cargar disponibilidad el artículo - Error: ' + data.errorInfo.Message);
            }
            this.blockUI.stop(); // Stop blocking
          }, (error: any) => {
            this.blockUI.stop(); // Stop blocking
            this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
          });
      }
    }
  }

  // funcion para seleccionar un almacen nuevo para el item a facturar
  avaItemSelected(event, avaItem, idx: number) {
    if (event.type === 'dblclick') {
      this.itemsList[this.indexAvaItem].WarehouseCode = avaItem.WhsCode;
      this.itemsList[this.indexAvaItem].WhsName = avaItem.WhsName;
      this.itemsList[this.indexAvaItem].Serie = '';
      this.itemsList[this.indexAvaItem].SysNumber = 0;
      (<HTMLButtonElement>document.getElementById('triggerWhsClose')).click();
      //   this.itemsList[this.indexAvaItem].WhsName);
    }
    //  else if (event.type === 'click') {
    //   this.itemService.GetSeriesByItem(this.itemCode, avaItem.WhsCode)
    //     .subscribe((data: any) => {
    //       if (data.result) {
    //         this.seriesList = data.series;
    //         if (data.series.length > 0) {
    //           this.expandRow(idx);
    //         } else {
    //          // this.alertService.infoInfoAlert('El item no tiene series en el almacén selecionado');
    //         }
    //         this.blockUI.stop(); // Stop blocking
    //       }
    //       // else {
    //       //   this.alertService.errorAlert('Error al obtener series de los almacenes - Error: ' + data.errorInfo.Message);
    //       // }
    //      }, (error: any) => {
    //       this.blockUI.stop(); // Stop blocking
    //       this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    //     });
    // }
  }

  // funcion para cerrar la modal para escoger la terminal y sucursal
  close() {
    this.modalReference.close();
  }

  selectSerie(series, avaItem) {
    if (series.Disponible > 0) {
      this.itemsList[this.indexAvaItem].Serie = series.PlacaChasis;
      this.itemsList[this.indexAvaItem].SysNumber = series.SysNumber;
      this.itemsList[this.indexAvaItem].WarehouseCode = avaItem.WhsCode;
      this.itemsList[this.indexAvaItem].WhsName = avaItem.WhsName;
      this.itemsList[this.indexAvaItem].UnitPrice = series.Precio;
      // this.itemsList[this.indexAvaItem].Marca = series.PlacaChasis;
      this.LineTotalCalculate(this.indexAvaItem);
      this.alertService.infoInfoAlert(`Se seleccionó la serie: ${series.PlacaChasis}`);
    } else {
      this.alertService.infoInfoAlert('No puede seleccionar esta serie ya que no posee disponibles');
    }
  }

  // Carga los datos parametrizados en las variables
  ChargeParamstoView() {
    // parametrizaciones para dtos de cabezera
    this.viewParamListHeader.forEach(element => {
      if (element.Name === 'lbCardCode') { this.lbCardCode = element; }
      if (element.Name === 'txtCardCode') { this.txtCardCode = element; }
      if (element.Name === 'lbCardName') { this.lbCardName = element; }
      if (element.Name === 'txtCardName') { this.txtCardName = element; }
      if (element.Name === 'lbCurrency') { this.lbCurrency = element; }
      if (element.Name === 'txtCurrency') { this.txtCurrency = element; }
      if (element.Name === 'txtComments') { this.txtComments = element; }
      if (element.Name === 'lbComments') { this.lbComments = element; }
      if (element.Name === 'txtSLP') { this.txtSLP = element; }
      if (element.Name === 'lbSLP') { this.lbSLP = element; }
    });
    // parametrizaciones datos de totales
    this.viewParamListTotales.forEach(element => {
      if (element.Name === 'lbTotalExe') { this.lbTotalExe = element; }
      if (element.Name === 'txtTotalExe') { this.txtTotalExe = element; }
      if (element.Name === 'lbDiscount') { this.lbDiscount = element; }
      if (element.Name === 'txtDiscount') { this.txtDiscount = element; }
      if (element.Name === 'lbTaxes') { this.lbTaxes = element; }
      if (element.Name === 'txtTaxes') { this.txtTaxes = element; }
      if (element.Name === 'lbTotal') { this.lbTotal = element; }
      if (element.Name === 'txtTotal') { this.txtTotal = element; }
    });

    // parametrizacion del titulo
    let obj = this.viewParamTitles.filter(param => {
      return param.Name === 'T_quotation';
    });
    this.title = obj[0].Text;
  }

  // Obtiene los tipos de monedas ya sea Col o Usd desde una vista SQL
  GetCurrencyType() {
    this.blockUI.start('Obteniendo parametros...'); // Start blocking
    this.paramsService.GetCurrencyType()
      .subscribe((data: any) => {
        this.blockUI.stop();
        if (data.length > 0) {
          this.currencyList = data;
          this.allCurrencyList = data;
          this.DefaultConfigurationBusinessPartner();
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert('Error al cargar las monedas - ' + data.errorInfo.Message);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // Retorna el simbolo de la moneda al elegir en el dropdown la moneda
  SetCurr() {
    let cur = this.currencyList.find(curr => curr.Id === this.QouForm.controls.currency.value);

    if (this.storage.GetDocEntry() > 0) {
      cur = this.currencyList.find(curr => curr.Id === JSON.parse(this.storage.GetCustomerData()).Currency);
    }

    if (!cur) return;
    this.setCurr = cur.Symbol;
  }

  CleanFields(): void {
    this.QouForm.controls["cardName"].enable();
    this.QouForm.controls["cardCode"].enable();
    this.commonService.hasDocument.next(``);
    this.storage.SaveBreadCrum(``);
    this.storage.SaveDocEntry(-1);
    this.storage.SaveUIAction(-1);
    this.storage.SaveCustomerData("{}");

    this.OnLoad();
  }

  // vacia las listas y recarga los datos para SO
  CreateNew() {
    this.IndexMinDocument = 0;
    this.IndexMaxUpdate = 0;
    this.isDocumentSubmitted = false;
    this.lastQuotation = 0;
    this.DEFAULT_BUSINESS_PARTNER = this.storage.getBranchOffice().CashBusinessPartnerCode;
    this.isSalesManSelected = true;
    //if (this.requestViewerSubscription) this.requestViewerSubscription.unsubscribe();
    this.cardNameSearchTerm = '';
    this.storage.SaveDocEntry(-1);
    this.commonService.hasDocument.next(``);
    this.storage.SaveBreadCrum(``);
    this.storage.SaveCustomerData("{}");
    this.saleQuotation = null;
    this.btnVisibleBool = true;
    this.isCreatingQuotation = true;
    this.isCreatingQuotation = true;
    this.QouForm.reset(true);
    this.itemsList.length = 0;
    this.getTotals();
    this.btnVisibleBool = true;
    this.correctQuotation = false;
    this.conta = 0;
    this.total = 0;
    this.Cant.setValue(1);
    this.totalUSD = 0;
    this.DailyExRate = 0;
    this.tax = 0;
    this.discount = 0;
    this.totalWithoutTax = 0;
    this.QouForm.controls['cardCode'].enable();
    this.QouForm.controls['cardName'].enable();
    this.ItemInfo.setValue('');
    this.QouForm = this.fb.group({
      cardCode: [this.DEFAULT_BUSINESS_PARTNER, Validators.required],
      cardName: ['', Validators.required],
      currency: ['', Validators.required],
      SlpList: ['', Validators.required],
      Comment: '',
      Discount: [0]
    });
    this.getMaxDiscout();
    this.GetCustomers();
    this.GetParamsViewList();
    //this.GetCurrencyType();
    this.getExRate();
    this.GetSalesPersonList();
    this.getTaxes();
    this.getItems();
    this.DefaultConfigurationBusinessPartner();
    this.Cant.setValue(1);
    this.hasLines = false;
  }

  GetSalesPersonList() {
    this.blockUI.start('Obteniendo vendedores, Espere Por Favor...'); // Start blocking
    this.smService.getSalesMan()
      .subscribe((data: any) => {
        if (data.result) {
          this.SlpsList = data.salesManList;
          if (data.salesManList.length > 0) {
            this.slpTypeaheadList = [];
            data.salesManList.forEach(element => {
              this.slpTypeaheadList.push({
                SlpCode: element.SlpCode,
                SlpName: element.SlpName,
                TypeAheadFormatt: `${element.SlpCode} ${element.SlpName}`
              });
            });
          }
          const DOC_ENTRY = this.storage.GetDocEntry();

          if (DOC_ENTRY > 0) {
            const CUSTOMER_DATA = JSON.parse(this.storage.GetCustomerData());
            const SLP = this.slpTypeaheadList.find(x => x.SlpCode === CUSTOMER_DATA.SlpCode.toString());
            if (SLP) {
              this.QouForm.patchValue({ SlpList: SLP });
            }
          }
          else {
            const SLP = this.slpTypeaheadList.find(x => x.SlpCode === this.defaultSlpCode.toString());

            if (SLP) {
              this.QouForm.patchValue({ SlpList: SLP });
            }
          }
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
        }

      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para obtener las compañias de la DBLocal
  // no recibe parametros
  getCompanies() {
    this.blockUI.start('Obteniendo Compañías, Espere Por Favor...'); // Start blocking
    this.companyService.GetCompanies()
      .subscribe((data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.companiesList.length = 0;
          this.companiesList = data.companiesList;
          this.companiesList.forEach(comp => {
            this.pesoBolsa = comp.ScaleWeightToSubstract;
            this.priceEditable = comp.IsLinePriceEditable;
            this.maxWeightTo0 = comp.ScaleMaxWeightToTreatAsZero;
          });
        } else {
          this.alertService.errorAlert('Error al cargar compañías - Error: ' + data.errorInfo.Message);
        }
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  setWarehouseInfo() {
    let session = this.storage.getSession(navigator.onLine);
    if (session) {
      session = JSON.parse(session);

      this.whCode = session.WhCode;
      this.whName = session.WhName;
    }
  }

  closeAfterPayModal(): void {

  }

  redirectToSO() {
    this.router.navigate(['so']);
  }

  redirectToSOandSQ() {
    this.router.navigate(['SOandSQ']);
  }

  LineTotalCalculateExt(idx: number) {
    let disc = 0;

    if (this.maxDiscuont) this.maxDiscuont = 10; //#VALORPRUEBA

    if (this.itemsList[idx].DiscountPercent <= this.maxDiscuont) {
      disc = this.itemsList[idx].DiscountPercent;
    }
    else {
      disc = this.maxDiscuont;
      this.alertService.infoInfoAlert('El descuento no puede ser mayor a ' + this.maxDiscuont + ' que es lo permitido para este usuario');
      this.itemsList[idx].DiscountPercent = this.maxDiscuont;
    }

    if (!this.itemsList[idx].DiscountPercent) { this.itemsList[idx].DiscountPercent = 0; }

    const qty = this.itemsList[idx].Quantity;
    const price = this.itemsList[idx].UnitPrice;
    let lineTotal = Number((qty * price).toFixed(2));
    const taxamount = Number((lineTotal * (this.itemsList[idx].TaxRate / 100)).toFixed(2));
    lineTotal = Number((lineTotal + taxamount).toFixed(2));
    lineTotal = Number((lineTotal - (lineTotal * (disc / 100))).toFixed(2));
    this.itemsList[idx].LinTot = lineTotal;
    this.getTotals();
    //this.isEditingPrice = false;
  }

  changeSlp($event: any): void {
    this.isSalesManSelected = true;
    let SLP = $event.item as ISaleMan;
    this.QouForm.patchValue({ SlpList: SLP });
  }

  overrideDiscount(): void {
    const DISCOUNT = this.QouForm.value.Discount;

    if (DISCOUNT > 10) {
      this.QouForm.patchValue({
        Discount: 0
      });
      this.alertService.infoInfoAlert(`El descuento no puede ser mayor a ${this.maxDiscuont}% que es lo permitido para este usuario`);
      return;
    }

    const SIZE = this.itemsList.length;

    for (let c = 0; c < SIZE; c++) {
      if (this.itemsList[c].LinTot != 0) {
        this.itemsList[c].DiscountPercent = DISCOUNT;
      }
      this.LineTotalCalculate(c);
    }
  }

  SavePreviusAmount(_previusAmount: number, _index: number): void {
    if (!this.isPriceEditable || this.itemsList[_index].InvntItem === 'Y') {
      this.alertService.infoInfoAlert(`No se puede editar el precio del item selecccionado`);
    }
    this.previusAmount = _previusAmount;
  }

  KeyUp(event: any, _index: number) {
    if (event.keyCode === 27) {
      this.itemsList[_index].UnitPrice = this.previusAmount;
      console.log(event.keyCode);
      this.LineTotalCalculate(_index);
      this.getTotals();
    }
  }


  /**
   * Method to map udfs values
   * @constructor
   */
  SetValueUdfs(): void {

    this.udfs = [];

    this.udfs.push({
      Name: 'U_Almacen',
      FieldType: 'String',
      Value: this.storage.getBranchOffice().Code
    },
    {
      Name: 'U_CLVS_POS_UniqueInvId',
      FieldType: 'String',
      Value:this.uniqueId
    }
    )
  }
}
