import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants, Company, User, IPrinter, IPrinterPerReport } from './../models/index';
import { BranchOfficeModel2 } from '../models/branch-office';
import {IKeysReportManager} from "../models/rpt-manager/i-key-report-manager";
import {LocalStorageVariables} from "../models/constantes";

@Injectable({
  providedIn: 'root'
})
export class
  StorageService {
  AuthenticationService

  private localStorageService;

  constructor(private router: Router) {
    this.localStorageService = localStorage;
    //this.setCurrentSessionOffline('1');
    //this.setCurrentSession('1');
  }

  // coloca los datos de la sesion actual, token y expiracion, usuario logueado
  setCurrentSession(currentUser): void {
    this.localStorageService.setItem('currentUser', JSON.stringify(currentUser));
  }
  // coloca los datos de la sesion actual, token y expiracion, usuario logueado
  setTokenPadron(padronToken): void {
    this.localStorageService.setItem('padronInfo', JSON.stringify(padronToken));
  }
  // coloca los datos de la sesion actual, token y expiracion, usuario logueado
  setStatusOffline(currentUser): void {
    this.localStorageService.setItem('status', JSON.stringify(currentUser));
  }
  // coloca los datos de la sesion actual, token y expiracion, usuario logueado
  setStatusOnline(currentUser): void {
    this.localStorageService.setItem('status', JSON.stringify(currentUser));
  }
  // coloca los datos de la sesion actual, token y expiracion, usuario logueado
  setCurrentSessionOffline(currentUser): void {
    this.localStorageService.setItem('currentUserOff', JSON.stringify(currentUser));
  }
  // Actualiza la moneda que usa la compania
  // setCompanyCurrency(_currency: string, _lock: boolean = true): boolean {

  //   if (_lock && _currency !== 'COL' && _currency !== 'USD') return false;

  //   this.localStorageService.setItem('COMPANY_CURRENCY', _currency);

  //   return true;
  // }
  // // Obtiene el tipo de moneda de la compania
  // getCompanyCurrency(): string {
  //   return this.localStorageService.getItem('COMPANY_CURRENCY');
  // }
  setOnline(): string {
    let a: string = `${AppConstants.sOnline}api/`;
    this.localStorageService.setItem('conexion', 'online');
    return a;
  }

  setOffline(): string {
    let a: string = `${AppConstants.sOffline}api/`;
    this.localStorageService.setItem('conexion', 'offline');
    return a;
  }

  estaOnline(): boolean {
    if (this.localStorageService.getItem('conexion') === 'online') return true;
    return false;
  }
  // remueve la sesion actual
  removeCurrentSession(): void {
    this.localStorageService.removeItem('currentUser');
    this.localStorageService.removeItem('currentUserOff');
    this.localStorageService.removeItem('padronInfo');
    this.localStorageService.removeItem('purchaseOrderDocNum');
    this.localStorageService.removeItem('HasOfflineMode');
    this.localStorageService.removeItem('conexion');
    this.localStorageService.removeItem('Decimal');
    this.localStorageService.removeItem('padronInfo');
    this.localStorageService.removeItem('DecimalsConfiguration');
    this.localStorageService.removeItem('reToken');
    this.localStorageService.removeItem('COMPANY_CURRENCY');
    this.localStorageService.removeItem('breadCrumDetail');
    this.localStorageService.removeItem('DocEntry');
    this.localStorageService.removeItem('CustomerData');
    this.localStorageService.removeItem('padronInfo');
    localStorage.removeItem('branchOffice');
  }
  // obtiene el token de la sesion actual
  getCurrentSession(): string {
    if (navigator.onLine) {
      if (this.localStorageService.getItem('currentUser') === null) {
        // this.logout();
      }
      return this.localStorageService.getItem('currentUser');
    }
    else {
      if (!this.getHasOfflineMode()) {
        return '{"access_token": "empty_token"}';
      }
      return this.localStorageService.getItem('currentUserOff');
    }
  }

  setReToken(_reToken: boolean): void {
    this.localStorageService.setItem('reToken', _reToken);
  }

  getRetoken(): boolean {
    return this.localStorageService.getItem('reToken');
  }

  getSession(isOnline: boolean) {
    return this.localStorageService.getItem(isOnline ? 'currentUser' : 'currentUserOff');
  }

  GetThirdPartySession(): object {
    return JSON.parse(this.localStorageService.getItem('padronInfo'));
  }
  // obtiene el token de la sesion actual
  getCurrentSessionOffline(): string {
    let token = this.localStorageService.getItem('currentUserOff');
    if (!token) token = '{"access_token": "empty_token"}';
    return token;
  }
  // obtiene el token de la sesion actual
  getTokenPadron(): string {
    return this.localStorageService.getItem('padronInfo');
  }
  // deslogueo
  logout(): void {
    this.removeCurrentSession();
    this.router.navigate(['/login']);
  }
  // Establece la cantidad de decimales que usara la app para el redondeo
  setCompanyConfiguration(_decimalAmountPrice: number, _decimalAmountTotalLine: number, _decimalAmountTotalDocument: number, _printerConfiguracion: IPrinter, _hasZeroBilling: boolean, _refreshDelay: number): void {
    const company = {} as Company;

    company.DecimalAmountPrice = _decimalAmountPrice;
    company.DecimalAmountTotalLine = _decimalAmountTotalLine;
    company.DecimalAmountTotalDocument = _decimalAmountTotalDocument;
    company.RefreshDelay = _refreshDelay
    if (_printerConfiguracion !== null) {
      company.PrinterConfiguration = JSON.stringify(_printerConfiguracion);
    }
    else {
      company.PrinterConfiguration = this.getCompanyConfiguration().PrinterConfiguration;
    }
    company.HasZeroBilling = _hasZeroBilling;

    this.localStorageService.setItem('CompanyConfiguration', JSON.stringify(company));
  }
  // Obiene la cantidad de decimales que usara la app para el redondeo
  getCompanyConfiguration(): Company {
    const company = {} as Company;

    const PARSED_JSON = JSON.parse(this.localStorageService.getItem('CompanyConfiguration'));
    if (PARSED_JSON) {
      company.DecimalAmountPrice = PARSED_JSON.DecimalAmountPrice && 0;
      company.DecimalAmountTotalLine = PARSED_JSON.DecimalAmountTotalLine;
      company.DecimalAmountTotalDocument = PARSED_JSON.DecimalAmountTotalDocument;
      company.PrinterConfiguration = PARSED_JSON.PrinterConfiguration;
      company.HasZeroBilling = PARSED_JSON.HasZeroBilling;
      company.RefreshDelay = PARSED_JSON.RefreshDelay;
    }
    else {
      company.DecimalAmountPrice = 2;
      company.DecimalAmountTotalLine = 2;
      company.DecimalAmountTotalDocument = 2;
      company.PrinterConfiguration = '';
      company.HasZeroBilling = false;
      company.RefreshDelay = 5;
    }

    return company;
  }
  // Establece la cantidad de decimales que usara la app para el redondeo
  setHasOfflineMode(_hasOfflineMode: boolean): void {
    this.localStorageService.setItem('HasOfflineMode', _hasOfflineMode);
  }
  // Obtiene el uso del modo offline
  getHasOfflineMode(): boolean {
    return this.localStorageService.getItem('HasOfflineMode') === 'true';
  }

  setPurchaseOrder(_docNum: number): void {
    this.localStorageService.setItem('purchaseOrderDocNum', _docNum.toString());
  }

  getPurchaseOrder(): number {
    return +this.localStorageService.getItem('purchaseOrderDocNum');
  }

  setLog(data: string): void {
    this.localStorageService.setItem('log value: ', data);
  }

  SaveDocEntry(_docNum: number): void {
    this.localStorageService.setItem('DocEntry', _docNum.toString());
  }

  GetDocEntry(): number {
    return +this.localStorageService.getItem('DocEntry');
  }

  SaveCustomerData(_customerData: string): void {
    this.localStorageService.setItem('CustomerData', _customerData);
  }

  GetCustomerData(): string {
    return this.localStorageService.getItem('CustomerData');
  }

  SaveUIAction(_action: number) {
    this.localStorageService.setItem('SOAndSQAction', _action);
  }

  GetUIAction(): number {
    return +this.localStorageService.getItem('SOAndSQAction');
  }

  addUserCredentials(_email: string, _password: string): void {
    let usersCredentials = JSON.parse(this.localStorageService.getItem('usersCredentials')) as User[];
    let notFound = false;
    if (usersCredentials !== null) {
      usersCredentials.forEach(x => {
        if (x.Email === _email) notFound = true;
      });

      if (!notFound) {
        usersCredentials.push({ Email: _email, Password: _password } as User);
      }
    }
    else {
      usersCredentials = [];
      usersCredentials.push({ Email: _email, Password: _password } as User);
    }
    this.localStorageService.setItem('usersCredentials', JSON.stringify(usersCredentials));
  }

  getUserCredentials(): User[] {
    const usersCredentials = JSON.parse(this.localStorageService.getItem('usersCredentials')) as User[];
    if (usersCredentials !== null) return usersCredentials;
    return [];
  }

  SaveBreadCrum(_detail: string): void {
    this.localStorageService.setItem('breadCrumDetail', _detail);
  }

  GetBreadCrum(): string {
    return this.localStorageService.getItem('breadCrumDetail');
  }

  SavePrinters(_printersPerReport: IPrinterPerReport[]): void {
    const STRINGED_PRINTERS = JSON.stringify(_printersPerReport);
    this.localStorageService.setItem('PRINTERS_CONF', STRINGED_PRINTERS);
  }

  GetPrinterConfiguration(_index: number): IPrinter {
    // IPrinterPerReport[]
    const PRINTERS = JSON.parse(localStorage.getItem('PRINTERS_CONF')) as IPrinterPerReport[];
    if (PRINTERS) return PRINTERS[_index].Printer;
    return null;
  }

  //#region Branch office
  setBranchOffice(branchOffice: BranchOfficeModel2) {
    localStorage.setItem('branchOffice', JSON.stringify(branchOffice));
  }

  getBranchOffice() {
    let branchOffice = localStorage.getItem('branchOffice');

    if (branchOffice) return JSON.parse(branchOffice);

    return {};
  }

  GetWareHouseName(): string {
    try {
      const SESSION = JSON.parse(localStorage.getItem('currentUser'));

      let wareHouseName = ``;

      if (SESSION && SESSION.WhName) {
        wareHouseName = SESSION.WhName;
      }

      return wareHouseName;
    }
    catch {
      return null;
    }
  }
  //#endregion

  //#region Report Manager
  GetKeysReportManager(): IKeysReportManager {
    try {
      const SESSION = JSON.parse(localStorage.getItem('currentUser'));
      if (!SESSION) {
        throw new Error('No session found');
      }

      const keyReportManager: IKeysReportManager = {
        CompanyKey: SESSION.CompanyKey ? SESSION.CompanyKey : 0,
        AppKey:  SESSION.AppKey ? SESSION.AppKey : 0,
      };

      return keyReportManager;
    }
    catch (error) {
      console.error('Error in GetKeysReportManager:', error);
      return null;
    }
  }
  //#endregion



  /**
   * Set a variable in the local storage
   * @constructor
   */
  SetLocalStorageVariable(pKey: LocalStorageVariables, pValue: any): void
  {
    if(!pKey)
    {
      console.error("The local storage key cannot be empty or null.");

      return;
    }

    localStorage.setItem(pKey, JSON.stringify(pValue));
  }

  /**
   * Get the value of a local storage variable
   * @param pKey Key of the local storage variable value to retrieve
   * @constructor
   */
  GetLocalStorageVariable<T>(pKey: LocalStorageVariables): T | null
  {
    if(!pKey)
    {
      console.error("The local storage key cannot be empty or null.");

      return null;
    }

    const value = localStorage.getItem(pKey);

    if(!value)
    {
      console.warn(`Local storage variable '${pKey}' not have a value.`);
    }

    return value ? JSON.parse(value) as T : null;
  }

  /**
   * Remove a variable from local storage
   * @param pKey Name of the variable to remove
   * @constructor
   */
  RemoveLocalStorageVariable(pKey: LocalStorageVariables): void
  {
    localStorage.removeItem(pKey);
  }
}
