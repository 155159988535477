import { Component, OnInit, HostListener } from "@angular/core";
import { DatePipe, formatDate } from "@angular/common";
import { SOAndSQService } from "src/app/services/soand-sq.service";
import {
  ISaleOrder,
  ISaleQuotation,
  ISaleOrderSearch,
  IPrinter,
} from "src/app/models";
import { NgBlockUI, BlockUI } from "ng-block-ui";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import {Subscription, Observable, forkJoin, of} from "rxjs";
import {debounceTime, map, distinctUntilChanged, switchMap, finalize, catchError} from "rxjs/operators";
import {
  SalesManService,
  AlertService,
  BusinessPartnerService,
  StoreService,
  CommonService,
  ReportsService,
  StorageService,
  DocumentService,
  PermsService,
} from "src/app/services";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbPaginationConfig } from "@ng-bootstrap/ng-bootstrap";
import {
  DocumentType,
  SOAndSQActions,
  ReportType,
  KEY_CODE,
  PRINTERS_PER_REPORT,
} from "src/app/enum/enum";
import swal from "sweetalert2";
import {IAutorizeOrRejectsOrder} from "../../../models/i-sl-document-model";

const printJS = require("print-js");

@Component({
  selector: "app-soand-sq",
  templateUrl: "./soand-sq.component.html",
  styleUrls: ["./soand-sq.component.scss"],
  providers: [DatePipe],
})
export class SOandSQComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  saleOrders: ISaleOrder[];
  saleOrder: ISaleOrder;
  saleQuotations: ISaleQuotation[];
  saleQuotation: ISaleQuotation;
  searchForm: FormGroup;
  previewForm: FormGroup;
  nowDate: any;
  bpCodeNameList: string[] = []; // lista de los codigo con nombres de clientes
  bpList: string[];
  StatusFact: String;
  Formadate: string;

  viewParamTitles: any[] = []; // llena la lista con los titulos de las paginas parametrizados
  title: string; // titulo de la vista
  currentUser: any; // variable para almacenar el usuario actual
  SlpsList: string[]; //= []; // lista de los vendedores
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  permisos: boolean = true;
  status: boolean[] = [true, false];
  invoiceSelected = false; //Existe factura seleccionada
  page = 1;
  pageSize = 10;
  collectionSize: number;
  model: any;
  isPermAutorizeSale: boolean; //Permiso usuarios autorizar ventas a clientes con documentos vencidos
  EstadoOV: boolean; //Permiso usuarios autorizar ventas a clientes con documentos vencidos
  data: any;

  autForm: FormGroup;

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (
      event.ctrlKey &&
      !event.altKey &&
      !event.shiftKey &&
      event.keyCode === KEY_CODE.F6
    ) {
      this.redirectToQuotation();
    }
    if (
      event.ctrlKey &&
      !event.altKey &&
      !event.shiftKey &&
      event.keyCode === KEY_CODE.F7
    ) {
      this.redirectToSO();
    }
  }

  constructor(
    private documentService: DocumentService,
    private storageService: StorageService,
    private reportsService: ReportsService,
    private commonService: CommonService,
    private soAndSqService: SOAndSQService,
    private smService: SalesManService,
    private alertService: AlertService,
    private bpService: BusinessPartnerService,
    private router: Router,
    private storeService: StoreService,
    private config: NgbPaginationConfig,
    private sPerm: PermsService,
    private datePipe: DatePipe
  ) {
    this.config.size = "sm";
  }

  ngOnInit() {
    this.OnLoad();
  }

  OnLoad(): void {
    this.InitVariables();
    this.InitDocument();
  }
  InitDocument():void{
    let user: any = this.storageService.getCurrentSession();
    if (!user) return;
    user = JSON.parse(user);

    //#region Obtener documentos
    let SEARCH = this.searchForm.value as ISaleOrderSearch;

    SEARCH.ViewType = "SO";

    if (!this.isNumber(SEARCH.docNum)) {
      SEARCH.docNum = 0;
    }
    SEARCH.docNum = this.searchForm.value.docNum;
    SEARCH.docStatus = this.searchForm.value.docStatus;

    SEARCH.U_EstadoOV = this.searchForm.value.U_EstadoOV;

    if (SEARCH.slpCode !== 0) {
      const CODE = this.searchForm.value.slpCode + "";
      SEARCH.slpCode = +CODE.split(" ")[0];
    }

    if (SEARCH.cardCode !== "") {
      SEARCH.cardCode = SEARCH.cardCode.split(" ")[0];
    }
    SEARCH.docNum = +SEARCH.docNum;
    this.saleOrders = [];
    this.saleQuotations = [];
    this.searchForm.patchValue({ docNum: SEARCH.docNum });

    //#endregion


    this.blockUI.start("Procesando, espere por favor...");
    let messageError = '';


    const observables: { [key: string]: Observable<any> } = {
      Perms: this.sPerm.getPerms(user.userId),
      SalesMan: this.smService.getSalesMan(),
      Customer: this.bpService.GetCustomers(),
      SaleOrder: SEARCH.doctype === "SO" ? this.soAndSqService.GetSaleOrders(SEARCH) : of(null)
    };

    const observablesArray: Observable<any>[] = Object.values(observables);

    forkJoin(observablesArray).pipe(
      finalize(() => {
        this.blockUI.stop();
        if(messageError.length > 0){
          this.alertService.errorAlert(
            `${messageError}`
          );
        }}),
      catchError(err => {
        this.alertService.errorInfoAlert(
          `Error: ${err}`
        );
        return of(null);
      })
    ).subscribe({
      next: ([PermsResponse,SalesManResponse,CustomerResponse, OrderResponse]) => {

        if (PermsResponse.result) {
          this.isPermAutorizeSale = PermsResponse.perms.find(
            (x) => x.Name === "Perm_Authorize_Sale"
          ).Active;
        }

        if (SalesManResponse.result) {
          this.SlpsList = [];
          SalesManResponse.salesManList.forEach((x) => {
            this.SlpsList.push(x.SlpCode + " - " + x.SlpName);
          });
        } else {
          messageError += `Error: Código: ${SalesManResponse.errorInfo.Code}, Mensaje: ${SalesManResponse.errorInfo.Message}`;
        }

        if (CustomerResponse && CustomerResponse.Code === undefined && CustomerResponse.BPS != null) {
          this.bpList = [];
          CustomerResponse.BPS.forEach((x) => {
            this.bpList.push(x.CardCode + " " + x.CardName);
          });
        } else {
          messageError += `Error: Código: ${CustomerResponse.errorInfo.Code}, Mensaje: ${CustomerResponse.errorInfo.Message}`;
        }

        if (OrderResponse && OrderResponse.result) {

          if (OrderResponse.SaleOrders.length === 0) {
            this.alertService.infoInfoAlert("No se encontraron documentos dentro de los parámetros de búsqueda.");
          }

          this.collectionSize = OrderResponse.SaleOrders.length;
          this.saleOrders = OrderResponse.SaleOrders.map((so, i) => ({
            id: i + 1,
            ...so,
          })).slice(
            (this.page - 1) * this.pageSize,
            (this.page - 1) * this.pageSize + this.pageSize
          );
        } else {
          messageError += `Error: Código: ${OrderResponse.errorInfo.Code}, Mensaje: ${OrderResponse.errorInfo.Message}`;
        }

      },
      error: err => {
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${err}`
        );
      }
    });


  }
  searchSLP = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.SlpsList.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
            ).slice(0, 10)
      )
    );

  searchBP = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.bpList
              .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
              .slice(0, 10)
      )
    );

  search(): void {
    let SEARCH = this.searchForm.value as ISaleOrderSearch;

    SEARCH.ViewType = "SO";

    if (!this.isNumber(SEARCH.docNum)) {
      SEARCH.docNum = 0;
    }
    SEARCH.docNum = this.searchForm.value.docNum;
    SEARCH.docStatus = this.searchForm.value.docStatus;

    SEARCH.U_EstadoOV = this.searchForm.value.U_EstadoOV;

    if (SEARCH.slpCode !== 0) {
      const CODE = this.searchForm.value.slpCode + "";
      SEARCH.slpCode = +CODE.split(" ")[0];
    }

    if (SEARCH.cardCode !== "") {
      SEARCH.cardCode = SEARCH.cardCode.split(" ")[0];
    }
    // console.log(SEARCH);
    SEARCH.docNum = +SEARCH.docNum;
    this.saleOrders = [];
    this.saleQuotations = [];
    this.searchForm.patchValue({ docNum: SEARCH.docNum });
    this.blockUI.start(`Obteniendo documentos`);
    if (SEARCH.doctype === "SO") {
      this.soAndSqService.GetSaleOrders(SEARCH).subscribe(
        (next) => {
          this.blockUI.stop();
          if (next.result) {

            if (next.SaleOrders.length === 0) {
              this.alertService.infoInfoAlert("No se encontraron documentos dentro de los parámetros de búsqueda.");
            }

            this.collectionSize = next.SaleOrders.length;
            this.saleOrders = next.SaleOrders.map((so, i) => ({
              id: i + 1,
              ...so,
            })).slice(
              (this.page - 1) * this.pageSize,
              (this.page - 1) * this.pageSize + this.pageSize
            );
          } else {
            this.alertService.errorAlert(
              `No se pudieron obtener las órdenes de venta, detalle: ${next.errorInfo.Message}`
            );
          }
        },
        (error) => {
          this.blockUI.stop();
          console.log(error);
        },
        () => {
          this.blockUI.stop();
        }
      );
    } else {
      this.soAndSqService.GetSaleQuotations(SEARCH).subscribe(
        (next) => {
          this.blockUI.stop();
          if (next.result) {
            if (next.Quotations.length === 0) {
              this.alertService.infoInfoAlert("No se encontraron documentos dentro de los parámetros de búsqueda.");
            }

            this.collectionSize = next.Quotations.length;
            this.saleQuotations = next.Quotations.map((sq, i) => ({
              id: i + 1,
              ...sq,
            })).slice(
              (this.page - 1) * this.pageSize,
              (this.page - 1) * this.pageSize + this.pageSize
            );
          } else {
            this.alertService.errorAlert(
              `No se pudieron obtener las proformas ${next.errorInfo.Message}`
            );
          }
        },
        (error) => {
          this.blockUI.stop();
          console.log(error);
          this.alertService.errorAlert(
            `No se pudieron obtener las proformas, detalle: ${error}`
          );
        },
        () => {
          this.blockUI.stop();
        }
      );
    }
  }

  edit(
    _docNum: number,
    _documentType: number,
    _currency: string,
    _cardCode: string,
    _slpCode: number
  ): void {
    let docNum = -1;
    if (_documentType === 0) {
      this.saleOrders.forEach((x) => {
        if (x.DocEntry === _docNum) docNum = x.DocNum;
      });
    } else {
      this.saleQuotations.forEach((x) => {
        if (x.DocEntry === _docNum) docNum = x.DocNum;
      });
    }
    this.saleOrder = null;
    this.saleOrders = [];
    this.saleQuotation = null;
    this.saleQuotations = [];
    this.SlpsList = [];
    this.bpList = [];
    let CustomerData: string = JSON.stringify({
      CardCode: _cardCode,
      Currency: _currency,
      SlpCode: _slpCode,
    });

    this.commonService.hasDocument.next(`Documento número ${docNum}`);
    this.storeService.SaveBreadCrum(`Documento número ${docNum}`);
    this.storeService.SaveCustomerData(CustomerData);
    if (_documentType === 0) {
      // SALE ORDER
      this.storeService.saveDocEntry(_docNum);
      this.storeService.SaveUIAction(SOAndSQActions.EditOrder);
      this.router.navigate(["/", "so"]);
    } else {
      this.storeService.saveDocEntry(_docNum);
      this.storeService.SaveUIAction(SOAndSQActions.EditQuotation);
      this.router.navigate(["/", "quotation"]);
    }
  }

  preview(_docNum: number, _documentType: number): void {
    this.blockUI.start("Obteniendo documento, espere por favor");
    if (_documentType === 0) {
      // SALE ORDER
      this.soAndSqService.GetSaleOrder(_docNum).subscribe(
        (next) => {
          this.blockUI.stop();
          if (next.result) {
            this.saleOrder = next.Data;
            this.saleOrder.DocDate = formatDate(
              this.saleOrder.DocDate,
              "yyyy-MM-dd",
              "en"
            );
            this.previewForm.patchValue({ ...this.saleOrder });
            (<HTMLButtonElement>(
              document.getElementById("previewTrigger")
            )).click();
          } else {
            console.log(next);
            this.alertService.errorAlert(
              `No se pudo obtener el documento ${next.errorInfo.Message}`
            );
          }
        },
        (error) => {
          this.blockUI.stop();
          console.log(error);
          this.alertService.errorAlert(
            `No se pudo obtener el documento ${error}`
          );
        },
        () => {
          this.blockUI.stop();
        }
      );
    } else {
      //SALE QUOTATION
      this.soAndSqService.GetSaleQuotation(_docNum).subscribe(
        (next) => {
          this.blockUI.stop();
          if (next.result) {
            this.saleQuotation = next.Data;
            this.saleQuotation.DocDate = formatDate(
              this.saleQuotation.DocDate,
              "yyyy-MM-dd",
              "en"
            );
            this.previewForm.patchValue({ ...this.saleQuotation });
            (<HTMLButtonElement>(
              document.getElementById("previewTrigger")
            )).click();
          } else {
            console.log(next);
            this.alertService.errorAlert(
              `No se pudo obtener el documento ${next.errorInfo.Message}`
            );
          }
        },
        (error) => {
          this.blockUI.stop();
          console.log(error);
          this.alertService.errorAlert(
            `No se pudo obtener el documento ${error}`
          );
        },
        () => {
          this.blockUI.stop();
        }
      );
    }
  }

  copyTo(
    _docNum: number,
    _currency: string,
    _cardCode: string,
    _slpCode: number
  ): void {
    localStorage.setItem("DocEntry", _docNum.toString());
    let docNum = -1;
    let CustomerData: string = JSON.stringify({
      CardCode: _cardCode,
      Currency: _currency,
      SlpCode: _slpCode,
    });
    this.storeService.SaveCustomerData(CustomerData);
    if (this.searchForm.get("doctype").value === "SO") {
      this.saleOrders.forEach((x) => {
        if (x.DocEntry === _docNum) docNum = x.DocNum;
      });
      this.commonService.hasDocument.next(`Basado en orden número ${docNum}`);
      this.storeService.SaveBreadCrum(`Basado en orden número ${docNum}`);
      localStorage.setItem(
        "SOAndSQAction",
        SOAndSQActions.CopyToInvoice.toString()
      );
    } else {
      this.saleQuotations.forEach((x) => {
        if (x.DocEntry === _docNum) docNum = x.DocNum;
      });
      this.commonService.hasDocument.next(
        `Basado en proforma número ${docNum}`
      );
      this.storeService.SaveBreadCrum(`Basado en proforma número ${docNum}`);
      localStorage.setItem(
        "SOAndSQAction",
        SOAndSQActions.CopyToOrder.toString()
      );
      this.router.navigateByUrl("so");
    }
  }

  getPurchaseorderList(): void {}

  changeDocumentType(_documentType: string): void {
    this.EstadoOV = this.searchForm.value.U_EstadoOV === "02" ? true : false;
    this.searchForm.patchValue({ doctype: _documentType });
    this.saleOrders = [];
    this.saleQuotations = [];
    this.page = 1;
    this.pageSize = 10;

    this.search();
  }

  GetSalesPersonList(): void {
    this.blockUI.start("Obteniendo vendedores, Espere Por Favor...");
    this.smService.getSalesMan().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.SlpsList = [];
          data.salesManList.forEach((x) => {
            this.SlpsList.push(x.SlpCode + " - " + x.SlpName);
          });
        } else {
          this.alertService.errorAlert(
            `Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
          );
        }
      },
      (error: any) => {
        this.blockUI.stop();
        console.log(error);
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      },
      () => {
        this.blockUI.stop();
      }
    );
  }

  getCustomers(): void {
    this.blockUI.start("Obteniendo Clientes, Espere Por Favor...");
    this.bpService.GetCustomers().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data && data.Code === undefined && data.BPS != null) {
          this.bpList = [];
          data.BPS.forEach((x) => {
            this.bpList.push(x.CardCode + " " + x.CardName);
          });
        } else {
          console.log(data.errorInfo);
          this.alertService.errorAlert(
            `Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
          );
        }
      },
      (error: any) => {
        this.blockUI.stop();
        console.log(error);
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      },
      () => {
        this.blockUI.stop();
      }
    );
  }

  /**
   * Load inicial of component
   * @constructor
   */
  InitVariables(): void {
    const mDate = formatDate(new Date(), "yyyy-MM-dd", "en");
    this.saleOrders = [];
    this.saleQuotations = [];
    this.saleQuotation = null;
    this.saleOrder = null;

    this.autForm = new FormGroup({
      ObservacionOV: new FormControl('', [Validators.required]),
      DocEntryOV: new FormControl('', [Validators.required]),
      DocNumOV: new FormControl(''),
      UniqueId: new FormControl('')
    });

    this.searchForm = new FormGroup({
      slpCode: new FormControl(""),
      cardCode: new FormControl(""),
      fini: new FormControl("", [Validators.required]),
      ffin: new FormControl(mDate, [Validators.required]),
      doctype: new FormControl("SO", [Validators.required]),
      docStatus: new FormControl("O"),
      docNum: new FormControl(0),
      U_EstadoOV: new FormControl(""),
      Cedula: new FormControl(""),
    }); // La definicion del formularioFormGroup;
    this.searchForm.patchValue({ ffin: mDate });
    this.searchForm.patchValue({ fini: mDate });
    this.searchForm.patchValue({ U_EstadoOV: "01" });

    this.saleQuotations = [];
    this.resetPreviewForm();
    this.pageSize = 10;
  }
  CreateNew() {
    this.OnLoad();
  }

  resetPreviewForm(): void {
    this.previewForm = new FormGroup({
      CardName: new FormControl(""),
      DocDate: new FormControl(""),
      DocNum: new FormControl(""),
      SalesMan: new FormControl(""),
      DocTotal: new FormControl(""),
    }); // La definicion del formularioFormGroup;
  }

  isNumber(value: string | number): boolean {
    return value != null && value !== "" && !isNaN(Number(value.toString()));
  }

  printQuotation(_docEntry: number) {
    this.blockUI.start("Generando la impresión...");
    this.reportsService.printReport(_docEntry, ReportType.Quotation).subscribe(
      (data: any) => {
        this.blockUI.stop();
        printJS({
          printable: data,
          type: "pdf",
          base64: true,
        });
      },
      (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  redirectToSO() {
    this.router.navigate(["so"]);
  }

  redirectToQuotation() {
    this.router.navigate(["quotation"]);
  }

  /**
   * Method to cancel sale order
   * @param _docEntry -Docnum document
   * @param _docNum
   */
  async cancelOrder(_docEntry: number, _docNum: number): Promise<void> {
    let confirmationResult = await this.alertService.ConfirmationAlert(
      "Confirmación",
      `Va a cancelar la orden de venta número ${_docNum} ¿Desea continuar?`,
      "Continuar"
    );
    if (!confirmationResult) return;

    this.blockUI.start(`Cancelando orden de venta, espere por favor`);
    this.documentService.SLCancelSaleOrder(_docEntry).subscribe(
      (next) => {
        this.blockUI.stop();
          this.alertService.successInfoAlert(
            `Orden de venta ${_docNum} cancelada correctamente`
          );
          this.search();

      },
      (error) => {
        this.blockUI.stop();
        console.log(error);
        let err = error && error.Code ? `Error al cancelar la orden, Código: ${error.Code}, Mensaje: ${error.Message}`: '';
        this.alertService.errorAlert(err ? err :`Error al intentar conectar con el servidor, Error: ${error}`);
      }
    );
  }

  async CancelQuotation(_docEntry: number, _docNum: number): Promise<void> {
    let confirmationResult = await this.alertService.ConfirmationAlert(
      "Confirmación",
      `Va a cancelar la proforma de venta número ${_docNum} ¿Desea continuar?`,
      "Continuar"
    );
    if (!confirmationResult) return;

    this.blockUI.start(`Cancelando proforma de venta, espere por favor`);
    this.documentService.CancelSaleQuotation(_docEntry).subscribe(
      (next) => {
        this.blockUI.stop();
          this.alertService.successInfoAlert(
            `Proforma de venta ${_docNum} cancelada correctamente`
          );
          this.search();
      },
      (error) => {
        this.blockUI.stop();
        console.log(error);
        let err = error && error.Code ? `Error al cancelar la proforma, Código: ${error.Code}, Mensaje: ${error.Message}`: '';
        this.alertService.errorAlert(err ? err :`Error al intentar conectar con el servidor, Error: ${error}`);
      }
    );
  }

  DuplicateDocument(
    _docNum: number,
    _documentType: number,
    _currency: string,
    _cardCode: string,
    _slpCode: number
  ): void {
    let docNum = -1;
    if (_documentType === 0) {
      this.saleOrders.forEach((x) => {
        if (x.DocEntry === _docNum) docNum = x.DocNum;
      });
    } else {
      this.saleQuotations.forEach((x) => {
        if (x.DocEntry === _docNum) docNum = x.DocNum;
      });
    }
    this.saleOrder = null;
    this.saleOrders = [];
    this.saleQuotation = null;
    this.saleQuotations = [];
    this.SlpsList = [];
    this.bpList = [];
    let CustomerData: string = JSON.stringify({
      CardCode: _cardCode,
      Currency: _currency,
      SlpCode: _slpCode,
    });

    this.commonService.hasDocument.next(`Documento número ${docNum}`);
    this.storeService.SaveBreadCrum(`Documento número ${docNum}`);
    this.storeService.SaveCustomerData(CustomerData);
    if (_documentType === 0) {
      // SALE ORDER
      this.storeService.saveDocEntry(_docNum);
      this.storeService.SaveUIAction(SOAndSQActions.DuplicateSaleOrder);
      this.router.navigate(["/", "so"]);
    } else {
      console.error(`Implementation pending`);
      // this.storeService.saveDocEntry(_docNum);
      // this.storeService.SaveUIAction(SOAndSQActions.EditQuotation);
      // this.router.navigate(['/', 'quotation']);
    }
  }

  checkPermits() {
    let user: any = this.storageService.getCurrentSession();
    if (!user) return;
    user = JSON.parse(user);

    this.sPerm.getPerms(user.userId).subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.isPermAutorizeSale = data.perms.find(
            (x) => x.Name === "Perm_Authorize_Sale"
          ).Active;
          // if (!this.isPermAutorizeSale) {
          //   this.searchForm.controls['U_EstadoOV'].disable();
          // }
        }
      },
      (error) => {
        console.log(error);
        this.blockUI.stop();
      }
    );
  }
  StatusOV(status): string {
    let OV = "N/A";
    switch (status) {
      case "01": {
        OV = "Autorizado";
        break;
      }
      case "02": {
        OV = "Pendiente";
        break;
      }
      case "03": {
        OV = "Rechazado";
        break;
      }
    }
    return OV;
  }

  OpenAutModal(_docEntry: number, _docNum,_id:string): void {
    this.autForm.patchValue({ DocEntryOV: _docEntry, DocNumOV: _docNum, UniqueId:_id });
    (<HTMLButtonElement>document.getElementById("autorizeTrigger")).click();
  }

  /**
   * Method to autorize or rejected orders
   * @param action
   * @constructor
   */
  AutorizeOrRejectOrder(action: number): void {
    if (this.autForm.valid) {
      let sender = {
        U_EstadoOV: action ? "01" : "03",
        DocEntry: this.autForm.controls.DocEntryOV.value,
        U_FechaAut: this.datePipe.transform(new Date(), "yyyy-MM-dd-h:mm:ss a"),
        U_CorreoAut: JSON.parse(this.storageService.getCurrentSession()).UserName,
        U_ObservacionAut:this.autForm.controls.ObservacionOV.value,
        U_CLVS_POS_UniqueInvId:this.autForm.controls.UniqueId.value
      } as IAutorizeOrRejectsOrder;

      swal({
        type: "warning",
        title: `Se procederá a ${
          action ? "autorizar" : "rechazar"
        } la órden de venta, ¿ Desea continuar con la acción?`,
        text: "",
        showCancelButton: true,
        confirmButtonColor: "#049F0C",
        cancelButtonColor: "#ff0000",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(
        (next) => {
          if (!(Object.keys(next)[0] === "dismiss")) {
            this.blockUI.start(`Espere por favor..`);

            this.documentService.AutorizeOrRejectsO(sender).subscribe(
              (result) => {
                this.blockUI.stop();
                  (<HTMLButtonElement>(document.getElementById("closeModalAut"))).click();
                  this.alertService.infoAlert(
                    `Orden de venta ${
                      action ? "autorizada" : "rechazada"
                    } con éxito.`
                  );

                  this.search();
              },
              (error) => {
                this.blockUI.stop();
                let err = error && error.Code ? `Ocurrio un error al intentar ${action ? "autorizar" : "rechazar"
                } la orden de venta, Código: ${error.Code}, Mensaje: ${error.Message}`: '';
                this.alertService.errorAlert(err ? err :`Error al intentar conectar con el servidor, Error: ${error}`);

              }
            );
          }
        },
        (dismiss) => {}
      );
    } else {
      this.alertService.errorAlert("Debe completar el campo observaciones");
    }
  }
}
