import { Component, OnInit, ElementRef, ViewChild, Inject, ViewChildren, QueryList, HostListener } from '@angular/core';
import { AlertService } from '../../../services/alert.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemService, PermsService, AuthenticationService, ExRateService } from 'src/app/services';
import { BusinessPartnerService } from '../../../services/business-partner.service';
import { debounceTime, map, distinctUntilChanged, filter } from 'rxjs/operators';
import { IBarcode, IItemModel } from '../../../models/i-item';
import { GoodsReciptService } from '../../../services/goods-recipt.service';
import { StoreService } from '../../../services/store.service';
import { ILine } from '../../../models/i-line';
import { TaxService } from '../../../services/tax.service';
import swal from 'sweetalert2';
import { FormControl, FormGroup } from '@angular/forms';
import { DOCUMENT, EventManager } from '@angular/platform-browser';
import { Renderer2 } from '@angular/core';
import { Company, IPrice } from 'src/app/models';
import { CompanyService } from '../../../services/company.service';
import { IViewGroup } from 'src/app/models';
import { StorageService } from '../../../services/storage.service';
import { PurchaseOrderService } from '../../../services/purchase-order.service';

@Component({
  selector: 'app-inventory-entry',
  templateUrl: './inventory-entry.component.html',
  styleUrls: ['./inventory-entry.component.css']
})

export class InventoryEntryComponent implements OnInit {
  whCode: string;
  whName: string;
  //VARBOX
  @ViewChild('scrollMe') private myScrollContainer: ElementRef; // Se usa para que la tabla haga autoscroll
  @ViewChild('input1') inputEl: ElementRef; // Lo uso para mandarle el autofocus cuando se escanea el codigo de barras
  @ViewChild('barcodeEl') barcodeEl: ElementRef; // Lo uso para mandarle el autofocus cuando se escanea el codigo de barras
  permisos = true; // Comprueba los permisos del usuario
  lines: ILine[]; // Representa la linea del producto que se ingresa
  itemsTypeaheadList: string[] = []; // lista de la concatenacion del Código con el nombre del item
  itemsList: IItemModel[]; // Contiene el nombre de los productos, preformateados desde el api y el codigo de estos
  globalItem: IItemModel;
  barcodeList: IBarcode[] = []; // Contiene la lista de los codigo de barra
  taxesList: any; // Contiene la lista de impuestos registrados en el sistema
  businessParters: any; // Contiene la lista de todos los proveedores
  @BlockUI() blockUI: NgBlockUI; // Usado para bloquear la interfaz de usuario
  currentUser: any; // variable para almacenar el usuario actual
  COMPANY: Company;
  TO_FIXED_PRICE: string;
  TO_FIXED_TOTALLINE: string;
  TO_FIXED_TOTALDOCUMENT: string;
  globalName: string; // Guarda el nombre del item seleccionado del typeahead
  modalTitleItem = 'Agregar producto al sistema';
  totalLines: number; // Guarda la sumatoria de todas las linea con (precio unitario * cantidad)
  item: IItemModel; // Model para el typeahead, cuando se lecciona un item de la lista este lo almacena
  priceList: IPrice[]; // Contiene la lista de precios del sistema, debe ser obtenida por request, por ahora se va quemada para mostrar funcionalidad
  UnitPrice: number;
  Quantity: number;
  SubTotal: number; // Guarda
  WareHouse: string; // Guarda el id del almacen del dropdown global
  Stores: any; // Guarda todos los almacenes de la empresa
  DiscountTotal: number;
  totalLinesWithDiscount: number; // Guarda el total de linea aplicado el descuento de esta
  TaxesTotal: number;
  USTotal: number; // Total en dolares
  CRCTotal: number; // Total en colones
  currentIndex: number;
  exrate: number;
  barcodeModel: any; // Usando para sacar el codigo de barras de un producto, de la lista de codigos que este tiene
  supplierModel: any; // Contiene el proveedor seleccionado
  globalBarcode: string; // Codigo de barras global para busqueda en el api de productos
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  lockedButtton = false;
  ItemInfo: FormControl = new FormControl();
  hasBeenSend: boolean;
  itemForm;
  viewGroupList: IViewGroup[] = []; //contiene lista de agrupaciones en linea
  isOnEditMode = false;
  isOnGroupLine: boolean;
  isUpdatingBarcode: boolean; // Variable para ver si se actualiza el codigo de barras

  @ViewChild('editQuantityId') editQuantityId: ElementRef; // Referencia al input de edicion de precio por linea, para poder setear el focus cuando se va a editar
  @ViewChild('editUnitPriceId') editUnitPriceyId: ElementRef; // Referencia al input de edicion de precio por linea, para poder setear el focus cuando se va a editar
  @ViewChild('editTotalLineId') editTotalLineId: ElementRef; // Referencia al input de edicion de precio por linea, para poder setear el focus cuando se va a editar

  barcodeForm = new FormGroup({
    barcodeModal: new FormControl(''),
    barcodeDescriptionModal: new FormControl('')
  });

  fItemName = (x: { ItemName: string }) => x.ItemName; // Formateador para el nombre de los productos
  sItemName = (text$: Observable<string>) => text$.pipe( // Busca en el nombre de item la coincidencia por codigo de barras, nombre, codigo item
    debounceTime(5),
    distinctUntilChanged(),
    map(term => term === '' ? []
      : this.itemsList.filter(v => v.ItemName.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 3)))

  searchItemCode = (text$: Observable<string>) =>
    text$.pipe(
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.itemsTypeaheadList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  supplierNameformatter = (x: { CardName: string }) => x.CardName; // Formateador para el nombre de los proveedores
  supplierSearchName = (text$: Observable<string>) => text$.pipe( // Busca en el nombre del proveedor por cedula, codigo, nombre
    debounceTime(5),
    map(term => term === '' ? []
      : this.businessParters.filter(v => v.CardName.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))

  constructor(private eventManager: EventManager,
    private modalService: NgbModal,
    private itemsService: ItemService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private sPerm: PermsService,
    private businessPartnerService: BusinessPartnerService,
    private goodsReciptService: GoodsReciptService,
    private purchaseOrderService: PurchaseOrderService,
    private storeService: StoreService,
    private storage: StorageService,
    private taxesService: TaxService,
    @Inject(DOCUMENT) private _document: Document,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private companyService: CompanyService,
    private exrateService: ExRateService,) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.lockedButtton = false;
    const removeGlobalEventListener = this.eventManager.addGlobalEventListener(
      'document',
      'keypress',
      (ev) => {
        if (ev.key === 'Enter') {
          if ((<HTMLInputElement>document.getElementById('inputPro'))) {
            if ((<HTMLInputElement>document.getElementById('inputPro')).value !== null || (<HTMLInputElement>document.getElementById('inputPro')).value !== '') {
              if ((<HTMLInputElement>document.getElementById('inputPro')).value + '' !== '') {
                this.globalBarcode = (<HTMLInputElement>document.getElementById('inputPro')).value + '';
                this.suggestItemCreation();
              }
            }
          }
        }
      }
    );
  }
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
    // var contextElement = document.getElementById("context-menu");
    // contextElement.style.top = event. offsetX+ "px";
    // contextElement.style.left = event.offsetY + "px";
    // contextElement.classList.add("active");
    // console.log(event)
    if ((<HTMLElement>event.target) && (<HTMLElement>event.target).getAttribute('ng-reflect-result')) {
      const CODE = (<HTMLElement>event.target).getAttribute('ng-reflect-result').split(' ')[0];
      this.ItemInfo.reset();
      console.log(CODE);
      this.raiseModalCreationFromRighClick(CODE);
      setTimeout(() => {
        // (<HTMLButtonElement>document.getElementById('auxClickBarcode')).click();
        this.barcodeEl.nativeElement.focus();
      }, 100);
    }
  }

  globalCode: string;
  ngOnInit() {
    this.modalTitleItem = 'Agregar producto al sistema';
    // window.addEventListener("contextmenu",function(event){
    //   event.preventDefault();
    //   var contextElement = document.getElementById("context-menu");
    //   contextElement.style.display = 'block';
    //   contextElement.style.top = event.offsetY + "px";
    //   contextElement.style.left = event.offsetX + "px";
    //   contextElement.classList.add("active");
    //   // console.log(event.target.getAttribute('ng-reflect-result'));
    //   // globalCode = (<HTMLElement>event.target).getAttribute('ng-reflect-result').split(' ')[0];//Property 'getAttribute' does not exist on type 'EventTarget'.

    // });

    // window.addEventListener("click",function(e){
    //   var contextElement = document.getElementById("context-menu").style.display = 'none';
    //   // document.getElementById("context-menu").classList.remove("active");
    //   console.log(e.target);
    // });

    setTimeout(() => this.inputEl.nativeElement.focus());
    this.resetItemForm();
    this.checkPermits(); // Verifica si el usuario tiene permisos para acceder a la pagina
    this.blockUI.start();
    this.InitVariables();
    this.blockUI.start('Obteniendo información, espere por favor...');
    // Carga la lista de proveedores y la formatea para poder buscar codigo en este
    this.businessPartnerService.GetSuppliers().subscribe(response => {
      this.businessParters = response.BPS;
      for (let c = 0; c < this.businessParters.length; c++) {
        const bp = this.businessParters[c];
        this.businessParters[c].CardName = `${bp.CardCode} - ${bp.CardName} - ${bp.Cedula}`;
      }
    });
    // Obtiene el tipo de cambio
    this.exrateService.getExchangeRate().subscribe((data: any) => {
      if (data.result) {
        this.exrate = data.exRate
        //this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
      } else {
        this.blockUI.stop();
        this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
      }

    }, (error: any) => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error getCustomers!!!, Error: ${error.error.Message}`);
    }), () => {
      this.blockUI.stop();
    };
    // Obtiene la lista de almances de la compania, este lo hice nuevo porque el que estaba llamaba otro metodo, pero sigue exisitendo
    this.storeService.getStoresv2().subscribe(response => {
      this.Stores = response.Stores;
      this.WareHouse = this.Stores[0].StoreCode;
    });
    // Obtiene la lista de impuestos registrados en el sistema
    this.taxesService.GetTaxes().subscribe(response => {
      this.taxesList = response.Taxes;
    }); // Consume los impuesto y los setea a dicha lista desde el api
    // Consulta si se hace agrupacion de lineas en esta vista
    this.companyService.GetViewGroupList().subscribe(next => {
      if (next.result) {
        ((next.ViewGroupList) as IViewGroup[]).forEach(x => {
          if (x.CodNum === 2) this.isOnGroupLine = x.isGroup;//Entrada de inventario
        });
      }
    });
    // Obtiene los items para ser usados en el typeahead
    this.itemsService.GetItems().subscribe(response => {
      this.itemsList = [];
      this.itemsTypeaheadList = response.ItemList.ItemCompleteName;
      for (let c = 0; c < response.ItemList.ItemCompleteName.length; c++) {
        const NAME = response.ItemList.ItemCompleteName[c];
        const ITEM_CODE = response.ItemList.ItemCode[c];
        const tmpItem = {} as IItemModel;
        tmpItem.ItemName = NAME;
        tmpItem.ItemCode = ITEM_CODE;
        this.itemsList.push(tmpItem);
      }
      this.blockUI.stop();
    });

    if (this.itemsService.hasLines) {
      this.lines = this.itemsService.GetLines(this);
      this.recalculate();
    } else {

    }
    this.getViewGroup();
  }
  getViewGroup() {
    this.companyService.GetViewGroupList().subscribe(response => {
      if (response.result) {
        // this.viewGroupList = [];
        response.ViewGroupList.forEach(vg => {
          this.viewGroupList.push({
            'Id': vg.Id,
            'CodNum': vg.CodNum,
            'NameView': vg.NameView,
            'isGroup': vg.isGroup
          });
        })
      }
    });
  }

  raiseModalCreation(): void {
    this.barcodeList = [];
    const item: IItemModel = {} as IItemModel;

    item.BarCode = this.globalBarcode;
    this.itemForm.patchValue({
      ItemName: '',
      ForeingName: '',
      BarCode: item.BarCode,
      TaxCode: '13IVA',
      Price: 0
    });
    this.barcodeList.push({
      BcdCode: this.globalBarcode,
      BcdName: '',
      BcdEntry: -1,
      UomEntry: -1
    });

    (<HTMLInputElement>document.getElementById('raiseItemModal')).click();
  }

  raiseModalCreationFromRighClick(ItemCode: string): void {
    this.barcodeList = [];
    const item: IItemModel = {} as IItemModel;

    this.itemsService.GetItemByItemCode(ItemCode, 1).subscribe(next => {
      if (next.result) {
        this.itemsService.GetBarcodesByItem(ItemCode).subscribe(next1 => {
          if (next1.result) {
            this.barcodeList = next1.Item === null ? [] : next1.Item.Barcodes;
            this.barcodeForm.patchValue({
              barcodeDescriptionModal: '',
              barcodeModal: ''
            });

            const LINE = {} as ILine;

            LINE.BarCode = next.Item.BarCode;
            LINE.ItemCode = next.Item.ItemCode;
            LINE.ItemName = next.Item.ItemName;
            LINE.Quantity = this.Quantity > 0 ? this.Quantity : 1;
            LINE.TaxCode = next.Item.TaxCode;
            LINE.UnitPrice = +(Number(next.Item.LastPurchasePrice)).toFixed(4);
            LINE.Discount = next.Item.Discount;
            LINE.Tax_Rate = next.Item.TaxRate;
            LINE.TaxRate = next.Item.TaxRate;
            LINE.WareHouse = this.WareHouse;
            LINE.TotalLine = next.Item.LastPurchasePrice * LINE.Quantity;

            this.globalItem = LINE;
            this.barcodeList = next1.Item === null ? [] : next1.Item.Barcodes;
            this.globalName = LINE.ItemName;
            this.itemForm.patchValue({
              ItemName: LINE.ItemName,
              ForeingName: LINE.ForeingName,
              BarCode: '',
              TaxCode: LINE.TaxCode,
              Price: LINE.UnitPrice
            });

            (<HTMLInputElement>document.getElementById('raiseBarcodesModal')).click();
          }
          else {
            console.log(next1);
            this.alertService.errorAlert(`Error al obtener los códigos de barra, Error: ${next1.errorInfo.message}`)
          }
        }, error => {
          this.alertService.errorAlert(`Error al conectar con el servidor, Error: ${error.error}`)
        });
      }
      else {
        this.alertService.errorAlert(`Error al obtener el producto, Error: ${next.errorInfo.message}`);
      }
    }, error => {
      console.log(error);
      this.alertService.errorAlert(`Error al conectar con el servidor ${error.error}`);
    });
  }
  // Agrega el codigo de barras de manera preeliminar
  addBarcode(): void {
    this.barcodeList.push({
      BcdCode: this.barcodeForm.value.barcodeModal,
      BcdName: this.barcodeForm.value.barcodeDescriptionModal,
      BcdEntry: -1,
      UomEntry: -1
    });
    let lookup = new Set();
    this.barcodeList = this.barcodeList.filter(obj => !lookup.has(obj['BcdCode']) && lookup.add(obj['BcdCode']));
    if (this.isUpdatingBarcode) {
      for (let c = 0; c < this.barcodeList.length; c++) {
        if (this.barcodeList[c].BcdCode === this.barcodeForm.value.barcodeModal) {
          this.barcodeList[c].BcdName = this.barcodeForm.value.barcodeDescriptionModal;
        }
      }
    }

    this.barcodeForm.patchValue({
      barcodeModal: '',
      barcodeDescriptionModal: ''
    });

    this.alertService.successInfoAlert(`Código ${this.isUpdatingBarcode ? 'actualizado' : ' agregado'}. Presione actualizar para confirmar los cambios`);
    this.isUpdatingBarcode = false;
    // this.barcodeForm.value.barcodeModal.enable();
  }
  updateBarcodeDesctiption(_index: number): void {
    this.isUpdatingBarcode = true;
    // this.barcodeForm.value.barcodeModal.disabled();
    this.barcodeForm.patchValue({
      barcodeModal: this.barcodeList[_index].BcdCode,
      barcodeDescriptionModal: this.barcodeList[_index].BcdName
    });
  }
  // Limpieza de los códigos de barra
  clearBarcode(): void {
    this.barcodeForm.patchValue({
      barcodeModal: '',
      barcodeDescriptionModal: ''
    });
    // this.isUpdating = false;
  }
  // Levanta la modal de pregunta para la creación del producto en caso de no existir
  suggestItemCreation(): void {
    (<HTMLInputElement>document.getElementById('raiseModalItemCreation')).click();
  }
  // Establece el indice de la linea seleccionada
  setCurrentIndex(index: number): void {
    this.currentIndex = index;
  }
  // Agrega una linea arriba de la linea seleccionada
  addLine(): void {
    if (this.lines.some(x => x.ItemCode === '----')) {
      this.alertService.infoInfoAlert('Por favor complete la línea recién agregada');
      return;
    }
    const LINE = {} as ILine;
    LINE.BarCode = '';
    LINE.ItemCode = '----';
    LINE.ItemName = '';
    LINE.Quantity = 1;
    LINE.TaxCode = '13IVA';
    LINE.UnitPrice = 0;
    LINE.Discount = 0;
    LINE.Tax_Rate = 0;
    LINE.TaxRate = '';
    LINE.WareHouse = this.whCode;
    LINE.TotalLine = 0;
    let auxiliarList = this.lines;

    const lista = auxiliarList.slice(0, this.currentIndex);
    const listb = auxiliarList.slice(this.currentIndex, auxiliarList.length);

    lista.push(LINE);

    listb.forEach(x => lista.push(x));
    this.lines = lista;

    setTimeout(() => this.inputEl.nativeElement.focus());
  }
  // Sube una posicion la linea seleccionada
  upLine(): void {
    if (this.currentIndex > 0) {
      let auxiliarList = this.lines;

      const itemup = auxiliarList.slice(this.currentIndex, auxiliarList.length);
      const itemmove = auxiliarList.slice(this.currentIndex - 1, auxiliarList.length);

      auxiliarList[this.currentIndex - 1] = itemup[0];
      auxiliarList[this.currentIndex] = itemmove[0];

      this.lines = auxiliarList;
    }

  }

  // Baja una posicion la linea seleccionada
  downLine(): void {
    if ((this.currentIndex + 1) != this.lines.length) {
      let auxiliarList = this.lines;

      const itemup = auxiliarList.slice(this.currentIndex, auxiliarList.length);
      const itemmove = auxiliarList.slice(this.currentIndex + 1, auxiliarList.length);

      auxiliarList[this.currentIndex + 1] = itemup[0];
      auxiliarList[this.currentIndex] = itemmove[0];
      this.lines = auxiliarList;
    }
  }

  clearItemForm(): void {

  }

  clearInputSeach(): void {
    this.ItemInfo.reset();
    setTimeout(() => this.inputEl.nativeElement.focus(), 300);
  }

  saveItem(): void {
    this.priceList = [];
    this.priceList.push({
      ListName: '',
      ListNum: 1,
      Price: +this.itemForm.value.Price
    });
    const mItem: IItemModel = {
      ItemCode: '',
      ItemBarCode: '',
      ItemName: this.itemForm.value.ItemName,
      PriceListId: this.priceList,
      TaxCode: this.itemForm.value.TaxCode,
      TaxRate: '',
      BarCode: '',
      Frozen: false,
      Discount: 0,
      ForeingName: this.itemForm.value.ForeingName,
      UnitPrice: 0,
      PriceList: this.priceList,
      Barcodes: this.barcodeList
    };
    console.log(mItem);
    if (mItem.ItemName.length === 0 || this.barcodeList.length === 0) {
      this.alertService.infoInfoAlert('El producto debe tener un nombre y un código de barras al menos');
      return;
    }
    this.blockUI.start('Creando el item, Espere Por Favor...');
    this.itemsService.CrateItem(mItem).subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.alertService.successInfoAlert('Se ha creado correctamente el producto');
        this.itemsService.GetItems().subscribe(response => {
          this.itemsList = [];
          this.itemsTypeaheadList = response.ItemList.ItemCompleteName;
          for (let c = 0; c < response.ItemList.ItemCompleteName.length; c++) {
            const NAME = response.ItemList.ItemCompleteName[c];
            const ITEM_CODE = response.ItemList.ItemCode[c];
            const tmpItem = {} as IItemModel;
            tmpItem.ItemName = NAME;
            tmpItem.ItemCode = ITEM_CODE;
            this.itemsList.push(tmpItem);
          }

          this.ItemInfo.reset();
          setTimeout(() => this.inputEl.nativeElement.focus());
        });
      } else {
        console.error(response);
        this.alertService.errorAlert(`Error al crear el producto ${response.errorInfo.Message}`);
      }
    }, error => {
      console.error(error);
      this.alertService.errorAlert(`Detalle error ${error}`);
      this.blockUI.stop();
    });
  }
  // Methodo para actualizar principalmente los codigos de barra del producto seleccionado del typeahead
  updateItem(): void {
    this.globalItem.Barcodes = this.barcodeList;
    console.log(this.globalItem);
    this.blockUI.start('Actualizando códigos de barra, espere Por Favor...');
    this.itemsService.UpdateItem(this.globalItem).subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.alertService.successAlert('Se han actualizado correctamente los códigos de barra');
        this.itemsService.GetItems().subscribe(next => {
          if (next.result) {
            for (let c = 0; c < next.ItemList.ItemCompleteName.length; c++) {
              const name = next.ItemList.ItemCompleteName[c];
              this.itemsTypeaheadList = next.ItemList.ItemCompleteName;
              this.itemsList.push({
                PriceListId: this.priceList,
                ItemCode: next.ItemList.ItemCode[c],
                ItemName: next.ItemList.ItemCompleteName[c],
                ItemBarCode: name.substring(name.lastIndexOf('COD. ') + 1, name.lastIndexOf(' COD.')),
                Frozen: true,
                TaxCode: '',
                TaxRate: '',
                BarCode: '',
                Discount: 0,
                ForeingName: '',
                UnitPrice: 0,
                PriceList: [],
                Barcodes: []
              });
            }
          }
        });
      } else {
        console.log(response.errorInfo);
        this.alertService.errorAlert(`Error al actualizar los códigos de barra ${response.errorInfo.Message}`);
      }
    }, error => {
      console.log(error);
      this.alertService.errorAlert(`Error al conectar con el servidor al intentar actualizar los códigos de barra, Error: ${error.error}`);
    });
  }
  // Restablece el formulario de creacion de item en sugerencia al no existir
  resetItemForm(): void {
    this.itemForm = new FormGroup({
      ItemName: new FormControl(''),
      ForeingName: new FormControl(''),
      BarCode: new FormControl(''),
      Price: new FormControl(''),
      TaxCode: new FormControl('')
    }); // Es el formulario para el item
  }
  // Agrega un item a la lista de lineas de la entrada
  addItems(item: any): void {
    const ITEM_CODE = item.item.split(' ')[0];
    this.onThClick(ITEM_CODE);
    item.preventDefault();
    this.ItemInfo.reset();
  }
  // Funcion que recalcula el precio total, subtotal, descuentos, impuestos, sobre la lista de lineas
  recalculate(): void {

    this.totalLines = +this.lines.reduce((a, b) => a + (((b.UnitPrice * b.Quantity)) || 0), 0).toFixed(4);

    this.totalLinesWithDiscount = +this.lines.reduce((a, b) => a + (((b.UnitPrice * b.Quantity) - ((b.UnitPrice * b.Quantity) * (b.Discount / 100))) || 0), 0).toFixed(4);

    this.TaxesTotal = +this.lines.reduce((a, b) => a + (((b.UnitPrice * b.Quantity) - ((b.UnitPrice * b.Quantity) * (b.Discount / 100))) * (b.Tax_Rate / 100) || 0), 0).toFixed(4);

    this.DiscountTotal = +this.lines.reduce((a, b) => a + ((b.UnitPrice * b.Quantity) * (b.Discount / 100) || 0), 0).toFixed(4);

    this.CRCTotal = +this.lines.reduce((a, b) => a +
      (((b.UnitPrice * b.Quantity) - ((b.UnitPrice * b.Quantity) * (b.Discount / 100))) +
        (((b.UnitPrice * b.Quantity) - ((b.UnitPrice * b.Quantity) * (b.Discount / 100))) * (b.Tax_Rate / 100)) || 0), 0).toFixed(4);

    this.CRCTotal = (this.totalLinesWithDiscount + this.TaxesTotal);

    // this.CRCTotal = +(Math.floor(100 * this.CRCTotal) / 100).toFixed(2);

    // this.CRCTotal = Math.round((this.CRCTotal + Number.EPSILON) * 100) / 100;

    this.USTotal = this.CRCTotal / this.exrate;

    if (this.lines.length > 0) {
      this.itemsService.hasLines = true;
      this.itemsService.UpdateLines(this.lines);
    } else {
      this.itemsService.hasLines = false;
      this.itemsService.UpdateLines([]);
    }
  }

  onFocusOutEvent(event: any, type: string) {
    this.ItemInfo.reset();
    switch (+type) {
      case 0:
        this.inputs.toArray()[+event].nativeElement.focus();
        break;
      case 1:
        this.unitPrices.toArray()[+event].nativeElement.focus();
        break;
    }
  }

  isOnEditMode_ = false;

  @ViewChildren('quantities') inputs: QueryList<ElementRef>;
  @ViewChildren('unitPrices') unitPrices: QueryList<ElementRef>;
  @ViewChildren('totalLine') totalLine: QueryList<ElementRef>;
  // (blur)="onFocusOutEvent(i, '0')"
  lostFocus(index: number, type: number) {
    switch (+type) {
      case 0:
        this.inputs.toArray()[+index].nativeElement.focus();
        break;
      case 1:
        this.unitPrices.toArray()[+index].nativeElement.focus();
        break;
      case 2:
        this.totalLine.toArray()[+index].nativeElement.focus();
        break;
    }
  }

  lostEditFocus(): void {
    //this.isOnEditMode = false;
  }

  toggleEdition(index: string, type: string): void {
    this.isOnEditMode = true;
  }

  onFocusOutEdit(i: string) {
    this.isOnEditMode = false;
  }

  // Saca un item de la lista de lineas basado en su indice
  removeItem(): void {
    if (this.currentIndex !== -1) {
      this.alertService.successInfoAlert(`Se ha eliminado ${this.lines.filter((x, i) => i === this.currentIndex)[0].ItemName}`);
      this.lines = this.lines.filter((x, i) => i !== this.currentIndex);
      this.recalculate();
      this.currentIndex = -1;
      setTimeout(() => this.inputEl.nativeElement.focus());
    }
  }
  // Evento para actualizar todos los almacenes con el dropdown global
  onSelectBoxChange(event: any): void {
    for (let c = 0; c < this.lines.length; c++) {
      this.lines[c].WareHouse = event.target.value;
    }
  }
  // Evento para actualizar el almacen de cada linea, basado en su indice
  onSelectBoxLineChange(event: any, index: number): void {
    this.lines[index].WareHouse = event.target.value;
  }
  // Evento para actulizar el impuesto de cada producto, basado en su indice
  onSelectBoxLineChangeTax(event: any, index: number): void {
    const TAX_CODE = event.target.value;
    const TAX = this.taxesList.filter(x => x.TaxCode === TAX_CODE)[0];
    this.lines[index].TaxCode = TAX.TaxCode;
    this.lines[index].Tax_Rate = +TAX.TaxRate;
    this.lines[index].TaxRate = TAX.TaxRate;
    this.recalculate();
  }
  // Evento para seleccionar un item del type ahead y cargarlo en la lista de lineas
  onThClick(_itemCode: string): void {
    // if (item.constructor.name.toString() === 'Object') {
    this.blockUI.start('Obteniendo información del item');
    this.itemsService.GetItemByItemCode(_itemCode, 1).subscribe(response => {
      if (response.result) {
        const LINE = {} as ILine;
        LINE.BarCode = response.Item.BarCode;
        LINE.ItemCode = response.Item.ItemCode;
        LINE.ItemName = response.Item.ItemName;
        LINE.Quantity = this.Quantity > 0 ? this.Quantity : 1;
        LINE.TaxCode = response.Item.TaxCode;
        // LINE.UnitPrice = +(Number(response.Item.LastPurchasePrice)).toFixed(this.DECS);
        LINE.UnitPrice = +(Number(response.Item.LastPurchasePrice)).toFixed(4);
        LINE.Discount = response.Item.Discount;
        LINE.Tax_Rate = response.Item.TaxRate;
        LINE.TaxRate = response.Item.TaxRate;
        LINE.WareHouse = this.WareHouse;
        LINE.TotalLine = response.Item.LastPurchasePrice * LINE.Quantity;

        if (this.lines.some(x => x.ItemCode === '----')) {
          if (this.isOnGroupLine) {
            this.summarize(LINE);
          }
          else {
            this.lines[this.lines.findIndex(x => x.ItemCode === '----')] = LINE;
          }
        }
        else {
          this.isOnGroupLine ? this.summarize(LINE) : this.lines.push(LINE);
        }

        this.Quantity = 1;
        this.item = {} as IItemModel;
        setTimeout(() => this.inputEl.nativeElement.focus());
        this.recalculate();
        this.blockUI.stop();
      } else {
        this.blockUI.stop();
        this.alertService.errorAlert(response.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorAlert(`Ha ocurrido un error al obtener el producto. Código ${error.status}, mensaje: ${error.error}`);
      console.log(error);
    });
  }

  summarize(_line: ILine): void {
    let isFound = false;
    this.lines.forEach(x => {
      if (x.ItemCode === _line.ItemCode) {
        x.Quantity = x.Quantity + (this.Quantity > 0 ? this.Quantity : 1);
        x.TotalLine = x.UnitPrice * x.Quantity - (x.UnitPrice * x.Quantity) * (x.Discount) / 100;
        isFound = true;
        if (this.lines.some(x => x.ItemCode === '----')) {
          this.alertService.infoInfoAlert(`Se agregado el producto en la linea ${this.lines.findIndex(x => x.ItemCode === _line.ItemCode) + 1}, pero sigue incompleta la linea ${this.lines.findIndex(x => x.ItemCode === '----') + 1}`);
        }
        this.recalculate();
      }
    });

    if (!isFound) {
      if (this.lines.some(x => x.ItemCode === '----')) this.lines[this.lines.findIndex(x => x.ItemCode === '----')] = _line;
      else this.lines.push(_line);
    }
  }

  currencyInputChanged(value) {
    // var num = value.replace(/[$,]/g, "");
    // return Number(num);
  }

  focusOut(event: any, i: number, field: string): void {

    switch (+field) {
      case 0:
        this.lines[i].Quantity = +(parseFloat((<HTMLInputElement>document.getElementById('quantity_' + i)).value).toFixed(4));
        this.lines[i].TotalLine = this.lines[i].Quantity * this.lines[i].UnitPrice;
        this.lines[i].TotalLine = +(this.lines[i].Quantity * this.lines[i].UnitPrice).toFixed(4);
        break;
      case 1:
        this.lines[i].UnitPrice = +(parseFloat((<HTMLInputElement>document.getElementById('unitPrice_' + i)).value).toFixed(4));
        this.lines[i].TotalLine = +(this.lines[i].Quantity * this.lines[i].UnitPrice).toFixed(4);
        break;
      case 2:
        this.lines[i].Discount = +(parseFloat((<HTMLInputElement>document.getElementById('discount_' + i)).value).toFixed(4));
        this.lines[i].TotalLine = (this.lines[i].Quantity * this.lines[i].UnitPrice) - ((this.lines[i].Quantity * this.lines[i].UnitPrice) * (+(parseFloat((<HTMLInputElement>document.getElementById('discount_' + i)).value).toFixed(4)) / 100));
        this.lines[i].Discount = +this.lines[i].Discount.toFixed(4);
        this.lines[i].TotalLine = +this.lines[i].TotalLine.toFixed(4);
        break;
      case 3:
        this.lines[i].UnitPrice = +(parseFloat((<HTMLInputElement>document.getElementById('totalLine_' + i)).value).toFixed(4)) / this.lines[i].Quantity;
        this.lines[i].UnitPrice = +this.lines[i].UnitPrice.toFixed(4);
        this.lines[i].TotalLine = +(parseFloat((<HTMLInputElement>document.getElementById('totalLine_' + i)).value).toFixed(4));
        break;
    }
    this.recalculate();
  }
  // Evento para actualizar los diferentes datos de un item en la linea, segun su indice
  onKeyUp(i: number, event: any, field: string): void {
    if (event.key === 'Escape') {
      this.isOnEditMode = false;
      setTimeout(() => {
        this.inputEl.nativeElement.focus();
      }, 0);
      switch (+field) {
        case 0:
          this.inputs.toArray()[+i].nativeElement.value = this.lines[i].Quantity;
          break;
        case 1:
          this.unitPrices.toArray()[+i].nativeElement.value = this.lines[i].UnitPrice;
          break;
        case 2:
          this.totalLine.toArray()[+i].nativeElement.value = this.lines[i].Discount;
          break;
        case 4:
          this.inputs.toArray()[+i].nativeElement.value = this.lines[i].UnitPrice;
          break;
      }
      return;
    }

    if (event.target.value !== '' && (event.key === 'Enter' || event.key === 'Tab')) {
      switch (+field) {
        case 0:
          this.lines[i].Quantity = +event.target.value;
          break;
        case 1:
          this.lines[i].UnitPrice = +event.target.value;
          break;
        case 2:
          this.lines[i].Discount = +event.target.value;
          break;
        case 4:
          this.lines[i].UnitPrice = +event.target.value / this.lines[i].Quantity;
          break;
      }
      this.isOnEditMode = false;
      setTimeout(() => {
        this.inputEl.nativeElement.focus();
      }, 0);
      this.recalculate();
    }
  }
  // Crea la entrada del inventario
  createGoodsRecipt(): void {
    if (this.lines.length === 0) {
      this.alertService.infoAlert('Debe ingresar un artículo al menos');
      return;
    }

    if (this.lines.some(x => x.ItemCode === '----')) {
      this.alertService.infoAlert('Existen líneas que no han sido completadas');
      return;
    }

    if (!this.supplierModel) {
      this.alertService.infoAlert('Debe Seleccionar el proveedor');
      return;
    }

    if (this.lockedButtton) {
      this.alertService.infoAlert('Ya se ha generado la entrada, por favor presione borrar campos para agregar una nueva entrada');
      return;
    }

    swal({
      type: 'warning',
      title: 'Se creará una entrada de inventario',
      text: '¿ Desea continuar ?',
      showCancelButton: true,
      confirmButtonColor: '#049F0C',
      cancelButtonColor: '#ff0000',
      confirmButtonText: 'Sí',
      cancelButtonText: 'No'
    }).then(next => {
      console.log(Object.keys(next));
      if (!(Object.keys(next)[0] === 'dismiss')) {
        this.blockUI.start('Agregando la entrada de inventario');
        this.goodsReciptService.CreateGoodsRecipt(
          this.lines, this.supplierModel.CardCode, this.supplierModel.CardName, this.supplierModel.Cedula
        ).subscribe(response => {
          this.blockUI.stop();
          if (response.result) {
            this.hasBeenSend = true;
            this.alertService.successAlert('Se ha creado correctamente la entrada de inventario');
            this.lockedButtton = true;
          } else {
            this.hasBeenSend = false;
            this.alertService.errorAlert('Error al agregar la entrada: ' + response.errorInfo.Message);
          }
        }, error => {
          this.blockUI.stop();
          this.alertService.errorAlert(`Error en la solicitud para agregar la entrada de invetario: ${error.error}`);
        }, () => {
          this.blockUI.stop();
        });
      }
    });
  }

  createPurchaseOrder(): void {
    if (this.lines.length === 0) {
      this.alertService.infoAlert('Debe ingresar un artículo al menos');
      return;
    }

    if (!this.supplierModel) {
      this.alertService.infoAlert('Debe Seleccionar el proveedor');
      return;
    }

    if (this.lockedButtton) {
      this.alertService.infoAlert('Ya se ha generado la orden de compra, por favor presione limpiar campos para agregar una nueva orden de compra');
      return;
    }

    swal({
      type: 'warning',
      title: 'Se creará una orden de compra',
      text: '¿ Desea continuar ?',
      showCancelButton: true,
      confirmButtonColor: '#049F0C',
      cancelButtonColor: '#ff0000',
      confirmButtonText: 'Sí',
      cancelButtonText: 'No'
    }).then(next => {
      console.log(Object.keys(next));
      if (!(Object.keys(next)[0] === 'dismiss')) {
        this.blockUI.start('Agregando la orden de compra');

        this.purchaseOrderService.CreatePurchaseOrder(
          this.lines, this.supplierModel.CardCode, this.supplierModel.CardName, this.supplierModel.Cedula
        ).subscribe(response => {
          this.blockUI.stop();
          if (response.result) {
            this.hasBeenSend = true;
            this.alertService.successAlert(`Se ha creado correctamente la orden de compra N° ${response.PurchaseOrder.DocNum}`);
            this.lockedButtton = true;
          } else {
            this.hasBeenSend = false;
            this.alertService.errorAlert('Error al agregar la orden de compra: ' + response.errorInfo.Message);
          }
        }, error => {
          this.blockUI.stop();
          console.log(error);
          this.alertService.errorAlert(`Error en la solicitud para crear la orden de compra: ${error.error}`);
        }, () => {
          this.blockUI.stop();
        });
      }
    });
  }

  // Resetea la intefaz usado por el boton de borrar campos
  resetGUI(): void {
    if (this.lines.length > 0 && !this.hasBeenSend) {
      swal({
        type: 'warning',
        title: 'No se ha guardado el documento',
        text: '¿ Desea limpiar los campos ?',
        showCancelButton: true,
        confirmButtonColor: '#049F0C',
        cancelButtonColor: '#ff0000',
        confirmButtonText: 'Sí',
        cancelButtonText: 'No'
      }).then(next => {
        console.log(Object.keys(next));
        if (!(Object.keys(next)[0] === 'dismiss')) {
          this.blockUI.start();
          this.InitVariables();
          this.blockUI.stop();
        }
      });
    }
    else {
      this.blockUI.start();
      this.InitVariables();
      this.blockUI.stop();
    }
  }


  // Incializa las variables a un estado por defecto
  InitVariables() {
    this.lines = [];
    this.barcodeList = [];
    this.CRCTotal = 0.0;
    this.Quantity = 1;
    this.SubTotal = 0.0;
    this.totalLines = 0.0;
    this.totalLinesWithDiscount = 0.0;
    this.DiscountTotal = 0.0;
    this.TaxesTotal = 0.0;
    this.USTotal = 0.0;
    this.CRCTotal = 0.0;
    this.COMPANY = this.storage.getCompanyConfiguration();

    this.TO_FIXED_PRICE = `1.${this.COMPANY.DecimalAmountPrice}-${this.COMPANY.DecimalAmountPrice}`;
    this.TO_FIXED_TOTALLINE = `1.${this.COMPANY.DecimalAmountTotalLine}-${this.COMPANY.DecimalAmountTotalLine}`;
    this.TO_FIXED_TOTALDOCUMENT = `1.${this.COMPANY.DecimalAmountTotalDocument}-${this.COMPANY.DecimalAmountTotalDocument}`;
    this.itemsService.UpdateLines([]);
    this.supplierModel = null;
    this.itemsService.hasLines = false;
    this.lockedButtton = false;
    this.hasBeenSend = false;
    this.ItemInfo.reset();
  }
  // Abre la modal de los codigos de barra
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  // Verifica si el usuario tiene permisos para acceder a la pagina
  checkPermits() {
    this.sPerm.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        data.perms.forEach(Perm => {
          if (Perm.Name === 'V_Inventory_Master') {
            this.permisos = Perm.Active;
          }
        });
      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }

  setWarehouseInfo() {
    let session = this.storage.getSession(navigator.onLine);
    if (session) {
      session = JSON.parse(session);

      this.whCode = session.WhCode;
      this.whName = session.WhName;
    }
  }
}

