import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService, PermsService } from './index';
import { Globals } from '../globals';
import { StorageService } from './storage.service';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private commonService: CommonService,
        private storageService: StorageService,
        private globalService: Globals,
        private router: Router,
        private authenticationService: AuthenticationService,
        private pService: PermsService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        const currentUser = this.authenticationService.currentUserValue || this.authenticationService.currentOfflineUserValue;
        const ROUTE_ = route.url[0];
        let permUser;
        if (!ROUTE_) {
            this.router.navigate(['/home'], { queryParams: { returnUrl: state.url } });
            permUser = false;
            return permUser;
        }

        if (!currentUser) { permUser = false; return permUser; }

        const PATH = ROUTE_.path;

        let viewName = '';

        let hasPermission = false;

        switch (PATH) {
            case 'offline': {
                viewName = 'V_RptMng';
                break;
            }
            case 'invonc': {
                viewName = 'V_NC';
                break;
            }
            case 'so': {
                viewName = 'V_SO';
                break;
            }
            case 'perms': {
                viewName = 'V_Perm';
                break;
            }
            case 'quotation': {
                viewName = 'V_Quo';
                break;
            }
            case 'items': {
                viewName = 'V_Inven';
                break;
            }
            case 'print': {
                viewName = 'V_Print';
                break;
            }
            case 'printIncPayCopy': {
                viewName = 'V_Print';
                break;
            }
            case 'users': {
                viewName = 'V_User';
                break;
            }
            case 'companies': {
                viewName = 'V_Comp';
                break;
            }
            case 'branch-office': {
                viewName = 'V_Comp';
                break;
            }
            case 'series': {
                viewName = 'V_Ser';
                break;
            }
            case 'params': {
                viewName = 'V_Param';
                break;
            }
            case 'incomingPayment': {
                viewName = 'V_Ipay';
                break;
            }
            case 'cancelPay': {
                viewName = 'V_Cpay';
                break;
            }
            case 'invo': {
                viewName = 'V_Inv';
                break;
            }
            case 'store': {
                viewName = 'V_Store';
                break;
            }
            case 'inventory': {
                viewName = 'V_Inf';
                break;
            }
            case 'AssignsUsers': {
                viewName = 'V_Assign';
                break;
            }
            case 'numbering': {
                viewName = 'V_FE';
                break;
            }
            case 'terminals': {
                viewName = 'V_Ter';
                break;
            }
            case 'purchaseorder': {
                viewName = 'V_POrd';
                break;
            }
            case 'merch': {
                viewName = 'V_Merch';
                break;
            }
            case 'quotation': {
                viewName = 'V_Coti';
                break;
            }
            case 'invonc': {
                viewName = 'V_NC';
                break;
            }
            case 'invoiceSupplier': {
                viewName = 'V_VInv';
                break;
            }
            case 'businessPartner': {
                viewName = 'V_BP';
                break;
            }
            case 'outgoingPayment': {
                viewName = 'V_MPay';
                break;
            }
            case 'balance': {
                viewName = 'V_Checkout';
                break;
            }
            case 'terminalBalance': {
                viewName = 'V_PCheckout';
                break;
            }
            case 'invonc': {
                viewName = 'M_Config';
                break;
            }
            case 'invonc': {
                viewName = 'M_SN';
                break;
            }
            case 'invonc': {
                viewName = 'M_Inv';
                break;
            }
            case 'invonc': {
                viewName = 'M_Sales';
                break;
            }
            case 'invonc': {
                viewName = 'M_Docs';
                break;
            }
            case 'invonc': {
                viewName = 'V_Comp';
                break;
            }
            case 'invonc': {
                viewName = 'V_Rpt';
                break;
            }
            case 'manage-reports': {
                viewName = 'V_RptMng';
                break;
            }
            case 'SOinvoice': {
                viewName = 'V_PDesk';
                break;
            }
            case 'invonc': {
                viewName = 'V_Daily';
                break;
            }
            case 'cashflow': {
                viewName = 'V_Cashflow';
                break;
            }
            case 'invonc': {
                viewName = 'P_ForgiveInterest';
                break;
            }
            case 'SOandSQ': {
                viewName = 'V_QuoSO';
                break;
            }
            case 'branch-offices': {
                viewName = 'V_Suc';
                break;
            }
            case 'oinv': {
                viewName = 'V_Oinv';
                break;
            }
        }
        return new Observable<boolean>(obs => {
            this.pService.getPerms(currentUser.userId).subscribe((data: any) => {
                data.perms.forEach(x => {
                    if (x.Name === viewName) {
                        hasPermission = x.Active;
                    }
                });

                if (hasPermission) {
                    obs.next(true);
                } else {
                    this.commonService.hasDocument.next(``);
                    this.storageService.SaveBreadCrum(``);
                    this.storageService.SaveDocEntry(-1);
                    this.router.navigate(['/home']);

                }
            });
        });
        // Flujo anterior
        // this.globalService.permList.forEach(x => {
        //     if (x.Name === viewName) {
        //         hasPermission = x.Active;
        //     }
        // });

        // if (hasPermission) {
        //     return true;
        // }


        // this.commonService.hasDocument.next(``);
        // this.storageService.SaveBreadCrum(``);
        // this.storageService.SaveDocEntry(-1);

        // this.router.navigate(['/home']);


    }
}
