import { Component, OnInit, OnDestroy, ɵConsole } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable, Subject, merge, Subscription } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
// Electron renderer service
// MODELOS

// SERVICIOS
import {
  BusinessPartnerService,
  StoreService,
  DocumentService,
  ParamsService,
  PaymentService,
  SerieService,
  AccountService,
  CardService, BankService,
  SalesManService,
  ExRateService,
  AuthenticationService,
  PermsService,
  AlertService,
  ReportsService,
  StorageService,
  CommonService
} from 'src/app/services';
const printJS = require("print-js");
import { Router } from '@angular/router';
import { ReportType } from 'src/app/enum/enum';
import { BranchOfficeModel2 } from 'src/app/models/branch-office';
@Component({
  selector: 'app-ap-payment',
  templateUrl: './ap-payment.component.html',
  styleUrls: ['./ap-payment.component.scss'],
  providers: [DecimalPipe, CurrencyPipe]
})
export class ApPaymentComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  branchOffice: BranchOfficeModel2;
  fecha: Date; // fecha actual

  searchForm: FormGroup; // formulario para la busqueda de pagos
  incomingPaymentForm: FormGroup; // formulario para los pagos
  cashForm: FormGroup; // formulario para el efectivo
  creditCardForm: FormGroup; // formulario para las tarjetas de credito
  transferForm: FormGroup; // formulario para la tranferencia
  checkForm: FormGroup; // formulario para el cheque

  serieList: any[] = []; // lista de las sedes
  bpList: any[] = []; // lista de clientes
  bpCodeList: string[] = []; // lista de los codigos de clientes
  bpNameList: string[] = []; // lista de los nombres de clientes
  bpCodeNameList: string[] = []; // lista de los codigo con nombres de clientes
  incomingPaymentList: any[] = []; // lista con los pagos
  currencyList: any[] = []; // lista de tipos de cambio
  currency: string; // moneda selecionada al buscar los anticipos
  totalCOL: number; // monto total en colones
  totalUSD: number; // monto total en dolares
  currencyChange: number; // monto del tipo de cambio
  accountList: any[] = []; // lista de cuentas
  cardsList: any[] = []; // lista de tarjetas
  banksList: any[] = []; // lista de bancos
  salesManList: any[] = []; // lista de vendedores
  currencyPayment: string; // moneda selecionada al buscar los anticipos
  attemptsWhileBilling: number = 0;
  isBilling: boolean;
  lastInvoice: number;
  btnVisibleBool: boolean;
  correctInvoice: boolean = false;  //dice si el pago se realizo correctamente
  facturando: boolean = false;

  // modal de pagos
  closeResult: string;
  modalPay: any; // instancia de la modal de pagos

  TotalG: number; // monto total del pago
  ReceivedG: number; // monto total recibido del pago
  DifferenceG: number; // monto total de la diferencia, o salto restante

  TotalCash: number; // monto total del pago en efectivo
  TotalCards: number; // monto total del pago en tarjetas
  TotalTransfer: number; // monto total del pago en transferencia
  TotalCheck: number; // monto total del pago en cheque

  V_CreditCards: any[] = []; // lista de pagos registrados con tarjetas de credito
  CardName: string; // nombre de la tarjeta seleccionada para el pago con tarjeta
  V_Checks: any[] = []; // lista de pagos registrados con cheques

  isPagoCuenta: boolean;

  unamePattern = '^\d{2}\/\d{2}$';
  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  permisos: boolean = true;

  viewParamTitles: any[] = []; // llena la lista con los titulos de las paginas parametrizados
  title: string; // titulo de la vista
  TotalCol: FormControl = new FormControl();
  TotalUSD: FormControl = new FormControl();

  constructor(private fb: FormBuilder,
    private businessPartnerService: BusinessPartnerService,
    private paramsService: ParamsService,
    private sPerm: PermsService,
    private authenticationService: AuthenticationService,
    private paymentService: PaymentService,
    private serieService: SerieService,
    private modalService: NgbModal,
    private accountService: AccountService,
    private cardService: CardService,
    private bankService: BankService,
    private salesManService: SalesManService,
    private exRateService: ExRateService,
    private router: Router,
    private reportsService: ReportsService,
    private alertService: AlertService,
    private dp: DecimalPipe,
    private storage: StorageService,
    private commonService: CommonService) {
    // obtiene al anno para el footer
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.fecha = new Date();
  }
  // Limpiar Campos
  CreateNew() {
    this.totalCOL = 0;
    this.totalUSD = 0;
    this.currencyChange = 0;
    this.isPagoCuenta = false;
    this.searchForm = this.fb.group({
      supplier: ['', Validators.required],
      sede: ['', Validators.required],
      currency: ['', Validators.required]
    });

    this.incomingPaymentForm = this.fb.group({
      salesMan: ['', Validators.required],
      comment: ['', Validators.required],
      pagocuenta: [this.isPagoCuenta],
      pagocuentavalue: [0],
      reference: ['']
    });

    this.cashForm = this.fb.group({
      AccountCash: ['', Validators.required],
      TotalCash: [0, Validators.required]
    });

    this.creditCardForm = this.fb.group({
      CreditCard: ["", Validators.required],
      CardNum: [""], // , [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      OwnerIdNum: ["", Validators.required],
      CardValid: [""], //, [Validators.required, Validators.minLength(3), Validators.maxLength(6)]
      CreditSum: [0, Validators.required],
      VoucherNum: ["", Validators.required],
    });
    this.transferForm = this.fb.group({
      AccountTransf: ['', Validators.required],
      DateTrans: ['', Validators.required],
      Ref: [''],
      TransSum: [0, Validators.required]
    });
    this.checkForm = this.fb.group({
      AccountCheck: ['', Validators.required],
      BankNames: ['', Validators.required],
      DueDate: ['', Validators.required],
      CountryCod: [{ value: 'CR', disabled: true }, Validators.required],
      AcctNum: ['', Validators.required],
      CheckNum: ['', Validators.required],
      CheckSum: [0, Validators.required]
    });

    this.V_CreditCards.length = 0;
    //this.getExchangeRate();
    // this.getSuppliers();
    //this.GetCurrencyType();
    //this.GetParamsViewList();
    //this.getSedes();
    //this.getSalesMan();
    //this.getAccount();
    //this.getCards();
    // this.getAccountsBank();
    this.btnVisibleBool = true;
    this.correctInvoice = false;
    this.isBilling = false;
    this.attemptsWhileBilling = 0;
    this.incomingPaymentList.length = 0;
    this.creditCardForm.reset(true);
  }

  ngOnInit() {
    this.checkPermits();
    this.branchOffice = this.storage.getBranchOffice();
    this.totalCOL = 0;
    this.totalUSD = 0;
    this.currencyChange = 0;
    this.isPagoCuenta = false;

    this.searchForm = this.fb.group({
      supplier: ['', Validators.required],
      sede: ['', Validators.required],
      currency: ['', Validators.required]
    });

    this.incomingPaymentForm = this.fb.group({
      salesMan: ['', Validators.required],
      comment: ['', Validators.required],
      pagocuenta: [this.isPagoCuenta],
      pagocuentavalue: [0],
      reference: ['']
    });

    this.cashForm = this.fb.group({
      AccountCash: ['', Validators.required],
      TotalCash: [0, Validators.required]
    });

    this.creditCardForm = this.fb.group({
      CreditCard: [""],
      CardNum: ["", [Validators.required]],
      OwnerIdNum: ["", Validators.required],
      CardValid: [""],
      CreditSum: [0, Validators.required],
      VoucherNum: ["", Validators.required],
    });
    this.transferForm = this.fb.group({
      AccountTransf: ['', Validators.required],
      DateTrans: ['', Validators.required],
      Ref: [''],
      TransSum: [0, Validators.required]
    });
    this.checkForm = this.fb.group({
      AccountCheck: ['', Validators.required],
      BankNames: ['', Validators.required],
      DueDate: ['', Validators.required],
      CountryCod: [{ value: 'CR', disabled: true }, Validators.required],
      AcctNum: ['', Validators.required],
      CheckNum: ['', Validators.required],
      CheckSum: [0, Validators.required]
    });

    this.creditCardForm.patchValue({ VoucherNum: '0' });
    this.creditCardForm.patchValue({ CardNum: '9999' });
    this.creditCardForm.patchValue({ CardValid: '01/99' });

    this.getExchangeRate();
    this.getSuppliers();
    this.GetCurrencyType();
    this.GetParamsViewList();
    this.getSedes();
    this.getSalesMan();
    this.getAccount();
    this.getCards();
    this.getAccountsBank();
    this.btnVisibleBool = true;
    this.correctInvoice = false;

  }

  // chequea que se tengan los permisos para acceder a la pagina
  checkPermits() {
    this.sPerm.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        let permListtable: any = data.perms;
        data.perms.forEach(Perm => {
          if (Perm.Name === "V_ApPayment") {
            this.permisos = Perm.Active;
          }
        });

      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }

  // funcion para obtener el tipo de cambio segun la compañía seleccionada
  getExchangeRate() {
    this.blockUI.start('Obteniendo Tipo de Cambio, Espere Por Favor...'); // Start blocking
    this.exRateService.getExchangeRate()
      .subscribe((data: any) => {
        if (data.result) {
          this.currencyChange = data.exRate;
          this.getSuppliers();

          this.blockUI.stop(); // Stop blocking
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert('Error al obtener tipo de cambio - ' + data.errorInfo.Message);
        }
      }, (error) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorAlert('Error al obtener tipo de cambio - ' + error);
      });
  }

  // funcion para obtener una lista de clientes segun la compañía seleccionada
  getSuppliers() {
    this.blockUI.start('Obteniendo Proveedores, Espere Por Favor...'); // Start blocking
    this.businessPartnerService.GetSuppliers()
      .subscribe((data: any) => {
        if (data.result) {
          this.bpList.length = 0;
          this.bpCodeList.length = 0;
          this.bpNameList.length = 0;
          this.bpCodeNameList.length = 0;
          this.bpList = data.BPS;
          for (let item of data.BPS) {
            this.bpCodeList.push(item.CardCode);
            this.bpNameList.push(item.CardName);
            this.bpCodeNameList.push(`${item.CardCode} - ${item.CardName}`);
          }
          this.blockUI.stop(); // Stop blocking

        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert('Error al obtener la lista de clientes - ' + data.errorInfo.Message);
        }
      }, (error) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para obtener una lista de sedes (stores) segun la compañía seleccionada
  getSedes() {
    this.blockUI.start('Obteniendo Sedes, Espere Por Favor...'); // Start blocking
    this.serieService.getSeries()
      .subscribe((data: any) => {
        if (data.result) {
          this.serieList = data.Series;
          this.searchForm.patchValue({ sede: data.Series[0].Serie });
          this.blockUI.stop(); // Stop blocking
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert('Error al obtener las sedes - ' + data.errorInfo.Message);
        }

      }, (error) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para obtener una lista de vendedores segun la compañía seleccionada
  getSalesMan() {
    this.blockUI.start('Obteniendo Vendedores, Espere Por Favor...'); // Start blocking
    this.salesManService.getSalesMan()
      .subscribe((data: any) => {
        if (data.result) {
          this.salesManList = data.salesManList;
          this.incomingPaymentForm.patchValue({ salesMan: data.salesManList[0].SlpCode });
          this.blockUI.stop(); // Stop blocking
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert('Error al obtener los vendedores - ' + data.errorInfo.Message);
        }
      }, (error) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para obtener una lista de cuentas segun la compañía seleccionada
  getAccount() {
    this.blockUI.start('Obteniendo Cuentas, Espere Por Favor...'); // Start blocking
    this.accountService.getAccount()
      .subscribe((data: any) => {
        if (data.result) {
          this.accountList = data.accountList;
          this.cashForm.patchValue({ AccountCash: data.accountList[0].Account });
          this.transferForm.patchValue({ AccountTransf: data.accountList[0].Account });
          this.checkForm.patchValue({ AccountCheck: data.accountList[0].Account });

        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert('Error al obtener las cuentas - ' + data.errorInfo.Message);
        }

      }, (error) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para obtener una lista de tarjetas segun la compañía seleccionada
  getCards() {
    this.blockUI.start('Obteniendo Tarjetas, Espere Por Favor...'); // Start blocking
    this.cardService.getCards()
      .subscribe((data: any) => {
        if (data.result) {
          this.cardsList = data.cardsList;
          this.creditCardForm.patchValue({ CreditCard: data.cardsList[0].CreditCard });
          this.CardName = data.cardsList[0].CardName;
          this.blockUI.stop(); // Stop blocking
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert('Error al obtener las tarjetas - ' + data.errorInfo.Message);
        }
      }, (error) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para obtener una lista de bancos segun la compañía seleccionada
  getAccountsBank() {
    this.blockUI.start('Obteniendo Bancos, Espere Por Favor...'); // Start blocking
    this.bankService.getAccountsBank()
      .subscribe((data: any) => {
        if (data.result) {
          this.banksList = data.banksList;
          this.checkForm.patchValue({ BankNames: data.banksList[0].BankCode });
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert('Error al obtener cuentas de bancos - ' + data.errorInfo.Message);
        }
        // Stop blocking
      }, (error) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para el typeahead
  searchBP = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.bpCodeNameList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  // funcion de obtener las Facturas
  getInvoiceList() {
    if (!this.isPagoCuenta) {
      // stop here if form is invalid
      if (this.searchForm.invalid) {
        this.alertService.infoAlert('Debe haber seleccionado un Proveedor, sede, moneda.');
        //this.alertService.infoInfoAlert('Debe haber seleccionado un Proveedor, sede, moneda');
        return;
      }
      this.incomingPaymentList.length = 0;
      this.currency = this.searchForm.value.currency;
      this.blockUI.start('Obteniendo Facturas, Espere Por Favor...'); // Start blocking
      this.paymentService.getPayApInvoices(this.searchForm)
        .subscribe((data: any) => {
          if (data.result) {
            this.incomingPaymentList = data.InvoicesList;
          } else {
            this.alertService.errorInfoAlert('Error al obtener lista de Facturas- ' + data.errorInfo.Message);
          }
          this.blockUI.stop(); // Stop blocking
        }, (error) => {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
        });

    } else {
      this.alertService.infoAlert('Para la nueva búsqueda, desmarque pago a cuenta.');
    }
  }

  // funcion para limpiar la tabla si es un pago a cuenta
  activePagocuenta() {
    if (!this.incomingPaymentForm.get('pagocuenta').value) {
      this.isPagoCuenta = true;
      this.incomingPaymentList.length = 0;
    } else {
      this.isPagoCuenta = false;
      this.incomingPaymentForm.get('pagocuentavalue').setValue(0);
    }
    this.totalCOL = 0;
    this.totalUSD = 0;
  }

  // funcion para colocar los totales en pago a cuenta segun la moneda seleccionada
  setPagoCuenta() {
    this.totalCOL = 0;
    this.totalUSD = 0;
    const value = this.searchForm.get('currency').value;
    if (value === 'COL') {
      this.totalCOL = parseFloat(this.incomingPaymentForm.get('pagocuentavalue').value);
      this.totalUSD = this.totalCOL / this.currencyChange;
    } else {
      this.totalUSD = parseFloat(this.incomingPaymentForm.get('pagocuentavalue').value);
      this.totalCOL = this.totalUSD * this.currencyChange;
    }
  }

  changeCurr() {
    if (this.incomingPaymentForm.get('pagocuenta').value) {
      if (this.searchForm.get('currency').value === 'USD') {
        this.totalUSD = this.incomingPaymentForm.get('pagocuentavalue').value;
        this.totalCOL = this.totalUSD * this.currencyChange;
      } else {
        this.totalCOL = this.incomingPaymentForm.get('pagocuentavalue').value;
        this.totalUSD = this.totalCOL / this.currencyChange;
      }
    }
  }
  // funcion para calcular el total a pagar en dolares y colones
  // recibe como parametro el monto del pago de la linea y el index
  setTotals() {
    this.totalCOL = 0;
    this.totalUSD = 0;
    this.incomingPaymentList.forEach(element => {
      if (element.Selected && this.currency === 'COL') {
        // si la moneda es colones y esta marcado
        this.totalCOL += parseFloat(element.Pago);
      } else if (element.Selected && this.currency === 'USD') {
        // si la moneda es dolares y esta marcado
        this.totalUSD += parseFloat(element.Pago);
      }
    });
    if (this.currency === 'COL') {
      this.totalUSD = this.totalCOL / this.currencyChange;
    } else {
      this.totalCOL = this.totalUSD * this.currencyChange;
    }
  }
  // asignar a pago el total de la factura
  changeSelectedPay(index: number, Selected: boolean, Pago: number, DocBalance: number) {
    if (Selected) {
      this.incomingPaymentList[index].Pago = DocBalance;//this.dp.transform(DocBalance, '1.2-2');

    } else {
      this.incomingPaymentList[index].Pago = 0;
    }
    this.setTotals();
  }

  // funcion para abrir la modal de pagos
  CheckPayBalance(content) {
    if (!this.incomingPaymentForm.invalid && !this.searchForm.invalid) {
      if ((this.incomingPaymentList.length > 0 || this.isPagoCuenta) && (this.totalCOL > 0 && this.totalUSD > 0)) {
        this.DifferenceG = this.totalCOL;
        this.resetModalData();
        this.TotalG = this.totalCOL;
        this.setTotalAmount();
        this.creditCardForm.patchValue({ VoucherNum: '0' });
        this.creditCardForm.patchValue({ CardNum: '9999' });
        this.creditCardForm.patchValue({ CardValid: '01/99' });

        this.commonService.setPaymentDefaultValues(
          this.branchOffice,
          this.cashForm,
          this.creditCardForm,
          this.transferForm,
          this.checkForm);

        this.modalPay = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'Modal-Xl' });

        this.modalPay.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else {
        if (!this.isPagoCuenta && (this.totalCOL === 0 && this.totalUSD === 0)) {
          this.alertService.infoAlert('No posee facturas para realizar el pago');
        } else if (this.isPagoCuenta && (this.totalCOL === 0 && this.totalUSD === 0)) {
          this.alertService.infoAlert('No tiene un total a pagar en pago a cuenta');
        }
      }
    } else {
      this.alertService.infoAlert('Debe haber seleccionado un Proveedor, sede, moneda, vendedor y comentario.');
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // funcion para cerrar la modal de pagos
  close() {
    this.modalPay.close();
  }
  // Cierra la modal
  closeModal() {
    this.modalPay.dismissAll('');
  }
  printInvoice() {
    this.printARInvoice(this.lastInvoice);
  }
  // funcion para imprimir la factura
  printARInvoice(DocEntry: number) {
    this.blockUI.start('Generando la impresión...');
    this.reportsService.printReport(DocEntry, ReportType.ArInvoice)
      .subscribe((data: any) => {
        this.blockUI.stop();
        printJS({
          printable: data,
          type: 'pdf',
          base64: true
        })
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para armar el objeto del pago y crearlo en SAP
  CreatePay() {
    if (this.isBilling) {
      this.attemptsWhileBilling++;
      return;
    }
    if (this.ReceivedG > 0) {
      const PaymentLines = [];
      let dT = 0;
      const newIncomingPaymentList = this.incomingPaymentList.filter(book => book.Selected === true);
      newIncomingPaymentList.forEach(element => {
        if (element.type === 'Factura') { dT = 1; }
        if (element.type === 'Anticipo') { dT = 2; }
        PaymentLines.push({
          'InvTotal': element.DocTotal,
          'PayTotal': element.Pago,
          'DocNum': element.DocNum,
          'DocEntry': element.DocEntry,
          'InstId': element.InstlmntID,
          'Currency': element.DocCur,
          'Balance': element.DocBalance,
          'DocType': dT,
          'PayBalanceChk': false // Verifica el pago vs saldo
        });
      });
      let total = 0;
      if (this.searchForm.value.currency === 'COL') {
        total = this.totalCOL;
      } else {
        total = this.totalUSD;
      }
      const Payments = {
        CardCode: this.searchForm.value.supplier.split(' - ')[0],
        CashAccount: this.cashForm.value.AccountCash,
        CashSum: this.cashForm.value.TotalCash,
        CashCurr: this.currencyPayment,
        Comments: this.incomingPaymentForm.value.comment,
        accountPayment: this.incomingPaymentForm.value.pagocuenta,
        Currency: this.currencyPayment,
        DocRate: this.currencyChange,
        Reference: this.incomingPaymentForm.value.reference,
        SlpCode: '-1',
        Total: total,
        V_Checks: this.V_Checks,
        V_CreditCards: this.V_CreditCards,
        V_PaymentLines: PaymentLines,
        transfSum: this.transferForm.value.TransSum,
        trfsrAcct: this.transferForm.value.AccountTransf,
        trfsrCurr: this.currencyPayment,
        trfsrDate: this.transferForm.value.DateTrans,
        trfsrNum: this.transferForm.value.Ref,
      };
      this.blockUI.start('Generando el Pago, Espere Por Favor...'); // Start blocking
      this.isBilling = true;
      this.facturando = true;
      console.log(Payments);
      this.paymentService.createPayApInvoices(Payments)
        .subscribe((data: any) => {
          this.blockUI.stop(); // Stop blocking
          if (data.result) {
            this.close();
            this.alertService.successAlertHtml(`Pago efectuado correctamente: DocEntry: ${data.DocEntry}, DocNum: ${data.DocNum}
          <br/><br/>
          <h3>Intentos de duplicación: <label class="text-danger">${this.attemptsWhileBilling}</label></h3>`);
            let modalOption: NgbModalOptions = {};
            modalOption.backdrop = 'static';
            modalOption.keyboard = false;
            modalOption.ariaLabelledBy = 'modal-basic-title';
            modalOption.size = 'lg';
            this.printARInvoice(data.DocEntry);
            this.lastInvoice = data.DocEntry;
            this.btnVisibleBool = false;
            this.correctInvoice = true;
            this.facturando = false;
            this.router.navigate(['/outgoingPayment']);
          } else {
            this.alertService.errorAlert(`Error al crear el pago: Codigo: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
            this.attemptsWhileBilling = 0;
            this.isBilling = false;
            this.facturando = false;

          }
          this.blockUI.stop(); // Stop blocking
        }, (error) => {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
          this.isBilling = false;
          this.attemptsWhileBilling = 0;
          this.facturando = false;
        });
    } else {
      this.alertService.infoAlert('El monto a recibido debe ser igual al total a cancelar.');
    }
  }

  // funcion para resetear los valores de la modal
  resetModalData() {
    this.currencyPayment = this.currencyList[1].Id;
    this.TotalCash = 0;
    this.TotalCards = 0;
    this.TotalCheck = 0;
    this.TotalTransfer = 0;
    this.TotalG = 0;
    this.ReceivedG = 0;
    this.DifferenceG = 0;
    this.V_Checks.length = 0;
    this.V_CreditCards.length = 0;
    this.cashForm.patchValue({ AccountCash: this.accountList[0].Account });
    this.cashForm.patchValue({ TotalCash: 0 });
    this.creditCardForm.patchValue({ CreditCard: this.cardsList[0].CreditCard });
    this.creditCardForm.patchValue({ CardNum: '' });
    this.creditCardForm.patchValue({ OwnerIdNum: '' });
    this.creditCardForm.patchValue({ CardValid: '' });
    this.creditCardForm.patchValue({ CreditSum: 0 });
    this.creditCardForm.patchValue({ VoucherNum: '' });
    this.transferForm.patchValue({ AccountTransf: this.accountList[0].Account });
    this.transferForm.patchValue({ DateTrans: '' });
    this.transferForm.patchValue({ Ref: '' });
    this.transferForm.patchValue({ TransSum: 0 });
    this.checkForm.patchValue({ AccountCheck: this.accountList[0].Account });
    this.checkForm.patchValue({ BankNames: this.banksList[0].BankCode });
    this.checkForm.patchValue({ DueDate: '' });
    this.checkForm.patchValue({ CountryCod: 'CR' });
    this.checkForm.patchValue({ AcctNum: '' });
    this.checkForm.patchValue({ CheckNum: '' });
    this.checkForm.patchValue({ CheckSum: 0 });
  }

  // funcion para colocar el monto a pagar en efectivo
  addAmountCash() {
    this.TotalCash = parseFloat(this.cashForm.value.TotalCash);
    if (this.TotalCash > 0) {
      if ((this.TotalCash + this.TotalCards + this.TotalTransfer + this.TotalCheck) <= this.TotalG) {
        this.setTotalAmount();
      } else {
        this.alertService.infoAlert('El monto ingresado en efectivo supera el total de la factura.');
        this.TotalCash = 0;
        this.setTotalAmount();
        this.cashForm.patchValue({ TotalCash: 0 });
      }
    } else {
      this.TotalCash = 0;
      this.setTotalAmount();
      this.alertService.infoAlert('El monto ingresado en Total es incorrecto.');
    }
  }

  // funcion para colocar el monto a pagar en tranferencia
  addAmountTransfer() {
    this.TotalTransfer = parseFloat(this.transferForm.value.TransSum);
    if (this.TotalTransfer > 0) {
      if ((this.TotalCash + this.TotalCards + this.TotalTransfer + this.TotalCheck) <= this.TotalG) {
        this.setTotalAmount();
      } else {
        this.alertService.infoAlert('El monto ingresado en transferencia supera el total de la factura.');
        this.TotalTransfer = 0;
        this.setTotalAmount();
      }
    } else {
      this.TotalTransfer = 0;
      this.setTotalAmount();
      this.alertService.infoAlert('El monto ingresado en Total es incorrecto.');
    }
  }

  // funcion para colocar el monto a pagar en la tarjeta de credito
  addAmounCreditCard() {
    let totalcard = 0;
    if (this.creditCardForm.valid) {
      if (parseFloat(this.creditCardForm.value.CreditSum) > 0) {
        if (this.V_CreditCards.length > 0) {
          this.V_CreditCards.forEach(vcc => {
            totalcard += parseFloat(vcc.CreditSum);
          });
          totalcard += parseFloat(this.creditCardForm.value.CreditSum);
        } else {
          totalcard = parseFloat(this.creditCardForm.value.CreditSum);
        }
        if (totalcard > 0) {
          if ((this.TotalCash + totalcard + this.TotalTransfer + this.TotalCheck) <= this.TotalG) {
            this.V_CreditCards.push({
              CardValid: this.creditCardForm.value.CardValid,
              CrCardNum: this.creditCardForm.value.CardNum,
              CrTypeCode: 1,
              CreditCard: this.creditCardForm.value.CreditCard,
              CreditSum: this.creditCardForm.value.CreditSum,
              Curr: this.currencyPayment,
              FormatCode: this.CardName,
              OwnerIdNum: this.creditCardForm.value.OwnerIdNum,
              VoucherNum: this.creditCardForm.value.VoucherNum,
            });
            this.TotalCards = totalcard;
            this.setTotalAmount();
          } else {
            this.alertService.infoAlert('El monto ingresado en la tarjeta de crédito supera el total de la factura.');
            this.TotalCards = 0;
            this.setTotalAmount();
          }
        } else {
          this.alertService.infoAlert('El monto ingresado en Total es incorrecto.');
        }
      } else {
        this.alertService.infoAlert('El monto total debe ser superior a cero.');
      }
    } else {
      this.alertService.infoAlert('Campos inválidos');
    }
  }

  // funcion para colocar el monto a pagar en cheques
  addAmountCheck() {
    let totalcheck = 0;

    if (this.checkForm.valid) {
      if (parseFloat(this.checkForm.value.CheckSum) > 0) {
        if (this.V_Checks.length > 0) {
          this.V_Checks.forEach(vcc => {
            totalcheck += parseFloat(vcc.CheckSum);
          });
          totalcheck += parseFloat(this.checkForm.value.CheckSum);
        } else {
          totalcheck = parseFloat(this.checkForm.value.CheckSum);
        }
        if (totalcheck > 0) {
          if ((this.TotalCash + this.TotalCards + this.TotalTransfer + totalcheck) <= this.TotalG) {
            this.V_Checks.push({
              AcctNum: this.checkForm.value.AcctNum,
              BankCode: this.checkForm.value.BankNames,
              CheckAct: this.checkForm.value.AccountCheck,
              CheckNum: this.checkForm.value.CheckNum,
              CheckSum: this.checkForm.value.CheckSum,
              CountryCod: this.checkForm.controls.CountryCod.value,
              Curr: this.currencyPayment,
              DueDate: this.checkForm.value.DueDate,
            });
            this.TotalCheck = totalcheck;
            this.setTotalAmount();
          } else {
            this.alertService.infoAlert('El monto ingresado en el cheque supera el total de la factura.');
            this.TotalCards = 0;
            this.setTotalAmount();
          }
        } else {
          this.alertService.infoAlert('El monto ingresado en Total es incorrecto.');
        }
      } else {
        this.alertService.infoAlert('El monto total debe ser superior a cero.');
      }
    } else {
      this.alertService.infoAlert('Campos inválidos');
    }
  }

  // funcion para calcular el total de recibido y de diferencia para el pago
  setTotalAmount() {
    this.ReceivedG = Number((this.TotalCash + this.TotalCards + this.TotalCheck + this.TotalTransfer).toFixed(2));
    this.DifferenceG = Number((this.TotalG - this.ReceivedG).toFixed(2));

  }

  getSelectedOptionText(event: Event) {
    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    this.CardName = selectedOptions[selectedIndex].text;
  }



  // funcion para eliminar el item de la lista de tarjetas de credito
  // recibe como parametro el item a eliminar
  removeCreditCard(index) {
    this.TotalCards -= this.V_CreditCards[index].CreditSum;
    this.V_CreditCards.splice(index, 1);
    this.setTotalAmount();
  }

  // funcion para eliminar el item de la lista de cheques
  // recibe como parametro el index del item a eliminar
  removeCheck(index) {
    this.TotalCheck -= this.V_Checks[index].CheckSum;
    this.V_Checks.splice(index, 1);
    this.setTotalAmount();
  }

  // funcion para cambiar el tipo de moneda en la modal de pagos
  changeCurrency() {
    if (this.currencyPayment === 'COL') {
      this.TotalG = this.totalCOL;
      this.ReceivedG = this.totalCOL;
    } else {
      this.TotalG = this.totalUSD;
      this.ReceivedG = this.totalUSD;
    }
    this.ReceivedG = 0;
    this.setTotalAmount();


  }

  // llena los campos de la tabla de items con los campos parametriados
  GetParamsViewList() {
    this.blockUI.update('Obteniendo parametros');
    this.paramsService.getParasmView()
      .subscribe((data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.viewParamTitles = data.Params.filter(param => {
            return param.type === 6;
          });
          this.ChargeParamstoView();
        } else {
          this.alertService.errorAlert('Error al cargar los parámetros de la pagina - ' + data.errorInfo.Message);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // Carga los datos parametrizados en las variables
  ChargeParamstoView() {
    // parametrizacion del titulo
    let obj = this.viewParamTitles.filter(param => {
      return param.Name === 'T_incomingPayment';
    });
    this.title = obj[0].Text;
  }

  // Obtiene los tipos de monedas ya sea Col o Usd desde una vista SQL
  GetCurrencyType() {
    this.blockUI.update('Obteniendo las monedas.');
    this.paramsService.GetCurrencyType()
      .subscribe((data: any) => {
        //this.blockUI.stop();
        if (data.length > 0) {
          this.currencyList = data;
          this.GetParamsViewList()
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert('Error al cargar las monedas - ' + data.errorInfo.Message);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  onCtrlBCash() {
    this.cashForm.patchValue({ TotalCash: this.DifferenceG });
    this.addAmountCash();
  }
  onCtrlBTarjeta() {
    this.creditCardForm.patchValue({ CreditSum: this.DifferenceG });
  }
  onCtrlBTransferencia() {
    this.transferForm.patchValue({ TransSum: this.DifferenceG });
    this.addAmountTransfer();
  }


}
