import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BusinessPartnerService, AlertService, AccountService, CardService, SeriesService, BranchOfficeService } from 'src/app/services';
import { first } from 'rxjs/operators';
import { IBusinessPartner, Series } from 'src/app/models';
import { BranchOfficeModel2 } from 'src/app/models/branch-office';
import { DocType } from 'src/app/enum/enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-branch-offices',
  templateUrl: './branch-offices.component.html',
  styleUrls: ['./branch-offices.component.scss']
})
export class BranchOfficesComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  branchOfficess: BranchOfficeModel2[];

  constructor(
    private alertService: AlertService,
    private branchOfficeService: BranchOfficeService,
    private router: Router) { }

  ngOnInit() {
    this.getBranchOfficess();
  }

  onClickEditBranchOffice(id: number) {
    this.router.navigate(['branch-office', id]);
  }

  getBranchOfficess() {
    this.branchOfficess = [];
    this.blockUI.start('Procesando...');

    this.branchOfficeService.GetBrachOfficess().pipe(first()).subscribe(response => {
      this.blockUI.stop();

      if (response.result) this.branchOfficess = response.BranchOfficess;
      else this.alertService.errorAlert(response.errorInfo.Message);
    }), err => {
      this.blockUI.stop();
      this.alertService.errorAlert(err);
    };
  }

}
