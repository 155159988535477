import { Component, OnInit, Input, HostListener } from '@angular/core';
import { LayoutService } from '../../../shared/services/layout.service';
import {AuthenticationService, RptManagerService, StorageService} from '../../../services/index';
import { Globals } from 'src/app/globals';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss']
})
export class LeftPanelComponent implements OnInit {
  asidebarHeight: number;
  @Input() navLayout: string;
  @Input() defaultNavbar: string;
  @Input() toggleNavbar: string;
  @Input() toggleStatus: boolean;
  @Input() navbarEffect: string;
  @Input() deviceType: string;
  @Input() headerColorTheme: string;
  @Input() navbarColorTheme: string;
  @Input() activeNavColorTheme: string;
  @Input() currentUser: string;
  @Input() currentOfflineUser: string;
  title: any;
  menuList: any;
  selected: any;

  constructor(
    private authenticationService: AuthenticationService,
    private layoutService: LayoutService,
    public globals: Globals,
    private rptManagerService: RptManagerService,
    private router: Router,
    private storage: StorageService) {
      this.authenticationService.currentOfflineUser.subscribe(x => { this.currentOfflineUser = x });
  }

  isActive(item) {
    return this.selected === item;
  }

  onItemSelect(item) {
    this.selected = this.selected === item.name;

    if (item.action) this[item.action](item);
    else if (item.url) this.router.navigateByUrl(item.url);
  }

  onSubItemSelect(item) {
    event.stopPropagation();
    this.selected = this.selected === item.name;
  }

  @HostListener('window:resize', ['$event'])
  onResizeHeight(event: any) {
    this.asidebarHeight = window.innerHeight;
  }

  ngOnInit() {
    this.layoutService.setAsidebarHeightCast.subscribe(setSidebarHeight => this.asidebarHeight = setSidebarHeight);
    this.title = 'Navigation';
    this.menuList = [
      {
        name: 'Home',
        icon: 'fas fa-home',
        url: '/home',
      },
      {
        perm: 'M_Config',
        name: 'Administración',
        icon: 'fas fa-cogs',
        subMenu: [
          {
            perm: 'V_Comp',
            name: 'Compañías',
            icon: 'far fa-building',
            url: '/companies',
          },
          {
            perm: 'V_Comp',
            name: 'Sucursales',
            icon: 'fas fa-map-marked-alt',
            url: '/branch-offices',
          },
          {
            perm: 'V_Store',
            name: 'Almacenes',
            icon: 'fas fa-store-alt',
            url: '/store',
          },
          {
            perm: 'V_User',
            name: 'Usuarios',
            icon: 'fas fa-users',
            url: '/users',
          },
          {
            perm: 'V_Assign',
            name: 'Asignaciones de usuario',
            icon: 'fas fa-users-cog',
            url: '/AssignsUsers',
          },
          {
            perm: 'V_Ser',
            name: 'Series de numeración',
            icon: 'fas fa-list-ol',
            url: '/series',
          },
          {
            perm: 'V_Perm',
            name: 'Permisos',
            icon: 'fas fa-key',
            url: '/perms',
          },
          {
            perm: 'V_Param',
            name: 'Parametrizaciones',
            icon: 'fas fa-database',
            url: '/params',
          },
          {
            perm: 'V_FE',
            name: 'Numeraciones de FE',
            icon: 'fas fa-database',
            url: '/numbering',
          },
          {
            perm: 'V_Ter',
            name: 'Terminales',
            icon: 'fas fa-hdd',
            url: '/terminals',
          },
          {
            perm: 'V_RptMng',
            name: 'Administrar reportes',
            icon: 'fas fa-file-pdf',
            url: '/manage-reports',
          },
          {
            perm: 'V_RptMng',
            name: 'Offline',
            icon: 'fas fa-file-pdf',
            url: '/offline',
          },
        ]
      },
      {
        perm: 'M_SN',
        name: 'Socio de negocio',
        icon: 'fas fa-user-lock',
        subMenu: [
          {
            perm: 'V_BP',
            name: 'Datos maestros socios',
            icon: 'fas fa-users',
            url: '/businessPartner',
          },
        ]
      },
      {
        perm: 'M_Inv',
        name: 'Inventario',
        icon: 'fas fa-list',
        subMenu: [
          {
            perm: 'V_Inven',
            name: 'Datos maestros de inventario',
            icon: 'fas fa-receipt',
            url: '/items',
          },
          {
            perm: 'V_POrd',
            name: 'Ordenes de compra',
            icon: 'fas fa-sticky-note',
            url: '/purchaseorder',
          },
          {
            perm: 'V_Merch',
            name: 'Entrada de mercadería',
            icon: 'fas fa-tasks',
            url: '/goodReceipt'
          },
          {
            perm: 'V_Merch',
            name: 'Devolución de mercadería',
            icon: 'fas fa-table',
            url: '/returnGood'
          },
          {
            perm: 'V_Inven',
            name: 'Entrada de inventario',
            icon: 'fas fa-indent',
            url: '/goodsReceiptInv'
          },
          {
            perm: 'V_Inven',
            name: 'Salida de inventario',
            icon: 'fas fa-outdent',
            url: '/goodsIssueInv'
          }
        ]
      },
      {
        perm: 'M_Sales',
        name: 'Ventas',
        icon: 'fas fa-credit-card',
        subMenu: [
          {
            perm: 'V_Ipay',
            name: 'Pagos recibidos',
            icon: 'fas fa-check-circle',
            url: '/incomingPayment',
          },
          {
            perm: 'V_MPay',
            name: 'Pagos efectuados',
            icon: 'fas fa-check-circle',
            url: '/outgoingPayment',
          },
          {
            perm: 'V_Cpay',
            name: 'Anular pagos',
            icon: 'fas fa-times-circle',
            url: '/cancelPay',
          }
        ]
      },
      {
        perm: 'M_Docs',
        name: 'Documentos',
        icon: 'fas fa-file-alt',
        subMenu: [
          {
            perm: 'V_QuoSO',
            name: 'Proformas y órdenes',
            icon: 'fas fa-file-contract',
            url: '/SOandSQ',
          },
          {
            perm: 'V_Inven',
            name: 'Inventario',
            icon: 'fas fa-warehouse',
            url: '/inventory',
          },
          {
            perm: 'V_SO',
            name: 'Orden de venta',
            icon: 'fas fa-shopping-cart',
            url: '/so',
          },
          {
            perm: 'V_Quo',
            name: 'Proforma',
            icon: 'fas fa-cart-arrow-down',
            url: '/quotation',
          },
          {
            perm: 'V_Inf',
            name: 'Consulta de artículos',
            icon: 'fas fa-tag',
            url: '/info',
          },
          {
            perm: 'V_Inv',
            name: 'Facturación',
            icon: 'fas fa-receipt',
            url: '/invo',
          },
          {
            perm: 'V_NC',
            name: 'Nota de crédito',
            icon: 'fas fa-table',
            url: '/invonc',
          },
          {
            perm: 'V_VInv',
            name: 'Facturación proveedor',
            icon: 'fas fa-receipt',
            url: '/invoiceSupplier',
          },
          {
            perm: 'V_Print',
            name: 'Reimpresión documentos',
            icon: 'fas fa-print',
            url: '/print',
          },
          {
            perm: 'V_Print',
            name: 'Reimpresión pagos',
            icon: 'fas fa-print',
            url: '/printIncPayCopy',
          },
        ]
      },
      {
        name: 'Cajas',
        perm: 'V_PDesk',
        icon: 'fas fa-cash-register',
        subMenu: [
          {
            perm: 'V_Daily',
            name: 'Diario',
            icon: 'fas fa-file-contract',
            url: '/SOinvoice',
          },
          {
            perm: 'V_Cashflow',
            name: 'Movimientos de dinero',
            icon: 'fas fa-exchange-alt',
            url: '/cashflow',
          },
          {
            perm: 'V_Checkout',
            name: 'Cierres de caja',
            icon: 'fas fa-cash-register',
            url: '/balance',
          },
          {
            perm: 'V_PCheckout',
            name: 'Precierre, cierre tarjetas',
            icon: 'fas fa-hand-holding-usd',
            url: '/terminalBalance',
          },
        ]
      },
      {
        perm: 'V_Rpt',
        name: 'Reportes',
        icon: 'fas fa-file-pdf',
        action: 'GetApplicationReports'
      }
    ];
  }

  GetApplicationReports(option: any) {
    if (!option.subMenu) {
      option.subMenu = [];
      let KeyReportManager = this.storage.GetKeysReportManager();
      this.rptManagerService
        .GetReports(+KeyReportManager.CompanyKey , +KeyReportManager.AppKey)
        .pipe(first())
        .subscribe(
          (response) => {
            if (response.Result) {
              console.log(response);
              response.Reports.forEach((x) => {
                option.subMenu.push({
                  name: x.DisplayName,
                  icon: 'fas fa-print',
                  url: `report/${x.Id}`
                });
              });
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

}
