import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SaleOrderComponent } from './components/sale-order/sale-order.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { StoreComponent } from './components/stores/store/store.component';
import { StoreConfComponent } from './components/stores/store-conf/store-conf.component';
import { CompaniesComponent } from './components/company/companies/companies.component';
import { CreateUpdateComponent } from './components/company/create-update/create-update.component';
import { UserComponent } from './components/users/user/user.component';
import { UserConfComponent } from './components/users/user-conf/user-conf.component';
import { UserViewComponent } from './components/users/user-view/user-view.component';
import { SeriesComponent } from './components/series/serie/series.component';
import { SerieConfComponent } from './components/series/serie-conf/serie-conf.component';
import { PermsByUserComponent } from './components/perms-by-user/perms-by-user.component';
import { ParamsComponent } from './components/params/params.component';
import { IncomingPaymentComponent } from './components/IncomingPayment/incoming-payment/incoming-payment.component';
import { CancelPaymentsComponent } from './components/cancel-payments/cancel-payments.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { QuotationComponent } from './components/quotation/quotation.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { ApInvoiceComponent } from './components/ap-invoice/ap-invoice.component';
import { InfoItemComponent } from './components/info-item/info-item.component'
import { AuthGuard } from '../services/auth.guard';
import { InvcopyComponent } from './components/invcopy/invcopy.component';
import { VerificationEmailComponent } from './components/verification-email/verification-email.component';
import { RecoverEmailComponent } from './components/recover-email/recover-email.component';
import { BalanceComponent } from './components/balance/balance.component';
import { NumberingComponentComponent } from './components/numbering-component/numbering-component.component'
import { NumberingConfComponent } from './components/numbering-conf/numbering-conf.component'
import { BusinessPartnerComponent } from './components/business-partner/business-partner.component';
import { ItemComponent } from './components/item/item.component';
import { InventoryEntryComponent } from './components/inventory-entry/inventory-entry.component';
import { InvoiceNcComponent } from './components/invoice-nc/invoice-nc.component';
import { InventoryReturnComponent } from './components/inventory-return/inventory-return.component';
import { ApPaymentComponent } from './components/ap-payment/ap-payment.component';
import { StockGoodreceiptComponent } from './components/stock-goodreceipt/stock-goodreceipt.component';
import { StockGoodissueComponent } from './components/stock-goodissue/stock-goodissue.component';
import { PurchaseOrderComponent } from './components/purchase-order/purchase-order.component';
import { PurchaseOrderUpdateComponent } from './components/purchase-order-update/purchase-order-update.component';
import { TerminalsBalanceComponent } from './components/terminals-balance/terminals-balance.component';
import { TerminalsComponent } from './components/terminals/terminals.component';
import { UsersComponent } from './components/users/users/users.component';
import { SOandSQComponent } from './components/soand-sq/soand-sq.component';
import { CashierComponent } from './components/cashier/cashier.component';
import { CashflowComponent } from './components/cashflow/cashflow.component';
import { PrintReportComponent } from './components/print-report/print-report.component';
import { ReportsComponent } from './components/reports/reports.component';
import { BranchOfficesComponent } from './components/branch-office/branch-offices/branch-offices.component';
import { BranchOfficeComponent } from './components/branch-office/branch-office/branch-office.component';
import { OfflineComponent } from './components/offline/offline.component';
import { PrintIncPayCopyComponent } from './components/print-inc-pay-copy/print-inc-pay-copy.component';
import { ResolveService } from '../services/resolve.service';
import { InfoInvoiceComponent } from './components/info-invoice/info-invoice.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent},
  { path: 'store', component: StoreComponent, canActivate: [AuthGuard] },
  { path: 'storeConf/:storeId', component: StoreConfComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'companies', component: CompaniesComponent, canActivate: [AuthGuard] },
  { path: 'companyCRUD/:companyId', component: CreateUpdateComponent },
  { path: 'AssignsUsers', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'userConf/:userId', component: UserConfComponent },
  { path: 'userView/:userId', component: UserViewComponent },
  { path: 'series', component: SeriesComponent, canActivate: [AuthGuard] },
  { path: 'serieConf/:SerieId', component: SerieConfComponent },
  { path: 'perms', component: PermsByUserComponent, canActivate: [AuthGuard] },
  { path: 'params', component: ParamsComponent, canActivate: [AuthGuard] },
  { path: 'incomingPayment', component: IncomingPaymentComponent, canActivate: [AuthGuard] },
  { path: 'cancelPay', component: CancelPaymentsComponent, canActivate: [AuthGuard] },
  { path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard] },
  { path: 'so', component: SaleOrderComponent, canActivate: [AuthGuard] },
  { path: 'quotation', component: QuotationComponent, canActivate: [AuthGuard] },
  { path: 'quotation', component: QuotationComponent, canActivate: [AuthGuard] },
  { path: 'SOinvoice', component: CashierComponent, canActivate: [AuthGuard] },
  { path: 'oinv', component: InfoInvoiceComponent, canActivate: [AuthGuard] },
  { path: 'SOandSQ', 
  component: SOandSQComponent, 
  canActivate: [AuthGuard],        
  // resolve:{resolvedData: ResolveService}
},
  { path: 'invo', component: InvoiceComponent, canActivate: [AuthGuard] },
  { path: 'invonc', component: InvoiceNcComponent, canActivate: [AuthGuard] },
  { path: 'invoiceSupplier', component: ApInvoiceComponent, canActivate: [AuthGuard] },
  { path: 'info', component: InfoItemComponent, canActivate: [AuthGuard] },
  { path: 'balance', component: BalanceComponent, canActivate: [AuthGuard] },
  { path: 'print', component: InvcopyComponent, canActivate: [AuthGuard] },
  { path: 'printIncPayCopy', component: PrintIncPayCopyComponent, canActivate: [AuthGuard] },
  { path: 'verificationEmail/:token/:verificationType', component: VerificationEmailComponent, canActivate: [AuthGuard] },
  { path: 'recoverEmail/:email', component: RecoverEmailComponent, canActivate: [AuthGuard] },
  { path: 'numbering', component: NumberingComponentComponent, canActivate: [AuthGuard] },
  { path: 'offline', component: OfflineComponent, canActivate: [AuthGuard] },
  { path: 'numConf/:Id', component: NumberingConfComponent, canActivate: [AuthGuard] },
  { path: 'businessPartner', component: BusinessPartnerComponent, canActivate: [AuthGuard] },
  { path: 'items', component: ItemComponent, canActivate: [AuthGuard] },
  { path: 'returnGood', component: InventoryReturnComponent, canActivate: [AuthGuard] },
  { path: 'goodReceipt', component: InventoryEntryComponent, canActivate: [AuthGuard] },
  { path: 'outgoingPayment', component: ApPaymentComponent, canActivate: [AuthGuard] },
  { path: 'goodsReceiptInv', component: StockGoodreceiptComponent, canActivate: [AuthGuard] },
  { path: 'goodsIssueInv', component: StockGoodissueComponent, canActivate: [AuthGuard] },
  { path: 'purchaseorder', component: PurchaseOrderComponent, canActivate: [AuthGuard] },
  { path: 'purchaseOrderUpdate', component: PurchaseOrderUpdateComponent, canActivate: [AuthGuard] },
  { path: 'terminalBalance', component: TerminalsBalanceComponent, canActivate: [AuthGuard] },
  { path: 'terminals', component: TerminalsComponent, canActivate: [AuthGuard] },
  { path: 'cashflow', component: CashflowComponent, canActivate: [AuthGuard] },
  { path: 'report/:reportId', component: PrintReportComponent },
  { path: 'manage-reports', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: 'branch-offices', component: BranchOfficesComponent , canActivate: [AuthGuard]},
  { path: 'branch-office/:id', component: BranchOfficeComponent },
  { path: '**', pathMatch: 'full', redirectTo: '' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { } 