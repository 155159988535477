import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { IBPAutorizedContacts } from "src/app/models/i-business-partner";
import { AlertService, BusinessPartnerService } from "src/app/services";

@Component({
  selector: "app-bpautorized-contacts",
  templateUrl: "./bpautorized-contacts.component.html",
  styleUrls: ["./bpautorized-contacts.component.scss"],
})
export class BPAutorizedContactsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @Input() public  cardCode: string;

  contacts: IBPAutorizedContacts[];

  constructor(
    private alertService: AlertService,
    private modalService: NgbModal,
    private bpService: BusinessPartnerService
  ) {}

  ngOnInit() {
    this.LoadContacts();
  }

  LoadContacts(): void {
    this.contacts = [];

    this.blockUI.start("Consultado información, espere por favor...");

    this.bpService.GetAutorizeContacts(this.cardCode).subscribe(
      (response) => {
        this.blockUI.stop();
        if (response.result) {        
          this.contacts = response.Data;
        
        } else {
          this.alertService.errorAlert(
            `Ocurrió un error consultado los contactos autorizados, causado por: ${response.errorInfo.Message} `
          );
        }
      },
      (err) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          "Ocurrió un error consultado los contactos autorizados, causado por: " +
            err
        );
      }
    );
  }

  CloseModal(){
    this.modalService.dismissAll();
  }


}
