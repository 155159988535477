import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  HostListener,
  DoCheck,
  Renderer2,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import {forkJoin, Observable, of, Subject, Subscription} from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  first,
  finalize,
  switchMap,
  catchError
} from "rxjs/operators";
import { DatePipe, DecimalPipe, formatDate } from "@angular/common";
import { NgbModal, ModalDismissReasons, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
const printJS = require("print-js");

// MODELOS
import {
  Params,
  IdentificationType,
  UserAssigns,
  IDocumentLine,
  PayTermsEnum,
  ISaleOrder,
  ISaleQuotation,
  IBusinessPartner,
  IPaymentMethod,
  IOverdueBalance,
  IPrinter,
  IDocument,
} from "./../../../models/index";
import {
  ReportType,
  BaseTypeLines,
  SOAndSQActions,
  ITEM_GROUP_NAME,
  KEY_CODE,
  PRINTERS_PER_REPORT,
  BoDocumentTypes,
} from "../../../enum/enum";
// RUTAS

// COMPONENTES

// SERVICIOS
import { ActivatedRoute, Router } from "@angular/router";
import {
  CompanyService,
  UserService,
  ItemService,
  BusinessPartnerService,
  DocumentService,
  TaxService,
  PermsService,
  AuthenticationService,
  ParamsService,
  ReportsService,
  AlertService,
  SalesManService,
  ExRateService,
  JsonDataService,
  StorageService,
  CommonService,
} from "../../../services/index";

// Electron renderer service
import { SOAndSQService } from "src/app/services/soand-sq.service";
import { app } from "electron";
import { constants } from "os";
import swal from "sweetalert2";
import { SLDocument } from "src/app/models/i-sl-document-model";
import {IUdf, UDFModel2} from "src/app/models/i-udf";
import { BPAutorizedContactsComponent } from "src/app/components/custom-modals/bpautorized-contacts/bpautorized-contacts.component";
import {IBusinessPartnerModel, ISaleMan} from "../../../models/i-business-partner";
// import { ConsoleReporter } from 'jasmine';

// PIPES

@Component({
  selector: "app-sale-order",
  templateUrl: "./sale-order.component.html",
  styleUrls: ["./sale-order.component.scss"],
  providers: [DecimalPipe, DatePipe],
})
export class SaleOrderComponent
  implements OnInit, OnDestroy, DoCheck, AfterViewInit {
  //VARBOX
  isDocumentSubmitted: boolean;
  previusAmount: number;
  isPriceEditable: boolean;
  eventManager: Subscription;
  uniqueInvCode: string;
  isSalesManSelected: boolean;
  documentType: string;
  isRequiredEmail: boolean;
  hasIdentification: boolean;
  isOnPrint: boolean;
  priceList: number;
  //subscriptions: Subscription;
  currentRequest: number;
  requestToAwait: number;
  $requestViewer: Subject<number>;
  InvalidCardName: boolean;
  returnedDocEntry: number;
  returnedDocNum: number;
  titleSaleOrder: string;
  overdueBalances: IOverdueBalance[];
  paymentMethods: IPaymentMethod[];
  isEditingPrice: boolean;
  isCashCustomer: boolean;
  typeArrow: boolean; // Hace toggle al darle click
  saleDocumentModel: ISaleOrder;
  hasLines: boolean;
  //#region Cambios Jorge EMA Aromas
  whCode: string;
  whName: string;
  //#endregion
  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual

  public model: any;
  @BlockUI() blockUI: NgBlockUI;

  soForm: FormGroup; // formulario para la orden de venta
  feForm: FormGroup;
  // totalForm: FormGroup; // formulario para el total de la orden de venta
  submitted = false; // variable para reconcer si se envio una peticion o no
  maxDiscuont: any;
  setCurr: string; // tipo de moneda escogida en simbolo
  currencyList: any[] = []; // lista de tipos de cambio
  allCurrencyList: any[] = []; // lista de todos los tipos de cambio existentes en la aplicacion
  itemsList: IDocumentLine[] = []; // lista de items
  itemsTypeaheadList: string[] = []; // lista de la concatenacion del Código con el nombre del item
  itemsCodeList: string[] = []; // lista de los Códigos de items
  itemsNameList: string[] = []; // lista de los nombres de items
  itemsStock: string[] = [];
  itemsInventory: string[] = [];
  PayTermsList: any[] = []; // lista de los terminos de pago
  PriceList: any[] = []; // lista de las listas de precio
  businessPartners: IBusinessPartnerModel[] = []; // lista de clientes
  companiesList: any[] = []; // lista de las compannias
  slpTypeaheadList: ISaleMan[] = [];

  userAssignsList: UserAssigns[] = [];
  defaultSlpCode: number = -1; // para saber cual es el salesperson por default de cada persona

  conta: number; // variable contador para colocar un 'id' a la lista de items
  total: number; // variable para almacenar el total de la factura
  totalUSD: number;
  tax: number; // variable para almacenar el total de impuestos
  discount: number; // variable para almacenar el total de descuento
  totalWithoutTax: number; // variable para almacenar el total sin impuesto
  DailyExRate: number;
  taxesList: any[] = []; // lista de los impuestos

  viewParamList: any[] = []; // llena la lista con los componentes parametrizados
  viewParamListHeader: any[] = []; // llena la lista con los componentes parametrizados
  viewParamListTotales: any[] = []; // llena la lista con los componentes parametrizados
  viewParamTitles: any[] = []; // llena la lista con los titulos de las paginas parametrizados

  closeResult: string; // variable para saber porque se cerro la modal
  modalReference: any; // instancia de la modal de terminal y sucursal

  WHAvailableItemList: any[] = []; // lista de los items disponibles por almacen
  indexAvaItem: number; // indice de la lista del item seleccionado para la disponibilidad
  itemCode: string; // variable para almacenar el Código del ite seleccionado para buscar la disponibilidad
  seriesList: any[] = []; // lista de las series de un item po almacen
  lastSO: number; // guarda el id de la ultima orden de venta generada, se usa para imprimir

  public expandedIndex: number; // variable para manejar el collapse de los items y reconocer sobre cual se va a trabajar

  btnVisibleBool: boolean; // activa y desactiva los botones de envio y nuevo
  isOnCreation: boolean;
  correctSO: boolean = false; //dice si el pago se realizo correctamente
  // --------Campos Parametrizados cabezera
  lbCardCode: Params = new Params(); // etiqueta para CardCode
  txtCardCode: Params = new Params(); // campo para CardCode
  lbCardName: Params = new Params(); // etiqueta para CardName
  txtCardName: Params = new Params(); // campo para CardName
  lbCurrency: Params = new Params(); // etiqueta para Moneda
  txtCurrency: Params = new Params(); // campo para Moneda
  txtPayTerms: Params = new Params(); // campo para Terminos de pagos
  lbPayTerms: Params = new Params(); // etiqueta para Terminos de pagos
  txtPriceList: Params = new Params(); // campo para Listas de precios
  lbPriceList: Params = new Params(); // etiqueta para Listas de precios
  txtComments: Params = new Params(); // campo para el comentario
  lbComments: Params = new Params(); // etiqueta para el comentario
  lbSLP: Params = new Params(); // etiqueta para el vendedor
  txtSLP: Params = new Params(); // campo para el vendedor
  DEFAULT_BUSINESS_PARTNER: string;
  onSubmitButtonPrefix: string;
  // -----------------------------
  // -------- Campos para metrizables de totales
  lbTotalExe: Params = new Params(); // etiqueta para Total sin impuestos
  txtTotalExe: Params = new Params(); // campo para Total sin impuestos
  lbDiscount: Params = new Params(); // etiqueta para descuentos
  txtDiscount: Params = new Params(); // campo para descuentos
  lbTaxes: Params = new Params(); // etiqueta para Impuestos
  txtTaxes: Params = new Params(); // campo para Impuestos
  lbTotal: Params = new Params(); // etiqueta para Total
  txtTotal: Params = new Params(); // campo para Total
  // -----------------------------
  TotalCol: FormControl = new FormControl();
  TotalUSD: FormControl = new FormControl();
  Cant: FormControl = new FormControl();
  ItemInfo: FormControl = new FormControl();
  title: string; // titulo de la vista
  defaultGroupNum: any;
  defaultListNum: any;
  identificationTypeList: any[] = [];
  provinceList: any[] = []; // provincias
  cantonList: any[] = []; // lista de cantones
  districtList: any[] = []; // lista de distritos
  neighborhoodList: any[] = []; // lista de barrios

  provinceId: string; // identificador de la provincia
  cantonId: string; // identificador del canton
  districtId: string; // identificador del distrito
  neighborhoodId: string; // identificador del barrio
  @ViewChild("name") nameField: ElementRef;
  permisos: boolean = true;

  pesoBolsa: number = 0.02;
  priceEditable: boolean = false;
  maxWeightTo0: number = 0.01;

  autForm: FormGroup;
  canAuthorize: boolean;
  typeArrow2: boolean;

  MapWidth: any;
  tableLength: number;

  isOnSubmit: boolean = false;

  baseLines: IDocumentLine[];
  UdfsInDocument: UDFModel2[];
  dateTimeExitUI = "";

  isOrderFromLink: boolean;

  BpDocuemntPendingPay: boolean; //Realizar orden o enviar orden a autorizacion
  isPermAutorizeSale: boolean; //Permiso usuarios autorizar ventas a clientes con documentos vencidos
  isAutBtn: boolean = false; //Actualizar orden de envio aut
  showBtnBPContacs: boolean;
  IndexMaxUpdate: number;
  IndexMinDocument: number;
  DOC_ENTRY = -1;

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (
      event.ctrlKey &&
      !event.altKey &&
      !event.shiftKey &&
      event.keyCode === KEY_CODE.Enter
    ) {
      this.isOnSubmit = true;
      this.onSubmit();
    }
    if (
      event.ctrlKey &&
      !event.altKey &&
      !event.shiftKey &&
      event.keyCode === KEY_CODE.F6
    ) {
      this.redirectToQuotation();
    }
    if (
      event.ctrlKey &&
      !event.altKey &&
      !event.shiftKey &&
      event.keyCode === KEY_CODE.F8
    ) {
      this.redirectToSOandSQ();
    }
  }

  constructor(
    private storageService: StorageService,
    private renderer: Renderer2,
    private commonService: CommonService,
    private fb: FormBuilder,
    private itemService: ItemService,
    private bpService: BusinessPartnerService,
    private decimalPipe: DecimalPipe,
    private documentService: DocumentService,
    private sPerm: PermsService,
    private companyService: CompanyService,
    private taxService: TaxService,
    private uService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    //private _electronService: ElectronService,
    private paramsService: ParamsService,
    private reportsService: ReportsService,
    private router: Router,
    private exrate: ExRateService,
    private alertService: AlertService,
    private smService: SalesManService,
    private jsonDataService: JsonDataService,
    private storage: StorageService,
    private soAdnSQService: SOAndSQService,
    private el: ElementRef,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) {
    this.$requestViewer = new Subject();
    this.currentUserSubscription =
      this.authenticationService.currentUser.subscribe((user) => {
        this.currentUser = user;
      });
    this.expandedIndex = -1;
  }

  ngDoCheck(): void {
    const parent: HTMLElement = document.getElementById(
      "scrollable-dropdown-menu"
    );
    if (parent && parent.children.length > 0) {
      const child = parent.children[1];
      if (child) {
        // for (let x in child.children) {
        //   const BUTTON = <HTMLButtonElement>child.children[x];
        //   if (BUTTON && BUTTON.children) {
        //     if (BUTTON.children.length < 2) {
        //       const ITEM_CODE = BUTTON.children[0].getAttribute('ng-reflect-result').split(' COD.')[0];
        //       const INDEX = this.itemsCodeList.findIndex(x => x === ITEM_CODE);
        //       if (this.itemsInventory[INDEX] === 'Y') {
        //         const SPAN = this.renderer.createElement('span');
        //         const STOCK = Math.floor(+this.itemsStock[INDEX]);
        //         const TEXT = this.renderer.createText(` Stock: ${STOCK}`);

        //         this.renderer.appendChild(SPAN, TEXT);
        //         if (STOCK > 0) this.renderer.setStyle(SPAN, 'color', 'green');
        //         else this.renderer.setStyle(SPAN, 'color', 'red');
        //         this.renderer.appendChild(BUTTON, SPAN);
        //       }
        //     }
        //   }
        // }
        this.renderer.setStyle(child, "max-height", " 400px");
        this.renderer.setStyle(child, "overflow-y", "auto");
      }
    }
  }

  expandRow(index: number): void {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  /**
   * Method to load initial data
   * @constructor
   */
  LoadInitialData() {
    this.showBtnBPContacs = false;
    this.lastSO = 0;
    this.isDocumentSubmitted = false;

    this.IndexMinDocument = 0 ;
    this.IndexMaxUpdate = 0;
    this.isRequiredEmail = false;

    //Customer
    this.businessPartners = [];

    //Items
    this.itemsTypeaheadList.length = 0;
    this.itemsList = [];
    this.itemsCodeList.length = 0;
    this.itemsNameList.length = 0;
    this.itemsStock = [];
    this.itemsInventory = [];
    this.Cant.setValue(1);


    //User
    this.userAssignsList = [];

    //Vendedores
    this.slpTypeaheadList = [];

    //Companias
    this.companiesList = [];

    //Currency
    this.currencyList = [];
    this.allCurrencyList = [];

    //PayTerms
    this.PayTermsList = [];

    //GetOrder
    this.conta = 0;
    this.total = 0;
    //Taxes
    this.taxesList = [];
    this.ItemInfo.setValue("");



    this.UdfsInDocument = [];
    this.UdfsInDocument = this.GetDocumentUDFs();
    this.isSalesManSelected = true;
    this.hasIdentification = false;
    this.isOnPrint = false;
    this.currentRequest = 0;
    this.requestToAwait = 2;
    this.InvalidCardName = false;
    this.overdueBalances = [];
    this.isOrderFromLink = false;

    let valueFromUrlParam = +this.route.snapshot.queryParamMap.get("DocEntry");


    if (valueFromUrlParam && valueFromUrlParam > 0) {
      this.storage.SaveDocEntry(valueFromUrlParam);
      localStorage.setItem(
        "SOAndSQAction",
        SOAndSQActions.EditOrder.toString()
      );
      this.isOrderFromLink = this.isAutBtn = true;
    }

    this.router.navigate([], {
      queryParams: {
        'DocEntry': null
      },
      queryParamsHandling: 'merge'
    });

    this.DOC_ENTRY = this.storage.GetDocEntry();

    this.canAuthorize = false;


    this.isOnCreation = true;
    this.typeArrow = false;
    this.typeArrow2 = true;
    this.onSubmitButtonPrefix = "Crear órden de venta";


    /* if (this.DOC_ENTRY > 0) {
       if (this.storage.GetCustomerData()) {
         this.DEFAULT_BUSINESS_PARTNER = JSON.parse(
           this.storage.GetCustomerData()
         ).CardCode;
       }


     } else {
       this.DEFAULT_BUSINESS_PARTNER =
         this.storage.getBranchOffice().CashBusinessPartnerCode;
       this.storage.SaveUIAction(-1);
       this.isOnCreation = true;
     }*/

    this.defaultGroupNum = PayTermsEnum.Contado.toString();
    this.defaultListNum = "-1";
    this.tableLength = 1000;
    this.MapWidth = {};
    this.MapWidth["Id"] = 80;
    this.MapWidth["ItemCode"] = 350;
    this.MapWidth["UnitPrice"] = 100;
    this.MapWidth["Marca"] = 200;
    this.MapWidth["Group"] = 200;
    this.MapWidth["Quantity"] = 40;
    this.MapWidth["SubGroup"] = 100;
    this.MapWidth["ItemName"] = 350;
    this.MapWidth["Discount"] = 80;
    this.MapWidth["TaxRate"] = 80;
    this.MapWidth["TaxCode"] = 50;
    this.MapWidth["WhsCode"] = 60;
    this.MapWidth["WhsName"] = 100;
    this.MapWidth["UPWT"] = 100;
    this.MapWidth["LastDate"] = 80;
    this.MapWidth["LinTot"] = 100;
    this.MapWidth["Serie"] = 100;
    this.MapWidth["Opc"] = 100;

    this.btnVisibleBool = true;
    this.conta = 0;
    this.total = 0;
    this.totalUSD = 0;
    this.tax = 0;
    this.discount = 0;
    this.totalWithoutTax = 0;
    this.DailyExRate = 0;
    this.provinceId = "0";
    this.cantonId = "0";
    this.districtId = "0";
    this.neighborhoodId = "0";
    this.hasLines = false;
    this.isEditingPrice = false;
    this.isCashCustomer = false;
    this.saleDocumentModel = null;

    this.identificationTypeList = IdentificationType;

    this.setWarehouseInfo();
    this.createFEForm();
    this.generateUniqueInvoiceId();
    this.correctSO = false;


    //this.checkPermits();
    //this.GetPaymentMethods();
    // this.getExRate();
    //this.getMaxDiscout();
    //this.getCustomers();
    //this.GetParamsViewList();
    //this.setSalesPerson();
    //this.getItems();
    //this.getCompanies();


    /* if (DOC_ENTRY > 0) {
       this.subscriptions = this.$requestViewer.subscribe((next) => {
         if (next === this.requestToAwait) {
           switch (Number(localStorage.getItem("SOAndSQAction"))) {
             case SOAndSQActions.EditOrder: {
               this.onSubmitButtonPrefix = "Actualizar órden de venta";
               this.soForm.controls["cardName"].disable();
               this.soForm.controls["cardCode"].disable();
               this.GetSaleOrder(DOC_ENTRY);
               break;
             }
             case SOAndSQActions.CopyToOrder: {
               this.getBaseLines(DOC_ENTRY);
               break;
             }
             case SOAndSQActions.DuplicateSaleOrder: {
               this.GetSaleOrder(DOC_ENTRY);
             }
           }
         }
       });
     }*/
  }

  OnLoad(): void {
    this.InitForm();
    this.LoadInitialData();
    this.InitDocument();
  }

  /**
   * Component initialization
   */
  ngOnInit(): void {
    this.eventManager = this.commonService.eventManager.subscribe(
      async (next) => {
        if (next) {

          if (next.ViewName.filter((x) => this.router.url.includes(x)).length > 0) {
            switch (next.FunctionName) {
              case "refreshCustomers":
                this.GetCustomers();
                break;
              case "redirectToSoAndSQ":
                if (!this.isDocumentSubmitted && this.itemsList.length > 0) {
                  let confirmationResult =
                    await this.alertService.ConfirmationAlert(
                      "Confirmación",
                      `No ha guardado el ¿Desea continuar?`,
                      "Continuar"
                    );
                  if (!confirmationResult) return;
                  this.router.navigate(["SOandSQ"]);
                } else {
                  this.router.navigate(["SOandSQ"]);
                }
                break;
            }
          }
        }
      }
    );

    this.OnLoad();
  }

  private InitForm(): void {
    this.soForm = this.fb.group({
      cardCode: ['', Validators.required],
      cardName: [""],
      currency: ["", Validators.required],
      PayTerms: ["0"],
      SlpList: ["", Validators.required],
      BillOverdue: ["1", Validators.required],
      PaymentMethod: ["01", Validators.required],
      Comment: "",
      NumAtCard: "",
      Discount: [0],
    });

    this.autForm = this.fb.group({
      Estado: ["", Validators.required],
      Observacion: [""],
      Autorizador: [""],
    });
  }

  /**
   * Method obtains initial requests
   * @constructor
   * @private
   */
  private InitDocument(): void {

    this.blockUI.start("Procesando, espere por favor...");
    let messageError = '';

    const observables: { [key: string]: Observable<any> } = {
      Customer: this.bpService.GetCustomers(),
      Items: this.itemService.GetItems(),
      Perms: this.sPerm.getPerms(this.currentUser.userId),
      PaymentMethods: this.companyService.GetPaymentMethods(),
      ExchangeRate: this.exrate.getExchangeRate(),
      MaxDiscout: this.documentService.getMaxDiscout(),
      ParamView: this.paramsService.getParasmView(),
      UserList: this.uService.getUserList(),
      SalesMan: this.smService.getSalesMan(),
      Companies: this.companyService.GetCompanies(),
      Currency: this.paramsService.GetCurrencyType(),
      PayTerms: this.itemService.GetPayTerms(),
      Taxes: this.taxService.GetTaxes(),
    };

    const observablesArray: Observable<any>[] = Object.values(observables);

    forkJoin(observablesArray).pipe(
      switchMap(([CustomerResponse, ItemsResponse, PermsResponse, PaymentMethodsResponse,
        ExchangeRateResponse, MaxDiscountResponse, ParamViewResponse, UserListResponse,
        SalesManResponse, CompaniesResponse, CurrencyResponse,
        PayTermsResponse, TaxesResponse]) => {

        //#region Load Data

        //#region Parametrizaciones

        if(ParamViewResponse.result){

          this.viewParamList = ParamViewResponse.Params.filter((param) => {
            // return param.type === 1;
            return param.type === 1 && param.Visibility;
          });

          this.viewParamList.splice(7, 0, {
            CompanyId: 4,
            Descrip: "Precio unitario con impuesto",
            Display: true,
            Id: 144,
            Name: "UPWT",
            Order: 15,
            ParamsId: 11,
            Text: "Precio+Imp",
            ViewsId: 1,
            Visibility: true,
            type: 1,
          });

          this.tableLength = 0;
          for (var i = 0; i < this.viewParamList.length; i++) {
            this.tableLength += this.MapWidth[this.viewParamList[i].Name];
          }
          this.viewParamListHeader = ParamViewResponse.Params.filter((param) => {
            return param.type === 2;
          });
          this.viewParamListTotales = ParamViewResponse.Params.filter((param) => {
            return param.type === 3;
          });
          this.viewParamTitles = ParamViewResponse.Params.filter((param) => {
            return param.type === 6;
          });

          this.ChargeParamstoView();
        }else {
          messageError += `Error: Código: ${ParamViewResponse.errorInfo.Code}, Mensaje: ${ParamViewResponse.errorInfo.Message}`
        }
        // endregion
        //#region Permisos
        if(PermsResponse.result){

          this.isPriceEditable = PermsResponse.perms.find(
            (x) => x.Name === "P_EditPrice"
          ).Active;

          this.isPermAutorizeSale = PermsResponse.perms.find(
            (x) => x.Name === "Perm_Authorize_Sale"
          ).Active;

          PermsResponse.perms.forEach((Perm) => {
            if (Perm.Name === "V_SO") {
              this.permisos = Perm.Active;
            }
          });

        } else {
          messageError += `Error: Código: ${PermsResponse.errorInfo.Code}, Mensaje: ${PermsResponse.errorInfo.Message}`
        }
        // endregion
        //#region Customer
        if(CustomerResponse.result){
          this.businessPartners = CustomerResponse.BPS;

        } else {
          messageError += `Error: Código: ${CustomerResponse.errorInfo.Code}, Mensaje: ${CustomerResponse.errorInfo.Message}`
        }


        // endregion
        //#region Items
        if(ItemsResponse.result){

          this.itemsTypeaheadList = ItemsResponse.ItemList.ItemCompleteName;
          this.itemsCodeList = ItemsResponse.ItemList.ItemCode;
          this.itemsNameList = ItemsResponse.ItemList.ItemName;
          this.itemsStock = ItemsResponse.ItemList.ItemStock;
          this.itemsInventory = ItemsResponse.ItemList.ItemInventoried;
        } else {
          messageError += `Error: Código: ${ItemsResponse.errorInfo.Code}, Mensaje: ${ItemsResponse.errorInfo.Message}`
        }
        // endregion

        if(PaymentMethodsResponse.result){
          this.paymentMethods = PaymentMethodsResponse.PaymentMethods;

        }else {
          messageError += `Error: Código: ${PaymentMethodsResponse.errorInfo.Code}, Mensaje: ${PaymentMethodsResponse.errorInfo.Message}`
        }
        if(ExchangeRateResponse.result){
          this.DailyExRate = ExchangeRateResponse.exRate;

        }else {
          messageError += `Error: Código: ${ExchangeRateResponse.errorInfo.Code}, Mensaje: ${ExchangeRateResponse.errorInfo.Message}`
        }
        if(MaxDiscountResponse.result){
          this.maxDiscuont = MaxDiscountResponse.discount;

        }else {
          messageError += `Error: Código: ${MaxDiscountResponse.errorInfo.Code}, Mensaje: ${MaxDiscountResponse.errorInfo.Message}`
        }
        //#region User

        if(UserListResponse.result){
          this.userAssignsList = UserListResponse.Users;
          this.userAssignsList.forEach((user) => {
            if (this.currentUser.userId.toString() === user.UserId.toString()) {
              this.defaultSlpCode = user.SlpCode;
              this.defaultListNum = user.PriceListDef;
            }
          });
        }else {
          messageError += `Error: Código: ${UserListResponse.errorInfo.Code}, Mensaje: ${UserListResponse.errorInfo.Message}`
        }
        //endregion
        //#region Vendedores

        if(SalesManResponse.result){
          this.slpTypeaheadList = SalesManResponse.salesManList.map(element => ({
            SlpCode: element.SlpCode,
            SlpName: element.SlpName,
            TypeAheadFormatt: `${element.SlpCode} ${element.SlpName}`
          }));
        }else {
          messageError += `Error: Código: ${SalesManResponse.errorInfo.Code}, Mensaje: ${SalesManResponse.errorInfo.Message}`
        }
        //endregion
        //#region companias

        if(CompaniesResponse.result){
          this.companiesList = CompaniesResponse.companiesList;
          this.companiesList.forEach((comp) => {
            this.pesoBolsa = comp.ScaleWeightToSubstract;
            this.priceEditable = comp.IsLinePriceEditable;
            this.maxWeightTo0 = comp.ScaleMaxWeightToTreatAsZero;
          });
        }else {
          messageError += `Error: Código: ${CompaniesResponse.errorInfo.Code}, Mensaje: ${CompaniesResponse.errorInfo.Message}`
        }
        //endregion
        //#region Currency

        if(CurrencyResponse.length > 0){
          this.currencyList = CurrencyResponse;
          this.allCurrencyList = CurrencyResponse;

        }else {
          messageError += `Error: Código: ${CurrencyResponse.errorInfo.Code}, Mensaje: ${CurrencyResponse.errorInfo.Message}`
        }
        //endregion
        //#region PayTerms
        if(PayTermsResponse.result){
          this.PayTermsList = PayTermsResponse.payTermsList;
        }else {
          messageError += `Error: Código: ${PayTermsResponse.errorInfo.Code}, Mensaje: ${PayTermsResponse.errorInfo.Message}`
        }
        //endregion
        //#region Taxes

        if(TaxesResponse.result){
          this.taxesList = TaxesResponse.Taxes;
          this.taxesList.push({
            TaxCode: "EXE",
            TaxRate: "0.00",
          });
        }else {
          messageError += `Error: Código: ${TaxesResponse.errorInfo.Code}, Mensaje: ${TaxesResponse.errorInfo.Message}`
        }

        //endregion
        //#endregion
        this.SetInitialData();
        if (this.DOC_ENTRY > 0) {
          switch (Number(localStorage.getItem("SOAndSQAction"))) {
            case SOAndSQActions.EditOrder: {
              this.isOnCreation = false;
              this.onSubmitButtonPrefix = "Actualizar órden de venta";
              this.soForm.controls["cardName"].disable();
              this.soForm.controls["cardCode"].disable();
              return this.soAdnSQService.GetSaleOrder(this.DOC_ENTRY);
              break;
            }
            case SOAndSQActions.CopyToOrder: {
              return this.soAdnSQService.GetSaleQuotationToCopy(this.DOC_ENTRY);
              break;
            }
            case SOAndSQActions.DuplicateSaleOrder: {
              return this.soAdnSQService.GetSaleOrder(this.DOC_ENTRY);
            }
              break;
            default:  return of(null);
          }
        } else {
          return of(null);
        }
      }),
      switchMap(res =>{
        if (res) {
          if(res.result) {
            this.saleDocumentModel = res.Data;
            this.baseLines = res.Data.DocumentLines;
            this.SetDocument();
            if (Number(localStorage.getItem("SOAndSQAction")) === SOAndSQActions.CopyToOrder) {
              this.SetDocumentBaseLines();
            } else {
              this.SetDocumentLines();
            }
          }else {
            messageError += `Error: Código: ${res.errorInfo.Code}, Mensaje: ${res.errorInfo.Message}`
          }
        }

        if(this.DOC_ENTRY <= 0){
          return this.bpService.CheckIfBPHaveAnyPending(this.DEFAULT_BUSINESS_PARTNER);
        } else{
          return of(null)}
      }),
      finalize(() => {
        this.blockUI.stop();
        if(messageError.length > 0){
          this.alertService.errorAlert(
            `${messageError}`
          );
        }}),
      catchError(err => {
        this.alertService.errorInfoAlert(
          `Error: ${err}`
        );
        return of(null);
      })
    ).subscribe({
      next: res => {
        if(res) {
          if (res.result) {
            this.showBtnBPContacs = false;

            if (res.Data.PayTermGroup != -1) {
              this.showBtnBPContacs = true;
            }
            if (res.Data.PayTermGroup != -1 && res.Data.RequiredAutorization) {
              this.alertService.infoAlert(
                "Tiene documentos pendientes de pago, realizar gestión de cobro."
              );
              this.BpDocuemntPendingPay = res.Data.RequiredAutorization;
            }

            if (
              this.storage.GetUIAction() !== SOAndSQActions.EditOrder &&
              this.BpDocuemntPendingPay &&
              this.soForm.controls.PayTerms.value === "1"
            ) {
              this.onSubmitButtonPrefix = "Enviar órden a autorización";
            }
          } else {
            messageError += `Error: Código: ${res.errorInfo.Code}, Mensaje: ${res.errorInfo.Message}`
          }
        }
        this.getTotals();
      },
      error: err => {
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${err}`
        );
      }
    });
  }

  ngAfterViewInit() { }

  GetPaymentMethods(): void {
    this.blockUI.start("Procesando medios de pago, espere por favor...");
    this.companyService.GetPaymentMethods().subscribe((next) => {
      if (next.result) {
        this.blockUI.stop();
        this.paymentMethods = next.PaymentMethods;
        if (this.saleDocumentModel !== null) {
          this.soForm.patchValue({
            PaymentMethod: this.saleDocumentModel.U_NVT_Medio_Pago,
          });
        }
      } else {
        this.blockUI.stop();
        console.log(next);
      }
    },
      (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      });
  }

  checkPermits() {
    this.blockUI.start("Procesando, espere por favor...");
    this.sPerm.getPerms(this.currentUser.userId).subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.isPriceEditable = data.perms.find(
            (x) => x.Name === "P_EditPrice"
          ).Active;

          this.isPermAutorizeSale = data.perms.find(
            (x) => x.Name === "Perm_Authorize_Sale"
          ).Active;

          data.perms.forEach((Perm) => {
            if (Perm.Name === "V_SO") {
              this.permisos = Perm.Active;
              if (this.permisos) {
                this.nameField.nativeElement.focus();
              }
            }
          });

        } else {
          this.permisos = false;
        }
      },
      (error) => {
        console.log(error);
        this.permisos = false;
        this.blockUI.stop();
      }
    );
  }

  setSalesPerson() {
    this.blockUI.start("Cargando listas de usuarios...");
    this.uService.getUserList().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.userAssignsList = data.Users;
          this.userAssignsList.forEach((user) => {
            if (this.currentUser.userId.toString() === user.UserId.toString()) {
              this.defaultSlpCode = user.SlpCode;
              this.defaultListNum = user.PriceListDef;
              //this.invForm.patchValue({SlpList: user.SlpCode});
            }
          });
          this.GetSalesPersonList();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(
            "Error al cargar la lista de usuarios - " + data.errorInfo.Message
          );
        }
      },
      (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  // obtiene el tipo de cambio
  getExRate() {
    this.blockUI.start("Obteniendo Tipos de Cambio, Espere Por Favor...");
    this.exrate.getExchangeRate().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.DailyExRate = data.exRate;
          this.currentRequest++;
          this.$requestViewer.next(this.currentRequest);
        } else {
          this.alertService.errorAlert(
            `Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
          );
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error getCustomers!!!, Error: ${error.error.Message}`
        );
      }
    );
  }

  createFEForm() {
    this.feForm = this.fb.group({
      IdType: ["", Validators.required],
      Identification: ["", Validators.required],
      Email: [""],
      ObservacionFE: [""],
    });

    this.feForm.patchValue({ IdType: this.identificationTypeList[0].Id });
  }

  redirectToSOSQ(): void {
    this.router.navigate(["SOandSQ"]);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.commonService.hasDocument.next(``);
    this.storage.SaveBreadCrum(``);
    this.currentUserSubscription.unsubscribe();
    this.storage.SaveDocEntry(-1);
    this.storage.SaveCustomerData("{}");
    //if (this.subscriptions) this.subscriptions.unsubscribe();
    this.eventManager.unsubscribe();
    localStorage.removeItem("SOAndSQAction");
  }

  getMaxDiscout() {
    this.blockUI.start("Procesando descuentos, espere por favor...");
    this.documentService.getMaxDiscout().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.currentRequest++;
          this.maxDiscuont = data.discount;
          this.$requestViewer.next(this.currentRequest);
        } else {
          this.alertService.errorAlert(
            `Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
          );
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error getCustomers!!!, Error: ${error.error.Message}`
        );
      }
    );
  }


  /**
   * Function to obtain customers from SAP
   * @constructor
   */
  GetCustomers() {
    this.blockUI.start("Obteniendo Clientes, Espere Por Favor...");

    this.bpService.GetCustomers().subscribe(
      (data: any) => {
        if (data.result) {
          this.blockUI.stop();
          this.businessPartners = [];
          this.businessPartners = [...data.BPS];
          this.GetCurrencyType();
          this.GetPayTerms();

          const MOBJECT = this.saleDocumentModel;
          if (MOBJECT) {
            const CUSTOMER = this.businessPartners.find(
              (x) => x.CardCode === MOBJECT.CardCode
            );
            if (CUSTOMER) {
              this.priceList = CUSTOMER.ListNum;
              this.isCashCustomer = CUSTOMER.QryGroup1 === "Y";
            }
          }
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(
            `Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
          );
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error getCustomers!!!, Error: ${error.error.Message}`
        );
      }
    );
  }
  // funcion para obtener los items desde SAP
  // no recibe parametros
  getItems() {
    this.blockUI.start("Obteniendo Items, Espere Por Favor...");
    this.itemService.GetItems().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {

          this.itemsTypeaheadList.length = 0;
          if (!this.itemsList) this.itemsList = [];
          this.itemsCodeList.length = 0;
          this.itemsNameList.length = 0;
          this.itemsStock = [];
          this.itemsInventory = [];
          this.itemsTypeaheadList = data.ItemList.ItemCompleteName;
          this.itemsCodeList = data.ItemList.ItemCode;
          this.itemsNameList = data.ItemList.ItemName;
          this.itemsStock = data.ItemList.ItemStock;
          this.itemsInventory = data.ItemList.ItemInventoried;
          this.Cant.setValue(1);
        } else {
          this.alertService.errorAlert(
            `Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
          );
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error getItems!!!, Error: ${error.error.Message}`
        );
      }
    );
  }
  // funcion para obtener los impuestos desde SAP
  // no recibe parametros
  getTaxes() {
    this.blockUI.start("Obteniendo Impuestos, Espere Por Favor...");
    this.taxService.GetTaxes().subscribe(
      (data: any) => {
        if (data.result) {
          this.taxesList.length = 0;
          this.taxesList = data.Taxes;
          this.taxesList.push({
            TaxCode: "EXE",
            TaxRate: "0.00",
          });
        } else {
        }
        this.blockUI.stop(); // Stop blocking
      },
      (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(
          `Error getTaxes!!!, Error: ${error.error.Message}`
        );
      }
    );
  }
  // funcion para obtener los clientes desde SAP
  // recibe como parametros el item y el index
  selectedItem(item, idx) {
    if (item.item !== "") {

      this.blockUI.start(
        "Obteniendo Información del Artículo, Espere Por Favor..."
      );
      this.itemService
        .GetItemByItemCode(
          item.item.split(" COD. ")[0],
          this.priceList,
          this.soForm.controls.cardCode.value
        )
        .subscribe(
          (data: any) => {
            if (data.result) {
              this.itemsList[idx].ItemCode = data.Item.ItemCode;
              // this.itemsList[idx].Marca = data.Item.Marca;
              this.itemsList[idx].ItemName = data.Item.ItemName;
              this.itemsList[
                idx
              ].Item = `${data.Item.ItemCode} - ${data.Item.ItemName}`;
              this.itemsList[idx].UnitPrice = data.Item.UnitPrice;
              this.itemsList[idx].U_SugPrice = data.Item.UnitPrice;
              this.itemsList[idx].TaxCode =
                data.Item.TaxMAGRate > 0
                  ? data.Item.TaxMAGCode
                  : data.Item.TaxCode;
              this.itemsList[idx].TaxRate =
                data.Item.TaxMAGRate > 0
                  ? data.Item.TaxMAGRate
                  : data.Item.TaxRate;
              this.itemsList[idx].TaxMAGCode = data.Item.TaxMAGCode;
              this.itemsList[idx].TaxMAGRate = data.Item.TaxMAGRate;
              this.itemsList[idx].DiscountPercent = data.Item.Discount;
              this.itemsList[idx].LinTot = parseFloat(data.Item.UnitPrice);
              this.itemsList[idx].Active = false;

              // this.itemsList[idx].Quantity = data.Item.Quantity;
              this.conta++;
              this.total += data.Item.UnitPrice;
              let itemAux = {
                Item: "",
                ItemCode: "",
                ItemName: "",
                UnitPrice: 0,
                // UnitPriceCol: 0,
                // UnitPriceDol: 0,
                U_SugPrice: 0,
                TaxCode: "EXE",
                Quantity: 1,
                Active: true,
                Id: this.conta,
                LinTot: 0,
                TaxRate: 0.0,
                DiscountPercent: 0,
                WarehouseCode: this.whCode,
                WhsName: this.whName,
                Serie: "",
                SysNumber: 0,
                U_NVT_ServicioMedico: 0,
              } as IDocumentLine;

              this.itemsList.push(itemAux);
              this.getTotals();
            } else {
              this.alertService.errorAlert(
                `Error: no se pudo obtener la información del item solicitado: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
              );
            }
            this.blockUI.stop(); // Stop blocking
          },
          (error: any) => {
            this.blockUI.stop(); // Stop blocking
            this.alertService.errorInfoAlert(
              `Error al intentar conectar con el servidor, Error: ${error}`
            );
          }
        );
    }
  }
  // funcion para eliminar el item de la lista
  // recibe como parametro el item a eliminar
  removeItem(item) {
    //if (this.conta !== item.Id) {
    const index = this.itemsList.indexOf(item);
    this.itemsList.splice(index, 1);
    this.getTotals();
    //} else {
    //this.alertService.warningInfoAlert('No se puede eliminar la última línea del documento');
    //}
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.soForm.controls;
  }
  get fe() {
    return this.feForm.controls;
  }
  // funcion para el envio de la SO a SAP
  // no recibe parametros
  onSubmit() {
    this.isOnPrint = false;
    if (this.itemsList.length === 0) {
      this.alertService.errorInfoAlert(
        `Por favor agregue un producto al menos`
      );
      return;
    }
    this.submitted = true;

    if (this.soForm.invalid) {
      this.isOnSubmit = false;
      return;
    }


    if (!this.soForm.value.cardName) {
      let cardName = "";
      if (
        this.storage.GetDocEntry() > 0 &&
        +localStorage.getItem("SOAndSQAction") !==
        SOAndSQActions.DuplicateSaleOrder
      ) {
        let customer = this.businessPartners.find(
          (x) => x.CardCode === this.DEFAULT_BUSINESS_PARTNER
        );
        if (customer) {
          cardName = customer.CardName;
          this.soForm.patchValue({cardName: cardName});

        }
      }
    }


    if (this.itemsList.some((x) => x.LinTot == 0)) {
      this.alertService.errorInfoAlert(`Existen lineas con costo 0`);
      return;
    }

    this.btnVisibleBool = false;

    let documentType = "";

    if (!this.isCashCustomer) {
      documentType = "FE";
    } else {
      if (
        this.isCashCustomer &&
        this.feForm.value.Identification !== "" &&
        this.feForm.value.Email !== ""
      ) {
        documentType = "FE";
      } else {
        documentType = "TE";
      }
    }
    let SLP = this.soForm.controls.SlpList.value as ISaleMan;
    let SLPList = this.slpTypeaheadList.find(x => x.SlpCode === SLP.SlpCode);
    if (SLPList) {
      if (SLP && SLP.SlpCode === "-1") {
        this.isSalesManSelected = false;
        return;
      }
    } else {
      this.isSalesManSelected = false;
      return;
    }
    this.UdfsInDocument.map(x => {
      x.Value = null;
      return x;
    });

    this.SetValueUDF<string>(
      "FacturaVencida",
      this.soForm.value.BillOverdue === "0" ? "SI" : "NO"
    );
    this.SetValueUDF<string>(
      "Facturacion",
      this.soForm.value.PayTerms === "0" ? "Contado" : "Credito"
    );
    this.SetValueUDF<string>("NVT_Medio_Pago", this.soForm.value.PaymentMethod);

    if (!this.isOrderFromLink) {
      this.SetValueUDF<string>("Almacen", this.storage.getBranchOffice().Code);
    }
    this.SetValueUDF<string>("CLVS_POS_UniqueInvId", this.uniqueInvCode);
    //fe
    this.SetValueUDF<string>("TipoDocE", documentType);

    if(this.feForm.value.IdType && +this.feForm.value.IdType !=0 && this.feForm.value.IdType!= 99){

      if(this.feForm.value.Identification && this.feForm.value.Email){
        this.SetValueUDF<string>("TipoIdentificacion", this.feForm.value.IdType);
        this.SetValueUDF<string>("CorreoFE", this.feForm.value.Email);
        this.SetValueUDF<string>("NumIdentFE", this.feForm.value.Identification);
      }

      if(this.feForm.value.ObservacionFE){
        this.SetValueUDF<string>("ObservacionFE", this.feForm.value.ObservacionFE);
      }

    }

    if (this.storage.GetUIAction() == SOAndSQActions.EditOrder) {
      if (this.soForm.controls.PayTerms.value == 1) {
        let estadoOVValue =
          this.BpDocuemntPendingPay && !this.canAuthorize
            ? "02"
            : this.autForm.controls.Estado.value;
        this.SetValueUDF<string>("EstadoOV", estadoOVValue);

        if (this.canAuthorize) {
          this.SetValueUDF<string>(
            "ObservacionAut",
            this.autForm.controls.Observacion.value
          );
          this.SetValueUDF<string>(
            "FechaAut",
            this.datePipe.transform(new Date(), "yyyy-MM-dd-h:mm:ss a")
          );
          this.SetValueUDF<string>(
            "CorreoAut",
            JSON.parse(this.storageService.getCurrentSession()).UserName
          );
        }
      }
    } else {
      if (
        this.soForm.controls.PayTerms.value == 1 &&
        this.BpDocuemntPendingPay
      ) {
        this.SetValueUDF<string>("EstadoOV", "02");
      }
    }

    if (
      this.storage.GetDocEntry() < 0 &&
      this.BpDocuemntPendingPay &&
      this.soForm.controls.PayTerms.value === "1"
    ) {
      console.log("Envio udf estado pendiente");
    }

    if ((this.itemsList.length - 1) < this.IndexMinDocument) {
      this.IndexMaxUpdate = -1;
    }

    let linesList: IDocumentLine[] = this.itemsList.map((line, index) => {
      line.LineNum == -1 ? this.IndexMaxUpdate = this.IndexMaxUpdate + 1 : this.IndexMaxUpdate;

      let item = {
        ItemCode: line.ItemCode.split("-")[0].trim(),
        DiscountPercent: line.DiscountPercent,
        Quantity: line.Quantity,
        TaxCode: line.TaxCode,
        TaxRate: line.TaxRate,
        UnitPrice: line.UnitPrice,
        WarehouseCode: line.WarehouseCode,
        LineNum: line.LineNum == -1 ? this.IndexMaxUpdate : line.LineNum,
        BaseEntry: line.BaseEntry,
        BaseLine: line.BaseLine,
        BaseType: line.BaseType || -1,
        LineStatus: line.LineStatus,
        U_NVT_ServicioMedico: line.U_NVT_ServicioMedico,
        TaxCode_BCK: line.TaxCode_BCK,
      } as IDocumentLine;

      return item;
    });

    if (this.storage.GetDocEntry() > 0) {
      if (
        this.baseLines &&
        Number(localStorage.getItem("SOAndSQAction")) ===
        SOAndSQActions.CopyToOrder
      ) {
        let documentEntry = this.storage.GetDocEntry();
        this.baseLines.forEach((i) => {
          let aux = linesList.find(
            (x) => x.ItemCode === i.ItemCode && !x["read"]
          );
          if (aux) {
            aux["read"] = true;
            aux.BaseEntry = documentEntry;
            aux.BaseLine = i.BaseLine;
            aux.BaseType = BaseTypeLines.SALE_ORDER;
          }
        });
      }

    }

    let docEntry: number =
      this.storage.GetUIAction() == SOAndSQActions.EditOrder
        ? this.storage.GetDocEntry()
        : 0;

    const mappedUdfs: IUdf[] = this.UdfsInDocument.filter(x=>x.Value).map(udf => {
      return {
        Name: udf.Description,
        Value: udf.Value,
        FieldType: udf.Type
      };
    });

    let saleOrder = {
      DocEntry: docEntry,
      BaseEntry: this.storage.GetDocEntry(),
      CardCode: this.soForm.controls.cardCode.value,
      CardName: this.soForm.controls.cardName.value,
      Comments: this.soForm.controls.Comment.value,
      DocCurrency: this.soForm.controls.currency.value,
      SalesPersonCode: Number(SLP.SlpCode),
      Udfs: mappedUdfs,
      DocumentLines: linesList,
      DocType: BoDocumentTypes.dDocument_Items,
      NumAtCard: this.soForm.value.NumAtCard,
    } as SLDocument;
    saleOrder.StringedDocument = JSON.stringify(saleOrder);
    if (this.storage.GetUIAction() == SOAndSQActions.EditOrder) {
      this.blockUI.start("Actualizando orden de venta, espere por favor...");
      // this.documentService.UpdateCreateSaleOrder(this.soForm, this.itemsList, this.feForm, '', this.storage.GetDocEntry() > 0 ? this.baseLines : [])
      this.documentService.UpdateSaleOrder(saleOrder).subscribe(
        data => {
          this.blockUI.stop();
            this.dateTimeExitUI = formatDate(
              new Date(),
              "yyyy-MM-dd HH:mm:ss SSS a",
              "en"
            );
            this.isOnPrint = true;
            this.baseLines = null;
            this.lastSO = this.saleDocumentModel.DocEntry;
            this.returnedDocEntry = this.saleDocumentModel.DocEntry;
            this.returnedDocNum = this.saleDocumentModel.DocNum;
            this.btnVisibleBool = false;
            this.correctSO = false;
            this.isDocumentSubmitted = true;
            this.titleSaleOrder = "Orden de venta actualizada correctamente";
            (<HTMLButtonElement>(
              document.getElementById("triggerAfterPayModal")
            )).click();

          this.isOnSubmit = false;
        },
        (error: any) => {
          this.isDocumentSubmitted = false;
          this.blockUI.stop();
          this.isOnSubmit = false;
          let err = error && error.Code ? `Error al intentar actualizar orden de venta, Código: ${error.Code}, Mensaje: ${error.Message}`: '';
          this.alertService.errorAlert(err ? err :`Error al intentar conectar con el servidor, Error: ${error}`);
        }
      );
    } else {
      if (
        this.soForm.value.NumAtCard !== "" &&
        !Number(this.soForm.value.NumAtCard)
      ) {
        this.alertService.infoInfoAlert(`La cédula solo debe tener números`);
        return;
      }

      this.isRequiredEmail = false;

      if (this.hasIdentification && this.feForm.value.Email === "") {
        this.isRequiredEmail = true;
        this.alertService.infoInfoAlert(
          `El correo es requerido para completar la factura electrónica`
        );
        return;
      }

      if (this.correctSO) {
        this.alertService.infoInfoAlert(
          `Presione el boton de Limpiar Campos para poder crear una nueva orden`
        );
        return;
      }

      this.blockUI.start("Procesando orden de venta, espere por favor...");
      this.documentService.CreateSaleOrder(saleOrder).subscribe(
        callback => {
          this.blockUI.stop();
          if (callback && callback.Data) {
            this.dateTimeExitUI = formatDate(
              new Date(),
              "yyyy-MM-dd HH:mm:ss SSS a",
              "en"
            );
            this.baseLines = null;
            this.lastSO = callback.Data.DocEntry;
            this.returnedDocEntry = callback.Data.DocEntry;
            this.returnedDocNum = callback.Data.DocNum;
            this.btnVisibleBool = false;
            this.correctSO = true;
            this.isDocumentSubmitted = true;
            this.titleSaleOrder = "Orden de venta creada con éxito";
            (<HTMLButtonElement>(
              document.getElementById("triggerAfterPayModal")
            )).click();
          } else {
            this.isDocumentSubmitted = false;
            this.alertService.errorAlert(
              `Error al intentar crear el documento, Código: ${callback.Error.Code}, Mensaje: ${callback.Error.Message}`
            );
          }
          this.isOnSubmit = false;
        },
        (error: any) => {
          this.isOnSubmit = false;
          this.isDocumentSubmitted = false;
          this.blockUI.stop();
          let err = error && error.Code ? `Error al intentar crear orden de venta, Código: ${error.Code}, Mensaje: ${error.Message}`: '';
          this.alertService.errorAlert(err ? err :`Error al intentar conectar con el servidor, Error: ${error}`);
        }
      );
    }

  }

  CreateSaleOrder(_documentType: string): void {
    if (
      this.soForm.value.NumAtCard !== "" &&
      !Number(this.soForm.value.NumAtCard)
    ) {
      this.alertService.infoInfoAlert(`La cédula solo debe tener números`);
      return;
    }

    this.isRequiredEmail = false;

    if (this.hasIdentification && this.feForm.value.Email === "") {
      this.isRequiredEmail = true;
      this.alertService.infoInfoAlert(
        `El correo es requerido para completar la factura electrónica`
      );
      return;
    }

    if (this.correctSO) {
      this.alertService.infoInfoAlert(
        `Presione el boton de Limpiar Campos para poder crear una nueva orden`
      );
      return;
    }

    this.blockUI.start("Creando orden de venta, espere por favor...");
    this.documentService
      .CreateSaleOrder2(
        this.soForm,
        this.itemsList,
        this.feForm,
        "",
        this.storage.GetDocEntry() > 0 ? this.baseLines : [],
        _documentType,
        this.uniqueInvCode
      )
      .subscribe(
        (data: any) => {
          this.blockUI.stop();
          if (data.result) {
            this.baseLines = null;
            this.lastSO = data.DocEntry;
            this.returnedDocEntry = data.DocEntry;
            this.returnedDocNum = data.DocNum;
            this.btnVisibleBool = false;
            this.correctSO = true;
            this.isDocumentSubmitted = true;
            this.titleSaleOrder = "Orden de venta creada con éxito";
            (<HTMLButtonElement>(
              document.getElementById("triggerAfterPayModal")
            )).click();
          } else {
            this.isDocumentSubmitted = false;
            this.alertService.errorAlert(
              `Error al intentar crear el documento, Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
            );
          }
          this.isOnSubmit = false;
        },
        (error: any) => {
          this.isOnSubmit = false;
          this.isDocumentSubmitted = false;
          this.blockUI.stop();
          this.alertService.errorInfoAlert(
            `Error al intentar conectar con el servidor, Error: ${error}`
          );
        }
      );
  }

  // checkInput(): void {
  //   if (this.feForm.value.Email !== '') {
  //     this.isRequiredEmail = false;
  //   }
  // }

  UpdateSaleOrder(_documentType: string): void {
    this.blockUI.start("Actualizando orden de venta, espere por favor...");
    // this.documentService.UpdateCreateSaleOrder(this.soForm, this.itemsList, this.feForm, '', this.storage.GetDocEntry() > 0 ? this.baseLines : [])
    this.documentService
      .UpdateSaleOrder2(
        this.soForm,
        this.itemsList,
        this.feForm,
        "",
        this.storage.GetDocEntry() > 0 ? this.baseLines : [],
        _documentType
      )
      .subscribe(
        (data: any) => {
          this.blockUI.stop();
          if (data.result) {
            this.isOnPrint = true;
            this.baseLines = null;
            this.lastSO = data.DocEntry;
            this.returnedDocEntry = data.DocEntry;
            this.returnedDocNum = data.DocNum;
            this.btnVisibleBool = false;
            this.correctSO = false;
            this.isDocumentSubmitted = true;
            this.titleSaleOrder = "Orden de venta actualizada correctamente";
            (<HTMLButtonElement>(
              document.getElementById("triggerAfterPayModal")
            )).click();
            // this.printSO();
          } else {
            this.isDocumentSubmitted = false;
            this.alertService.errorAlert(
              `Error al intentar actualizar el documento, Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
            );
          }
          this.isOnSubmit = false;
        },
        (error: any) => {
          this.isDocumentSubmitted = false;
          this.blockUI.stop();
          this.isOnSubmit = false;
          console.log(error);
          this.alertService.errorInfoAlert(
            `Error al intentar conectar con el servidor, Error: ${error}`
          );
        }
      );
  }

  formatter = (slp: any) => slp.TypeAheadFormatt || slp;
  /**
   * Typeahead search business partner
   * @param businessPartner
   */
  formatterBusinessPartner = (businessPartner: IBusinessPartnerModel) => businessPartner.CardName || businessPartner;

  formatterCardCodeBusinessPartner = (businessPartner: IBusinessPartnerModel) => businessPartner.CardCode || businessPartner;


  searchSlpName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.slpTypeaheadList
            .filter(
              (v) =>
                v.SlpCode.toLowerCase().includes(term.toLowerCase()) ||
                v.SlpName.toLowerCase().includes(term.toLowerCase())
            )
            .slice(0, 10)
      )
    );


  searchBPCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.businessPartners
            .filter((v) => v.CardCode.toLowerCase().indexOf(term.toLowerCase()) > -1)
            .slice(0, 10)
      )
    );

  searchBPName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.businessPartners
            .filter((businessPartner) =>{
                return (businessPartner.CardName.toLowerCase().includes(term.toLowerCase()) ||
                businessPartner.Cedula.toLowerCase().includes(term.toLowerCase()))
            }).slice(0, 10)
      )
    );

  searchItemCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(0),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.itemsTypeaheadList
            .filter((v) => {
              let a = v.toLowerCase();

              const stringSize = a.length;

              const t = term.toLowerCase();

              const b = t.split("*").filter((x) => x !== "");

              const size = b.length;

              let isSorted = true;

              if (size > 1) {
                let indexes = [];

                for (let c = 0; c < size; c++) {
                  b[c] = b[c].replace(" ", "");
                  // const invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
                  // b[c] = b[c] .replace(invalid, '');
                  const ii = a.indexOf(b[c]);
                  if (ii > -1) {
                    a = a.slice(ii, stringSize);
                    if (indexes.length > 0)
                      indexes.push(indexes[indexes.length - 1] + ii);
                    else indexes.push(ii);
                  }
                }

                let sizeIndexes = indexes.length;

                if (sizeIndexes === size) {
                  for (let c = 0; c < sizeIndexes - 1; c++) {
                    if (indexes[c] > indexes[c + 1]) {
                      isSorted = false;
                      c = sizeIndexes;
                    }
                  }
                  return isSorted;
                }
              }

              return v.toLowerCase().indexOf(term.toLowerCase()) > -1;
            })
            .sort(
              (x, y) =>
                x.toLowerCase().indexOf(term.toLowerCase()) -
                y.toLowerCase().indexOf(term.toLowerCase())
            )
            .slice(0, 50)
      )
    );

  /**
   * Funcion para detectar el cambio en el input de Código
   * @param $event -Recibe como parametro el Código del item
   * @constructor
   */
  ChangeCode($event: any) {
    if ($event != null) {
      let businessPartner = $event.item as IBusinessPartnerModel;
      $event.preventDefault();

      this.soForm.controls['cardCode'].setValue(businessPartner.CardCode);

      this.CheckIfBPHaveAnyPending(businessPartner.CardCode);

        this.getCurrencyByUser(businessPartner.Currency);

        if (businessPartner && businessPartner.CardName !== this.soForm.get("cardName").value) {
          this.soForm.patchValue({ cardName: businessPartner.CardName });
        }

        this.SetDataBPLines(businessPartner.CardCode);
    }
  }

  /**
   * Set data business partners
   * @param _cardCode
   * @constructor
   * @private
   */
  private SetDataBPLines(_cardCode: string): void{
  const customer = this.businessPartners.find(x => x.CardCode.toString() === _cardCode);

  if (customer) {
    this.priceList = customer.ListNum;
    this.soForm.patchValue({ cardCode: _cardCode });
    this.getCurrencyByUser(customer.Currency);
    this.isCashCustomer = customer.QryGroup1 === "Y";
    this.triggerFECollapser(this.isCashCustomer, this.typeArrow);
  }

  if (this.itemsList.length > 0) this.setupRecalculate();

}
  CheckIfBPHaveAnyPending(_code :string):void {

    this.blockUI.start("Verificando datos del cliente, por favor espere..");
    this.BpDocuemntPendingPay = false;
    this.bpService.CheckIfBPHaveAnyPending(_code).subscribe(
      (info) => {
        this.blockUI.stop();

        if (info.result) {
          this.showBtnBPContacs = false;

          if (info.Data.PayTermGroup != -1) {
            this.showBtnBPContacs = true;
          }
          if (info.Data.PayTermGroup != -1 && info.Data.RequiredAutorization) {
            this.alertService.infoAlert(
              "Tiene documentos pendientes de pago, realizar gestión de cobro."
            );
            this.BpDocuemntPendingPay = info.Data.RequiredAutorization;
          }

          if (
            this.storage.GetUIAction() !== SOAndSQActions.EditOrder &&
            // !this.isAutBtn &&
            this.BpDocuemntPendingPay &&
            this.soForm.controls.PayTerms.value === "1"
          ) {
            this.onSubmitButtonPrefix = "Enviar órden a autorización";
          } else if (this.storage.GetDocEntry() > 0) {
            this.onSubmitButtonPrefix = "Actualizar órden de venta";
          } else {
            this.onSubmitButtonPrefix = "Crear órden de venta";
          }
        }
      },
      (err) => {
        this.blockUI.stop();
        this.alertService.errorAlert(
          "Ocurrio un error verificando los datos del cliente, causado por: " +
          err
        );
      }
    );
  }

  setupRecalculate(): void {
    this.conta = 0;
    this.total = 0;
    this.totalUSD = 0;
    this.tax = 0;
    this.discount = 0;
    this.totalWithoutTax = 0;
    let itemsToCalculate: any[] = []; // lista de items

    this.itemsList.forEach((x) => itemsToCalculate.push(x));
    this.blockUI.start("Recalculando impuestos, espere por favor");
    setTimeout(() => {
      this.pollItemsData(itemsToCalculate);
    }, 1000);
  }

  pollItemsData(_itemsList: any[]): void {
    if (_itemsList.length === 0) {
      this.blockUI.stop();
      return;
    }
    const ITEM_INDEX = _itemsList.length - 1;
    const ITEM = _itemsList[ITEM_INDEX];
    this.itemService
      .GetItemByItemCode(
        ITEM.Item,
        this.priceList,
        this.soForm.controls.cardCode.value
      )
      .subscribe(
        (data: any) => {
          if (data.result) {
            this.total += ITEM.UnitPrice;
            const HAS_RETRIVED_TAX =
              data.Item.ItemGroupCode == ITEM_GROUP_NAME.SERV_VETERINARIA &&
              this.soForm.controls.PaymentMethod.value === "02"; // 02 - Targeta
            // let tot = this.soForm.get("currency").value === "COL" ? (ITEM.UnitPrice * ITEM.Quantity) : (ITEM.Quantity * (parseFloat(Number(ITEM.UnitPrice / this.DailyExRate).toFixed(2))));
            this.itemsList[ITEM_INDEX].DiscountPercent = data.Item.Discount;
            this.itemsList[ITEM_INDEX].TaxCode = HAS_RETRIVED_TAX
              ? "44"
              : data.Item.TaxCode;
            this.itemsList[ITEM_INDEX].TaxRate = HAS_RETRIVED_TAX
              ? 0
              : data.Item.TaxRate;
            this.itemsList[ITEM_INDEX].U_NVT_ServicioMedico = HAS_RETRIVED_TAX
              ? 1
              : 0;
            this.itemsList[ITEM_INDEX].TaxCode_BCK = data.Item.TaxCode;
            this.itemsList[ITEM_INDEX].TaxRate_BCK = data.Item.TaxRate;

            this.LineTotalCalculateExt(_itemsList.length - 1);
            this.getTotals();
            _itemsList.pop();
            this.pollItemsData(_itemsList);
          } else {
            this.pollItemsData([]);
            this.alertService.errorAlert(
              `Error no se pudo recalcular los impuestos, motivo: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
            );
          }
        },
        (error: any) => {
          this.pollItemsData([]);
          this.alertService.errorInfoAlert(
            `Error no se pudo recalcular los impuestos, motivo: ${error}`
          );
        }
      );
  }

  /**
   * Funcion que permite llenar el select de moneda segun las monedas que posee el cliente
   * @param {string} currency Variable que contiene el tipo de moneda del cliente
   */
  getCurrencyByUser(currency: string) {
    this.currencyList = this.allCurrencyList;
    if (currency === "COL" || currency === "USD") {
      this.currencyList = this.currencyList.filter(
        (cur) => cur.Id === currency
      );
    }
    if (this.currencyList.length === 1) {
      this.soForm.patchValue({ currency: this.currencyList[0].Id }); //this.currencyList[0].Id
    }
    this.SetCurr();
  }

  /**
   * Funcion para selecionar socio del typeahead
   * @param $event - Recibe como parametro el nombre cliente
   * @constructor
   */
  ChangeDescription($event: any) {
    if ($event != null) {

      let businessPartner: IBusinessPartnerModel = $event.item;
      $event.preventDefault();

      this.soForm.controls['cardCode'].setValue(businessPartner.CardCode);
      this.soForm.controls['cardName'].setValue(businessPartner.CardName);
      this.CheckIfBPHaveAnyPending(businessPartner.CardCode);
      this.SetDataBPLines(businessPartner.CardCode);

    }
  }

  /**
   * Funcion para detectar el cambio en el input de nombre cliente
   * @param $event
   * @constructor
   */
  OnChangeNameBusinessPartner($event: any) {
    if ($event != null) {
      let cardName = $event.target.value;
      let cardCode = this.soForm.get("cardCode").value;

      let businessPartner = this.businessPartners.find((x) => x.CardCode == cardCode);

      if (businessPartner && businessPartner.CardName != cardName) {
        this.soForm.controls['cardName'].setValue(cardName);
      }
    }
  }

  // funcion para calcular los totales de la SO
  // calcula por separado los precios en dolares y colones para poder evitar inconsistencias en
  // las conversiones
  // no recibe parametros
  getTotals() {
    this.total = 0;
    this.totalUSD = 0;
    this.tax = 0;
    this.discount = 0;
    this.totalWithoutTax = 0;
    this.itemsList.forEach((element) => {
      const lintot = parseFloat(
        (element.UnitPrice * element.Quantity).toString()
      );
      const disc = parseFloat(
        Number(lintot * (element.DiscountPercent / 100)).toString()
      );
      this.discount = parseFloat(Number(disc + this.discount).toString());
      this.totalWithoutTax = parseFloat(
        Number(lintot - disc + this.totalWithoutTax).toString()
      );
      this.tax = parseFloat(
        Number((lintot - disc) * (element.TaxRate / 100) + this.tax).toString()
      );
    });

    const MOBJECT = this.saleDocumentModel;

    if (MOBJECT) {
      if (MOBJECT.DocCurrency == "COL") {
        this.total += parseFloat(
          Number(this.totalWithoutTax + this.tax).toString()
        );
        this.totalUSD += parseFloat(
          Number(
            (this.totalWithoutTax + this.tax) / this.DailyExRate
          ).toString()
        );
      } else {
        this.total += parseFloat(
          Number(
            (this.totalWithoutTax + this.tax) * this.DailyExRate
          ).toString()
        );
        this.totalUSD += parseFloat(
          Number(this.totalWithoutTax + this.tax).toString()
        );
      }
    } else if (this.soForm.value.currency) {
      if (this.soForm.value.currency == "COL") {
        this.total += parseFloat(
          Number(this.totalWithoutTax + this.tax).toString()
        );
        this.totalUSD += parseFloat(
          Number(
            (this.totalWithoutTax + this.tax) / this.DailyExRate
          ).toString()
        );
      } else {
        this.total += parseFloat(
          Number(
            (this.totalWithoutTax + this.tax) * this.DailyExRate
          ).toString()
        );
        this.totalUSD += parseFloat(
          Number(this.totalWithoutTax + this.tax).toString()
        );
      }
    }

    this.total = +this.total.toFixed(2);
    this.totalUSD = +this.totalUSD.toFixed(2);
  }

  getTotalOnLoad() {
    this.total = 0;
    this.totalUSD = 0;
    this.tax = 0;
    this.discount = 0;
    this.totalWithoutTax = 0;
    this.itemsList.forEach((element) => {
      const lintot = parseFloat(
        (element.UnitPrice * element.Quantity).toString()
      );
      const disc = parseFloat(
        Number(lintot * (element.DiscountPercent / 100)).toString()
      );
      this.discount = parseFloat(Number(disc + this.discount).toString());
      this.totalWithoutTax = parseFloat(
        Number(lintot - disc + this.totalWithoutTax).toString()
      );
      this.tax = parseFloat(
        Number((lintot - disc) * (element.TaxRate / 100) + this.tax).toString()
      );
    });
    //this.total += parseFloat(Number(this.totalWithoutTax + this.tax).toFixed(2));
    if (JSON.parse(this.storage.GetCustomerData()).Currency === "COL") {
      this.total += parseFloat(
        Number(this.totalWithoutTax + this.tax).toString()
      );
      this.totalUSD += parseFloat(
        Number((this.totalWithoutTax + this.tax) / this.DailyExRate).toString()
      );
    } else {
      this.total += parseFloat(
        Number((this.totalWithoutTax + this.tax) * this.DailyExRate).toString()
      );
      this.totalUSD += parseFloat(
        Number(this.totalWithoutTax + this.tax).toString()
      );
    }

    this.total = +this.total.toFixed(2);
    this.totalUSD = +this.totalUSD.toFixed(2);
  }

  // funcion al cambiar el tipo de taxcode
  // recibe como parametro el taxxode y el indice de la lista
  changeTaxCode(i: number, item: any) {
    const rate = this.taxesList.find(
      (i) => i.TaxCode === item.TaxCode.toUpperCase()
    );
    //const index =( this.itemsList.length - 1) - idx ;
    const idx: number = this.itemsList.indexOf(
      this.itemsList.find((x) => x.ItemCode == item.ItemCode)
    );

    if (typeof rate !== "undefined") {
      this.itemsList[idx].TaxRate = parseFloat(
        this.decimalPipe.transform(rate.TaxRate, ".2")
      );
      this.itemsList[idx].TaxCode = rate.TaxCode.toUpperCase();
      this.LineTotalCalculate(idx);
    }
  }

  // funcion para calcular el total de la linea
  // recibe como parametro el index de la lista de items
  LineTotalCalculate(idx: number) {
    if (!this.maxDiscuont) this.maxDiscuont = 10;
    if (
      !this.itemsList[idx].DiscountPercent ||
      this.itemsList[idx].DiscountPercent < 0
    )
      this.itemsList[idx].DiscountPercent = 0;

    if (this.itemsList[idx].DiscountPercent > this.maxDiscuont) {
      this.alertService.infoInfoAlert(
        "El descuento no puede ser mayor a " +
        this.maxDiscuont +
        "% que es lo permitido para este usuario"
      );
      this.itemsList[idx].DiscountPercent = this.maxDiscuont;
    }

    const qty = this.itemsList[idx].Quantity;
    const price = this.itemsList[idx].UnitPrice;
    let lineTotal = Number((qty * price).toString());
    const taxamount = Number(
      (lineTotal * (this.itemsList[idx].TaxRate / 100)).toString()
    );
    lineTotal = Number((lineTotal + taxamount).toString());
    lineTotal = Number(
      (
        lineTotal -
        lineTotal * (this.itemsList[idx].DiscountPercent / 100)
      ).toString()
    );
    this.itemsList[idx].LinTot = lineTotal;
    this.getTotals();
  }

  LineTotalCalculateExt(idx: number) {
    let disc = 0;
    if (this.itemsList[idx].DiscountPercent <= this.maxDiscuont) {
      disc = this.itemsList[idx].DiscountPercent;
    } else {
      disc = this.maxDiscuont;
      this.alertService.infoInfoAlert(
        "El descuento no puede ser mayor a " +
        this.maxDiscuont +
        " que es lo permitido para este usuario"
      );
      this.itemsList[idx].DiscountPercent = this.maxDiscuont;
    }
    if (this.itemsList[idx].DiscountPercent == null) {
      this.itemsList[idx].DiscountPercent = 0;
    }

    const qty = this.itemsList[idx].Quantity;
    const price = this.itemsList[idx].UnitPrice;
    let lineTotal = Number((qty * price).toFixed(2));
    const taxamount = Number(
      (lineTotal * (this.itemsList[idx].TaxRate / 100)).toFixed(2)
    );
    lineTotal = Number((lineTotal + taxamount).toFixed(2));
    lineTotal = Number((lineTotal - lineTotal * (disc / 100)).toFixed(2));
    this.itemsList[idx].LinTot = lineTotal;
    this.getTotals();
    this.isEditingPrice = false;
  }

  togglePriceEdition(): void {
    this.isEditingPrice = true;
    console.log("click");
  }

  // funcion que se llama desde la ui para hacer la impresion de la orden de venta
  printSO() {
    this.printSaleOrder(this.lastSO);
  }

  // funcion para imprimir la factura
  printSaleOrder(DocEntry: number) {
    this.blockUI.start("Generando la impresión..."); // Start blocking
    this.reportsService.printReport(DocEntry, ReportType.SaleOrder).subscribe(
      (data: any) => {
        this.blockUI.stop(); // Stop blocking
        printJS({
          printable: data,
          type: "pdf",
          base64: true,
        });
      },
      (error) => {
        this.blockUI.stop();
        console.log(error);
        this.alertService.errorInfoAlert(
          `Error al generar el reporte, Error: ${error}`
        );
      }
    );
  }
  openModal(content) {
    this.modalReference = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg",
    });
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  // llena los campos de la tabla de items con los campos parametriados
  GetParamsViewList() {
    this.blockUI.start("Procesando parametrizaciones, espere por favor..."); // Start blocking
    this.paramsService.getParasmView().subscribe(
      (data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.viewParamList = data.Params.filter((param) => {
            // return param.type === 1;
            return param.type === 1 && param.Visibility;
          });

          this.viewParamList.splice(7, 0, {
            CompanyId: 4,
            Descrip: "Precio unitario con impuesto",
            Display: true,
            Id: 144,
            Name: "UPWT",
            Order: 15,
            ParamsId: 11,
            Text: "Precio+Imp",
            ViewsId: 1,
            Visibility: true,
            type: 1,
          });

          this.tableLength = 0;
          for (var i = 0; i < this.viewParamList.length; i++) {
            this.tableLength += this.MapWidth[this.viewParamList[i].Name];
          }
          this.viewParamListHeader = data.Params.filter((param) => {
            return param.type === 2;
          });
          this.viewParamListTotales = data.Params.filter((param) => {
            return param.type === 3;
          });
          this.viewParamTitles = data.Params.filter((param) => {
            return param.type === 6;
          });
          this.ChargeParamstoView();
        } else {
          this.alertService.errorAlert(
            "Error al cargar los parámetros de la página - " +
            data.errorInfo.Message
          );
        }
      },
      (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }
  // funcion para obtener la información de los disponibles de un item en los almacenes
  // recibe como parametros el item y el index
  GetWHAvailableItem(event, content, ItemCode, idx) {
    if (ItemCode !== "") {
      if (event.type === "dblclick") {
        this.blockUI.start(
          "Obteniendo Disponibilidad del Artículo, Espere Por Favor..."
        ); // Start blocking
        // this.itemService.GetWHAvailableItem(item.item.split(' - ')[0])
        this.itemService.GetWHAvailableItem(ItemCode).subscribe(
          (data: any) => {
            if (data.result) {
              this.WHAvailableItemList.length = 0;
              this.itemCode = ItemCode;
              this.indexAvaItem = idx;
              this.WHAvailableItemList = data.whInfo;
              if (data.whInfo.length > 0) {
                this.expandedIndex = -1;
                // this.expandRow(this.expandedIndex);
                // this.openModal(content);
                (<HTMLButtonElement>(
                  document.getElementById("triggerWhsPreview")
                )).click();
              } else {
                this.alertService.errorInfoAlert(
                  "Este artículo no está disponibles en ningún almacén."
                );
              }
            } else {
              this.alertService.errorAlert(
                "Error al obtener Items del almacén - Error: " +
                data.errorInfo.Message
              );
            }
            this.blockUI.stop(); // Stop blocking
          },
          (error: any) => {
            this.blockUI.stop(); // Stop blocking
            this.alertService.errorInfoAlert(
              `Error al intentar conectar con el servidor, Error: ${error}`
            );
          }
        );
      }
    }
  }

  // funcion para seleccionar un almacen nuevo para el item a facturar
  avaItemSelected(event, avaItem, idx: number) {
    if (event.type === "dblclick") {
      this.itemsList[this.indexAvaItem].WarehouseCode = avaItem.WhsCode;
      this.itemsList[this.indexAvaItem].WhsName = avaItem.WhsName;
      this.itemsList[this.indexAvaItem].Serie = "";
      this.itemsList[this.indexAvaItem].SysNumber = 0;
      (<HTMLButtonElement>document.getElementById("triggerWhsClose")).click();
    }
    //  else if (event.type === 'click') {
    //   this.itemService.GetSeriesByItem(this.itemCode, avaItem.WhsCode)
    //     .subscribe((data: any) => {
    //       if (data.result) {
    //         this.seriesList.length = 0;
    //         this.seriesList = data.series;
    //         if (data.series.length > 0) {
    //           this.expandRow(idx);
    //         } else {
    //           //this.alertService.infoInfoAlert('El item no tiene series en el almacén selecionado');
    //         }
    //         this.blockUI.stop(); // Stop blocking
    //       }
    //       //  else {
    //       //   this.alertService.errorAlert('error al obtener la series del almacen - Error: ' + data.errorInfo.Message);
    //       // }

    //     }, (error: any) => {
    //       this.blockUI.stop(); // Stop blocking
    //       this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    //     });
    // }
  }

  // funcion para cerrar la modal para escoger la terminal y sucursal
  close() {
    this.modalReference.close();
  }

  // funcion para selecccionar una seria
  // recibe como parametro el objeto de serie y del almacen en el que se selecciono la serie
  selectSerie(series, avaItem) {
    const idx: number = this.itemsList.indexOf(
      this.itemsList.find((x) => x.ItemCode == this.itemCode)
    );
    if (series.Disponible > 0) {
      this.itemsList[this.indexAvaItem].Serie = series.PlacaChasis;
      this.itemsList[this.indexAvaItem].SysNumber = series.SysNumber;
      this.itemsList[this.indexAvaItem].WarehouseCode = avaItem.WhsCode;
      this.itemsList[this.indexAvaItem].WhsName = avaItem.WhsName;
      this.itemsList[this.indexAvaItem].UnitPrice = series.Precio;
      // this.itemsList[this.indexAvaItem].Marca = series.PlacaChasis;
      this.LineTotalCalculateExt(idx);
      this.alertService.infoInfoAlert(
        `Se seleccionó la serie: ${series.PlacaChasis}`
      );
    } else {
      this.alertService.infoInfoAlert(
        "No puede seleccionar esta serie ya que no posee disponibles"
      );
    }
  }

  // Carga los datos parametrizados en las variables
  ChargeParamstoView() {
    // parametrizaciones para dtos de cabezera
    this.viewParamListHeader.forEach((element) => {
      if (element.Name === "lbCardCode") {
        this.lbCardCode = element;
      }
      if (element.Name === "txtCardCode") {
        this.txtCardCode = element;
      }
      if (element.Name === "lbCardName") {
        this.lbCardName = element;
      }
      if (element.Name === "txtCardName") {
        this.txtCardName = element;
      }
      if (element.Name === "lbCurrency") {
        this.lbCurrency = element;
      }
      if (element.Name === "txtCurrency") {
        this.txtCurrency = element;
      }
      if (element.Name === "txtPayTerms") {
        this.txtPayTerms = element;
      }
      if (element.Name === "lbPayTerms") {
        this.lbPayTerms = element;
      }
      if (element.Name === "txtPriceList") {
        this.txtPriceList = element;
      }
      if (element.Name === "lbPriceList") {
        this.lbPriceList = element;
      }
      if (element.Name === "txtComments") {
        this.txtComments = element;
      }
      if (element.Name === "lbComments") {
        this.lbComments = element;
      }
      if (element.Name === "txtSLP") {
        this.txtSLP = element;
      }
      if (element.Name === "lbSLP") {
        this.lbSLP = element;
      }
    });

    // parametrizaciones datos de totales
    this.viewParamListTotales.forEach((element) => {
      if (element.Name === "lbTotalExe") {
        this.lbTotalExe = element;
      }
      if (element.Name === "txtTotalExe") {
        this.txtTotalExe = element;
      }
      if (element.Name === "lbDiscount") {
        this.lbDiscount = element;
      }
      if (element.Name === "txtDiscount") {
        this.txtDiscount = element;
      }
      if (element.Name === "lbTaxes") {
        this.lbTaxes = element;
      }
      if (element.Name === "txtTaxes") {
        this.txtTaxes = element;
      }
      if (element.Name === "lbTotal") {
        this.lbTotal = element;
      }
      if (element.Name === "txtTotal") {
        this.txtTotal = element;
      }
    });

    // parametrizacion del titulo
    let obj = this.viewParamTitles.filter((param) => {
      return param.Name === "T_so";
    });
    this.title = obj[0].Text;
  }

  // Obtiene los tipos de monedas ya sea Col o Usd desde una vista SQL
  GetCurrencyType() {
    this.blockUI.start("Procesando monedas, espere por favor..."); // Start blocking
    this.paramsService.GetCurrencyType().subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.currencyList = data;
          this.allCurrencyList = data;
          this.DefaultConfigurationBusinessPartner();
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(
            "Error al cargar las monedas - " + data.errorInfo.Message
          );
        }
      },
      (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  // Retorna el simbolo de la moneda al elegir en el dropdown la moneda
  SetCurr() {
    let cur = this.currencyList.find(
      (curr) => curr.Id === this.soForm.controls.currency.value
    );

    if (this.storage.GetDocEntry() > 0) {
      cur = this.currencyList.find(
        (curr) => {
          let stringCustomerData = this.storage.GetCustomerData();
          if(stringCustomerData){
            return curr.Id === JSON.parse(stringCustomerData).Currency;
          } else {
           return false;
          }
        }
      );
    }

    if (!cur) return;
    this.setCurr = cur.Symbol;
  }
  CleanFields(): void {
    this.soForm.controls["cardName"].enable();
    this.soForm.controls["cardCode"].enable();
    this.commonService.hasDocument.next(``);
    this.storage.SaveBreadCrum(``);
    if (this.typeArrow)
      (<HTMLButtonElement>(
        document.getElementById("triggerFECollapser")
      )).click();
    this.storage.SaveDocEntry(-1);
    this.storage.SaveUIAction(-1);

    this.OnLoad();
  }

  /**
   * Clear lists and reload data for OS
   * @constructor
   */
  CreateNew() {
    this.commonService.hasDocument.next(``);
    this.storage.SaveBreadCrum(``);
    if (this.typeArrow)
      (<HTMLButtonElement>(
        document.getElementById("triggerFECollapser")
      )).click();
    this.storage.SaveDocEntry(-1);
    this.storage.SaveUIAction(-1);


    this.IndexMinDocument = 0 ;
    this.IndexMaxUpdate = 0;
    this.showBtnBPContacs = false;
    this.isOrderFromLink = false;
    this.canAuthorize = false;
    this.UdfsInDocument = [];
    this.UdfsInDocument = this.GetDocumentUDFs();
    this.isDocumentSubmitted = false;
    this.lastSO = 0;
    this.DEFAULT_BUSINESS_PARTNER =
      this.storage.getBranchOffice().CashBusinessPartnerCode;
    this.generateUniqueInvoiceId();
    this.isSalesManSelected = true;
    this.hasIdentification = false;
    this.isRequiredEmail = false;
    this.isOnPrint = false;
    //if (this.subscriptions) this.subscriptions.unsubscribe();
    this.InvalidCardName = false;
    this.commonService.hasDocument.next(``);
    this.storage.SaveBreadCrum(``);
    if (this.typeArrow)
      (<HTMLButtonElement>(
        document.getElementById("triggerFECollapser")
      )).click();
    this.storage.SaveDocEntry(-1);
    this.storage.SaveUIAction(-1);
    this.onSubmitButtonPrefix = "Crear órden de venta";
    this.btnVisibleBool = true;
    this.isCashCustomer = false;
    this.isOnCreation = true;
    this.correctSO = false;
    this.isEditingPrice = false;
    this.saleDocumentModel = null;
    this.soForm.reset();
    this.feForm.reset();
    this.itemsList.length = 0;
    this.overdueBalances = [];
    this.cantonId = "0";
    this.districtId = "0";
    this.Cant.setValue(1);
    this.neighborhoodId = "0";
    this.provinceId = "0";
    //this.getItems();
    this.getTaxes();
    this.getTotals();
    this.soForm.patchValue({ currency: this.currencyList[0].Id });
    this.total = 0;
    this.totalUSD = 0;
    this.ChangeCode(this.DEFAULT_BUSINESS_PARTNER);
    this.btnVisibleBool = true;
    this.conta = 0;
    this.total = 0;
    this.totalUSD = 0;
    this.tax = 0;
    this.discount = 0;
    this.totalWithoutTax = 0;
    this.DailyExRate = 0;
    this.provinceId = "0";
    this.cantonId = "0";
    this.districtId = "0";
    this.neighborhoodId = "0";
    // this.currencyList = CurrencyType;
    this.soForm.controls["cardName"].enable();
    this.soForm.controls["cardCode"].enable();
    this.ItemInfo.setValue("");
    this.soForm = this.fb.group({
      cardCode: [this.DEFAULT_BUSINESS_PARTNER, Validators.required],
      cardName: [""],
      currency: ["", Validators.required],
      PayTerms: ["0"],
      // PriceList: ['', Validators.required],
      SlpList: ["", Validators.required],
      BillOverdue: ["1", Validators.required],
      PaymentMethod: ["01", Validators.required],
      Comment: "",
      NumAtCard: "",
      Discount: [0],
    });

    this.identificationTypeList = IdentificationType;
    this.getProvinces();
    this.getExRate();
    this.createFEForm();
    this.getMaxDiscout();
    this.GetCustomers();
    this.GetParamsViewList();
    //this.GetCurrencyType();
    this.GetPriceList();
    this.GetSalesPersonList();
    this.getItems();
    this.GetPayTerms();
    this.nameField.nativeElement.focus();
  }

  // obtiene los terminos de pago
  GetPayTerms() {
    this.blockUI.start("Obteniendo Terminos de pago, Espere Por Favor..."); // Start blocking
    this.itemService.GetPayTerms().subscribe(
      (data: any) => {
        if (data.result) {
          this.PayTermsList = data.payTermsList;
          //this.soForm.patchValue({ PayTerms: this.PayTermsList[0].GroupNum });
          // this.soForm.patchValue({ PayTerms: this.defaultGroupNum });
          this.blockUI.stop(); // Stop blocking
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert(
            `Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
          );
        }
      },
      (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }
  // obtiene la lista de pago
  GetPriceList() {
    this.blockUI.start("Obteniendo Listas de precios, Espere Por Favor..."); // Start blocking
    this.itemService.GetPriceList().subscribe(
      (data: any) => {
        if (data.result) {
          this.PriceList = data.priceList;
          this.soForm.patchValue({ PriceList: this.PriceList[0].ListNum });

          this.blockUI.stop(); // Stop blocking
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert(
            `Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
          );
        }
      },
      (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  // obtiene la lista de personas vendedoras
  GetSalesPersonList() {
    this.blockUI.start("Obteniendo vendedores, Espere Por Favor..."); // Start blocking
    this.smService.getSalesMan().subscribe(
      (data: any) => {
        if (data.result) {
          if (data.salesManList.length > 0) {
            this.slpTypeaheadList = [];
            data.salesManList.forEach(element => {
              this.slpTypeaheadList.push({
                SlpCode: element.SlpCode,
                SlpName: element.SlpName,
                TypeAheadFormatt: `${element.SlpCode} ${element.SlpName}`
              });
            });
          }
          const DOC_ENTRY = this.storage.GetDocEntry();
          if (DOC_ENTRY > 0) {

            const CUSTOMER_DATA = JSON.parse(this.storage.GetCustomerData());

            if (CUSTOMER_DATA && !(Object.keys(CUSTOMER_DATA).length === 0)) {
              const SLP = this.slpTypeaheadList.find(x => x.SlpCode === CUSTOMER_DATA.SlpCode.toString());

              if (SLP) {
                this.soForm.patchValue({ SlpList: SLP });
              }
            }
          } else {
            const SLP = this.slpTypeaheadList.find(x => x.SlpCode === this.defaultSlpCode.toString());

            if (SLP) {
              this.soForm.patchValue({ SlpList: SLP });
            }
          }

          // this.soForm.patchValue({ SlpList: this.defaultSlpCode.toString() });
          this.blockUI.stop(); // Stop blocking
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert(
            `Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
          );
        }
      },
      (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  ClearItemList() {
    this.itemsList.length = 0;
    this.getItems();
  }

  //chequea si hay existencias del item a agregar
  CheckAvailableItem(ItemCode) {
    if (ItemCode !== "") {
      this.blockUI.start(
        "Obteniendo Disponibilidad del Artículo, Espere Por Favor..."
      ); // Start blocking
      this.itemService.GetWHAvailableItem(ItemCode).subscribe(
        (data: any) => {
          if (data.result) {
            this.WHAvailableItemList.length = 0;
            this.itemCode = ItemCode;
            this.WHAvailableItemList = data.whInfo;
            if (data.whInfo.length <= 0) {
              this.blockUI.stop(); // Stop blocking
              this.alertService.infoInfoAlert(
                "Este artículo no posee disponibles en ningún almacén."
              );
            }
            this.blockUI.stop(); // Stop blocking
          } else {
            this.blockUI.stop(); // Stop blocking
            this.alertService.errorAlert(
              "Error al Obtener disponibilidad el artículo - " +
              data.errorInfo.Message
            );
          }
        },
        (error: any) => {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorInfoAlert(
            `Error al intentar conectar con el servidor, Error: ${error}`
          );
        }
      );
    }
  }
  // agrega un item a la lista
  addItems(item) {
    if (this.ItemInfo.value !== "") {
      let mobileNavigatorObject: any = window.navigator;
      if (mobileNavigatorObject && mobileNavigatorObject.clipboard) {
        mobileNavigatorObject.clipboard
          .readText()
          .then((text) => {
            if (!isNaN(parseInt(text))) {
              if (Number(text) > this.maxWeightTo0) {
                //this.Cant.setValue(Math.max(Number(text) - this.pesoBolsa, 0.0001)); // AGUILA COMENTA ESTA LINEA PORQUE EL CLIPBOARD ENSUCIA EL CAMPO DE CANTIDAD
                //this.invForm.patchValue({cardName: cardName});
                mobileNavigatorObject.clipboard
                  .writeText("*")
                  .then((text) => {
                    //this.Cant.setValue(Number(text));
                    //this.invForm.patchValue({cardName: cardName});
                  })
                  .catch((err) => {
                    // console.error('Failed to clear clipboard contents: ', err);
                  });
              }
            }

            if (this.correctSO) {
              this.ItemInfo.setValue("");
              this.alertService.infoInfoAlert(
                `Presione el boton limpiar para poder agregar productos a la orden`
              );
              return;
            }
            let cant = Number(this.Cant.value);

            let code = item.item.split(" COD. ")[0];
            let searchitem = true;
            this.blockUI.start(
              "Obteniendo Información del Artículo, Espere Por Favor..."
            ); // Start blocking
            //this.itemsList.find(x => x.ItemCode == code).
            // this.CheckAvailableItem(code);
            var index: number = this.itemsList.indexOf(
              this.itemsList.find((x) => x.Item == code)
            );
            // this.itemsList.forEach(x => {
            if (index != -1) {
              this.itemsList[index].Quantity += cant;
              this.itemsList[index].LinTot =
                this.itemsList[index].Quantity *
                this.itemsList[index].UnitPrice;
              searchitem = false;
              this.LineTotalCalculateExt(index);
              this.getTotals();
              this.blockUI.stop();
            }

            this.itemService
              .GetItemByItemCode(
                item.item.split(" COD. ")[0],
                this.priceList,
                this.soForm.controls.cardCode.value
              ) // GETITEMINFO
              .subscribe(
                (data: any) => {
                  if (data.result) {
                    if (searchitem) {
                      this.conta++;
                      this.total += data.Item.UnitPrice;
                      const HAS_RETRIVED_TAX =
                        data.Item.ItemGroupCode ==
                        ITEM_GROUP_NAME.SERV_VETERINARIA &&
                        this.soForm.controls.PaymentMethod.value === "02"; // 02 - Targeta
                      let tot =
                        this.soForm.get("currency").value === "COL"
                          ? data.Item.UnitPrice * cant
                          : cant *
                          parseFloat(
                            Number(
                              data.Item.UnitPrice / this.DailyExRate
                            ).toFixed(2)
                          );
                      let itemAux = {
                        Item: data.Item.ItemCode,
                        ItemCode: `${data.Item.ItemCode} - ${data.Item.ItemName}`,
                        ItemName: `${data.Item.ItemName}`,
                        CodeName: `${data.Item.ItemCode} - ${data.Item.ItemName}`,
                        UnitPrice:
                          this.soForm.get("currency").value === "COL"
                            ? data.Item.UnitPrice
                            : parseFloat(
                              Number(
                                data.Item.UnitPrice / this.DailyExRate
                              ).toFixed(2)
                            ),
                        UnitPriceCol: data.Item.UnitPrice,
                        UnitPriceDol: parseFloat(
                          Number(
                            data.Item.UnitPrice / this.DailyExRate
                          ).toFixed(2)
                        ),
                        U_SugPrice: 0,
                        TaxCode: HAS_RETRIVED_TAX ? "44" : data.Item.TaxCode, // 44 Iva devuelto codigo
                        TaxRate: HAS_RETRIVED_TAX ? 0 : data.Item.TaxRate, // 0 Iva devualto valor
                        TaxCode_BCK: data.Item.TaxCode,
                        TaxRate_BCK: data.Item.TaxRate,
                        ItemGroupCode: data.Item.ItemGroupCode,
                        Quantity: cant,
                        Active: true,
                        Id: this.conta,
                        LinTot: tot,
                        DiscountPercent: data.Item.Discount,
                        WarehouseCode: this.whCode,
                        WhsName: this.whName,
                        Serie: "",
                        SysNumber: 0,
                        OnHand: data.Item.OnHand,
                        U_NVT_ServicioMedico: HAS_RETRIVED_TAX ? 1 : 0,
                        InvntItem: data.Item.InvntItem,
                        LineStatus: "O",
                        LineNum: -1
                      } as IDocumentLine;

                      this.itemsList.unshift(itemAux);
                      this.LineTotalCalculateExt(0);
                      this.getTotals();
                    }
                    this.Cant.setValue(1);
                    this.ItemInfo.setValue("");

                    if (data.Item.InvntItem === "Y" && data.Item.OnHand <= 0) {
                      this.itemsList[0].Quantity = 0;
                      this.itemsList[0].LinTot = 0;
                      searchitem = false;
                      this.LineTotalCalculate(0);
                      this.getTotals();
                    }
                  } else {
                    this.alertService.errorAlert(
                      `Error: no se pudo obtener la información del item solicitado: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`
                    );
                  }
                  this.blockUI.stop(); // Stop blocking
                },
                (error: any) => {
                  this.blockUI.stop(); // Stop blocking
                  this.alertService.errorInfoAlert(
                    `Error al intentar conectar con el servidor, Error: ${error}`
                  );
                }
              );
          })
          .catch((err) => {
            // console.error('Failed to read clipboard contents: ', err);
          });
      }
    }
  }
  cantChange() {
    if (this.Cant.value < 1) {
      this.Cant.setValue(1);
    }
  }

  identificationTypeChange(IdentificationValue: string) {
    switch (IdentificationValue) {
      case "00": {
        this.feForm.controls["Identification"].setValidators([]);
        this.feForm.controls["Identification"].updateValueAndValidity();
        // this.feForm.controls['Direccion'].setValidators([]);
        // this.feForm.controls['Direccion'].updateValueAndValidity();
        this.feForm.controls["Email"].setValidators([]);
        this.feForm.controls["Email"].updateValueAndValidity();
        this.neighborhoodList.length = 0;
        this.districtList.length = 0;
        this.cantonList.length = 0;
        this.provinceList.length = 0;
        break;
      }
      case "01": {
        this.validatorFeForm(9, 9);
        //this.getProvinces();
        break;
      }
      case "02":
      case "04": {
        this.validatorFeForm(10, 10);
        //this.getProvinces();
        break;
      }
      case "03": {
        this.validatorFeForm(11, 12);
        //this.getProvinces();
        break;
      }
    }
  }

  validatorFeForm(min: number, max: number) {
    this.feForm.controls["Identification"].setValidators([
      Validators.required,
      Validators.minLength(min),
      Validators.maxLength(max),
    ]);
    this.feForm.controls["Identification"].updateValueAndValidity();
    // this.feForm.controls['Direccion'].setValidators([Validators.required, Validators.maxLength(250)]);
    // this.feForm.controls['Direccion'].updateValueAndValidity();
    this.feForm.controls["Email"].setValidators([
      Validators.required,
      Validators.minLength(2),
      Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
    ]);
    this.feForm.controls["Email"].updateValueAndValidity();
  }

  getProvinces() {
    this.jsonDataService.getJSONProvinces().subscribe((data: any) => {
      this.provinceList = data.Provinces;
      this.feForm.patchValue({ Provincia: this.provinceList[0].ProvinceId });
      this.getCantons(this.provinceList[0].ProvinceId);
    });
  }

  getCantons(provinceId) {
    this.provinceId = provinceId;
    this.jsonDataService.getJSONCountryPlaces().subscribe((data: any) => {
      this.cantonList = this.unique(
        data.Country.filter((x) => x.ProvinceId === this.provinceId),
        "CantonId"
      );
      if (typeof this.feForm.value.Canton !== "undefined") {
        if (this.provinceId === "0") {
          this.feForm.patchValue({ Canton: this.cantonList[0].CantonId });
        } else {
          this.feForm.patchValue({ Canton: this.cantonId });
        }
      }
    });
  }
  // funcion para obtener la nueva lista de distritos segun la provincia y canton
  // recibe como parametro el id del canton y utiliza el id de la provincia para filtrar
  getDistrics(cantonId) {
    this.cantonId = cantonId;
    this.jsonDataService.getJSONCountryPlaces().subscribe((data: any) => {
      this.districtList = this.unique(
        data.Country.filter(
          (x) =>
            x.ProvinceId === this.provinceId && x.CantonId === this.cantonId
        ),
        "DistrictId"
      );
      if (typeof this.feForm.value.Distritos !== "undefined") {
        if (this.provinceId === "0") {
          this.feForm.patchValue({
            Distritos: this.districtList[0].DistrictId,
          });
        } else {
          this.feForm.patchValue({ Distritos: this.districtId });
        }
      }
    });
  }
  // funcion para obtener la nueva lista de barrios segun la provincia, canton y distrito
  // recibe como parametro el id del distrito, y utiliza el id de la provincia y del canton para filtrar
  getNeighborhood(districtId) {
    this.districtId = districtId;
    this.jsonDataService.getJSONCountryPlaces().subscribe((data: any) => {
      this.neighborhoodList = this.unique(
        data.Country.filter(
          (x) =>
            x.ProvinceId === this.provinceId &&
            x.CantonId === this.cantonId &&
            x.DistrictId === this.districtId
        ),
        "NeighborhoodId"
      );
      if (typeof this.feForm.value.Barrio !== "undefined") {
        if (this.neighborhoodId === "0") {
          this.feForm.patchValue({
            Barrio: this.neighborhoodList[0].neighborhoodId,
          });
        } else {
          this.feForm.patchValue({ Barrio: this.neighborhoodId });
        }
      }
    });
  }

  /**
   * Sets the default values
   * @constructor
   */
  DefaultConfigurationBusinessPartner() {

    const MOBJECT = this.saleDocumentModel;

    if(MOBJECT){
      this.getCurrencyByUser(MOBJECT.DocCurrency);

      this.soForm.patchValue({ cardName: MOBJECT.CardName });
      this.priceList = this.businessPartners.find((x) => x.CardCode === MOBJECT.CardCode).ListNum;

    }else{

      let customer = this.businessPartners.find(customer => customer.CardCode === this.DEFAULT_BUSINESS_PARTNER);
      if(customer){
        this.getCurrencyByUser(customer.Currency);

        this.soForm.patchValue({ cardName: customer.CardName });
        this.priceList = customer.ListNum;
      }

    }

  }

  unique(array, propertyName) {
    return array.filter(
      (e, i) =>
        array.findIndex((a) => a[propertyName] === e[propertyName]) === i
    );
  }

  getCompanies() {
    this.blockUI.start("Obteniendo Compañías, Espere Por Favor..."); // Start blocking
    this.companyService.GetCompanies().subscribe(
      (data: any) => {
        if (data.result) {
          this.companiesList.length = 0;
          this.companiesList = data.companiesList;
          this.companiesList.forEach((comp) => {
            this.pesoBolsa = comp.ScaleWeightToSubstract;
            this.priceEditable = comp.IsLinePriceEditable;
            this.maxWeightTo0 = comp.ScaleMaxWeightToTreatAsZero;
          });
        } else {
          this.alertService.errorAlert(
            "Error al cargar compañías - Error: " + data.errorInfo.Message
          );
        }
        this.blockUI.stop(); // Stop blocking
      },
      (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }

  setWarehouseInfo() {
    let session = this.storage.getSession(navigator.onLine);
    if (session) {
      session = JSON.parse(session);

      this.whCode = session.WhCode;
      this.whName = session.WhName;
    }
  }
  // se comunica con electron para imprimir el archivo
  /* public sendPrintSignal(data: any) {
    const isAnElectronApp: boolean = this._electronService.isElectronApp;
    if(this._electronService.isElectronApp) {
      this._electronService.ipcRenderer.send('msgPrint', data);
    }
    else{
    }
  } */

  /**
   * Load initial data
   * @constructor
   * @private
   */
  private SetInitialData():void {
    if (this.permisos) {
      this.nameField.nativeElement.focus();
    }

    this.DEFAULT_BUSINESS_PARTNER = this.storage.getBranchOffice().CashBusinessPartnerCode;

    if (this.DOC_ENTRY <= 0) {
    this.storage.SaveUIAction(-1);
    this.isOnCreation = true;
    }

    const selectedSLP = this.slpTypeaheadList.find(x => x.SlpCode === this.defaultSlpCode.toString());

    if (selectedSLP) {
      this.soForm.patchValue({ SlpList: selectedSLP });
    }


    let customer = this.businessPartners.find(x => x.CardCode === this.DEFAULT_BUSINESS_PARTNER);
    if (customer) {

      this.soForm.patchValue({
        cardCode: this.DEFAULT_BUSINESS_PARTNER,
        cardName: customer.CardName
      });

      this.priceList = customer.ListNum;
      this.isCashCustomer = customer.QryGroup1 === "Y";

      this.getCurrencyByUser(customer.Currency);

    }


  }

  /**
   * Set initial document
   * @constructor
   * @private
   */
  private SetDocument():void {

    let CustomerData: string = JSON.stringify({
      CardCode: this.saleDocumentModel.CardCode,
      Currency: this.saleDocumentModel.DocCurrency,
      SlpCode: this.saleDocumentModel.SalesPersonCode,
    });

    this.storageService.SaveCustomerData(CustomerData);
    this.DEFAULT_BUSINESS_PARTNER = this.saleDocumentModel.CardCode;


    //#region Autorizacion orden venta
    let estadoOv = this.saleDocumentModel.U_EstadoOV;

    if (this.isOrderFromLink) {

      let msg: string = '';

      if (estadoOv == "02") {
        if (!this.isPermAutorizeSale)
          msg = "No cuenta con los permisos para realizar esta acción.";
      } else {
        msg = "El acceso a esta orden de venta no esta permitido.";
      }

      if (msg != '') {
        this.alertService.errorAlert(
          msg
        );
        this.router.navigate(["/home"]);
      }

      this.commonService.hasDocument.next(`Documento número ${this.saleDocumentModel.DocNum}`);
      this.storageService.SaveBreadCrum(`Documento número ${this.saleDocumentModel.DocNum}`);


    }

    if (estadoOv != null) {
      this.canAuthorize = estadoOv == "02" && this.isPermAutorizeSale;
      this.autForm.patchValue({ Estado: estadoOv });
    }
    //endregion

    //#region DocumentForm
    const selectedSLP = this.slpTypeaheadList.find(
      x => x.SlpCode === this.saleDocumentModel.SalesPersonCode.toString()
    );

    this.soForm.patchValue({
      SlpList: selectedSLP ? selectedSLP : "",
      cardCode: this.saleDocumentModel.CardCode,
      cardName: this.saleDocumentModel.CardName,
      Comment: this.saleDocumentModel.Comments,
      currency: this.saleDocumentModel.DocCurrency
    });

    if(Number(localStorage.getItem("SOAndSQAction")) !== SOAndSQActions.CopyToOrder){
      this.soForm.patchValue({
        BillOverdue: this.saleDocumentModel.U_FacturaVencida === "SI" ? "0" : "1",
        PayTerms: this.saleDocumentModel.U_Facturacion === "Contado" ? "0" : "1",
        PaymentMethod: this.saleDocumentModel.U_NVT_Medio_Pago,
        NumAtCard: this.saleDocumentModel.NumAtCard === "0" ? "" : this.saleDocumentModel.NumAtCard
      });
    }
    //endregion

    const customer = this.businessPartners.find(x => x.CardCode === this.saleDocumentModel.CardCode);

    if (customer) {
      this.priceList = customer.ListNum;
      this.getCurrencyByUser(customer.Currency);
      this.isCashCustomer = customer.QryGroup1 === "Y";

    }

    if (Number(localStorage.getItem("SOAndSQAction")) !== SOAndSQActions.CopyToOrder && this.saleDocumentModel.U_NumIdentFE) {

      this.feForm.patchValue({
        IdType: this.saleDocumentModel.U_TipoIdentificacion,
        Identification: this.saleDocumentModel.U_NumIdentFE,
        Email: this.saleDocumentModel.U_CorreoFE,
        ObservacionFE: this.saleDocumentModel.U_ObservacionFE,
      });

      if (this.isCashCustomer) {
        (<HTMLButtonElement>(
          document.getElementById("triggerFECollapser")
        )).click();
      }
    }

  }

  private SetDocumentBaseLines(): void{

    this.saleDocumentModel.DocumentLines.forEach((x) => {
      this.conta++;
      const tot = x.UnitPrice * x.Quantity;
      let itemAux = {
        Item: `${x.ItemCode}`,
        ItemCode: `${x.ItemCode} - ${x.ItemCode}`,
        ItemName: `${x.ItemName}`,
        CodeName: `${x.ItemCode} - ${x.ItemName}`,
        UnitPrice:
          JSON.parse(this.storage.GetCustomerData()).Currency === "COL"
            ? x.UnitPrice
            : parseFloat(
              Number(x.UnitPrice / this.DailyExRate).toString()
            ),
        UnitPriceCol: x.UnitPrice,
        UnitPriceDol: x.UnitPrice,
        U_SugPrice: 0,
        TaxCode: x.TaxCode,
        Quantity: x.Quantity,
        Active: true,
        Id: this.conta,
        LinTot: tot,
        TaxRate: x.TaxRate != 0.0 ? x.TaxRate : 0.0,
        DiscountPercent: x.DiscountPercent,
        WarehouseCode: x.WarehouseCode,
        WhsName: x.WhsName,
        Serie: "",
        SysNumber: 0,
        OnHand: x.OnHand,
        BaseEntry: x.BaseEntry,
        BaseType: x.BaseType,
        BaseLine: x.BaseLine == -1 ? null : x.BaseLine,
        LineNum: x.LineNum,
        U_NVT_ServicioMedico: x.U_NVT_ServicioMedico,
        TaxCode_BCK: x.TaxCode_BCK,
        TaxRate_BCK: x.TaxRate_BCK,
        ItemGroupCode: x.ItemGroupCode,
        LineStatus: x.LineStatus,
      } as IDocumentLine;

      this.itemsList.push(itemAux);
      this.LineTotalCalculate(this.itemsList.length - 1);
    });

  }
  private SetDocumentLines():void
  {
    this.IndexMaxUpdate =  this.saleDocumentModel.DocumentLines.reduce((acc, i) => (i.LineNum > acc.LineNum ? i : acc)).LineNum || 0;
    this.IndexMinDocument =  this.saleDocumentModel.DocumentLines.reduce((acc, i) => (i.LineNum < acc.LineNum ? i : acc)).LineNum || 0;

    this.saleDocumentModel.DocumentLines.forEach((x) => {
      this.conta++;
      this.total += x.UnitPrice;
      const tot = x.UnitPrice * x.Quantity;
      let itemAux = {
        Item: `${x.ItemCode}`,
        ItemCode: `${x.ItemCode} - ${x.ItemCode}`,
        ItemName: `${x.ItemName}`,
        CodeName: `${x.ItemCode} - ${x.ItemName}`,
        UnitPrice:
          JSON.parse(this.storage.GetCustomerData()).Currency === "COL"
            ? x.UnitPrice
            : parseFloat(
              Number(x.UnitPrice / this.DailyExRate).toString()
            ),
        UnitPriceCol: x.UnitPrice,
        UnitPriceDol: x.UnitPrice,
        U_SugPrice: 0,
        TaxCode: x.TaxCode,
        Quantity: x.Quantity,
        Active: true,
        Id: this.conta,
        LinTot: tot,
        TaxRate: x.TaxRate != 0.0 ? x.TaxRate : 0.0,
        DiscountPercent: x.DiscountPercent,
        WarehouseCode: x.WarehouseCode,
        WhsName: x.WhsName,
        Serie: "",
        SysNumber: 0,
        OnHand: x.OnHand,
        BaseEntry: x.BaseEntry,
        BaseType: x.BaseType,
        BaseLine: x.BaseLine,
        LineNum: x.LineNum,
        U_NVT_ServicioMedico: x.U_NVT_ServicioMedico,
        TaxCode_BCK: x.TaxCode_BCK,
        TaxRate_BCK: x.TaxRate_BCK,
        ItemGroupCode: x.ItemGroupCode,
        InvntItem: x.InvntItem,
        LineStatus: x.LineStatus,
      } as IDocumentLine;

      this.itemsList.push(itemAux);
      this.LineTotalCalculate(this.itemsList.length - 1);
    });

  }
  GetSaleOrder(quotationDocEntry: number) {
    this.blockUI.start("Procesando documento, espere por favor...");
    this.soAdnSQService.GetSaleOrder(quotationDocEntry).subscribe(
      (next) => {
        this.blockUI.stop();
        if (next.result) {
          this.conta = 0;
          this.total = 0;
          this.itemsList = [];



          let CustomerData: string = JSON.stringify({
            CardCode: next.Data.CardCode,
            Currency: next.Data.DocCurrency,
            SlpCode: next.Data.SalesPersonCode,
          });
          this.storageService.SaveCustomerData(CustomerData);
          this.DEFAULT_BUSINESS_PARTNER = next.Data.CardCode;

          this.saleDocumentModel = next.Data;

          let estadoOv = next.Data.U_EstadoOV;

          if (this.isOrderFromLink) {

            let msg: string = '';

            if (estadoOv == "02") {
              if (!this.isPermAutorizeSale)
                msg = "No cuenta con los permisos para realizar esta acción.";
            } else {
              msg = "El acceso a esta orden de venta no esta permitido.";
            }

            if (msg != '') {
              this.alertService.errorAlert(
                msg
              );
              this.router.navigate(["/home"]);
            }

            this.commonService.hasDocument.next(`Documento número ${this.saleDocumentModel.DocNum}`);
            this.storageService.SaveBreadCrum(`Documento número ${this.saleDocumentModel.DocNum}`);


          }

          if (estadoOv != null) {
            this.canAuthorize = estadoOv == "02" && this.isPermAutorizeSale;
            this.autForm.patchValue({ Estado: estadoOv });
          }

          this.baseLines = next.Data.DocumentLines;


          this.soForm.patchValue({
            PayTerms:
              this.saleDocumentModel.U_Facturacion === "Contado" ? "0" : "1",
            BillOverdue:
              this.saleDocumentModel.U_FacturaVencida === "SI" ? "0" : "1",
            SlpList: this.saleDocumentModel.SalesPersonCode,
            NumAtCard:
              this.saleDocumentModel.NumAtCard === "0"
                ? ""
                : this.saleDocumentModel.NumAtCard,
          });

          if (this.slpTypeaheadList.length > 0) {
            const SLP = this.slpTypeaheadList.find(x => x.SlpCode === this.saleDocumentModel.SalesPersonCode.toString());
            if (SLP) {
              this.soForm.patchValue({ SlpList: SLP });
            }
          }
          if (this.businessPartners.length > 0) {
            const CUSTOMER = this.businessPartners.find(
              (x) => x.CardCode === this.saleDocumentModel.CardCode
            );
            if (CUSTOMER) this.priceList = CUSTOMER.ListNum;
          }

          if (this.saleDocumentModel.U_NumIdentFE !== null) {
            this.feForm.patchValue({
              IdType: this.saleDocumentModel.U_TipoIdentificacion,
              Identification: this.saleDocumentModel.U_NumIdentFE,
              Email: this.saleDocumentModel.U_CorreoFE,
              ObservacionFE: this.saleDocumentModel.U_ObservacionFE,
            });

            this.isCashCustomer =
              this.saleDocumentModel.U_Facturacion === "Contado";

            if (this.isCashCustomer)
              (<HTMLButtonElement>(
                document.getElementById("triggerFECollapser")
              )).click();
          }

          this.soForm.patchValue({
            cardName: this.saleDocumentModel.CardName,
            PaymentMethod: this.saleDocumentModel.U_NVT_Medio_Pago,
            Comment: this.saleDocumentModel.Comments,
          });

          this.IndexMaxUpdate =  next.Data.DocumentLines.reduce((acc, i) => (i.LineNum > acc.LineNum ? i : acc)).LineNum || 0;
          this.IndexMinDocument =  next.Data.DocumentLines.reduce((acc, i) => (i.LineNum < acc.LineNum ? i : acc)).LineNum || 0;

          next.Data.DocumentLines.forEach((x) => {
            this.conta++;
            this.total += x.UnitPrice;
            const tot = x.UnitPrice * x.Quantity;
            let itemAux = {
              Item: `${x.ItemCode}`,
              ItemCode: `${x.ItemCode} - ${x.ItemCode}`,
              ItemName: `${x.ItemName}`,
              CodeName: `${x.ItemCode} - ${x.ItemName}`,
              UnitPrice:
                JSON.parse(this.storage.GetCustomerData()).Currency === "COL"
                  ? x.UnitPrice
                  : parseFloat(
                    Number(x.UnitPrice / this.DailyExRate).toString()
                  ),
              UnitPriceCol: x.UnitPrice,
              UnitPriceDol: x.UnitPrice,
              U_SugPrice: 0,
              TaxCode: x.TaxCode,
              Quantity: x.Quantity,
              Active: true,
              Id: this.conta,
              LinTot: tot,
              TaxRate: x.TaxRate != 0.0 ? x.TaxRate : 0.0,
              DiscountPercent: x.DiscountPercent,
              WarehouseCode: x.WarehouseCode,
              WhsName: x.WhsName,
              Serie: "",
              SysNumber: 0,
              OnHand: x.OnHand,
              BaseEntry: x.BaseEntry,
              BaseType: x.BaseType,
              BaseLine: x.BaseLine,
              LineNum: x.LineNum,
              U_NVT_ServicioMedico: x.U_NVT_ServicioMedico,
              TaxCode_BCK: x.TaxCode_BCK,
              TaxRate_BCK: x.TaxRate_BCK,
              ItemGroupCode: x.ItemGroupCode,
              InvntItem: x.InvntItem,
              LineStatus: x.LineStatus,
            } as IDocumentLine;

            this.itemsList.push(itemAux);
            this.LineTotalCalculate(this.itemsList.length - 1);
          });
          this.getTotals();
        } else {
          console.log(next);
          this.alertService.errorAlert(next.errorInfo.Message);
        }
      },
      (error) => {
        console.log(error);
        this.blockUI.stop();
        this.alertService.errorAlert(error);
      }
    );
  }

  queryFEData2() {
    let paymentTerm = this.PayTermsList.find(
      (x) => x.GroupNum === Number(this.soForm.controls.PayTerms.value)
    );

    this.hasIdentification = false;
    // if (paymentTerm.Type === PayTermsEnum.Contado && this.feForm.controls.IdType.value && this.feForm.controls.Identification.value) {
    if (
      this.isCashCustomer &&
      this.feForm.controls.IdType.value &&
      this.feForm.controls.Identification.value
    ) {
      this.blockUI.start("Obteniendo datos FE...");
      this.bpService
        .GetCustomersCont(
          this.feForm.controls.IdType.value,
          this.feForm.controls.Identification.value
        )
        .subscribe(
          (data: any) => {
            this.blockUI.stop();
            this.hasIdentification = true;
            if (data.result) {
              if (this.isOnCreation)
                this.soForm.patchValue({ cardName: data.FEInfo.CardName });
              this.feForm.patchValue({ Email: data.FEInfo.Email });
            } else {
              // busca info del cliente en el padron
              this.blockUI.stop();
              this.blockUI.start("Obteniendo datos del padrón...");
              if (this.feForm.controls.Identification.value != "") {
                this.bpService
                  .GetCustomersContPadron(
                    this.feForm.controls.Identification.value
                  )
                  .subscribe(
                    (data) => {
                      this.blockUI.stop();
                      if (
                        data.tipoIdentificacion ==
                        this.feForm.controls.IdType.value
                      ) {
                        if (this.isOnCreation)
                          this.soForm.patchValue({
                            cardName: data.nombre,
                          });
                      } else {
                        this.alertService.errorInfoAlert(
                          `Error no se ha encontrado la información en el padron del número de identificación ingresado.`
                        );
                      }
                    },
                    (error: any) => {
                      this.blockUI.stop();
                      this.alertService.errorInfoAlert(
                        `Error Padrón: No se ha encontrado la información en el padron del número de identificación ingresado.`
                      );
                    }
                  );
              }
            }
          },
          (error: any) => {
            this.blockUI.stop();
            this.alertService.errorInfoAlert(
              `Error Obteniendo datos FE!!!, Error: ${error.error.Message}`
            );
          }
        );
    }
  }
  // Usa para invertir flecha que despliega la data de fe
  toggleArrow(): void {
    this.typeArrow = !this.typeArrow;
  }
  // Usada para validar el estado del formulario de fe
  triggerFECollapser(_isCashCustomer: boolean, _isToggledArrow: boolean): void {
    if (!_isCashCustomer) {
      this.createFEForm();
      if (_isToggledArrow)
        (<HTMLButtonElement>(
          document.getElementById("triggerFECollapser")
        )).click();
    }
  }

  recalculateTaxes(): void {
    const PAYMENT_METHOD = this.soForm.controls.PaymentMethod.value;
    this.itemsList.forEach((x, i) => {
      if (
        x.ItemGroupCode == ITEM_GROUP_NAME.SERV_VETERINARIA &&
        PAYMENT_METHOD === "02"
      ) {
        // 02 - Tarjeta
        x.TaxCode = "44";
        x.TaxRate = 0;
        x.U_NVT_ServicioMedico = 1;
      } else {
        x.U_NVT_ServicioMedico = 0;
        x.TaxCode = x.TaxCode_BCK || x.TaxCode;
        x.TaxRate = x.TaxRate_BCK || x.TaxRate;
      }
      this.LineTotalCalculate(i);
    });
    this.getTotals();
  }

  raiseCustomerBalance(): void {
    this.overdueBalances = [];
    this.blockUI.start(`Obteniendo balance`);
    this.bpService
      .GetCustomerBalance(this.soForm.controls.cardCode.value)
      .subscribe((next) => {
        this.blockUI.stop();
        if (next.result) {
          this.overdueBalances = next.OverdueBalance;
          (<HTMLButtonElement>(
            document.getElementById("triggerBalancePreview")
          )).click();
        }
      });
  }

  closeAfterPayModal(): void { }

  redirectToQuotation() {
    this.router.navigate(["quotation"]);
  }

  redirectToSOandSQ() {
    this.router.navigate(["SOandSQ"]);
  }

  changeSlp($event: any): void {
    this.isSalesManSelected = true;
    let SLP = $event.item as ISaleMan;
    this.soForm.patchValue({ SlpList: SLP });
  }

  generateUniqueInvoiceId(): void {
    const USER_PREFIXID = JSON.parse(this.storage.getCurrentSession()).PrefixId;

    const DATE = new Date();

    const DAYS =
      DATE.getDate() < 10 ? "0" + DATE.getDate() : DATE.getDate().toString();
    const MONTS =
      DATE.getMonth() + 1 < 10
        ? "0" + (DATE.getMonth() + 1)
        : (DATE.getMonth() + 1).toString();
    const YEAR = DATE.getFullYear()
      .toString()
      .slice(
        DATE.getFullYear().toString().length - 2,
        DATE.getFullYear().toString().length
      );

    const HOURS =
      DATE.getHours() < 10 ? "0" + DATE.getHours() : DATE.getHours();
    const MINUTES =
      DATE.getMinutes() < 10 ? "0" + DATE.getMinutes() : DATE.getMinutes();
    const SECONDS =
      DATE.getSeconds() < 10 ? "0" + DATE.getSeconds() : DATE.getSeconds();

    this.uniqueInvCode = `${USER_PREFIXID + DAYS + MONTS + YEAR + HOURS + MINUTES + SECONDS
      }`;

    console.log("UIC ", this.uniqueInvCode);
  }

  overrideDiscount(): void {
    const DISCOUNT = this.soForm.value.Discount;

    if (DISCOUNT > 10) {
      this.soForm.patchValue({
        Discount: 0,
      });
      this.alertService.infoInfoAlert(
        `El descuento no puede ser mayor a ${this.maxDiscuont}% que es lo permitido para este usuario`
      );
      return;
    }

    const SIZE = this.itemsList.length;

    for (let c = 0; c < SIZE; c++) {
      if (this.itemsList[c].LinTot != 0) {
        this.itemsList[c].DiscountPercent = DISCOUNT;
      }
      this.LineTotalCalculate(c);
    }
  }

  SavePreviusAmount(_previusAmount: number, _index: number): void {
    if (!this.isPriceEditable || this.itemsList[_index].InvntItem === "Y") {
      this.alertService.infoInfoAlert(
        `No se puede editar el precio del item selecccionado`
      );
    }
    this.previusAmount = _previusAmount;
  }

  KeyUp(event: any, _index: number) {
    if (event.keyCode === 27) {
      this.itemsList[_index].UnitPrice = this.previusAmount;
      console.log(event.keyCode);
      this.LineTotalCalculate(_index);
      this.getTotals();
    }
  }

  SetValueUDF<V>(key: string, value: V): void {
    for (let i = 0; i < this.UdfsInDocument.length; i++) {
      if (this.UdfsInDocument[i].UDFName === key) {
        this.UdfsInDocument[i].Value = value;
        break;
      }
    }
  }

  GetDocumentUDFs(): UDFModel2[] {
    let UDFList: UDFModel2[] = [
      {
        Value: null,
        UDFName: "FacturaVencida",
        Description: "U_FacturaVencida",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "Facturacion",
        Description: "U_Facturacion",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "NVT_Medio_Pago",
        Description: "U_NVT_Medio_Pago",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "ObservacionFE",
        Description: "U_ObservacionFE",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "Almacen",
        Description: "U_Almacen",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "TipoDocE",
        Description: "U_TipoDocE",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "CorreoFE",
        Description: "U_CorreoFE",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "NumIdentFE",
        Description: "U_NumIdentFE",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "CLVS_POS_UniqueInvId",
        Description: "U_CLVS_POS_UniqueInvId",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "TipoIdentificacion",
        Description: "U_TipoIdentificacion",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "EstadoOV",
        Description: "U_EstadoOV",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "ObservacionAut",
        Description: "U_ObservacionAut",
        Type: 'String',
      },
      {
        Value: null,
        UDFName: "FechaAut",
        Description: "U_FechaAut",
        Type: 'DateTime',
      },
      {
        Value: null,
        UDFName: "CorreoAut",
        Description: "U_CorreoAut",
        Type: 'String',
      },
    ];

    return UDFList;
  }

  ChangePayterms($event: any): void {


    if (this.storage.GetDocEntry() > 0 && $event.target.value == "1") {
      this.blockUI.start("Consultado información del cliente.");
      this.bpService
        .CheckIfBPHaveAnyPending(this.saleDocumentModel.CardCode)
        .subscribe(
          (info) => {
            this.blockUI.stop();

            if (info.result) {
              if (info.Data.PayTermGroup != -1 && info.Data.RequiredAutorization) {
                this.alertService.infoAlert(
                  "Tiene documentos pendientes de pago, realizar gestión de cobro."
                );
                this.BpDocuemntPendingPay = info.Data.RequiredAutorization;
                if (
                  this.BpDocuemntPendingPay &&
                  this.soForm.controls.PayTerms.value === "1"
                  && this.storage.GetUIAction() !== SOAndSQActions.EditOrder
                ) {
                  this.onSubmitButtonPrefix = "Enviar órden a autorización";
                } else if (this.storage.GetUIAction() == SOAndSQActions.EditOrder) {
                  this.onSubmitButtonPrefix = "Actualizar órden de venta";
                } else {
                  this.onSubmitButtonPrefix = "Crear órden de venta";
                }

              }
            }
          },
          (err) => {
            this.blockUI.stop();
            this.alertService.errorAlert(
              "Ocurrio un error verificando los datos del cliente, causado por: " +
              err
            );
          }
        );
    } else
      if (
        this.BpDocuemntPendingPay &&
        this.soForm.controls.PayTerms.value === "1"
        && this.storage.GetDocEntry() < 0
      ) {
        this.onSubmitButtonPrefix = "Enviar órden a autorización";
      } else if (this.storage.GetUIAction() == SOAndSQActions.EditOrder) {
        this.onSubmitButtonPrefix = "Actualizar órden de venta";
      } else {
        this.onSubmitButtonPrefix = "Crear órden de venta";
      }

  }


  RisePBAutorizeContacts(): void {
    let modalOption: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'Modal-Xl',
      centered: true
    };

    let modalBPContacts = this.modalService.open(BPAutorizedContactsComponent, modalOption);
    modalBPContacts.componentInstance.cardCode = this.soForm.controls.cardCode.value;
  }


}
