import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgBlockUI, BlockUI } from "ng-block-ui";
import * as printJS from 'print-js';
import { PRINTERS_PER_REPORT, ReportType } from 'src/app/enum/enum';
import { IDocumentLine, IPrinter, IReportMerger, ISaleOrder } from 'src/app/models';
import { InvoicesOpen, SLSaleOrderToARInvoice } from 'src/app/models/i-document';
import { AlertService, DocumentService, ReportsService, StorageService } from 'src/app/services';
import { SOAndSQService } from 'src/app/services/soand-sq.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-info-invoice',
  templateUrl: './info-invoice.component.html',
  styleUrls: ['./info-invoice.component.scss']
})
export class InfoInvoiceComponent implements OnInit {
  invoiceSend: SLSaleOrderToARInvoice;
  Invoices: InvoicesOpen[] = [];
  saleOrder: ISaleOrder;
  DocumentLines: IDocumentLine[] = [];
  permisos: boolean = true;
  page = 1;
  pageSize = 10;
  collectionSize: number;
  previewForm: FormGroup;
  dateTimeExitUI = "";
  titleInvoice: string;
  returnedDocNum: string;
  returnedDocEntry: string;
  returnedNumFE: string;
  docEntries: number[];
  @BlockUI() blockUI: NgBlockUI;
  constructor(private soAndSqService: SOAndSQService,
    private alertService: AlertService,
    private documentService: DocumentService,
    private reportsService: ReportsService,
    private storageService: StorageService,) { }

  ngOnInit() {
    this.resetPreviewForm();
    this.initVarialbles();
  }

  closeAfterPayModal(): void {
    this.initVarialbles();
  }
  resetPreviewForm(): void {
    this.previewForm = new FormGroup({
      CardName: new FormControl(""),
      DocDate: new FormControl(""),
      DocNum: new FormControl(""),
      SalesMan: new FormControl(""),
      DocTotal: new FormControl(""),
      U_Facturacion: new FormControl(""),
    }); // La definicion del formularioFormGroup;
  }

  initVarialbles(): void {
    this.returnedDocNum = "0";
    this.returnedDocEntry = "0";
    this.returnedNumFE = "";
    this.dateTimeExitUI = "";
    this.invoiceSend = null;
    this.Invoices = [];
    this.saleOrder = null;
    this.getInvoicesOpen();
  }

  getInvoicesOpen(): void {
    this.Invoices = [];
    let almacen = this.storageService.getBranchOffice().Code;
    if (almacen) {

    } else {
      this.alertService.infoInfoAlert('Ha surgido un problema en la lectura de la caché para obtener almacén, cierre e inicie sessión.');
      return;
    }
    this.blockUI.start("Obteniendo documentos, espere por favor");
    this.soAndSqService.GetOpenInvoices(almacen).subscribe(
      (response) => {
        this.blockUI.stop();
        if (response.result) {

          if (response.Data.length === 0) {
            this.alertService.infoInfoAlert("No se encontraron facturas de contado en estado abiertas.");
          }

          this.collectionSize = response.Data.length;
          this.Invoices = response.Data;
        } else {
          this.alertService.errorAlert(
            "Ocurrió un error obteniendo facturas de contado en estado abiertas" +
            response.errorInfo.Message
          );
        }
      },
      (err) => {
        this.blockUI.stop();
        this.alertService.errorAlert(
          "Ocurrió un error obteniendo facturas de contado en estado abiertas" + err
        );
      }
    );
  }



  SendInvoice(_uniqueInvId: string): void {
    this.invoiceSend = null;
    this.soAndSqService.getLocalInvoice(_uniqueInvId).subscribe(
      (response) => {
        if (response.result) {
          if (response.Data.Document) {
            this.invoiceSend = response.Data;
            this.blockUI.start("Reenviando factura y pago, espere por favor");

            this.documentService
              .CreateInvoiceFromSO(this.invoiceSend)
              .subscribe(
                (callback) => {
                  this.blockUI.stop();
                  if (
                    callback && callback.Data &&
                    callback.Data.PaymentsResponse &&
                    callback.Data.PaymentsResponse.length > 0 &&
                    callback.Data.PaymentsResponse[0].PaymentResponse &&
                    callback.Data.PaymentsResponse[0].PaymentResponse.result
                  ) {

                    this.dateTimeExitUI = formatDate(
                      new Date(),
                      "yyyy-MM-dd HH:mm:ss SSS a",
                      "en"
                    );
                    this.returnedDocNum = "";
                    this.returnedDocEntry = "";
                    this.returnedNumFE = "";
                    this.titleInvoice = "Pago exitoso";


                    (<HTMLButtonElement>(
                      document.getElementById("triggerAfterPayModal")
                    )).click();

                    let docEntries: number[] = [];

                    callback.Data.PaymentsResponse.forEach((x) => {
                      this.returnedDocNum += x.DocNum + ", ";
                      this.returnedDocEntry += x.DocEntry + ", ";
                      this.returnedNumFE += x.NumDocFe + ", ";
                      docEntries.push(x.DocEntry);
                    });

                    this.printARInvoice(docEntries);

                    this.returnedDocNum = this.returnedDocNum.slice(0, -2);
                    this.returnedDocEntry = this.returnedDocEntry.slice(0, -2);
                    this.returnedNumFE = this.returnedNumFE.slice(0, -2);


                  } else {
                    console.log(callback.Data);
                    if (callback.Data && callback.Data) {
                      let message = "";

                      if (callback.Data && callback.Data.PaymentsResponse.length > 0) {
                        if (
                          callback.Data.PaymentsResponse[0].PaymentResponse &&
                          callback.Data.PaymentsResponse[0].PaymentResponse.errorInfo
                        ) {
                          message =
                            callback.Data.PaymentsResponse[0].PaymentResponse.errorInfo
                              .Message;
                        }
                      }

                      this.alertService.errorAlert(
                        `Error al aplicar pago ${message}`
                      );
                    } else {
                      this.alertService.errorAlert(
                        `Error al generar factura y pago ${callback.Data && callback.Data.errorInfo ? callback.Data.errorInfo.Message : ""
                        }`);
                    }

                  }

                },
                (error) => {
                  console.log(error);

                  this.blockUI.stop();
                  this.alertService.errorInfoAlert(
                    `Error al intentar conectar con el servidor, Error: ${error}`
                  );

                }
              );

          } else {
            this.alertService.infoInfoAlert("No se encontró documento en la bases de datos local para reenviar pago");
          }
        } else {
          this.alertService.errorAlert(
            "Ocurrió un error obteniendo factura de la bases de datos local" +
            response.errorInfo.Message
          );
        }
      },
      (err) => {
        this.blockUI.stop();
        this.alertService.errorAlert(
          "Ocurrió un error obteniendo  obteniendo factura de la bases de datos local" + err
        );
      }
    );


  }

  preview(_uniqueInvId: string, _Docnum: number): void {
    this.blockUI.start("Obteniendo documento, espere por favor");
    this.soAndSqService.getLocalInvoice(_uniqueInvId).subscribe(
      (response) => {
        this.blockUI.stop();
        if (response.result) {
          if (response.Data.Document) {
            this.DocumentLines = [];
            this.saleOrder = response.Data.Document.SaleOrders[0];
            response.Data.Document.SaleOrders.forEach((y) => {
              if (y.U_CLVS_POS_UniqueInvId === _uniqueInvId) {
                this.saleOrder.DocTotal = y.DocTotal;
                this.saleOrder.DocNum = _Docnum;
                this.saleOrder.SalesMan = y.SalesMan;
                if (y.DocumentLines.length > 0) {
                  y.DocumentLines.forEach((x) => {
                    let itemAux = {
                      Item: x.ItemCode,
                      ItemCode: x.ItemCode,
                      ItemName: x.ItemName,
                      CodeName: `${x.ItemCode} - ${x.ItemName}`,
                      UnitPrice: x.UnitPrice,
                      UnitPriceCol: x.UnitPrice,
                      UnitPriceDol: x.UnitPrice,
                      U_SugPrice: 0,
                      TaxCode: x.TaxCode,
                      Quantity: x.Quantity,
                      Active: true,
                      Id: 0,
                      LinTot: 0,
                      TaxRate: x.TaxRate != 0.0 ? x.TaxRate : 0.0,
                      DiscountPercent: x.DiscountPercent,
                      WarehouseCode: x.WarehouseCode,
                      Serie: "",
                      SysNumber: 0,
                      OnHand: 0,
                      BaseEntry: 0,
                      BaseType: x.BaseType,
                      BaseLine: x.BaseLine,
                      LineNum: x.LineNum,
                      U_NVT_ServicioMedico: x.U_NVT_ServicioMedico,
                    } as IDocumentLine;

                    this.DocumentLines.push(itemAux);
                  });
                }
              }

            });

            this.saleOrder.DocumentLines = [];
            this.saleOrder.DocumentLines = this.DocumentLines;
            this.saleOrder.DocDate = formatDate(
              this.saleOrder.DocDate,
              "yyyy-MM-dd",
              "en"
            );
            this.previewForm.patchValue({ ... this.saleOrder });

            (<HTMLButtonElement>(
              document.getElementById("previewTrigger")
            )).click();
          } else {
            this.alertService.infoInfoAlert("No se encontró documento en la bases de datos local");
          }

        } else {
          this.alertService.errorAlert(
            "Ocurrió un error obteniendo factura de la bases de datos local" +
            response.errorInfo.Message
          );
        }
      },
      (err) => {
        this.blockUI.stop();
        this.alertService.errorAlert(
          "Ocurrió un error obteniendo obteniendo factura de la bases de datos local" + err
        );
      }
    );
  }

  printARInvoice(_docEntries: number[]): void {
    this.docEntries = _docEntries;
    this.blockUI.start("Generando la impresión...");
    const REPORT_MERGER = {
      DocEntries: _docEntries,
      ReportType: ReportType.ArInvoice,
    } as IReportMerger;
    this.reportsService.MergeReportsToPrint(REPORT_MERGER).subscribe(
      (data: any) => {
        this.blockUI.stop();
        printJS({
          printable: data,
          type: "pdf",
          base64: true,
        });
      },
      (error: any) => {
        this.blockUI.stop();
        console.log("error");
        this.alertService.errorInfoAlert(
          `Error al intentar conectar con el servidor, Error: ${error}`
        );
      }
    );
  }
  printInvoice(): void {
    this.printARInvoice(this.docEntries);
  }
}
