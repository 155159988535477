import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// MODELOS
import { AppConstants, ITerminal } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { BaseResponse, IPPBalanceResponse, ITerminalResponse, ITerminalsResponse } from '../models/responses';
import { Observable } from 'rxjs';
import { IPPBalanceRequest } from '../models/i-ppbalance-request';

// PIPES

@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor( private http: HttpClient,
    private storage: StorageService ) {
  }

  // funcion para obtener los bancos
  // no recibe parametros
  getAccountsBank() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(`${AppConstants.apiUrl}api/Banks/GetAccountsBank`,
      { headers });
  }

  getTerminals(): Observable<ITerminalsResponse> {
    const token = JSON.parse(this.storage.getCurrentSessionOffline());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<ITerminalsResponse>(`${AppConstants.offlineUrl}api/Banks/GetTerminals`,
      { headers });
  }

  getTerminal(_id: number): Observable<ITerminalResponse> {
    const token = JSON.parse(this.storage.getCurrentSessionOffline());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<ITerminalResponse>(`${AppConstants.offlineUrl}api/Banks/GetTerminal?id=${_id}`, { headers });
  }

  CreateTerminal(_terminal: ITerminal): Observable<BaseResponse> {
    const token = JSON.parse(this.storage.getCurrentSessionOffline());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<BaseResponse>(`${AppConstants.offlineUrl}api/Banks/CreateTerminal`, _terminal, { headers });
  }

  UpdateTerminal(_terminal: ITerminal): Observable<BaseResponse> {
    const token = JSON.parse(this.storage.getCurrentSessionOffline());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<BaseResponse>(`${AppConstants.offlineUrl}api/Banks/UpdateTerminal`, _terminal, { headers });
  }

  PreBalance(_balanceRequest: IPPBalanceRequest): Observable<IPPBalanceResponse> {
    const token = JSON.parse(this.storage.getCurrentSessionOffline());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IPPBalanceResponse>(`${AppConstants.offlineUrl}api/Banks/PreBalance`, _balanceRequest, { headers });
  }

  GetRequestsFromRegisters(_balanceRequest: IPPBalanceRequest): Observable<IPPBalanceResponse> {
    const token = JSON.parse(this.storage.getCurrentSessionOffline());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IPPBalanceResponse>(`${AppConstants.offlineUrl}api/Banks/PreBalanceOnRegister`, _balanceRequest, { headers });
  }
  
  Balance(_terminalId: number): Observable<IPPBalanceResponse> {
    const token = JSON.parse(this.storage.getCurrentSessionOffline());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IPPBalanceResponse>(`${AppConstants.offlineUrl}api/Banks/Balance?terminalId=${_terminalId}`, {  headers });
  }

}
