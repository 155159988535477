import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Observable, Subscription, fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { DecimalPipe, CurrencyPipe, DatePipe } from "@angular/common";
import { NgbModal, ModalDismissReasons, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { formatDate } from '@angular/common';

// MODELOS
import { Params, UserAssigns, IdentificationType, PayTermsEnum, TypeInvoice, IViewGroup, IPPTransaction, Company, IPrinter } from "./../../../models/index";
import { ReportType, BACErrorCodes } from "../../../enum/enum";
// CONSTANTS
const printJS = require("print-js");

// SERVICIOS
import { Router, ActivatedRoute } from '@angular/router';
import {
  CompanyService, UserService, ItemService, BusinessPartnerService, DocumentService, TaxService, PermsService, AuthenticationService, ParamsService, ReportsService,
  AccountService, ExRateService, CardService, BankService, AlertService, SalesManService, JsonDataService, StorageService, PaymentService, CommonService
} from "../../../services/index";

// Electron renderer service
import { BranchOfficeModel2 } from "src/app/models/branch-office";

// PIPES

export enum KEY_CODE {
  F4 = 115,
  F7 = 118,
  F8 = 119

}

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  providers: [DecimalPipe, CurrencyPipe, DatePipe]
})
export class InvoiceComponent implements OnInit, OnDestroy, AfterViewInit {

  //#region Branch office
  branchOffice: BranchOfficeModel2;
  //#endregion

  //#region Cambios Jorge EMA Aromas
  whName: string;
  whCode: string;
  //#endregion

  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual

  public model: any;
  @BlockUI() blockUI: NgBlockUI;

  invForm: FormGroup; // formulario para la orden de venta
  feForm: FormGroup;
  // totalForm: FormGroup; // formulario para el total de la orden de venta
  submitted = false; // variable para reconcer si se envio una peticion o no
  maxDiscuont: any;
  setCurr: string; // tipo de moneda escogida en simbolo
  currencyList: any[] = []; // lista de tipos de cambio
  allCurrencyList: any[] = []; // lista de todos los tipos de cambio existentes en la aplicacion
  itemsList: any[] = []; // lista de items
  companiesList: any[] = []; // lista de las compannias
  itemsTypeaheadList: string[] = []; // lista de la concatenacion del Código con el nombre del item
  itemsCodeList: string[] = []; // lista de los Códigos de items
  itemsNameList: string[] = []; // lista de los nombres de items
  PayTermsList: any[] = []; // lista de los Terminos de pagos
  PriceList: any[] = []; // lista de las listas de precios
  SlpsList: any[] = []; // lista de los vendedores
  bpList: any[] = []; // lista de clientes
  bpCodeList: string[] = []; // lista de los Códigos de clientes
  bpNameList: string[] = []; // lista de los nombres de clientes
  conta: number; // variable contador para colocar un 'id' a la lista de items
  total: number; // variable para almacenar el total de la factura
  totalUSD: number;
  tax: number; // variable para almacenar el total de impuestos
  isAllowedPriceListChange: boolean; // Controla el cambio de lista por parte del usuario
  discount: number; // variable para almacenar el total de descuento
  totalWithoutTax: number; // variable para almacenar el total sin impuesto
  taxesList: any[] = []; // lista de los impuestos
  viewParamList: any[] = []; // llena la lista con los componentes parametrizados
  viewParamListHeader: any[] = []; // llena la lista con los componentes parametrizados
  viewParamListTotales: any[] = []; // llena la lista con los componentes parametrizados
  viewParamTitles: any[] = []; // llena la lista con los titulos de las paginas parametrizados
  closeResult: string; // variable para saber porque se cerro la modal
  modalReference: any; // instancia de la modal de terminal y sucursal
  WHAvailableItemList: any[] = []; // lista de los items disponibles por almacen
  indexAvaItem: number; // indice de la lista del item seleccionado para la disponibilidad
  itemCode: string; // variable para almacenar el Código del ite seleccionado para buscar la disponibilidad
  seriesList: any[] = []; // lista de las series de un item po almacen
  nombreProvincia: string; // almacena el nombre de la provincia de la direccion de la fe
  nombreCanton: string; // almacena el nombre de la canton de la direccion de la fe
  nombreDistrito: string; // almacena el nombre de la distrito de la direccion de la fe
  nombreBarrio: string; // almacena el nombre de la barrio de la direccion de la fe
  userCurrency: string; // Usado para precargar el tipo de moneda que usara el usuario
  // modal de pagos
  cashForm: FormGroup; // formulario para el efectivo
  creditCardForm: FormGroup; // formulario para las tarjetas de credito
  transferForm: FormGroup; // formulario para la tranferencia
  checkForm: FormGroup; // formulario para el cheque
  accountList: any[] = []; // lista de cuentas
  cardsList: any[] = []; // lista de tarjetas
  banksList: any[] = []; // lista de bancos
  salesManList: any[] = []; // lista de vendedores
  currencyPayment: string; // moneda selecionada al buscar los anticipos
  modalPay: any; // instancia de la modal de pagos
  modalChange: any; // instancia de la modal de vueltos
  TotalG: number; // monto total del pago
  ReceivedG: number; // monto total recibido del pago
  DifferenceG: number; // monto total de la diferencia, o salto restante
  ChangeG: number; // guarda el vuelto
  TotalCash: number; // monto total del pago en efectivo
  TotalCards: number; // monto total del pago en tarjetas
  TotalTransfer: number; // monto total del pago en transferencia
  TotalCheck: number; // monto total del pago en cheque
  V_CreditCards: any[] = []; // lista de pagos registrados con tarjetas de credito
  CardName: string; // nombre de la tarjeta seleccionada para el pago con tarjeta
  V_Checks: any[] = []; // lista de pagos registrados con cheques
  correctInvoice: boolean = false;  //dice si el pago se realizo correctamente
  facturando: boolean = false;
  hasLines: boolean = false; //dice si el pago se realizo correctamente
  isPagoCuenta: boolean;
  currencyChange: number; // monto del tipo de cambio
  unamePattern = "^d{2}/d{2}$";
  btnVisibleBool: boolean;
  defaultSlpCode: number = -1;
  defaultSlpCodeStr: any;
  defaultGroupNum: any;
  defaultListNum: any;
  defaultContado: any;
  public expandedIndex: number; // variable para manejar el collapse de los items y reconocer sobre cual se va a trabajar
  title: string; // titulo de la vista
  feOpen: boolean = false; // dice si la pestana de datos de fe esta abierta
  _timeout: any = null;
  changeColones: number; // vuelto en colones
  changeDolares: number; // vuelto en dolares
  // --------Campos Parametrizados cabezera
  lbCardCode: Params = new Params(); // etiqueta para CardCode
  txtCardCode: Params = new Params(); // campo para CardCode
  lbCardName: Params = new Params(); // etiqueta para CardName
  txtCardName: Params = new Params(); // campo para CardName
  lbCurrency: Params = new Params(); // etiqueta para CardName
  txtCurrency: Params = new Params(); // campo para CardName
  txtPayTerms: Params = new Params(); // campo para Terminos de pagos
  lbPayTerms: Params = new Params(); // etiqueta para Terminos de pagos
  txtPriceList: Params = new Params(); // campo para Listas de precios
  lbPriceList: Params = new Params(); // etiqueta para Listas de precios
  txtComments: Params = new Params(); // campo para el comentario
  lbComments: Params = new Params(); // etiqueta para el comentario
  lbSLP: Params = new Params(); // etiqueta para el vendedor
  txtSLP: Params = new Params(); // campo para el vendedor
  pinPadCard: IPPTransaction;
  pinPadCards: IPPTransaction[];
  DEFAULT_BUSSINESS_PARTNER: string;
  // -----------------------------
  // -------- Campos para metrizables de totales
  lbTotalExe: Params = new Params(); // etiqueta para Total sin impuestos
  txtTotalExe: Params = new Params(); // campo para Total sin impuestos
  lbDiscount: Params = new Params(); // etiqueta para descuentos
  txtDiscount: Params = new Params(); // campo para descuentos
  lbTaxes: Params = new Params(); // etiqueta para Impuestos
  txtTaxes: Params = new Params(); // campo para Impuestos
  lbTotal: Params = new Params(); // etiqueta para Total
  txtTotal: Params = new Params(); // campo para Total
  // -----------------------------
  TotalCol: FormControl = new FormControl();
  TotalUSD: FormControl = new FormControl();
  Cant: FormControl = new FormControl();
  ItemInfo: FormControl = new FormControl();
  permisos: boolean = true;
  paymentTypes: any[] = [
    { id: '1', name: "A30Dias" },
    { id: '2', name: "Contado" },
  ];
  TypesInvoice: any[] = [];
  paymentType: number = 1;
  pesoBolsa: number = 0.020;
  priceEditable: boolean = false;
  maxWeightTo0: number = 0.01;
  userAssignsList: UserAssigns[] = [];
  identificationTypeList: any[] = [];
  typesInvoice: any[] = [];
  TRANSFER_DATE = new Date(Date.now());
  provinceList: any[] = [];// provincias
  cantonList: any[] = []; // lista de cantones
  districtList: any[] = []; // lista de distritos
  neighborhoodList: any[] = []; // lista de barrios
  provinceId: string; // identificador de la provincia
  cantonId: string; // identificador del canton
  districtId: string; // identificador del distrito
  neighborhoodId: string; // identificador del barrio
  DailyExRate: number;
  MapWidth: any;
  tableLength: number;
  isOnSubmit: boolean = false;
  @ViewChild("name") nameField: ElementRef;
  lastInvoice: number;
  @ViewChild("payContent") payContentModal: any;
  isBilling: boolean;
  attemptsWhileBilling: number = 0;
  viewGroupList: IViewGroup[] = []; //contiene lista de agrupaciones en linea
  isOnEditMode = false;
  isOnGroupLine: boolean;
  isRequestinBacPayment: boolean;
  @ViewChild('editUnitPrice') editUnitPrice: ElementRef; // Referencia al input de edicion de precio por linea, para poder setear el focus cuando se va a editar
  onlineEvent: Observable<Event>; // Permite detectar el evento de la conexion cuando se recupera
  offlineEvent: Observable<Event>;// Permite detectar el evento de la conexion cuanod se pierde
  subscriptions: Subscription[] = []; // Contiene la lista de todas las subscriptions
  COMPANY: Company; // Usada para guardar las configuraciones del a compania
  TO_FIXED_PRICE: string; // Contiene la cantidad de decimales a usar en el precio unitario
  TO_FIXED_TOTALLINE: string; // Contiene la cantidad de decimales a usar en el total de linea
  TO_FIXED_TOTALDOCUMENT: string; // Contiene la cantidad de decimales a usar en el total del documento
  // Permite cambiar entre los inpus de lectura y edicion para el precio unitario
  toggleEdition(): void {
    if (this.priceEditable) {
      setTimeout(() => {
        this.editUnitPrice.nativeElement.focus();
      }, 0);
      this.isOnEditMode = true;
    }
  }
  // Si el input de editar el precio pierde el foco, se restablece el contenido al valor previo que tenia
  onFocusOutEvent($event: any) {
    this.isOnEditMode = false;
  }

  onkeyUp(event: any, index: number): void {
    if (this.priceEditable) {
      if (event.key === 'Enter') {
        this.itemsList[index].UnitPrice = +event.target.value;
        this.isOnEditMode = false;
        this.LineTotalCalculateExt(index);
      }
      if (event.key === 'Escape') {
        this.isOnEditMode = false;
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (!event.ctrlKey && event.altKey && !event.shiftKey && event.keyCode === 73) {

      console.log('ALT + I');
    }
    if (!event.ctrlKey && event.altKey && !event.shiftKey && event.keyCode === 79) {
      console.log('ALT + O');
    }
    if (!event.ctrlKey && event.altKey && !event.shiftKey && event.keyCode === 80) {
      console.log('ALT + P');
    }
    switch (event.keyCode) {
      case KEY_CODE.F4: {
        this.nameField.nativeElement.focus();
        break;
      }
      case KEY_CODE.F7: {
        if (!this.isOnSubmit) {
          this.isOnSubmit = true;
          this.onSubmit(this.payContentModal);
          break;
        }

      }
      case KEY_CODE.F8: {
        if (!this.isOnSubmit) {
          this.CreateNew();
          break;
        }

      }
    }
  };

  Comments: FormControl = new FormControl();

  constructor(private fb: FormBuilder,
    private itemService: ItemService,
    private cdr: ChangeDetectorRef,
    private bpService: BusinessPartnerService,
    private sPerm: PermsService,
    private decimalPipe: DecimalPipe,
    private documentService: DocumentService,
    private taxService: TaxService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private paramsService: ParamsService,
    private companyService: CompanyService,
    private uService: UserService,
    private reportsService: ReportsService,
    private accountService: AccountService,
    private exRateService: ExRateService,
    private exrate: ExRateService,
    private cardService: CardService,
    private bankService: BankService,
    private router: Router,
    private alertService: AlertService,
    private jsonDataService: JsonDataService,
    private smService: SalesManService,
    private cp: CurrencyPipe,
    private storage: StorageService,
    private activatedRoute: ActivatedRoute,
    private paymentService: PaymentService,
    private datePipe: DatePipe,
    private commonService: CommonService) {

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
      this.checkPermits();
    });
    this.expandedIndex = -1;
  }

  mDate: string;

  initVarialbles(): void {
    this.COMPANY = this.storage.getCompanyConfiguration();
    //#region Branch office
    this.branchOffice = this.storage.getBranchOffice();
    //#endregion

    this.TO_FIXED_PRICE = `1.${this.COMPANY.DecimalAmountPrice}-${this.COMPANY.DecimalAmountPrice}`;
    this.TO_FIXED_TOTALLINE = `1.${this.COMPANY.DecimalAmountTotalLine}-${this.COMPANY.DecimalAmountTotalLine}`;
    this.TO_FIXED_TOTALDOCUMENT = `1.${this.COMPANY.DecimalAmountTotalDocument}-${this.COMPANY.DecimalAmountTotalDocument}`;
    this.DEFAULT_BUSSINESS_PARTNER = this.storage.getBranchOffice().CashBusinessPartnerCode;

    this.isRequestinBacPayment = false;
    this.pinPadCards = [];
    this.pinPadCard = null;
    this.defaultGroupNum = "-1";
    this.defaultListNum = "-1";
    this.defaultContado = "Contado";
    this.defaultSlpCodeStr = "-1";
    this.tableLength = 1000;
    this.MapWidth = {};
    this.MapWidth["Id"] = 80;
    this.MapWidth["ItemCode"] = 450;
    this.MapWidth["UnitPrice"] = 200;
    this.MapWidth["Marca"] = 200;
    this.MapWidth["Group"] = 200;
    this.MapWidth["Quantity"] = 110;
    this.MapWidth["SubGroup"] = 100;
    this.MapWidth["ItemName"] = 350;
    this.MapWidth["Discount"] = 80;
    this.MapWidth["TaxRate"] = 80;
    this.MapWidth["TaxCode"] = 80;
    this.MapWidth["WhsCode"] = 80;
    this.MapWidth["WhsName"] = 200;
    this.MapWidth["LastDate"] = 100;
    this.MapWidth["LinTot"] = 100;
    this.MapWidth["Serie"] = 100;
    this.MapWidth["Opc"] = 100;
    this.btnVisibleBool = true;
    this.conta = 0.0;
    this.total = 0.0;
    this.totalUSD = 0.0;
    this.tax = 0.0;
    this.discount = 0.0;
    this.totalWithoutTax = 0.0;
    this.TotalG = 0.0;
    this.identificationTypeList = IdentificationType;
    this.typesInvoice = TypeInvoice;
    this.isAllowedPriceListChange = false;
    this.currentUser = JSON.parse(this.storage.getSession(navigator.onLine));
    this.invForm = this.fb.group({
      DocumentType: ['', Validators.required],
      cardCode: [this.DEFAULT_BUSSINESS_PARTNER, Validators.required],
      cardName: ['', Validators.required],
      currency: ['', Validators.required],
      PayTerms: ['', Validators.required],
      PriceList: ['', Validators.required],
      SlpList: ['', Validators.required],
      paymentType: ['']
    });

    this.invForm.patchValue({ paymentType: this.paymentTypes[0].id });
    this.invForm.patchValue({ DocumentType: this.typesInvoice[0].Name });
    this.cashForm = this.fb.group({
      AccountCash: ["", Validators.required],
      TotalCash: [0, Validators.required],
    });
    this.creditCardForm = this.fb.group({
      CreditCard: ["", Validators.required],
      CardNum: [""], // , [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      OwnerIdNum: ["", Validators.required],
      CardValid: [""], //, [Validators.required, Validators.minLength(3), Validators.maxLength(6)]
      CreditSum: [0, Validators.required],
      VoucherNum: ["", Validators.required],
    });


    // this.terminalForm.patchValue({
    //   TerminalId: -1,
    //   From: DATE.getFullYear() + '-' + (DATE.getMonth() + 1) + '-' + DATE.getDate(),
    //   To: DATE.getFullYear() + '-' + (DATE.getMonth() + 1) + '-' + DATE.getDate()
    // });
    this.mDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    this.transferForm = this.fb.group({
      AccountTransf: ["", Validators.required],
      DateTrans: [this.mDate, Validators.required],
      Ref: [""],
      TransSum: [0, Validators.required],
    });

    this.checkForm = this.fb.group({
      AccountCheck: ['', Validators.required],
      BankNames: ['', Validators.required],
      DueDate: ['', Validators.required],
      CountryCod: [{ value: 'CR', disabled: true }, Validators.required],
      AcctNum: ['', Validators.required],
      CheckNum: ['', Validators.required],
      CheckSum: [0, Validators.required]
    });
    this.creditCardForm.patchValue({ VoucherNum: '0' });
    this.creditCardForm.patchValue({ CardNum: '9999' });
    this.creditCardForm.patchValue({ CardValid: '01/99' });
    this.createFEForm();
    this.getMaxDiscout();
    this.getCustomers();
    this.GetParamsViewList();

    //this.GetCurrencyType();
    this.getExchangeRate();
    this.getExRate();
    this.GetPriceList();

    this.getItems();
    this.getTaxes();
    this.getAccount();
    this.getCards();
    this.getAccountsBank();
    this.getCompanies();
    this.generateUniqueInvoiceId();
    this.setSalesPerson();
    this.setWarehouseInfo();

    this.Cant.setValue(1);
    this.correctInvoice = false;
  }

  ngOnInit() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(async e => {
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
    }));
    this.initVarialbles();
    this.companyService.GetViewGroupList().subscribe(next => {
      if (next.result) {
        ((next.ViewGroupList) as IViewGroup[]).forEach(x => {
          if (x.CodNum === 3) this.isOnGroupLine = x.isGroup;//Facturación
        });
      }
    });
    // const DATE = new Date();
    // this.transferForm.patchValue({
    //   DateTrans: DATE.getFullYear() + '/' + (DATE.getMonth() + 1) + '/' + DATE.getDate()
    // });
  }


  ngAfterViewInit() { }

  getBPFEData() {
    if (Number(this.invForm.controls.PayTerms.value) === PayTermsEnum.A30Dias) {
      this.blockUI.start('Obteniendo datos FE...');
      this.bpService.GetCustomersCred(this.invForm.controls.cardCode.value).subscribe((data: any) => {
        this.blockUI.stop();
        if (data.result) {
          // console.log(data);
          this.getProvincesPatch(data);
        }
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error getTaxes!!!, Error: ${error.error.Message}`);
      });
    }
    // borra los datos de FE si existieran cuando se pasa a contado
    else {
      this.feForm.patchValue({ IdType: '01' });
      this.feForm.get('Identification').setValidators([]); // or clearValidators()
      this.feForm.get('Identification').updateValueAndValidity();
      this.feForm.patchValue({ Identification: '' });
      this.feForm.patchValue({ Email: '' });
      this.feForm.get('Email').setValidators([]); // or clearValidators()
      this.feForm.get('Email').updateValueAndValidity();
    }
  }
  // obtiene la informacion de factura electronica cuando se introducen ciertos datos preliminares de factura de contado
  queryFEData() {
    if (this._timeout) {
      window.clearTimeout(this._timeout);
    }
    this._timeout = window.setTimeout(() => {
      this._timeout = null;
      if (Number(this.invForm.controls.PayTerms.value) === PayTermsEnum.Contado && this.feForm.controls.IdType.value !== "") {
        this.blockUI.start('Obteniendo datos FE...');
        this.bpService.GetCustomersCont(this.feForm.controls.IdType.value, this.feForm.controls.Identification.value).subscribe((data: any) => {
          this.blockUI.stop();
          if (data.result) {
            this.feForm.patchValue({ Email: data.FEInfo.Email });
            this.feForm.patchValue({ Direccion: data.FEInfo.Direccion });
            let provid = '01';
            let provname = this.provinceList[0].ProvinceName;
            this.provinceList.forEach(prov => {
              if (prov.ProvinceName.toUpperCase() === data.FEInfo.Provincia.toUpperCase()) {
                provid = prov.ProvinceId;
                provname = prov.ProvinceName;
              }
            });
            this.feForm.patchValue({ Provincia: Number(provid).toString() });
            this.nombreProvincia = provname;
            this.getCantonsPatch(provid, data.FEInfo.Canton, data.FEInfo.Distrito, data.FEInfo.Barrio);
          }
        }, (error: any) => {
          this.blockUI.stop();
          this.alertService.errorInfoAlert(`Error getTaxes!!!, Error: ${error.error.Message}`);
        });
      }
    }, 2000);
  }

  queryFEData2() {
    if (Number(this.invForm.controls.PayTerms.value) === PayTermsEnum.Contado && this.feForm.controls.IdType.value !== "") {
      this.blockUI.start('Obteniendo datos FE...');
      this.bpService.GetCustomersCont(this.feForm.controls.IdType.value, this.feForm.controls.Identification.value).subscribe((data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.invForm.patchValue({ cardName: data.FEInfo.CardName });
          this.feForm.patchValue({ Email: data.FEInfo.Email });
          // this.feForm.patchValue({ Direccion: data.FEInfo.Direccion });
          // let ProvinciaObj = data.FEInfo.Provincia.split("-");
          // let ProvinciaId = ProvinciaObj[0];
          // switch (ProvinciaId) {
          //   case "01": {
          //     ProvinciaId = "1";
          //     break;
          //   }
          //   case "02": {
          //     ProvinciaId = "2";
          //     break;
          //   }
          //   case "03": {
          //     ProvinciaId = "3";
          //     break;
          //   }
          //   case "04": {
          //     ProvinciaId = "4";
          //     break;
          //   }
          //   case "05": {
          //     ProvinciaId = "5";
          //     break;
          //   }
          //   case "06": {
          //     ProvinciaId = "6";
          //     break;
          //   }
          //   case "07": {
          //     ProvinciaId = "7";
          //     break;
          //   }
          //   default: {
          //     ProvinciaId = "99";
          //     break;
          //   }
          // }
          // let provid = ProvinciaId;
          // let provname = this.provinceList[0].ProvinceName;
          // this.provinceList.forEach(prov => {
          //   if (prov.ProvinceName.toUpperCase() === data.FEInfo.Provincia.toUpperCase()) { //data.FEInfo.Provincia
          //     provid = prov.ProvinceId;
          //     provname = prov.ProvinceName;
          //   }
          // });

          // this.nombreProvincia = provname;
          // this.feForm.patchValue({ Provincia: Number(provid).toString() });  //data.FEInfo.Provincia
          // let BarrioObj = data.FEInfo.Barrio.split("-");
          // let CantonObj = data.FEInfo.Canton.split("-");
          // let DistritoObj = data.FEInfo.Distrito.split("-");
          // let Barrio = BarrioObj[1];
          // let Canton = CantonObj[1];
          // let Distrito = DistritoObj[1];
          // this.getCantonsPatch(provid, Canton, Distrito, Barrio);
        }
        else {
          // busca info del cliente en el padron
          this.blockUI.stop();
          this.blockUI.start('Obteniendo datos del padrón...');
          if (this.feForm.controls.Identification.value != '') {
            this.bpService.GetCustomersContPadron(this.feForm.controls.Identification.value).subscribe((data: any) => {
              this.blockUI.stop();
              if (data.Result.result) {
                this.invForm.patchValue({ cardName: data.Result.CardName });
              }
            }, (error: any) => {
              this.blockUI.stop();
              this.alertService.errorInfoAlert(`Error Padrón!!!, Error: ${error.error.Message}`);
            });
          }
        }
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error Obteniendo datos FE!!!, Error: ${error.error.Message}`);
      });
    }
  }
  // chequea que se tengan los permisos para acceder a la pagina
  checkPermits() {
    if (this.currentUser != null) {
      this.sPerm.getPerms(this.currentUser.userId).subscribe((data: any) => {
        this.blockUI.stop();
        if (data.result) {
          // console.log(data);
          let permListtable: any = data.perms;
          data.perms.forEach(Perm => {
            if (Perm.Name === "V_Inv") {
              this.permisos = Perm.Active;
              if (this.permisos) {
                // this.nameField.nativeElement.focus();
              }
            }
            if (Perm.Name === 'V_LstChng') {
              this.isAllowedPriceListChange = Perm.Active;
            }
          });
        } else {
          this.permisos = false;
        }
      }, error => {
        this.permisos = false;
        this.blockUI.stop();
      });
    }
  }

  // establece la persona que es el vendedor
  setSalesPerson() {
    this.blockUI.start('Cargando listas de usuarios...');
    this.uService.getUserList().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        this.userAssignsList = data.Users;
        this.userAssignsList.forEach(user => {
          if (this.currentUser.userId === user.UserId) {
            // this.defaultListNum = user.PriceListDef;
            // this.invForm.patchValue({PriceList: this.defaultListNum});
            this.defaultSlpCode = user.SlpCode;
            this.defaultSlpCodeStr = user.SlpCode;
            this.invForm.patchValue({ SlpList: user.SlpCode });
          }
        });
        this.GetSalesPersonList();
      } else {
        this.blockUI.stop();
        this.alertService.errorAlert('Error al cargar la lista de usuarios - ' + data.errorInfo.Message);
      }
    }, error => {
      console.log(error);
      this.alertService.errorAlert('Error al cargar la lista de usuarios - ' + error);
    });
  }

  addCommas(num) {
    let str = num.toString().split(".");
    if (str[0].length >= 4) {
      //add comma every 3 digits befor decimal
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    return str.join('.');
  }
  summarize(_line: any): void {
    let isFound = false;
    this.itemsList.forEach(x => {
      if (x.item === _line.item) {
        x.Quantity = x.Quantity + 1;
        this.cant = x.Quantity;
        isFound = true;
      }
    });

    if (!isFound) this.itemsList.push(_line);
  }

  cant: number;
  addItems(item) {

    if (this.ItemInfo.value) {

      let mobileNavigatorObject: any = window.navigator;
      if (mobileNavigatorObject && mobileNavigatorObject.clipboard) {
        mobileNavigatorObject.clipboard.readText()
          .then(text => {
            const code = item.item.split(' COD. ')[0];
            const priceList = this.invForm.get('PriceList').value;

            if (!isNaN(parseInt(text))) {
              if (Number(text) > this.maxWeightTo0) {
                // this.Cant.setValue(Math.max(Number(text) - this.pesoBolsa, 0.0001)); // REVISAR EN CASO DE ALGUN PROBLEMA
                //this.invForm.patchValue({cardName: cardName});
                //console.log('Pasted content: ', text);
                mobileNavigatorObject.clipboard.writeText("") // * antes de limpiar string
                  .then(text => {
                    //this.Cant.setValue(Number(text));
                    //this.invForm.patchValue({cardName: cardName});
                    //console.log('Clipboard cleared ');
                  })
                  .catch(err => {
                    // console.error('Failed to clear clipboard contents: ', err);
                  });
              }

            }

            this.cant = Number(this.Cant.value);
            let cant2 = Number(this.Cant.value);
            let searchitem = true;
            if (this.isOnGroupLine) {
              var index: number = this.itemsList.indexOf(this.itemsList.find(x => x.Item == code));
              if (index != -1) {
                this.itemsList[index].Quantity += this.cant;
                this.itemsList[index].LinTot = this.itemsList[index].Quantity * this.itemsList[index].UnitPrice;
                searchitem = false;
                this.LineTotalCalculate(index);
                this.getTotals();
                this.ItemInfo.setValue('');
                this.Cant.setValue(1);
                return;
              }
            }
            this.blockUI.start('Obteniendo Información del Artículo, Espere Por Favor...');
            this.hasLines = true;
            this.itemService.GetItemByItemCode(code, priceList, this.invForm.controls.cardCode.value) // TO AVOID BREAK ON GETITEMINFO
              .subscribe((data: any) => {
                if (data.result) {
                  if (searchitem) {
                    this.conta++;
                    this.total += data.Item.UnitPrice;
                    let tot = (data.Item.UnitPrice * this.cant);

                    this.invForm.patchValue({ currency: this.userCurrency });
                    let AUXILIAR_ITEM = {
                      'Item': `${code}`,
                      'ItemCode': `${data.Item.ItemCode} - ${data.Item.ItemName}`,
                      'ItemName': `${data.Item.ItemName}`,
                      'CodeName': `${data.Item.ItemCode} - ${data.Item.ItemName}`,
                      'UnitPrice': this.userCurrency === 'COL' ? data.Item.UnitPrice : (parseFloat(Number(data.Item.UnitPrice / this.DailyExRate).toString())),
                      'U_SugPrice': '0',
                      'TaxCode': data.Item.TaxRate != 0.0 ? data.Item.TaxCode : 'EXE',
                      'Quantity': this.cant,
                      'Active': true,
                      'Id': this.conta,
                      'LinTot': tot,
                      'TaxRate': data.Item.TaxRate != 0.0 ? data.Item.TaxRate : 0.00,
                      'Discount': 0.00,
                      'WhsCode': this.whCode,
                      'WhsName': this.whName,
                      'Serie': '',
                      'SysNumber': 0,
                      'InvntItem': data.Item.InvntItem,
                      'OnHand': data.Item.OnHand
                    };

                    if (this.userCurrency != 'COL' && this.userCurrency != 'USD') {
                      AUXILIAR_ITEM.Quantity = 1;
                      AUXILIAR_ITEM.LinTot = 0;
                      AUXILIAR_ITEM.UnitPrice = 0;
                      this.storage.setLog(`ERROR!, fecha: ${new Date()} ---(${this.invForm.controls.currency.value})`);
                    }

                    this.itemsList.push(AUXILIAR_ITEM);
                    this.LineTotalCalculate(this.itemsList.length - 1)
                    this.getTotals();
                  }
                  this.Cant.setValue(1);
                  this.ItemInfo.setValue('');
                  this.itemService.GetWHAvailableItem(code)
                    .subscribe((data: any) => {
                      if (data.result) {
                        let available: boolean = false;

                        data.whInfo.forEach(wh => {
                          if (wh.InvntItem === "N") {
                            available = true;
                          }
                          if (wh.Disponible >= this.cant) {
                            available = true;
                          }
                        });

                        if (!available) {
                          index = this.itemsList.indexOf(this.itemsList.find(x => x.Item == code));
                          if (index !== -1) {
                            this.itemsList[index].Quantity = 0;
                            this.itemsList[index].LinTot = 0;
                            searchitem = false;
                            this.LineTotalCalculate(index)
                            this.getTotals();
                          }
                          this.blockUI.stop();
                          this.alertService.infoInfoAlert('Este artículo no posee disponibles en ningún almacén.');
                        }
                        this.blockUI.stop();
                      } else {
                        this.blockUI.stop();
                        this.alertService.errorAlert('Error al Obtener disponibilidad el artículo - ' + data.errorInfo.Message);
                      }

                    }, (error: any) => {
                      this.blockUI.stop();
                      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
                    });

                } else {
                  this.blockUI.stop();
                  this.alertService.errorAlert(`Error: no se pudo obtener la información del item solicitado: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
                }
                this.blockUI.stop();
              }, (error: any) => {
                this.blockUI.stop();
                this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
              });


          })
          .catch(err => {
            this.blockUI.stop();
            // console.error('Failed to read clipboard contents: ', err);
          });
      }
    }
  }

  cantChange() {
    if (this.Cant.value < 1) {
      this.Cant.setValue(1);
    }
  }
  // Hace la peticion de pago al api local para uso del pin pad
  requestPinPadPayment(): void {
    const AMOUNT = +this.creditCardForm.value.CreditSum;

    if (AMOUNT <= 0) {
      this.alertService.infoInfoAlert(`El monto a cobrar debe ser superior a 0`);
      console.log(this.uniqueInvCode);
      return;
    }

    let totalcard = 0;

    if (parseFloat(this.creditCardForm.value.CreditSum) > 0) {
      if (this.V_CreditCards.length > 0) {
        this.V_CreditCards.forEach((vcc) => {
          totalcard += parseFloat(vcc.CreditSum);
        });
        totalcard += parseFloat(this.creditCardForm.value.CreditSum);
      } else {
        totalcard = parseFloat(this.creditCardForm.value.CreditSum);
      }
      if (totalcard > 0) {//ReceivedG
        if ((this.TotalCash + totalcard + this.TotalTransfer + this.TotalCheck) <= this.TotalG) {
          this.isRequestinBacPayment = true;
          this.paymentService.requestPinPadPayment(AMOUNT, this.uniqueInvCode, this.currencyPayment, this.GeneratePreInvoice()).subscribe(next => {
            if (next.result) {
              console.log(next.PPTransaction);
              if (next.PPTransaction.ResponseCode === '00') {
                const PIN_PAD_CARD = next.PPTransaction;
                this.pinPadCard = PIN_PAD_CARD;
                this.creditCardForm.patchValue({
                  'CardValid': PIN_PAD_CARD.ExpirationDate[2] + PIN_PAD_CARD.ExpirationDate[3] + '/' + PIN_PAD_CARD.ExpirationDate[0] + PIN_PAD_CARD.ExpirationDate[1],
                  'CardNum': PIN_PAD_CARD.CardNumber,
                  'OwnerIdNum': PIN_PAD_CARD.ReferenceNumber
                });
                this.pinPadCards.push(this.pinPadCard);
                this.alertService.successInfoAlert(`Cobro realizado con éxito, presione aceptar para completar la factura`);
                this.TotalCards = totalcard;
                this.setTotalAmount();
                document.getElementById('raiseCardPayment').click();
              }
              else {
                console.log(next);
                let errorRegistered = false;
                let errorDetail = '';
                let localIndex = 0;
                BACErrorCodes.forEach(x => {
                  if (Object.keys(x)[0] == next.PPTransaction.ResponseCode) {
                    errorDetail = BACErrorCodes[localIndex][next.PPTransaction.ResponseCode];
                    errorRegistered = true;
                  }
                  localIndex++;
                });

                if (!errorRegistered) {
                  this.alertService.errorAlert(`Error al realizar el cobro. No existe descripción para el siguiente código de error: ${next.PPTransaction.ResponseCode}`);
                }
                else {
                  this.alertService.errorAlert(`Error al realizar el cobro. Código: ${next.PPTransaction.ResponseCode}, Detalle ${errorDetail}`);
                }
              }
            }
            else {
              console.log(next);
              if (next.errorInfo.Code === 401) {
                this.recoveringLocalCredentials(next.errorInfo.Message);
              }
              else {
                this.alertService.errorAlert(`Error al realizar el cobro. Código: ${next.PPTransaction.ResponseCode}, Detalle: ${next.errorInfo.Message}`);
              }
            }
            this.isRequestinBacPayment = false;
          }, error => {
            this.isRequestinBacPayment = false;
            console.log(error);
            this.recoveringLocalCredentials('Intentando conectar con los servicios pin pad');
          });
        } else {
          this.alertService.infoInfoAlert('El monto ingresado en la tarjeta de crédito supera el total de la factura.');
        }
      } else {
        this.alertService.infoInfoAlert(
          "El monto ingresado en Total es incorrecto."
        );
      }
    } else {
      this.creditCardForm.patchValue({ CreditSum: 0 });
      this.alertService.infoInfoAlert(
        "El monto total debe ser superior a cero."
      );
    }
  }

  getSelectedOptionText(envent: any) {
  }

  recoveringLocalCredentials(message: string): void {

    this.blockUI.start(message);
    setTimeout(() => {
      const _SESSION = JSON.parse(this.storage.getCurrentSession());
      if (_SESSION !== null) {
        if (_SESSION['access_token'] !== null) {
          // console.log(_SESSION);
          this.authenticationService.authPinPadCredentials(_SESSION.UserName, _SESSION.Password)
            .subscribe(
              next => {
                this.blockUI.stop();
                if (next && next.access_token) {
                  this.storage.setCurrentSessionOffline(next);
                  this.alertService.infoInfoAlert('Servicios pin pad obtenidos, vuelva a realizar el cobro. Gracias');
                }
                else {
                  this.alertService.errorInfoAlert('No se pudo obtener los servicios pin pad');
                }
                this.blockUI.stop();
              }, error => {
                this.alertService.errorAlert(`No se pudo conectar con el servidor local, Error: ${error}`);
                console.log(error);
                this.blockUI.stop();
              }, () => {
                this.blockUI.stop();
              });
        }
      }
      else {
        this.blockUI.stop();
        this.alertService.errorAlert(`Error al procesar la tarjeta: No se ha podico conectar con el servidor local`);
      }
    }, 2500);
  }
  // obtiene el tipo de cambio
  getExRate() {
    this.blockUI.start('Obteniendo tipos de cambios, Espere Por Favor...');
    this.exrate.getExchangeRate().subscribe((data: any) => {
      if (data.result) {

        this.DailyExRate = data.exRate
        this.blockUI.stop();
        //this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
      } else {
        this.blockUI.stop();
        this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
      }

    }, (error: any) => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error getCustomers!!!, Error: ${error.error.Message}`);
    }), () => {
      this.blockUI.stop();
    };
  }

  // obtiene el maximo descuento posible
  getMaxDiscout() {
    this.blockUI.start('Obteniendo tipos de cambio, Espere Por Favor...');
    this.documentService.getMaxDiscout().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        // console.log(data);
        this.maxDiscuont = data.discount
        this.blockUI.stop();
      }
      else {
        this.blockUI.stop();
        this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
      }
    }, (error: any) => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error getCustomers!!!, Error: ${error.error.Message}`);
    });

  }

  expandRow(index: number): void {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }
  createFEForm() {
    this.feForm = this.fb.group({
      IdType: [''],
      Identification: [''],
      Email: [''],
      Provincia: [''],
      Canton: [''],
      Distrito: [''],
      Barrio: [''],
      Direccion: ['']
    });

    this.feForm.patchValue({ IdType: this.identificationTypeList[0].Id });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    // this.currentUserSubscription.unsubscribe();
  }

  // *****Parte de fecuturacion*****/

  // funcion para obtener los clientes desde SAP
  // no recibe parametros
  getCustomers() {
    this.blockUI.start('Obteniendo Clientes, Espere Por Favor...');
    this.bpService.GetCustomers()
      .subscribe((data: any) => {
        // console.log(data.Code != null && data.Code == '404');
        if (data && data.Code === undefined && data.BPS != null) {
          this.bpList.length = 0;
          this.bpCodeList.length = 0;
          this.bpNameList.length = 0;
          this.bpList = data.BPS;
          for (let item of data.BPS) {
            // console.log(item);
            this.defaultGroupNum = item.GroupNum;

            if (item.ClienteContado) this.defaultContado = "Contado";
            else this.defaultContado = "Credito";
            this.bpCodeList.push(item.CardCode);
            this.bpNameList.push(item.CardName);
          }
          this.GetCurrencyType();
          this.GetPayTerms();
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
        }

      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para obtener los items desde SAP
  // no recibe parametros
  getItems() {
    this.blockUI.start('Obteniendo Items, Espere Por Favor...');
    this.itemService.GetItems()
      .subscribe((data: any) => {
        if (data.result) {
          this.itemsTypeaheadList.length = 0;
          this.itemsList.length = 0;
          this.itemsCodeList.length = 0;
          this.itemsNameList.length = 0;
          /*
              this.itemsList.push({
                'Item': '',
                'ItemCode': '',
                'ItemName': '',
                'UnitPrice': '0',
                'TaxCode': 'EXE',
                'Quantity': '1',
                'Active': true,
                'Id': this.conta,
                'LinTot': 0,
                'TaxRate': 0.00,
                'Discount': 0,
                'WhsCode': '01',
                'WhsName': 'Almacen Prueba',
                'Serie': '',
                'SysNumber': 0
              });
          */
          this.itemsTypeaheadList = data.ItemList.ItemCompleteName;
          this.itemsCodeList = data.ItemList.ItemCode;
          this.itemsNameList = data.ItemList.ItemName;
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);

        }
      }, (error: any) => {
        this.blockUI.stop();
        // console.log('error');
        // console.log(error);
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para obtener los impuestos desde SAP
  // no recibe parametros

  getTaxes() {
    this.blockUI.start('Obteniendo Impuestos, Espere Por Favor...');
    this.taxService.GetTaxes()
      .subscribe((data: any) => {
        if (data.result) {
          this.taxesList.length = 0;
          this.taxesList = data.Taxes;
          this.taxesList.push({
            "TaxCode": 'EXE',
            "TaxRate": '0.00'
          })
        } else {
        }
        this.blockUI.stop();
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error getTaxes!!!, Error: ${error.error.Message}`);
      });
  }

  // funcion para obtener los clientes desde SAP
  // recibe como parametros el item y el index
  selectedItem(item, idx) {
    if (item.item !== "") {
      // console.log(item);
      // console.log(idx);
      this.blockUI.start('Obteniendo Información del Artículo, Espere Por Favor...');
      this.itemService.GetItemByItemCode(item.item.split(' COD. ')[0], this.invForm.controls.PriceList.value)
        .subscribe((data: any) => {
          // console.log(data);
          if (data.result) {
            this.itemsList[idx].ItemCode = data.Item.ItemCode;
            this.itemsList[idx].ItemName = data.Item.ItemName;
            this.itemsList[idx].Item = `${data.Item.ItemCode} - ${data.Item.ItemName}`;
            this.itemsList[idx].UnitPrice = data.Item.UnitPrice;
            this.itemsList[idx].U_SugPrice = data.Item.UnitPrice;
            this.itemsList[idx].TaxCode = data.Item.TaxCode;
            this.itemsList[idx].Discount = data.Item.Discount;
            this.itemsList[idx].TaxRate = data.Item.TaxRate;
            this.itemsList[idx].LinTot = parseFloat(data.Item.UnitPrice);
            this.itemsList[idx].Active = false;
            this.conta++;
            this.total += data.Item.UnitPrice;
            this.itemsList.push({
              'Item': '',
              'ItemCode': '',
              'ItemName': '',
              'UnitPrice': '0',
              'TaxCode': 'EXE',
              'Quantity': '1',
              'Active': true,
              'Id': this.conta,
              'LinTot': 0,
              'TaxRate': 0.00,
              'Discount': 0,
              'WhsCode': this.whCode,
              'WhsName': this.whName,
              'Serie': '',
              'SysNumber': 0
            });
            this.getTotals();
          } else {
            this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
          }
          this.blockUI.stop();
        }, (error: any) => {
          this.blockUI.stop();
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
        });
    }
  }

  // funcion para eliminar el item de la lista
  // recibe como parametro el item a eliminar
  removeItem(item) {
    if (item !== null) {
      const index = this.itemsList.indexOf(item);
      this.itemsList.splice(index, 1);
      this.getTotals();
      if (this.itemsList.length > 0) this.hasLines = true;
      else this.hasLines = false;
    } else {
      this.alertService.warningInfoAlert(
        "No se puede eliminar la ultima linea del documento"
      );
    }
  }

  getClipboardData() {

    let mobileNavigatorObject: any = window.navigator;
    if (mobileNavigatorObject && mobileNavigatorObject.clipboard) {
      mobileNavigatorObject.clipboard.readText()
        .then(text => {
          this.Cant.setValue(Number(text));
          //this.invForm.patchValue({cardName: cardName});
          // console.log('Pasted content: ', text);
          mobileNavigatorObject.clipboard.writeText("0")
            .then(text => {
              //this.Cant.setValue(Number(text));
              //this.invForm.patchValue({cardName: cardName});
              // console.log('Clipboard cleared ');
            })
            .catch(err => {
              // console.error('Failed to clear clipboard contents: ', err);
            });
        })
        .catch(err => {
          // console.error('Failed to read clipboard contents: ', err);
        });
    }

  }

  // convenience getter for easy access to form fields
  get f() {
    return this.invForm.controls;
  }
  get fe() {
    return this.feForm.controls;
  }
  searchBPCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.bpCodeList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  searchBPName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.bpNameList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  searchItemCode = (text$: Observable<string>) =>
    text$.pipe(
      //debounceTime(5),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.itemsTypeaheadList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  // funcion para detectar el cambio en el input de Código
  // recibe como parametro el Código del item
  changeCode(code) {
    if (code != null) {
      let cardCode = this.bpCodeList.filter(
        (book) => book.toLowerCase() === code.toLowerCase()
      );
      if (cardCode.length > 0) {
        this.setDefaultList(cardCode[0]);
        // tslint:disable-next-line:no-shadowed-variable
        let code = cardCode[0];
        let codePos = this.bpCodeList.indexOf(code);
        let cardName = this.bpNameList[codePos];

        let customer = this.bpList.filter(cus => {
          return cus.CardCode === code;
        });
        this.getCurrencyByUser(customer[0].Currency);

        // tslint:disable-next-line:no-unused-expression
        if (cardName !== this.invForm.get('cardName').value) {
          this.invForm.patchValue({ cardName: cardName });
        }

        for (let item of this.bpList) {

          if (item.CardCode === customer[0].CardCode) {
            this.defaultGroupNum = item.GroupNum;
            this.invForm.patchValue({ PayTerms: item.GroupNum.toString() });
          }
        }
        for (let item of this.PriceList) {
          if (item.ListNum.toString() === customer[0].ListNum.toString()) {
            //this.defaultListNum = item.ListNum;
            //this.invForm.patchValue({PriceList: customer[0].ListNum});
          }
        }
        let nombre = "Contado";
        this.defaultContado = "Contado";
        if (customer[0].ClienteContado === false) {
          nombre = "A30Dias";
          this.defaultContado = "A30Dias";
        }
        // console.log('PaymentTypes----------------------------------------');
        for (let item of this.paymentTypes) {
          if (nombre === item.name.toString()) {
            this.invForm.patchValue({ paymentType: item.id });
          }
        }
        setTimeout(() => {
          this.nameField.nativeElement.focus();
        }, 0);
      }
    }
  }

  // funcion para detectar el cambio en el input de descripcion
  // recibe como parametro la descripcion del item
  changeDescription(description) {
    if (description != null) {
      let itemDescription = this.bpNameList.filter(
        (book) => book.toLowerCase() === description.toLowerCase()
      );
      if (itemDescription.length > 0) {
        // tslint:disable-next-line:no-shadowed-variable
        let description = itemDescription[0];
        let descriptionPos = this.bpNameList.indexOf(description);
        let cardCode = this.bpCodeList[descriptionPos];

        let customer = this.bpList.filter((cus: any) => {
          return cus.CardCode === cardCode;
        });
        this.getCurrencyByUser(customer[0].Currency);
        // tslint:disable-next-line:no-unused-expression
        if (cardCode !== this.invForm.get('cardCode').value) {
          this.invForm.patchValue({ cardCode: cardCode });
        }
        for (let item of this.bpList) {

          if (item.CardCode === customer[0].CardCode) {
            this.defaultGroupNum = item.GroupNum;
            this.invForm.patchValue({ PayTerms: item.GroupNum.toString() });
          }
        }
        let nombre = "Contado";
        this.defaultContado = "Contado";
        if (customer[0].ClienteContado === false) {
          nombre = "A30Dias";
          this.defaultContado = "A30Dias";
        }
        for (let item of this.paymentTypes) {
          if (nombre === item.name.toString()) {
            this.invForm.patchValue({ paymentType: item.id });
          }
        }
      }
    }
  }

  // funcion para calcular los totales de la SO
  // no recibe parametros
  getTotals() {
    this.total = 0;
    this.totalUSD = 0;
    this.tax = 0;
    this.discount = 0;
    this.totalWithoutTax = 0;
    this.itemsList.forEach((element) => {
      const lintot = parseFloat(
        (element.UnitPrice * element.Quantity).toString()
      );
      const disc = parseFloat(
        Number(lintot * (element.Discount / 100)).toString()
      );
      this.discount = parseFloat(Number(disc + this.discount).toString());
      this.totalWithoutTax = parseFloat(Number((lintot - disc) + this.totalWithoutTax).toString());
      this.tax = parseFloat(Number(((lintot - disc) * (element.TaxRate / 100)) + this.tax).toString());
    });
    //this.total += parseFloat(Number(this.totalWithoutTax + this.tax).toFixed(2));
    if (this.invForm.controls.currency.value == 'COL') {
      this.total += (parseFloat(Number(this.totalWithoutTax + this.tax).toString()));
      this.totalUSD += parseFloat(Number((this.totalWithoutTax + this.tax) / this.DailyExRate).toString());
    }
    else {
      this.total += (parseFloat(Number((this.totalWithoutTax + this.tax) * this.DailyExRate).toString()));
      this.totalUSD += parseFloat(Number(this.totalWithoutTax + this.tax).toString());
    }

    this.total = +this.total.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.totalUSD = +this.totalUSD.toFixed(this.COMPANY.DecimalAmountTotalDocument);
  }

  // funcion al cambiar el tipo de taxcode
  // recibe como parametro el taxxode y el indice de la lista
  changeTaxCode(i: number, item: any) {


    const rate = this.taxesList.find(i => i.TaxCode === item.TaxCode.toUpperCase());
    //const index =( this.itemsList.length - 1) - idx ;
    const idx: number = this.itemsList.indexOf(this.itemsList.find(x => x.ItemCode == item.ItemCode));

    if (typeof rate !== 'undefined') {
      this.itemsList[idx].TaxRate = parseFloat(this.decimalPipe.transform(rate.TaxRate, '.2'));
      this.itemsList[idx].TaxCode = rate.TaxCode.toUpperCase();
      this.LineTotalCalculate(idx);
    }
  }

  // funcion para calcular el total de la linea
  // recibe como parametro el index de la lista de items
  LineTotalCalculate(idx: number) {
    let disc = 0;
    if (this.itemsList[idx].Discount <= this.maxDiscuont) {
      disc = this.itemsList[idx].Discount;
    }
    else {
      disc = this.maxDiscuont;
      this.alertService.infoInfoAlert('El descuento no puede ser mayor a ' + this.maxDiscuont + ' que es lo permitido para este usuario');
      this.itemsList[idx].Discount = this.maxDiscuont;
    }
    if (this.itemsList[idx].Discount == null) { this.itemsList[idx].Discount = 0; }
    const qty = this.itemsList[idx].Quantity;
    const price = this.itemsList[idx].UnitPrice;
    let lineTotal = Number((qty * price).toString());
    const taxamount = Number(
      (lineTotal * (this.itemsList[idx].TaxRate / 100)).toString()
    );
    lineTotal = Number((lineTotal + taxamount).toString());
    lineTotal = Number((lineTotal - (lineTotal * (disc / 100))).toString());
    this.itemsList[idx].LinTot = lineTotal.toString();
    this.getTotals();
    // console.log(13);
  }

  LineTotalCalculateExt(idx: number) {
    idx = this.itemsList.length - idx - 1;
    let disc = 0;
    if (this.itemsList[idx].Discount <= this.maxDiscuont) {
      disc = this.itemsList[idx].Discount;
    }
    else {
      disc = this.maxDiscuont;
      this.alertService.infoInfoAlert('El descuento no puede ser mayor a ' + this.maxDiscuont + ' que es lo permitido para este usuario');
      this.itemsList[idx].Discount = this.maxDiscuont;
    }
    if (this.itemsList[idx].Discount == null) { this.itemsList[idx].Discount = 0; }
    const qty = this.itemsList[idx].Quantity;
    const price = this.itemsList[idx].UnitPrice;
    let lineTotal = Number((qty * price).toString());
    const taxamount = Number(
      (lineTotal * (this.itemsList[idx].TaxRate / 100)).toString()
    );
    lineTotal = Number((lineTotal + taxamount).toString());
    lineTotal = Number((lineTotal - (lineTotal * (disc / 100))).toString());
    this.itemsList[idx].LinTot = lineTotal.toString();
    this.getTotals();
    // console.log(13);
  }

  // funcion para imprimir la factura
  printARInvoice(DocEntry: number) {
    this.blockUI.start('Generando la impresión...');
    this.reportsService.printReport(DocEntry, ReportType.ArInvoice)
      .subscribe((data: any) => {
        // console.log(data);
        this.blockUI.stop();

        //var fileBase64 = atob(data);
        /*
        printJS({
            printable: data,
            type: 'pdf',
            base64: true
          })
      */
        //this.playPingPong(fileBase64);
          printJS({
            printable: data,
            type: 'pdf',
            base64: true
          });
        /*
          var length = fileBase64.length;
          var arrayBuffer = new ArrayBuffer(length);
          var uintArray = new Uint8Array(arrayBuffer);
          for (var i = 0; i < length; i++) {
            uintArray[i] = fileBase64.charCodeAt(i);
          }
          var tab = window.open();
          var pdfFile = new Blob([uintArray], { type: 'application/pdf' });
          var fileUrl = URL.createObjectURL(pdfFile);
          tab.location.href = fileUrl;
        tab.print();
        */
      }, (error: any) => {
        this.blockUI.stop();
        // console.log('error');
        // console.log('error');
        // console.log(error);
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // ***** Modal de Items para compañias
  openModal(content) {
    this.modalReference = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  // llena los campos de la tabla de items con los campos parametriados
  GetParamsViewList() {
    this.blockUI.start('Obteniendo parametros...');
    this.paramsService.getParasmView()
      .subscribe((data: any) => {

        if (data.result) {
          this.viewParamList = data.Params.filter(param => {
            return param.type === 1 && param.Visibility;
          });
          this.tableLength = 0;
          for (var i = 0; i < this.viewParamList.length; i++) {
            this.tableLength += this.MapWidth[this.viewParamList[i].Name];
          }
          this.viewParamListHeader = data.Params.filter(param => {
            return param.type === 2;
          });
          this.viewParamListTotales = data.Params.filter(param => {
            return param.type === 3;
          });
          this.viewParamTitles = data.Params.filter(param => {
            return param.type === 6;
          });
          this.ChargeParamstoView();
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert('Error al cargar los parámetros de la pagina - ' + data.errorInfo.Message);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para obtener la informacion de los disponibles de un item en los almacenes
  // recibe como parametros el item y el index
  GetWHAvailableItem(event, content, ItemCode, idx) {
    if (ItemCode !== '') {
      if (event.type === 'dblclick') {
        event.srcElement.blur();
        event.preventDefault();
        // console.log(ItemCode);
        // console.log(idx);
        this.blockUI.start('Obteniendo Disponibilidad del Artículo, Espere Por Favor...');
        this.itemService.GetWHAvailableItem(ItemCode)
          .subscribe((data: any) => {
            // console.log(data);
            if (data.result) {

              this.WHAvailableItemList.length = 0;
              this.itemCode = ItemCode;
              this.indexAvaItem = this.itemsList.length - 1 - idx;
              // console.log(this.indexAvaItem);
              this.WHAvailableItemList = data.whInfo;
              if (data.whInfo.length > 0) {
                this.expandedIndex = -1;
                this.expandRow(this.expandedIndex);
                this.openModal(content);
              } else {
                this.alertService.infoInfoAlert('Este artículo no posee disponibles en ningún almacén.');
              }
            } else {
              this.alertService.errorAlert('Error al Obtener disponibilidad el artículo - ' + data.errorInfo.Message);
            }
            this.blockUI.stop();
          }, (error: any) => {
            this.blockUI.stop();
            this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
          });
      }

    }
  }

  //chequea si hay existencias del item a agregar
  CheckAvailableItem(ItemCode) {
    if (ItemCode !== '') {
      this.blockUI.start('Obteniendo Disponibilidad del Artículo, Espere Por Favor...');
      this.itemService.GetWHAvailableItem(ItemCode)
        .subscribe((data: any) => {
          // console.log(data);
          if (data.result) {
            this.WHAvailableItemList.length = 0;
            this.itemCode = ItemCode;
            this.WHAvailableItemList = data.whInfo;
            if (data.whInfo.length <= 0) {
              this.blockUI.stop();
              this.alertService.infoInfoAlert('Este artículo no posee disponibles en ningún almacén.');
            }
            this.blockUI.stop();
          } else {
            this.blockUI.stop();
            this.alertService.errorAlert('Error al Obtener disponibilidad el artículo - ' + data.errorInfo.Message);
          }

        }, (error: any) => {
          this.blockUI.stop();
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
        });
    }
  }

  // funcion para seleccionar un almacen nuevo para el item a facturar
  avaItemSelected(event, avaItem, idx: number) {
    if (event.type === 'dblclick') {
      // console.log(this.indexAvaItem);
      this.itemsList[this.indexAvaItem].WhsCode = avaItem.WhsCode;
      this.itemsList[this.indexAvaItem].WhsName = avaItem.WhsName;
      this.itemsList[this.indexAvaItem].Serie = "";
      this.itemsList[this.indexAvaItem].SysNumber = 0;
      this.close();
    } else if (event.type === 'click') {
      this.itemService.GetSeriesByItem(this.itemCode, avaItem.WhsCode).subscribe((data: any) => {
        if (data.result) {
          this.seriesList.length = 0;
          this.seriesList = data.series;
          if (data.series.length > 0) {
            this.expandRow(idx);
          } else {
          }
          this.blockUI.stop();
        }
      },
        (error: any) => {
          this.blockUI.stop();
          this.alertService.errorInfoAlert(
            `Error al intentar conectar con el servidor, Error: ${error}`
          );
        }
      );
    }
  }

  // funcion para cerrar la modal para escoger la terminal y sucursal
  close() {
    this.modalReference.close();
  }

  // funcion para selecccionar una seria
  // recibe como parametro el objeto de serie y del almacen en el que se selecciono la serie
  selectSerie(series, avaItem) {
    // console.log(series);
    if (series.Disponible > 0) {
      this.itemsList[this.indexAvaItem].Serie = series.PlacaChasis;
      this.itemsList[this.indexAvaItem].SysNumber = series.SysNumber;
      this.itemsList[this.indexAvaItem].WhsCode = avaItem.WhsCode;
      this.itemsList[this.indexAvaItem].WhsName = avaItem.WhsName;
      this.itemsList[this.indexAvaItem].UnitPrice = series.Precio;
      this.itemsList[this.indexAvaItem].Marca = series.PlacaChasis;
      this.LineTotalCalculate(this.indexAvaItem);
      this.alertService.infoInfoAlert(
        `Se seleccionó la serie: ${series.PlacaChasis}`
      );
    } else {
      this.alertService.infoInfoAlert(
        "No puede seleccionar esta serie ya que no posee disponibles"
      );
    }
  }

  // Carga los datos parametrizados en las variables
  ChargeParamstoView() {
    // parametrizaciones para dtos de cabezera
    this.viewParamListHeader.forEach((element) => {
      if (element.Name === "lbCardCode") {
        this.lbCardCode = element;
      }
      if (element.Name === "txtCardCode") {
        this.txtCardCode = element;
      }
      if (element.Name === "lbCardName") {
        this.lbCardName = element;
      }
      if (element.Name === "txtCardName") {
        this.txtCardName = element;
      }
      if (element.Name === "lbCurrency") {
        this.lbCurrency = element;
      }
      if (element.Name === "txtCurrency") {
        this.txtCurrency = element;
      }
      if (element.Name === "txtPayTerms") {
        this.txtPayTerms = element;
      }
      if (element.Name === "lbPayTerms") {
        this.lbPayTerms = element;
      }
      if (element.Name === "txtPriceList") {
        this.txtPriceList = element;
      }
      if (element.Name === "lbPriceList") {
        this.lbPriceList = element;
      }
      if (element.Name === "txtComments") {
        this.txtComments = element;
      }
      if (element.Name === "lbComments") {
        this.lbComments = element;
      }
      if (element.Name === "txtSLP") {
        this.txtSLP = element;
      }
      if (element.Name === "lbSLP") {
        this.lbSLP = element;
      }
    });
    // parametrizaciones datos de totales
    this.viewParamListTotales.forEach((element) => {
      if (element.Name === "lbTotalExe") {
        this.lbTotalExe = element;
      }
      if (element.Name === "txtTotalExe") {
        this.txtTotalExe = element;
      }
      if (element.Name === "lbDiscount") {
        this.lbDiscount = element;
      }
      if (element.Name === "txtDiscount") {
        this.txtDiscount = element;
      }
      if (element.Name === "lbTaxes") {
        this.lbTaxes = element;
      }
      if (element.Name === "txtTaxes") {
        this.txtTaxes = element;
      }
      if (element.Name === "lbTotal") {
        this.lbTotal = element;
      }
      if (element.Name === "txtTotal") {
        this.txtTotal = element;
      }
    });
    // parametrizacion del titulo
    let obj = this.viewParamTitles.filter(param => {
      return param.Name === 'T_Inv';
    });
    this.title = obj[0].Text;
  }

  // ***** parte de Pagos *****

  // cierra el modal de pagos
  closePayModal() {
    if (this.V_CreditCards.length > 0 && !this.isBilling) {
      this.alertService.infoInfoAlert(`Elimine las tarjetas agregadas`);
      return;
    }
    // console.log('payments', this.V_CreditCards);
    // console.log('payments', this.isBilling);

    this.modalPay.close();
    this.isOnSubmit = false;
  }

  closeChangeModal() {
    this.modalChange.close();
  }

  CreatePay() {
    if (this.isBilling) {
      this.attemptsWhileBilling++;
      console.log('Intento duplicación de factura ', this.attemptsWhileBilling);
      // this.alertService.infoInfoAlert(`Intento duplicación de factura ${this.attemptsWhileBilling}`);
      return;
    }

    let _totalCards = 0;
    if (this.V_CreditCards.length > 0) {
      this.V_CreditCards.forEach((vcc) => {
        _totalCards += parseFloat(vcc.CreditSum);
      });
      _totalCards += parseFloat(this.creditCardForm.value.CreditSum);
    } else {
      _totalCards = parseFloat(this.creditCardForm.value.CreditSum);
    }
    let totalCash = +this.cashForm.value.TotalCash;
    let totalTransfers = +this.transferForm.value.TransSum;
    _totalCards = +_totalCards;

    if (this.TotalTransfer > 0) {
      if ((this.TotalCash + this.TotalCards + this.TotalTransfer + this.TotalCheck) <= this.TotalG) {
        this.setTotalAmount();
      } else {
        this.alertService.infoInfoAlert('El monto ingresado en transferencia supera el total de la factura.');
        return;
      }
    }

    this.blockUI.start('Generando factura y pago, Espere Por Favor...');

    this.isBilling = true;
    // if (!navigator.onLine) {
    //   this.blockUI.stop();
    //   this.alertService.infoAlert("Parece que no tienes internet. Vuelve a intertarlo mas tarde");
    //   return;
    // }
    this.facturando = true;
    let payOk = true;
    if (this.ReceivedG < this.TotalG) {
      payOk = this.partialPayValidator();
    }
    if (payOk) {
      const PaymentLines = [];
      let dT = 1;
      PaymentLines.push({
        'InvTotal': this.total,
        'DocNum': 0,
        'PayTotal': this.TotalG,
        'DocEntry': 0,
        'InstId': 30, // plasos
        'Currency': this.invForm.value.currency,
        'Balance': this.TotalG,
        'DocType': dT,
        'PayBalanceChk': false, // Verifica el pago vs saldo
        'ReceivedAmount': this.ReceivedG,
        'Change': this.ChangeG
      });
      let total = 0;
      total = this.total;

      const Payments = {
        CardCode: this.invForm.value.cardCode,
        CashAccount: this.cashForm.value.AccountCash,
        CashSum: this.cashForm.value.TotalCash,
        CashCurr: this.currencyPayment,
        Comments: 'pago de fatura',
        Currency: this.currencyPayment,
        DocRate: this.currencyChange,
        SlpCode: this.invForm.controls.SlpList.value,
        Total: total,
        V_Checks: this.V_Checks,
        V_CreditCards: this.V_CreditCards,
        V_PaymentLines: PaymentLines,
        transfSum: this.transferForm.value.TransSum,
        trfsrAcct: this.transferForm.value.AccountTransf,
        trfsrCurr: this.currencyPayment,
        trfsrDate: this.transferForm.value.DateTrans,
        trfsrNum: this.transferForm.value.Ref,
      };

      // let gprov = this.feForm.value.Provincia;
      // let gcant = this.feForm.value.Canton;
      // let gdist = this.feForm.value.Distrito;
      // let gbarr = this.feForm.value.Barrio;
      // this.feForm.patchValue({ Provincia: "0" + this.feForm.value.Provincia + "-" + this.nombreProvincia });
      // this.feForm.patchValue({ Canton: this.feForm.value.Canton + "-" + this.nombreCanton });
      // this.feForm.patchValue({ Distrito: this.feForm.value.Distrito + "-" + this.nombreDistrito });
      // this.feForm.patchValue({ Barrio: this.feForm.value.Barrio + "-" + this.nombreBarrio });

      this.documentService.CreateInvoice(this.invForm, Payments, this.itemsList, this.feForm, this.uniqueInvCode)
        .subscribe((data: any) => {
          this.blockUI.stop();
          if (data.result) {
            this.closePayModal();

            // console.log('usd', total / +this.currencyChange); console.log('usd2', total / +this.exrate);

            this.alertService.successAlertHtml(`Factura creada correctamente: DocEntry: ${data.DocEntry}, DocNum: ${data.DocNum}, NumFE: ${data.NumDocFe} <br/><br/>
            <h1><strong>Vuelto</strong></h1><br/> <h1>Colones: ${this.cp.transform(this.changeColones, '₡', true, '1.2-2')}</h1><br/><h1>Dolares: ${this.cp.transform(this.changeDolares, 'USD', true, '1.2-2')}</h1>
            <h3>Intentos de duplicación: <label class="text-danger">${this.attemptsWhileBilling}</label></h3>`);
            let modalOption: NgbModalOptions = {};
            modalOption.backdrop = 'static';
            modalOption.keyboard = false;
            modalOption.ariaLabelledBy = 'modal-basic-title';
            modalOption.size = 'lg';
            this.printARInvoice(data.DocEntry);
            this.lastInvoice = data.DocEntry;
            this.btnVisibleBool = false;
            this.correctInvoice = true;
            this.facturando = false;
            if (this.pinPadCards.length > 0) {
              this.pinPadCard.DocEntry = data.DocNum;
              this.pinPadCards.forEach(x => x.DocEntry = data.DocNum);

              this.paymentService.updatePinPadPayment(this.pinPadCards).subscribe(next => {
                if (next.result) {
                  this.pinPadCards = [];
                  this.pinPadCard = null;
                }
                else {
                  console.log(next.errorInfo);
                  this.alertService.errorAlert(`Error al actualizar el número de factura en el api local: ${next.errorInfo.Message}`);
                }
              }, error => {
                console.log(error);
                this.alertService.errorAlert(`Error al actulizar el número de factura: ${error.error}`);
              });
            }
          } else {
            this.blockUI.stop();
            console.log("Lista else")
            console.log(this.itemsList);
            this.blockUI.stop();
            this.facturando = false;
            this.alertService.errorAlert(`Error al crear el pago: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
            this.attemptsWhileBilling = 0;
            this.isBilling = false;
          }
          this.facturando = false;
        }, (error) => {
          console.log("Lista error");
          console.log(this.itemsList);
          // this.feForm.patchValue({ Provincia: gprov });
          // this.feForm.patchValue({ Canton: gcant });
          // this.feForm.patchValue({ Distrito: gdist });
          // this.feForm.patchValue({ Barrio: gbarr });
          this.facturando = false;
          this.blockUI.stop();
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
          this.attemptsWhileBilling = 0;
          this.isBilling = false;
        });
    }
  }
  // Genera un preeliminar del objeto de la factura para amarrarla a la transaccion de venta del pinpad
  GeneratePreInvoice(): string {
    const PaymentLines = [];
    let dT = 1;
    PaymentLines.push({
      'InvTotal': this.total,
      'DocNum': 0,
      'PayTotal': this.TotalG,
      'DocEntry': 0,
      'InstId': 30, // plasos
      'Currency': this.invForm.value.currency,
      'Balance': this.TotalG,
      'DocType': dT,
      'PayBalanceChk': false, // Verifica el pago vs saldo
      'ReceivedAmount': this.ReceivedG,
      'Change': this.ChangeG
    });

    let total = 0;
    total = this.total;

    const Payments = {
      CardCode: this.invForm.value.cardCode,
      CashAccount: this.cashForm.value.AccountCash,
      CashSum: this.cashForm.value.TotalCash,
      CashCurr: this.currencyPayment,
      Comments: 'pago de fatura',
      Currency: this.currencyPayment,
      DocRate: this.currencyChange,
      SlpCode: this.invForm.controls.SlpList.value,
      Total: total,
      V_Checks: this.V_Checks,
      V_CreditCards: this.V_CreditCards,
      V_PaymentLines: PaymentLines,
      transfSum: this.transferForm.value.TransSum,
      trfsrAcct: this.transferForm.value.AccountTransf,
      trfsrCurr: this.currencyPayment,
      trfsrDate: this.transferForm.value.DateTrans,
      trfsrNum: this.transferForm.value.Ref,
    };

    const linesList: any[] = [];

    this.itemsList.forEach(element => {
      if (element.ItemCode !== '') {
        element.ItemCode = element.ItemCode.split('-')[0].trim();
        linesList.push(element);
      }
    });

    let FE = {
      'IdType': this.feForm.value.IdType,
      'Email': this.feForm.value.Email,
      'Identification': this.feForm.value.Identification
    }

    const Invoice = {
      'InvoiceLinesList': linesList,
      'DocumentType': this.invForm.value.DocumentType,
      'CardCode': this.invForm.value.cardCode,
      'CardName': this.invForm.value.cardName,
      'Currency': this.invForm.value.currency,
      'PayTerms': this.invForm.value.PayTerms,
      'Comment': this.invForm.value.Comment,
      'SlpCode': this.invForm.value.SlpList,
      'FEInfo': FE,
      'CLVS_POS_UniqueInvId': this.uniqueInvCode
    };

    const createInvoice = {
      'Invoice': Invoice,
      'Payment': Payments
    };

    return JSON.stringify(createInvoice);
  }

  DeleteOffLineInvoice(invId: number) {
    this.documentService.DeleteOffLineInvoice(invId)
      .subscribe((data: any) => {
        if (data.result) {
        }
        else {
          console.log(`Error al crear el pago: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
        }
      }, (error) => {
        console.log(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  testKeydown(a) {
    if (a.which === 13) {
      this.closeChangeModal();
    }
    // console.log(a);
  }

  printInvoice() {
    this.printARInvoice(this.lastInvoice);
  }
  uniqueInvCode: string;
  onSubmit(payContent) {


    if (!this.COMPANY.HasZeroBilling) {
      const CORRUPTED_ITEM = this.itemsList.find(x => x.LinTot == 0);

      if (CORRUPTED_ITEM) {
        this.alertService.errorAlert(`El total de linea del producto "${CORRUPTED_ITEM.ItemCode}" es 0, elimínelo por favor.`);
        return;
      }
    }
    else {

    }

    if (!this.invForm.invalid) {
      if (this.itemsList.length > 0) {
        if (this.total > 0) {
          this.facturando = true;

          if (Number(this.invForm.controls.PayTerms.value) === PayTermsEnum.A30Dias) {
            this.blockUI.start('Generando factura crédito, Espere Por Favor...');
            this.documentService.CreateInvoice(this.invForm, {}, this.itemsList, this.feForm, this.uniqueInvCode)
              .subscribe((data: any) => {
                // //this.documentService.CreateInvoice(this.invForm, {}, this.itemsList, this.feForm)
                // this.blockUI.stop();
                // this.alertService.infoInfoAlert(`Factura creada correctamente`);
                // this.btnVisibleBool = false;
                // this.correctInvoice = true;
                // this.lastInvoice = data.DocEntry;
                // this.facturando = false;
                if (data.result) {
                  //this.closePayModal();
                  this.blockUI.stop();
                  this.alertService.successAlertHtml(`Factura crédito creada correctamente: DocEntry: ${data.DocEntry}, DocNum: ${data.DocNum}, NumFE: ${data.NumDocFe} <br/><br/>
                  <h3>Intentos de duplicación: <label class="text-danger">${this.attemptsWhileBilling}</label></h3>`);
                  let modalOption: NgbModalOptions = {};
                  modalOption.backdrop = 'static';
                  modalOption.keyboard = false;
                  modalOption.ariaLabelledBy = 'modal-basic-title';
                  modalOption.size = 'lg';
                  this.printARInvoice(data.DocEntry);
                  this.lastInvoice = data.DocEntry;
                  this.btnVisibleBool = false;
                  this.correctInvoice = true;
                  this.facturando = false;
                } else {
                  this.blockUI.stop();
                  console.log("Lista else")
                  console.log(this.itemsList);
                  this.blockUI.stop();
                  this.facturando = false;
                  this.alertService.errorAlert(`Error al crear el pago: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
                  this.attemptsWhileBilling = 0;
                  this.isBilling = false;
                }
              }, (error) => {
                this.blockUI.stop();
                this.facturando = false;
                this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
              });
          } else {
            this.DifferenceG = this.total;
            this.resetModalData();
            this.TotalG = this.total;
            this.setTotalAmount();
            if (this.invForm.controls.currency.value == 'COL') {
            }
            else {
              this.changeCurrency();
            }

            let modalOption: NgbModalOptions = {};
            modalOption.backdrop = 'static';
            modalOption.keyboard = false;
            modalOption.ariaLabelledBy = 'modal-basic-title';
            modalOption.size = 'lg';
            modalOption.windowClass = 'Modal-Xl';

            this.creditCardForm.patchValue({ VoucherNum: '0' });
            this.creditCardForm.patchValue({ CardNum: '9999' });
            this.creditCardForm.patchValue({ CardValid: '01/99' });

            this.commonService.setPaymentDefaultValues(
              this.branchOffice,
              this.cashForm,
              this.creditCardForm,
              this.transferForm,
              this.checkForm);

            this.modalPay = this.modalService.open(payContent, modalOption);

            this.modalPay.result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
              this.facturando = false;
            }, (reason) => {
              this.facturando = false;
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }
        } else {
          this.isOnSubmit = false;
          this.alertService.infoInfoAlert(
            "El monto total de la factura debe ser mayor a 0"
          );
        }
      } else {
        this.isOnSubmit = false;
        this.alertService.infoInfoAlert(
          "Debe existir al menos un Item en la factura"
        );
      }
    } else {
      this.isOnSubmit = false;
      this.alertService.infoAlert(
        "Debe haber seleccionado Tipo Factura, Cliente, Moneda, Termino de Pago"
      );
    }
  }
  // Genera el id para la creacion de la factura usanda en sap y el pin pad
  generateUniqueInvoiceId(): void {
    const USER_PREFIXID = JSON.parse(this.storage.getCurrentSession()).PrefixId;

    const DATE = new Date();

    const DAYS = DATE.getDate() < 10 ? '0' + DATE.getDate() : DATE.getDate().toString();
    const MONTS = (DATE.getMonth() + 1) < 10 ? '0' + (DATE.getMonth() + 1) : (DATE.getMonth() + 1).toString();
    const YEAR = DATE.getFullYear().toString().slice(0, 2);

    const HOURS = DATE.getHours() < 10 ? '0' + DATE.getHours() : DATE.getHours();
    const MINUTES = DATE.getMinutes() < 10 ? '0' + DATE.getMinutes() : DATE.getMinutes();
    const SECONDS = DATE.getSeconds() < 10 ? '0' + DATE.getSeconds() : DATE.getSeconds();

    this.uniqueInvCode = `${USER_PREFIXID + DAYS + MONTS + YEAR + HOURS + MINUTES + SECONDS}`;

    console.log('UIC ', this.uniqueInvCode);
  }

  CreateInvOnline(ClaveFE: string, NumFe: string) {
    this.documentService.createInvOnline(this.invForm, {}, this.itemsList, this.feForm, ClaveFE, NumFe)
      .subscribe((data: any) => {
        this.documentService.CreateInvoice(this.invForm, {}, this.itemsList, this.feForm, this.uniqueInvCode)
        this.blockUI.stop();
        this.alertService.infoInfoAlert(`Factura creada correctamente`);
        this.btnVisibleBool = false;
        this.correctInvoice = true;
        this.lastInvoice = data.DocEntry;
        this.facturando = false;
      }, (error) => {
        this.blockUI.stop();
        this.facturando = false;
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // funcion para resetear los valores de la modal
  resetModalData() {
    this.currencyPayment = "COL";
    this.TotalCash = 0;
    this.TotalCards = 0;
    this.TotalCheck = 0;
    this.TotalTransfer = 0;
    this.TotalG = 0;
    this.ReceivedG = 0;
    this.DifferenceG = 0;
    this.ChangeG = 0;
    this.V_Checks.length = 0;
    this.V_CreditCards.length = 0;
    this.cashForm.patchValue({ AccountCash: this.accountList[0].Account });
    this.cashForm.patchValue({ TotalCash: 0 });
    this.creditCardForm.patchValue({ CreditCard: this.cardsList[0].CreditCard });
    this.creditCardForm.patchValue({ CardNum: '' });
    this.creditCardForm.patchValue({ OwnerIdNum: '' });
    this.creditCardForm.patchValue({ CardValid: '' });
    this.creditCardForm.patchValue({ CreditSum: 0 });
    this.creditCardForm.patchValue({ VoucherNum: '' });
    this.transferForm.patchValue({ AccountTransf: this.accountList[0].Account });

    this.transferForm.patchValue({ DateTrans: this.datePipe.transform(new Date(), 'y-MM-dd') });
    this.transferForm.patchValue({ Ref: '' });
    this.transferForm.patchValue({ TransSum: 0 });
    this.checkForm.patchValue({ AccountCheck: this.accountList[0].Account });
    this.checkForm.patchValue({ BankNames: this.banksList[0].BankCode });
    this.checkForm.patchValue({ DueDate: '' });
    this.checkForm.patchValue({ CountryCod: 'CR' });
    this.checkForm.patchValue({ AcctNum: '' });
    this.checkForm.patchValue({ CheckNum: '' });
    this.checkForm.patchValue({ CheckSum: 0 });
  }

  // funcion para calcular el total de recibido y de diferencia para el pago
  setTotalAmount() { //decimalPipe
    this.ReceivedG = Number((this.TotalCash + this.TotalCards + this.TotalCheck + this.TotalTransfer).toString());
    let diff = Number((this.TotalG - this.ReceivedG).toString());
    this.DifferenceG = Math.max(diff, 0.0);
    this.ChangeG = Math.max(0, -1 * diff);

    if (this.currencyPayment !== 'COL') {
      this.changeDolares = this.ChangeG;
      this.changeColones = Number((this.ChangeG * this.currencyChange).toString());
    }
    else {
      this.changeDolares = Number((this.ChangeG / this.currencyChange).toString());;
      this.changeColones = this.ChangeG;
    }
  }

  // funcion para obtener una lista de cuentas segun la compañía seleccionada
  getAccount() {
    this.blockUI.start('Obteniendo Cuentas, Espere Por Favor...');
    this.accountService.getAccount()
      .subscribe((data: any) => {
        if (data.result) {
          this.accountList = data.accountList;
          this.cashForm.patchValue({ AccountCash: data.accountList[0].Account });
          this.transferForm.patchValue({ AccountTransf: data.accountList[0].Account });
          this.checkForm.patchValue({ AccountCheck: data.accountList[0].Account });
          //this.GetPayTerms();
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert('Error al cargar Cuentas - Error: ' + data.errorInfo.Message);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);

      });
  }

  // redondea un numero hacia arriba con la cantidad de decimales establecidos en la variable precision
  roundUp(num, precision) {
    precision = Math.pow(10, precision);
    num = Math.ceil(num * precision) / precision;
    return Number((num).toString());
  }

  // funcion para cambiar el tipo de moneda en la modal de pagos
  changeCurrency() {
    // console.log(this.currencyPayment);
    if (this.currencyPayment !== 'COL') {
      this.TotalG = this.totalUSD;//Number((this.total / this.currencyChange).toFixed(2));
      this.ReceivedG = this.totalUSD;//Number((this.total / this.currencyChange).toFixed(2));
      this.currencyPayment = 'USD';
    } else {
      this.TotalG = this.total;
      this.ReceivedG = this.total;
      this.currencyPayment = 'COL';
    }
    //this.TotalG = this.roundUp(this.TotalG, 2);
    // console.log(this.currencyPayment);
    this.ReceivedG = 0;
    this.setTotalAmount();
  }

  // funcion qu obtiene el tipo de cambio
  getExchangeRate() {
    this.blockUI.start('Obteniendo Tipo de Cambio, Espere Por Favor...');
    this.exRateService.getExchangeRate()
      .subscribe((data: any) => {
        if (data.result) {
          this.currencyChange = data.exRate;
          this.blockUI.stop();

        } else {
          this.blockUI.stop();
          this.alertService.errorAlert('error al cargar tipo de cambio - Error: ' + data.errorInfo.Message);

        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);

      }, () => { this.blockUI.stop() });
  }

  getCards() {
    this.blockUI.start('Obteniendo Tarjetas, Espere Por Favor...');
    this.cardService.getCards()
      .subscribe((data: any) => {
        if (data.result) {
          this.cardsList = data.cardsList;
          this.creditCardForm.patchValue({ CreditCard: data.cardsList[0].CreditCard });
          this.CardName = data.cardsList[0].CardName;
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert('error al cargar tarjetas de credito - Error: ' + data.errorInfo.Message);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.errorAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  getAccountsBank() {
    this.blockUI.start('Obteniendo Bancos, Espere Por Favor...');
    this.bankService.getAccountsBank()
      .subscribe((data: any) => {
        if (data.result) {
          this.banksList = data.banksList;
          this.checkForm.patchValue({ BankNames: data.banksList[0].BankCode });
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert('error al obtener información de los Bancos - Error: ' + data.errorInfo.Message);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  addAmountCash() {
    // console.log('cash');

    this.ReceivedG = +this.ReceivedG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalCash = parseFloat(this.cashForm.value.TotalCash);
    if (this.TotalCash > 0) {
      if ((this.TotalCash + this.TotalCards + this.TotalTransfer) <= this.TotalG || 0.0 === (this.TotalCards + this.TotalTransfer)) {
        this.setTotalAmount();
      } else {
        this.alertService.errorAlert('El monto ingresado en efectivo supera el total de la factura.');
      }
    } else {
      this.cashForm.patchValue({ TotalCash: 0 });
      this.TotalCash = 0;
      this.setTotalAmount();
      this.alertService.errorInfoAlert('El monto ingresado en Total es incorrecto.');
    }
    //this.cashForm.controls['TotalCash'].setValue(this.currencyFormat(this.TotalCash));
  }
  // funcion para colocar el monto a pagar en tranferencia
  addAmountTransfer() {
    this.TotalTransfer = parseFloat(this.transferForm.value.TransSum);
    if (this.TotalTransfer > 0) {
      if ((this.TotalCash + this.TotalCards + this.TotalTransfer + this.TotalCheck) <= this.TotalG) {
        this.setTotalAmount();
      } else {
        this.alertService.infoInfoAlert('El monto ingresado en transferencia supera el total de la factura.');
      }
    } else {
      this.transferForm.patchValue({ TransSum: 0 });
      this.TotalTransfer = 0;
      this.setTotalAmount();
      this.alertService.infoInfoAlert(
        "El monto ingresado en Total es incorrecto."
      );
    }
  }

  addAmounCreditCard() {
    console.log(this.V_CreditCards);
    let totalcard = 0;
    if (this.creditCardForm.valid) {
      if (parseFloat(this.creditCardForm.value.CreditSum) > 0) {
        if (this.V_CreditCards.length > 0) {
          this.V_CreditCards.forEach((vcc) => {
            totalcard += parseFloat(vcc.CreditSum);
          });
          totalcard += parseFloat(this.creditCardForm.value.CreditSum);
        } else {
          totalcard = parseFloat(this.creditCardForm.value.CreditSum);
        }
        if (totalcard > 0) {
          if ((this.TotalCash + totalcard + this.TotalTransfer + this.TotalCheck) <= this.TotalG) {

            const CREDIT_CARD = this.cardsList.filter(x => x.CreditCard === this.creditCardForm.value.CreditCard)[0];

            this.V_CreditCards.push({
              CardValid: this.creditCardForm.value.CardValid,
              CrCardNum: this.creditCardForm.value.CardNum,
              CrTypeCode: CREDIT_CARD.CardName.split(' ')[0],
              CreditCard: this.creditCardForm.value.CreditCard,
              CreditSum: this.creditCardForm.value.CreditSum,
              Curr: this.currencyPayment,
              FormatCode: CREDIT_CARD.CardName,
              OwnerIdNum: this.creditCardForm.value.OwnerIdNum,
              VoucherNum: this.creditCardForm.value.VoucherNum,
            });

            this.TotalCards = totalcard;
            this.setTotalAmount();

            this.creditCardForm.patchValue({ OwnerIdNum: '' });
            this.creditCardForm.patchValue({ CardNum: '9999' });
            this.creditCardForm.patchValue({ CardValid: '01/99' });
            this.creditCardForm.patchValue({ CreditSum: '' });
          } else {
            this.alertService.infoInfoAlert('El monto ingresado en la tarjeta de crédito supera el total de la factura.');
          }
        } else {
          this.alertService.infoInfoAlert(
            "El monto ingresado en Total es incorrecto."
          );
        }
      } else {
        this.creditCardForm.patchValue({ CreditSum: 0 });
        this.alertService.infoInfoAlert(
          "El monto total debe ser superior a cero."
        );
      }
    } else {
      this.alertService.infoInfoAlert("Campos inválidos");
    }
  }

  addAmountCheck() {
    let totalcheck = 0;
    if (this.checkForm.valid) {
      if (parseFloat(this.checkForm.value.CheckSum) > 0) {
        if (this.V_Checks.length > 0) {
          this.V_Checks.forEach((vcc) => {
            totalcheck += parseFloat(vcc.CheckSum);
          });
          totalcheck += parseFloat(this.checkForm.value.CheckSum);
        } else {
          totalcheck = parseFloat(this.checkForm.value.CheckSum);
        }
        if (totalcheck > 0) {
          if ((this.TotalCash + this.TotalCards + this.TotalTransfer + totalcheck) <= this.TotalG) {
            this.V_Checks.push({
              AcctNum: this.checkForm.value.AcctNum,
              BankCode: this.checkForm.value.BankNames,
              CheckAct: this.checkForm.value.AccountCheck,
              CheckNum: this.checkForm.value.CheckNum,
              CheckSum: this.checkForm.value.CheckSum,
              CountryCod: this.checkForm.controls.CountryCod.value,
              Curr: this.currencyPayment,
              DueDate: this.checkForm.value.DueDate,
            });
            this.TotalCheck = totalcheck;
            this.setTotalAmount();
          } else {
            this.alertService.infoInfoAlert('El monto ingresado en el cheque supera el total de la factura.');
            this.TotalCards = 0;
            this.setTotalAmount();
          }
        } else {
          // falta validar cuando no se digita nada en el campo del monto
          this.TotalCards = 0;
          this.alertService.infoInfoAlert(
            "El monto ingresado en Total es incorrecto."
          );
        }
      } else {
        this.alertService.infoInfoAlert(
          "El monto total debe ser superior a cero."
        );
      }
    } else {
      this.alertService.infoInfoAlert("Campos inválidos");
    }
  }

  removeCreditCard(index, _voucherNumber: string) {
    console.log('voucher ->', _voucherNumber);
    let hasPinPadPayment = this.pinPadCards.some(x => x.ReferenceNumber === _voucherNumber);
    // this.pinPadCards.forEach(x => {
    //   if (x.ReferenceNumber === _voucherNumber) hasPinPadPayment = true;
    // });
    let iPPTrasaction = this.pinPadCards.find(x => x.ReferenceNumber === _voucherNumber);

    if (iPPTrasaction !== undefined) {
      this.blockUI.start(`Anulando tarjeta, espere por favor`);
      this.paymentService.cancelPinPadCard(iPPTrasaction).subscribe(next => {
        if (next.result) {
          this.pinPadCards = this.pinPadCards.filter(x => x.ReferenceNumber !== _voucherNumber);
          this.TotalCards -= this.V_CreditCards[index].CreditSum;
          this.V_CreditCards.splice(index, 1);
          this.setTotalAmount();
        }
        else {
          this.alertService.errorAlert(`No se puede anular la tarjeta seleccionada, Detalle: ${next.errorInfo.Message}`);
        }
        this.blockUI.stop();
      }, error => {
        this.blockUI.stop();
        console.log(error);
      }, () => this.blockUI.stop());
    }
    else {
      this.TotalCards -= this.V_CreditCards[index].CreditSum;
      this.V_CreditCards.splice(index, 1);
      this.setTotalAmount();
    }
  }

  removeCheck(index) {
    this.TotalCheck -= this.V_Checks[index].CheckSum;
    this.V_Checks.splice(index, 1);
    this.setTotalAmount();
  }

  partialPayValidator() {
    if (
      confirm(
        "el monto del pago es menor del monto total, ¿desea realizar el pago parcial?"
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  getCurrencyByUser(currency: string) {
    this.currencyList = this.allCurrencyList;
    if (currency === 'COL' || currency === 'USD') {
      this.currencyList = this.currencyList.filter(cur => cur.Id === currency);

      this.currencyList.push({
        Id: "USD",
        Name: "Dólares",
        Symbol: "$"
      });
    }
    this.currencyList = this.currencyList.sort();
    if (this.currencyList.length === 1) {
      this.invForm.patchValue({ currency: this.currencyList[0].Id });
      this.currencyPayment = this.currencyList[0].Id;
    }
    else {
      this.currencyPayment = 'COL';
      this.invForm.patchValue({ currency: "COL" }); //
    }

    this.SetCurr();
  }

  GetCurrencyType() {
    this.blockUI.start('Obteniendo los tipos de monedas...');
    this.paramsService.GetCurrencyType()
      .subscribe((data: any) => {
        if (data.length > 0) {
          this.currencyList = data;
          this.currencyList.sort();
          this.allCurrencyList = data;
          this.currencyPayment = 'COL';
          this.funcion();
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert('Error al cargar las monedas - ' + data.errorInfo.Message);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  SetCurr() {
    let cur = this.currencyList.filter((curr) => {
      return curr.Id === this.invForm.controls.currency.value;
    });
    this.setCurr = cur[0].Symbol;
    if (this.invForm.controls.currency.value === 'COL') {

      this.setCurr = '₡';
      this.currencyPayment = 'COL';
    }
    else {
      this.currencyPayment = 'USD';
      this.setCurr = '$';
    }
    this.userCurrency = this.currencyPayment;
  }

  CreateNew() {
    this.facturando = false;
    this.resetModalData();
    this.btnVisibleBool = true;
    this.invForm.reset(true);
    this.cashForm.reset(true);
    this.creditCardForm.reset(true);
    this.transferForm.reset(true);
    this.checkForm.reset(true);

    this.itemsList.length = 0;

    this.V_CreditCards.length = 0;
    this.V_Checks.length = 0;
    this.conta = 0;
    this.total = 0;
    this.totalUSD = 0;
    this.tax = 0;
    this.discount = 0;
    this.totalWithoutTax = 0;
    this.identificationTypeList = IdentificationType;

    this.invForm = this.fb.group({
      DocumentType: ['', Validators.required],
      cardCode: [this.DEFAULT_BUSSINESS_PARTNER, Validators.required], //CD0051 - SUPER // C0001 - DISUMED
      cardName: ['', Validators.required],
      currency: ['', Validators.required],
      PayTerms: ['', Validators.required],
      PriceList: ['', Validators.required],
      SlpList: ['', Validators.required],
      paymentType: [''],
    });

    this.invForm.patchValue({ DocumentType: this.typesInvoice[0].Name });
    this.invForm.patchValue({ paymentType: this.paymentTypes[0].id });
    this.cashForm = this.fb.group({
      AccountCash: ["", Validators.required],
      TotalCash: [0, Validators.required],
    });
    this.creditCardForm = this.fb.group({
      CreditCard: [""],
      CardNum: ["", [Validators.required]],
      OwnerIdNum: ["", Validators.required],
      CardValid: [""],
      CreditSum: [0, Validators.required],
      VoucherNum: ["", Validators.required],
    });
    this.transferForm = this.fb.group({
      AccountTransf: ["", Validators.required],
      DateTrans: ["", Validators.required],
      Ref: [""],
      TransSum: [0, Validators.required],
    });
    this.checkForm = this.fb.group({
      AccountCheck: ['', Validators.required],
      BankNames: ['', Validators.required],
      DueDate: ['', Validators.required],
      CountryCod: [{ value: 'CR', disabled: true }, Validators.required],
      AcctNum: ['', Validators.required],
      CheckNum: ['', Validators.required],
      CheckSum: [0, Validators.required]
    });

    this.invForm.patchValue({ cardCode: this.DEFAULT_BUSSINESS_PARTNER });
    this.getExchangeRate();
    this.createFEForm();
    this.getMaxDiscout();
    this.getCustomers();
    this.GetParamsViewList();
    this.getExRate();
    this.GetPriceList();
    this.GetSalesPersonList();
    this.getTaxes();
    this.getAccount();
    this.getCards();
    this.getAccountsBank();
    this.GetPayTerms();
    this.Cant.setValue(1);
    this.nameField.nativeElement.focus();
    this.correctInvoice = false;
    this.correctInvoice = false;
    this.Cant.setValue(1);
    this.nameField.nativeElement.focus();
    this.currencyPayment = 'COL';
    this.getCompanies();
    this.generateUniqueInvoiceId();
    this.currencyPayment = 'COL';
    this.hasLines = false;
    this.isBilling = false;
    this.attemptsWhileBilling = 0;
  }

  funcion() {
    let inputValue = this.DEFAULT_BUSSINESS_PARTNER
    let code = inputValue;
    let codePos = this.bpCodeList.indexOf(code);
    let cardName = this.bpNameList[codePos];


    let customer = this.bpList.filter(cus => {
      return cus.CardCode === code;
    });

    this.userCurrency = customer[0].Currency;

    this.getCurrencyByUser(customer[0].Currency);

    if (cardName !== this.invForm.get('cardName').value) {
      this.invForm.patchValue({ cardName: cardName });
    }
  }

  GetPayTerms() {
    this.blockUI.start('Obteniendo Terminos de pago, Espere Por Favor...');
    this.itemService.GetPayTerms()
      .subscribe((data: any) => {
        if (data.result) {
          this.PayTermsList = data.payTermsList;
          this.invForm.patchValue({ PayTerms: PayTermsEnum.Contado });
          //this.invForm.patchValue({ PriceList: this.defaultListNum });
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
        }
      },
        (error: any) => {
          this.blockUI.stop();
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
        });
  }

  GetPriceList() {
    this.blockUI.start('Obteniendo Listas de precios, Espere Por Favor...');
    this.itemService.GetPriceList()
      .subscribe((data: any) => {
        if (data.result) {
          this.PriceList = data.priceList;

          this.invForm.patchValue({ PriceList: this.PriceList[0].ListNum });
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
        }
        this.setDefaultList(this.DEFAULT_BUSSINESS_PARTNER);
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }
  ClearItemList() {
    this.itemsList.length = 0;
    this.getItems();
    this.getTaxes();
    this.getAccount();
    this.getCards();
    this.getAccountsBank();
  }

  setDefaultList(_cardCode: string) {
    this.itemService.GetPriceListDefault(_cardCode).subscribe(next => {
      if (next.result) {
        this.invForm.patchValue({
          PriceList: next.PriceList.ListNum
        });
      }
    });
  }

  GetSalesPersonList() {
    this.blockUI.start('Obteniendo vendedores, Espere Por Favor...');
    this.smService.getSalesMan()
      .subscribe((data: any) => {
        if (data.result) {
          this.SlpsList = data.salesManList;
          this.invForm.patchValue({ SlpList: this.defaultSlpCodeStr });
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
        }

      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  identificationTypeChange(IdentificationValue: string) {
    switch (IdentificationValue) {
      case '00': {
        this.feForm.controls['Identification'].setValidators([]);
        this.feForm.controls['Identification'].updateValueAndValidity();
        this.feForm.controls['Direccion'].setValidators([]);
        this.feForm.controls['Direccion'].updateValueAndValidity();
        this.feForm.controls['Email'].setValidators([]);
        this.feForm.controls['Email'].updateValueAndValidity();
        break;
      }
      case '01': {
        this.validatorFeForm(9, 9);
        this.getProvinces();
        break;
      }
      case '02':
      case '04': {
        this.validatorFeForm(10, 10);
        this.getProvinces();
        break;
      }
      case '03': {
        this.validatorFeForm(11, 12);
        this.getProvinces();
        break;
      }
    }
  }

  validatorFeForm(min: number, max: number) {
    this.feForm.controls['Identification'].setValidators([Validators.required, Validators.minLength(min), Validators.maxLength(max)]);
    this.feForm.controls['Identification'].updateValueAndValidity();
    this.feForm.controls['Direccion'].setValidators([Validators.required, Validators.maxLength(250)]);
    this.feForm.controls['Direccion'].updateValueAndValidity();
    this.feForm.controls['Email'].setValidators([Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]);
    this.feForm.controls['Email'].updateValueAndValidity();
  }

  getProvinces() {

    this.jsonDataService.getJSONProvinces()
      .subscribe((data: any) => {
        this.provinceList = data.Provinces;
        this.feForm.patchValue({ Provincia: this.provinceList[0].ProvinceId });
        this.nombreProvincia = this.provinceList[0].ProvinceName;
        this.getCantons(this.provinceList[0].ProvinceId)
      });
  }

  setProvinceName(provId) {
    let provList: any;
    this.jsonDataService.getJSONProvinces()
      .subscribe((data: any) => {
        provList = data.Provinces;
        provList.forEach(prov => {
          if (Number(prov.ProvinceId) === Number(provId)) {
            this.nombreProvincia = prov.ProvinceName;
          }
        });
      });
  }

  setCantonName(cantonId) {
    let cantList: any;
    this.jsonDataService.getJSONCountryPlaces()
      .subscribe((data: any) => {
        cantList = this.unique(data.Country.filter(x => x.ProvinceId === this.provinceId), 'CantonId');
        if (true) {
          cantList.forEach(cant => {
            if (Number(cant.CantonId) === Number(cantonId)) {
              this.nombreCanton = cant.CantonName;
            }
          });
        }
      });
  }

  setDistrictName(distId) {
    let distList: any;
    this.jsonDataService.getJSONCountryPlaces()
      .subscribe((data: any) => {
        distList = this.unique(data.Country.filter(x => x.ProvinceId === this.provinceId && x.CantonId === this.cantonId), 'DistrictId');
        if (true) {
          distList.forEach(dist => {
            if (Number(dist.DistrictId) === Number(distId)) {
              this.nombreDistrito = dist.DistrictName;
            }
          });
        }
      });
  }

  setNeighborhoodName(neighId) {
    let neighList: any;
    this.jsonDataService.getJSONCountryPlaces()
      .subscribe((data: any) => {
        neighList = this.unique(data.Country.filter(x => x.ProvinceId === this.provinceId && x.CantonId === this.cantonId && x.DistrictId === this.districtId), 'NeighborhoodId');
        if (true) {
          neighList.forEach(neigh => {
            if (Number(neigh.NeighborhoodId) === Number(neighId)) {
              this.nombreBarrio = neigh.NeighborhoodName;
            }
          });
        }
      });
  }

  getProvincesPatch(data) {
    this.blockUI.start('Obteniendo datos FE...');
    this.jsonDataService.getJSONProvinces()
      .subscribe((data2: any) => {
        this.blockUI.stop();
        //this.provinceList = data2.Provinces;
        this.feForm.patchValue({ IdType: data.FEInfo.IdType });
        this.feForm.patchValue({ Identification: data.FEInfo.Identification });
        this.feForm.patchValue({ Email: data.FEInfo.Email });
        //this.feForm.patchValue({ Direccion: data.FEInfo.Direccion });
        // let provid = '01';
        // let provname = this.provinceList[0].ProvinceName;
        // this.provinceList.forEach(prov => {
        //   if (prov.ProvinceName.toUpperCase() === data.FEInfo.Provincia.toUpperCase()) {
        //     provid = prov.ProvinceId;
        //     provname = prov.ProvinceName;
        //   }
        // });
        // this.nombreProvincia = provname;
        // this.feForm.patchValue({ Provincia: Number(provid).toString() });
        // this.getCantonsPatch(provid, data.FEInfo.Canton, data.FEInfo.Distrito, data.FEInfo.Barrio);
      });
  }

  getCantons(provinceId) {
    this.setProvinceName(provinceId);
    this.provinceId = provinceId;
    this.jsonDataService.getJSONCountryPlaces()
      .subscribe((data: any) => {
        this.cantonList = this.unique(data.Country.filter(x => x.ProvinceId === this.provinceId), 'CantonId');
        if (true) {
          this.cantonId = this.cantonList[0].CantonId;
          this.feForm.patchValue({ Canton: this.cantonId });
          this.nombreCanton = this.cantonList[0].CantonName;
          this.getDistrics(this.cantonId);
        }
      });
  }

  getCantonsPatch(provinceId, cantonName, districtName, neighbourhoodName) {
    this.provinceId = provinceId;
    this.setProvinceName(provinceId);
    this.blockUI.start('Obteniendo datos FE...');
    this.jsonDataService.getJSONCountryPlaces()
      .subscribe((data: any) => {
        this.blockUI.stop();
        this.cantonList = this.unique(data.Country.filter(x => Number(x.ProvinceId) === Number(this.provinceId)), 'CantonId');
        let cantid = '01';
        let cantname = this.cantonList[0].CantonName;
        this.cantonList.forEach(cant => {
          if (cant.CantonName.toUpperCase() === cantonName.toUpperCase()) {
            cantid = cant.CantonId;
            cantname = cant.CantonName;
          }
        });
        if (true) {
          this.cantonId = cantid;
          this.nombreCanton = cantname;
          this.feForm.patchValue({ Canton: cantid });
          this.getDistricsPatch(cantid, districtName, neighbourhoodName);
        }
      });
  }

  getDistrics(cantonId) {
    this.cantonId = cantonId;
    this.setCantonName(cantonId);
    this.jsonDataService.getJSONCountryPlaces()
      .subscribe((data: any) => {
        this.districtList = this.unique(data.Country.filter(x => x.ProvinceId === this.provinceId && x.CantonId === this.cantonId), 'DistrictId');
        if (typeof this.feForm.value.Distrito !== 'undefined') {
          this.districtId = this.districtList[0].DistrictId;
          this.nombreDistrito = this.districtList[0].DistrictName;
          this.feForm.patchValue({ Distrito: this.districtId });
          this.getNeighborhood(this.districtId);
        }
      });
  }

  getDistricsPatch(cantonId, districtName, neighbourhoodName) {
    this.cantonId = cantonId;
    this.setCantonName(cantonId);
    this.blockUI.start('Obteniendo datos FE...');
    this.jsonDataService.getJSONCountryPlaces()
      .subscribe((data: any) => {
        this.blockUI.stop();
        this.districtList = this.unique(data.Country.filter(x => Number(x.ProvinceId) === Number(this.provinceId) && x.CantonId === this.cantonId), 'DistrictId');
        let distid = '01';
        let distname = this.districtList[0].DistrictName;
        this.districtList.forEach(dist => {
          if (dist.DistrictName.toUpperCase() === districtName.toUpperCase()) {
            distid = dist.DistrictId;
            distname = dist.DistrictName;
          }
        });
        if (true) {
          this.districtId = distid;
          this.nombreDistrito = distname;
          this.feForm.patchValue({ Distrito: distid });
          this.getNeighborhoodPatch(distid, neighbourhoodName);
        }
      });
  }

  getNeighborhood(districtId) {
    this.districtId = districtId;
    this.setDistrictName(districtId);
    this.jsonDataService.getJSONCountryPlaces()
      .subscribe((data: any) => {
        this.neighborhoodList = this.unique(data.Country.filter(x => Number(x.ProvinceId) === Number(this.provinceId) && x.CantonId === this.cantonId && x.DistrictId === this.districtId), 'NeighborhoodId');
        if (typeof this.feForm.value.Barrio !== 'undefined') {
          this.neighborhoodId = this.neighborhoodList[0].NeighborhoodId;
          this.nombreBarrio = this.neighborhoodList[0].NeighborhoodName;
          this.feForm.patchValue({ Barrio: this.neighborhoodId });
        }
      });
  }

  getNeighborhoodPatch(districtId, neighbourhoodName) {
    this.districtId = districtId;
    this.setDistrictName(districtId);
    this.blockUI.start('Obteniendo datos FE...');
    this.jsonDataService.getJSONCountryPlaces()
      .subscribe((data: any) => {
        this.blockUI.stop();
        this.neighborhoodList = this.unique(data.Country.filter(x => Number(x.ProvinceId) === Number(this.provinceId) && x.CantonId === this.cantonId && x.DistrictId === this.districtId), 'NeighborhoodId');
        let neighid = '01';
        let neighname = this.neighborhoodList[0].NeighborhoodName;
        this.neighborhoodList.forEach(neigh => {
          if (neigh.NeighborhoodName.toUpperCase() === neighbourhoodName.toUpperCase()) {
            neighid = neigh.NeighborhoodId;
            neighname = neigh.NeighborhoodName;
          }
        });
        if (true) {
          this.neighborhoodId = neighid;
          this.nombreBarrio = neighname;
          this.feForm.patchValue({ Barrio: neighid });
        }
      });
  }

  unique(array, propertyName) {
    return array.filter(
      (e, i) =>
        array.findIndex((a) => a[propertyName] === e[propertyName]) === i
    );
  }

  patchZeroes() {
    /*
    this.checkForm.patchValue({CheckSum: 0 });
    this.cashForm.patchValue({TotalCash: 0 });
    this.transferForm.patchValue({TransSum: 0 });
    this.creditCardForm.patchValue({CreditSum: 0 });
    */
  }

  onCtrlBCash() {
    this.patchZeroes();
    this.cashForm.patchValue({ TotalCash: this.DifferenceG.toFixed(this.COMPANY.DecimalAmountTotalDocument) });
    this.addAmountCash();
  }

  onCtrlBTransferencia() {
    this.patchZeroes();
    this.transferForm.patchValue({ TransSum: this.DifferenceG.toFixed(this.COMPANY.DecimalAmountTotalDocument) });
    this.addAmountTransfer();
  }

  onCtrlBCheque() {
    this.patchZeroes();
    this.checkForm.patchValue({ CheckSum: this.DifferenceG.toFixed(this.COMPANY.DecimalAmountTotalDocument) });
  }

  onCtrlBTarjeta() {
    this.patchZeroes();
    this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.ReceivedG = +this.ReceivedG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.creditCardForm.patchValue({ CreditSum: this.DifferenceG.toFixed(this.COMPANY.DecimalAmountTotalDocument) });
  }

  onAltArticulos() {

  }

  getCompanies() {
    this.blockUI.start('Obteniendo Compañías, Espere Por Favor...');
    this.companyService.GetCompanies().subscribe((data: any) => {
      if (data.result) {
        this.companiesList.length = 0;
        this.companiesList = data.companiesList;
        this.companiesList.forEach(comp => {
          this.pesoBolsa = comp.ScaleWeightToSubstract;
          this.priceEditable = comp.IsLinePriceEditable;
          this.maxWeightTo0 = comp.ScaleMaxWeightToTreatAsZero;
        });
      } else {
        this.alertService.errorAlert('Error al cargar compañías - Error: ' + data.errorInfo.Message);
      }
      this.blockUI.stop();
    }, (error: any) => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  // formatea el monto a currency
  public currencyFormat(number: number) {
    return this.cp.transform(number, '', '', '1.2-2');
  }

  //#region Cambios Jorge EMA Aromas
  setWarehouseInfo() {
    let session = this.storage.getSession(navigator.onLine);
    if (session) {
      session = JSON.parse(session);

      this.whCode = session.WhCode;
      this.whName = session.WhName;
    }
  }
  //#endregion

  /*
  NOTAS:
    EN EL HTML
    <!-- <input [class.text-danger]="item.Quantity === 0 && item.InvntItem==='Y'" class="form-control form-control-sm invi" *ngIf=" param.Visibility && param.Name == 'ItemCode'" type="text" [(ngModel)]="item.CodeName" readonly> -->
    ESTA LINEA ES LA ORIGINAL, PERO LA COMENTE PARA INTENTAR CORREGIR EL MARCADO SIN INVENTARIO, Y ASI CON TODAS LAS COLUMNAS DE LA TABLA
    QUITE && item.InvntItem==='Y'
  */
}

