import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

// MODELOS
import { AppConstants, UserAssigns, SeriesbyUser, BaseResponse, Users } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService, } from './storage.service';
import { AlertService } from './alert.service';
import { Observable } from 'rxjs';
import { IUserResponse, IUsersResponse, GetUsersConnServiceResponse } from '../models/responses';
import { AuthenticationService } from '.';
import { first } from 'rxjs/operators';

// PIPES

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: UserAssigns;
  InvSerieList: SeriesbyUser;
  QuoSerieList: SeriesbyUser;
  IpaySerieList: SeriesbyUser;
  SOSerieList: SeriesbyUser;
  NcSerie: SeriesbyUser;
  CustomerSerieList: SeriesbyUser;
  SupplierSerieList: SeriesbyUser;
  ApInvoiceSerieList: SeriesbyUser;
  ApPaymentSerieList: SeriesbyUser;

  constructor(private http: HttpClient, private storage: StorageService, private router: Router,
    private alertService: AlertService) {
    this.user = new UserAssigns();
    this.InvSerieList = new SeriesbyUser();
    this.QuoSerieList = new SeriesbyUser();
    this.IpaySerieList = new SeriesbyUser();
    this.SOSerieList = new SeriesbyUser();
    this.NcSerie = new SeriesbyUser();
    this.CustomerSerieList = new SeriesbyUser();
    this.SupplierSerieList = new SeriesbyUser();
    this.ApInvoiceSerieList = new SeriesbyUser();
    this.ApPaymentSerieList = new SeriesbyUser();

  }
  @BlockUI() blockUI: NgBlockUI;
  // obtiene la lista de ususarios.
  getUserList() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Users/GetUserUserAssignList`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }
  // obtiene los almacenes
  getStoresList(company: number) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Stores/GetStoresByCompany/?company=` + company;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }
  // obtiene las compañias
  getGetCompanies() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetCompanies`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<BaseResponse>(url, { headers });
  }

  // obtiene las series, se debe indicar el tipo de documento para obtener las series asignadas a este
  getSeries(_docType: number) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Series/GetSeries`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<BaseResponse>(url, { headers });
  }

  sendUserInfo(grupo: FormGroup, userName: string, Id: number) {
    this.user.Id = Id;
    this.user.UserId = userName;
    this.user.SAPUser = grupo.value.cSAPUser;
    this.user.UserName = grupo.value.cUserName;
    this.user.CompanyId = grupo.value.CompanyId;
    this.user.StoreId = grupo.value.StoreId;
    this.user.minDiscount = grupo.value.minDiscount;
    this.user.SlpCode = grupo.value.SlpCode;
    this.user.SAPPass = grupo.value.SAPPass;
    this.user.CenterCost = grupo.value.CenterCost !== '' ? grupo.value.CenterCost : 0;
    this.user.PriceListDef = grupo.value.PriceListDef;
    this.user.Active = grupo.value.Active === 'S';
    this.user.BranchOfficeId = grupo.value.BranchOfficeId;
    this.user.SuperUser = grupo.value.SuperUser === 'S';
    this.user.ConnectionServiceId = Number(grupo.value.UserConnSer);

    let urlApi = 'api/Users/UpdateUser';

    if (this.user.Id === 0) urlApi = 'api/Users/CreateNewUser';

    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}` + urlApi;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    this.http.post(url, this.user, { headers }).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        this.alertService.successInfoAlert('El usuario seleccionado debe cerrar sesión para aplicar los cambios');
        this.router.navigate(['AssignsUsers']);
      } else {
        this.alertService.errorAlert('Error al enviar la información de usuarios - ' + data.errorInfo.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert('Se produjo un error al intentar conectar con el servidor ');
      this.router.navigate(['AssignsUsers']);
    });
  }

  getUsersApp(): Observable<IUsersResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Users/GetUsersApp`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IUsersResponse>(url, { headers });
  }

  getUserApp(_id: string): Observable<IUserResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Users/GetUserApp?id=${encodeURIComponent(_id)}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IUserResponse>(url, { headers });
  }

  createUserApp(_user: Users): Observable<BaseResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Users/CreateUserApp`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<BaseResponse>(url, _user, { headers });
  }
  updateUserApp(_user: Users): Observable<BaseResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Users/UpdateUserApp`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<BaseResponse>(url, _user, { headers });
  }

  CanEditInterest(user: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<BaseResponse>(`${AppConstants.apiUrl}api/Users/CanEditInterest`, user, { headers });
  }

  GetUsersConnService() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<GetUsersConnServiceResponse>(`${AppConstants.apiUrl}api/Users/GetConnectionServer`,
      { headers });
  }
}
