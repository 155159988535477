import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { ITerminal, Users, User } from 'src/app/models';
import { AlertService, BankService, PermsService, UserService } from 'src/app/services';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  users: Users[]; // Lista de los useres obtenidos
  @BlockUI() blockUI: NgBlockUI; // Para bloquear la interfaz mientras se realiza una acccion
  @ViewChild("UserName") UserName: ElementRef; // Para mandar el focus a este elemento
  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  permisos: boolean = true; // Para comprobar los permisos del usuario
  userModalTitle: string; // Titulo dinamico para la modal
  userTarget: Users; // Modelo usando para actualizar una user
  isValidForm: boolean; // Representa el estado  del formulario aprobado-rechazado
  userForm = new FormGroup({
    Id: new FormControl(''),
    PasswordHash: new FormControl(''),
    FullName: new FormControl('', [Validators.required]),
    UserName: new FormControl('', [Validators.required]),
    Status: new FormControl(''),
    EmailAuthorizer: new FormControl('',[Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
  }); // La definicio del formulario del user
 
  constructor(
    private sPerm: PermsService,
    private userService: UserService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.blockUI.start(`Obteniendo usuarios`);
    this.initVariables();
    this.getusers();
  }

  get formControls() {
    return this.userForm.controls;
  }
  // Trigger para levantar la modal de creacion de user
  raiseuserModalCreation(): void {
    this.resetuserForm();
    this.isValidForm = true;
    this.userModalTitle = 'Crear usuario';
    this.isUpdating = false;
    (<HTMLButtonElement>document.getElementById('triggerRaiseUserModal')).click();
    this.userForm.patchValue({ Active: true });
    this.userTarget = {} as Users;
  }
  // Trigger para levantar la modal de actualizacion de user
  isUpdating: boolean;
  raiseUserModal(_userId: number): void {
    this.userService.getUserApp(_userId.toString()).subscribe(next => {
      // this.userTarget = {} as ITerminal;
     
      if (next.result) {
        this.resetuserForm();
        this.userModalTitle = 'Editar usuario';
        this.userTarget = next.User;
        this.isUpdating = true;
        this.userForm.patchValue({ ...next.User });
        
        (<HTMLButtonElement> document.getElementById('triggerRaiseUserModal')).click();
      }
      else {
        this.alertService.errorAlert(`Error al obtener el usuario, detalle: ${next.errorInfo.Message}`);
      }
      this.blockUI.stop();
    }, error => {
      this.blockUI.stop();
      console.log(error);
      this.alertService.errorAlert(`Error al obtener el usuario, detalle: ${error}`);
    }, () => { });
  }
  // Actualiza o crea un user segun sea el caso
  saveuser(): void {
     this.userTarget = { ...this.userForm.value } as Users;   
    this.isValidForm = this.userForm.valid
    
    if (this.isValidForm) {
      (<HTMLButtonElement> document.getElementById('hideButtonTrigger')).click();
      this.blockUI.start('Procesando transacción, espere por favor');
      if (this.userTarget.Id) {
        this.userService.updateUserApp(this.userTarget).subscribe(next => {
          if (next.result) {
            this.alertService.successInfoAlert(`Usuario actualizado exitosamente`);
            this.getusers();
          }
          else {
            this.alertService.errorAlert(`Error al actualizar el usuario, detalle: ${next.errorInfo.Message}`);
          }
          this.blockUI.stop();
        }, error => {
          this.blockUI.stop();
          console.log(error);
          this.alertService.errorAlert(`Error al actualizar el usuario, detalle: ${error}`);
        }, () => { });
      }
      else {
        this.userService.createUserApp(this.userTarget).subscribe(next => {
          if (next.result) {
            this.alertService.successInfoAlert(`Usuario creado exitosamente`);
            this.getusers();
          }
          else {
            this.alertService.errorAlert(`Error al crear el usuario, detalle: ${next.errorInfo.Message}`);
          }
          this.blockUI.stop();
        }, error => {
          this.blockUI.stop();
          console.log(error);
          this.alertService.errorAlert(`Error al crear el usuario, detalle: ${error}`);
        }, () => { });
        // this.bankService.Createuser(this.userTarget).subscribe(next => {
        //   if (next.result) {
        //     this.alertService.successInfoAlert(`user creado con éxito`);
        //     this.getusers();
        //   }
        //   else {
        //     this.alertService.errorAlert(`Error al crear la user, detalle: ${next.errorInfo.Message}`);
        //   }
        //   this.blockUI.stop();
        // }, error => {
        //   this.blockUI.stop();
        //   console.log(error);
        //   this.alertService.errorAlert(`Error al crear la user, detalle: ${error}`);
        // }, () => {});
      }
    }
  }
  // Verifica los permisos del usuario en la apliacion
  checkPermits() {
    this.sPerm.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        data.perms.forEach(Perm => {
          if (Perm.Name === "V_Inven") {
            this.permisos = Perm.Active;
          }
        });
      } else {
        this.permisos = false;
      }
    }, error => {
      console.log(error);
      this.blockUI.stop();
      this.permisos = false;
    });
  }
  // Inicializa las variables a un estado por defecto
  initVariables(): void {
    this.resetuserForm();
  }
  // Restablece a un estado por defect el formulario del user
  resetuserForm(): void {
    this.userForm = new FormGroup({
      Id: new FormControl(''),
      FullName: new FormControl('', [Validators.required]),
      PasswordHash: new FormControl(''),
      Email: new FormControl('', [Validators.required]),
      Active: new FormControl(''),
      EmailAuthorizer: new FormControl('',[Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
    });
  }
  // Obtiene los useres registrados en el sistema
  getusers(): void {
    this.userService.getUsersApp().subscribe(next => {
      this.blockUI.stop();
      if (next.result) {
        this.alertService.successInfoAlert(`Usuarios obtenidos exitosamente`);
        this.users = next.Users;      
      }
      else {
        console.log(next);
        if (next.Users.length === 0) this.alertService.infoAlert(`No hay usuarios registrados en el sistema`);
        else {
          this.alertService.infoAlert(`Error el obtener los usuarios: ${next.errorInfo.Message}`);
        }
      }
    }, error => {
      this.blockUI.stop();
      console.log(error);
      this.alertService.errorAlert(`Error al obtener la usuarios, detalle: ${error}`);
    }, () => { });
    // this.bankService.getusers().subscribe(next => {
    //   this.blockUI.stop();
    //   if (next.result) {
    //     this.alertService.successInfoAlert(`useres obtenidos exitosamente`);
    //     this.users = next.PPusers;
    //     console.log(this.users);
    //   }
    //   else {
    //     console.log(next);
    //     if (next.PPusers.length === 0) this.alertService.infoAlert(`No hay useres registrados en el sistema`);
    //     else {
    //       this.alertService.infoAlert(`Error el obtener los useres: ${next.errorInfo.Message}`);
    //     }
    //   }  
    // }, error => {
    //   this.blockUI.stop();
    //   console.log(error);
    //   this.alertService.errorAlert(`Error al obtener la user, detalle: ${error}`);
    // }, () => {});
  }
  // Alterna el estado del user entre activo inactivo
  toggleStatus(): void {
    this.userForm.patchValue({
      Status: !this.userForm.value.Active
    });
  }
}
