import { Injectable } from '@angular/core';

// SERVICIOS
import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from '../models';
import { IItemModel } from '../models/i-item';
import { ILine } from '../models/i-line';
@Injectable({
  providedIn: 'root'
})
export class GoodsReciptService {

  constructor( private http: HttpClient,
    private storage: StorageService ) {
}

  // Edita un modelo
  CreateGoodsRecipt(lines: ILine[], cardCode: string, cardName: string, LicTradNum: string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/GoodsReceipt/CreateGoodsReceipt`;
    // const url = `${AppConstants.apiUrl}api/PurchaseOrder/CreatePurchaseOrder`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<any>(url, {
      'Lines': lines,
      'BusinessPartner': {
        'CardCode': cardCode,
        'CardName': cardName,
        'LicTradNum': LicTradNum
    }} , { headers });
  }

  // Edita un modelo
  CreateGoodsReciptReturn(lines: ILine[], cardCode: string, cardName: string, LicTradNum: string, comment: string, numAtCard: string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/GoodsReceipt/GoodsReceiptReturn`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<any>(url, {
      'Lines': lines,
      'Comments': comment,
      'NumAtCard': numAtCard,
      'BusinessPartner': {
        'CardCode': cardCode,
        'CardName': cardName,
        'LicTradNum': LicTradNum
    }} , { headers });
  }
}
