import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';

// MODELOS
import { AppConstants, IPrinter } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { AlertService } from './alert.service';
import { Observable } from 'rxjs';
import { ICompanySapResponse, IPaymentMethodsReponse } from '../models/responses';


// PIPES

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  @BlockUI() blockUI: NgBlockUI;

  constructor( private http: HttpClient,
    private storage: StorageService,
    private router: Router) {
      this.hola();
  }

  // funcion para obtener las compañias de la DBLocal
  // no recibe parametros
  GetCompanies() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetCompanies`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  // funcion para obtener las informacion de la compañia de la DBLocal
  // recibe como parametro el id de la compannia
  GetCompanyById(companyId: number) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetCompanyById?companyId=${companyId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }
  // Servicio para obtener la información de la compania por defecto
  GetDefaultCompany(): Observable<ICompanySapResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetDefaultCompany`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<ICompanySapResponse>(url, { headers });
  }

  // funcion para crear una compañía nueva y el correo
  // recibe como parametro el formulario de la compañía con el correo, ademas del id de la compannia y el del correo
  CreateCompanyAndMail(companyMailForm: FormGroup, companyId: number, mailDataId: number, selectedFile: File,
                       PrintInvFile: File, PrintQuoFile: File, PrintSOFile: File, PrintCopyFile: File,
                       fileInvenFile: File, r: Router,  alertService: AlertService, decimalsForm: FormGroup, printerConfiguration: IPrinter
                       ,_invCpy: File
                       ,_incPay: File
                       ,_incPayCopy: File) {
    const companyAndMail = this.CreateCompanyMailModel(companyMailForm, companyId, mailDataId, decimalsForm, printerConfiguration);

    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/CreateCompany`;
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': `Bearer ${token.access_token}`
    // });
    // return this.http.post(url,
    //   companyAndMail,
    //   { headers }
    // );
    let XHR = new XMLHttpRequest();
    const FD = new FormData();
    // se agregan los archivos de logo y de imprecion
    FD.append('file', selectedFile);
    FD.append('fileInv', PrintInvFile);
    FD.append('fileQuo', PrintQuoFile);
    FD.append('fileSO', PrintSOFile);
    FD.append('fileCopy', _invCpy);
    FD.append('fileRecPaid', _incPay);
    FD.append('fileInven', fileInvenFile);
    FD.append('fileIncPayCopy', _incPayCopy);
    FD.append('companyAndMail', JSON.stringify(companyAndMail));

    XHR.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        let response = JSON.parse(XHR.responseText);
        if (response.result) {
          alertService.successInfoAlert('Compañía Creada con éxito');
          r.navigate(['/companies']);
        } else {
          alertService.errorAlert('Error al cargar la información de las compañias - Error: ' + response.errorInfo.Message);
        }
      }
    };
    this.blockUI.stop(); // Stop blocking
    XHR.open('POST', url, true);
    XHR.setRequestHeader('Authorization', `Bearer ${token.access_token}`);
    // Send our FormData object; HTTP headers are set automatically
    XHR.send(FD);
  }

  hola() {
    // console.log('hola');
  }
  // funcion para modificar una compañía y el correo
  // recibe como parametro el formulario de la compañía con el correo, ademas del id de la compannia y el del correo
  UpdateCompanyAndMail(companyMailForm: FormGroup, companyId: number, mailDataId: number, selectedFile: File,
                       PrintInvFile: File, PrintQuoFile: File, PrintSOFile: File, PrintBalanceFile: File,
                       fileInvenFile: File, r: Router, alertService: AlertService, decimalsForm: FormGroup, printerConfiguration: IPrinter
                       ,_invCpy: File
                       ,_incPay: File
                       ,_incPayCopy: File
                       ,_cn: File
                       ,_oinvCredit: File
                       ,_oinvCreditCopy: File) {
    const companyAndMail = this.CreateCompanyMailModel(companyMailForm, companyId, mailDataId, decimalsForm, printerConfiguration);
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/UpdateCompany`;

    let XHR = new XMLHttpRequest();
    const FD = new FormData();
    // se agregan los archivos de logo y de imprecion
    FD.append('file', selectedFile);
    FD.append('fileInv', PrintInvFile);
    FD.append('fileQuo', PrintQuoFile);
    FD.append('fileSO', PrintSOFile);
    FD.append('fileBalance', PrintBalanceFile);
    FD.append('fileInven', fileInvenFile);
    FD.append('fileCopy', _invCpy);
    FD.append('fileRecPaid', _incPay);
    FD.append('fileIncPayCopy', _incPayCopy);
    FD.append('fileCreditNote', _cn);
    FD.append('fileInvoiceCredit', _oinvCredit);
    FD.append('fileInvoiceCreditCopy', _oinvCreditCopy);
    FD.append('companyAndMail', JSON.stringify(companyAndMail));

    XHR.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        let response = JSON.parse(XHR.responseText);
        if (response.result) {
          alertService.successInfoAlert('Compañía actualizada con éxito');
          r.navigate(['/companies']);
        } else {
          alertService.errorAlert('Error al cargar la información de las compañias - Error: ' + response.errorInfo.Message);
        }
      }
    };
    this.blockUI.stop(); // Stop blocking
    XHR.open('POST', url, true);
    XHR.setRequestHeader('Authorization', `Bearer ${token.access_token}`);

    // Send our FormData object; HTTP headers are set automatically
    XHR.send(FD);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  }

  // metodo para la creacion del objeto compannia y mailDataId
  // recibe como parametro el formulario de la compannia y correo, ademas del id de la compannia y el del correo
  CreateCompanyMailModel(companyMailForm: FormGroup, companyId: number, mailDataId: number, decimalsForm: FormGroup, printerConfiguration: IPrinter) {
    return {
      'company': {
        'Id': companyId,
        'DBName': companyMailForm.value.DBName,
        'DBCode': companyMailForm.value.DBCode,
        'SAPConnectionId': companyMailForm.value.SAPConnectionId,
        'Active': companyMailForm.value.Active,
        'ExchangeRate': companyMailForm.value.ExchangeRate,
        'ExchangeRateValue': companyMailForm.value.ExchangeRateValue,
        'HandleItem': companyMailForm.value.HandleItem,
        'BillItem': companyMailForm.value.BillItem,
        'SP_ItemInfo': companyMailForm.value.SP_ItemInfo,
        'SP_InvoiceInfoPrint': companyMailForm.value.SP_InvoiceInfoPrint,
        'SP_WHAvailableItem': companyMailForm.value.SP_WHAvailableItem,
        'SP_SeriesByItem': companyMailForm.value.SP_SeriesByItem,
        'SP_PayDocuments': companyMailForm.value.SP_PayDocuments,
        'SP_CancelPayment': companyMailForm.value.SP_CancelPayment,
        'V_BPS': companyMailForm.value.V_BPS,
        'V_Items': companyMailForm.value.V_Items,
        'V_ExRate': companyMailForm.value.V_ExRate,
        'V_Taxes': companyMailForm.value.V_Taxes,
        'V_GetAccounts': companyMailForm.value.V_GetAccounts,
        'V_GetCards': companyMailForm.value.V_GetCards,
        'V_GetBanks': companyMailForm.value.V_GetBanks,
        'V_GetSalesMan': companyMailForm.value.V_GetSalesMan,
        'ScaleWeightToSubstract': companyMailForm.value.ignoreWeight,
        'IsLinePriceEditable': companyMailForm.value.Editable,
        'ScaleMaxWeightToTreatAsZero': companyMailForm.value.maxAs0,
        'DecimalAmountPrice': decimalsForm.value.price,
        'DecimalAmountTotalLine': decimalsForm.value.totalLine,
        'DecimalAmountTotalDocument': decimalsForm.value.totalDocument,
        'HasOfflineMode': companyMailForm.value.hasOfflineMode,
        'PrinterConfiguration': JSON.stringify(printerConfiguration),
        'HasZeroBilling': companyMailForm.value.hasZeroBilling,
        'RefreshDelay': companyMailForm.value.RefreshDelay,
      },
      'mail': {
        'Id': mailDataId !== null ? mailDataId : 0,
        'Host': companyMailForm.value.Host,
        'from': companyMailForm.value.from,
        'pass': companyMailForm.value.pass,
        'SSL': companyMailForm.value.SSL,
        'port': companyMailForm.value.port !== '' ? companyMailForm.value.port : 0,
        'subject': companyMailForm.value.subject,
        'user': companyMailForm.value.user
      }
    };
  }

  // funcion para obtener el logo de la compañia de la DBLocal
  // no recibe parametros
  GetCompanyLogo() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetCompanyLogo`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  retornar() {
    this.router.navigate(['/companies']);
  }
  GetViewGroupList() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetViewGroupList`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }
  UpdateViewLineAgrupation(ViewGroupList: any){
    console.log(ViewGroupList);
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/UpdateViewLineAgrupation`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url,
      ViewGroupList,
      { headers }
    );
  }

  GetPaymentMethods(): Observable<IPaymentMethodsReponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetPaymentMethods`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IPaymentMethodsReponse>(url, { headers });
  }
}
