import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ReportTypeList } from '../../../models/constantes';
import { Subscription } from 'rxjs';
const printJS = require('print-js');
import { Injectable } from '@angular/core';
import { PRINTERS_PER_REPORT, ReportType } from '../../../enum/enum';
import { PermsService, DocumentService, ReportsService, ParamsService, AlertService, SalesManService, AuthenticationService, EprintService, StorageService } from '../../../services/index';
import { IPrinter } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-invcopy',
  templateUrl: './invcopy.component.html',
  styleUrls: ['./invcopy.component.scss']
})
export class InvcopyComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  userList: any[] = []; // lista de usuarios para busar la la lista de facturas segun usuario
  invList: any[] = []; // listas de las facturas que se obtiene a partir de la busqueda segun el usuario
  InvCopy: FormGroup; // nombre del formulario de facturas a reimprimir
  date: Date; // fecha actual
  nowDate: any;
  reportType: any[] = [];
  viewParamTitles: any[] = []; // llena la lista con los titulos de las paginas parametrizados
  title: string; // titulo de la vista
  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  permisos: boolean = true;
  // 			   private readonly _elec: EprintService
  constructor(
    private storageService: StorageService,
    private sPerm: PermsService,
    private fbs: FormBuilder,
    private invSer: DocumentService,
    private repoSer: ReportsService,
    private paramsService: ParamsService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private smService: SalesManService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.date = new Date();
    this.nowDate = `${this.date.getFullYear()}-${('0' + (this.date.getMonth() + 1)).slice(-2)}-${('0' + this.date.getDate()).slice(-2)}`;
  }

  ngOnInit() {
    this.checkPermits();
    this.GetParamsViewList();
    this.InvCopy = this.fbs.group({
      slpCode: [''],
      CardName: [''],
      DocEntry: [''],
      FechaIni: [''],
      FechaFin: [''],
      InvType: ['']
    });
    this.chargeUser();
    this.reportType = ReportTypeList;
    this.InvCopy.patchValue({ InvType: this.reportType[0].Id });
    this.InvCopy.controls.FechaFin.setValue(this.nowDate);
  }

  // chequea que se tengan los permisos para acceder a la pagina
  checkPermits() {

    this.sPerm.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        // console.log(data);
        let permListtable: any = data.perms;
        data.perms.forEach(Perm => {
          if (Perm.Name === "V_Print") {
            this.permisos = Perm.Active;
          }
        });

      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }

  // obtien ela lista de ususarios para la busqueda por slpCode
  // sin parametros
  chargeUser() {
    this.blockUI.start('Cargando listas de usuarios...');
    this.smService.getSalesMan().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        this.userList = data.salesManList;
        // this.userList.unshift({
        //     "SlpCode": -1,
        //     "SlpName": ""
        // });
        this.InvCopy.patchValue({ slpCode: -1 });
        this.InvCopy.controls.FechaIni.setValue(this.nowDate);
        this.InvCopy.controls.FechaFin.setValue(this.nowDate);
        this.InvCopy.patchValue({ InvType: this.reportType[0].Id });
      } else {
        this.alertService.errorAlert('Error al cargar la lista de usuarios - ' + data.errorInfo.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }
  // obtiene la lista de las SO para mostrarlas en la tabla
  // no parametros
  getInvList() {
    const inv = {
      slpCode: this.InvCopy.value.slpCode,
      CardName: this.InvCopy.value.CardName,
      DocEntry: this.InvCopy.value.DocEntry,
      FechaIni: this.InvCopy.value.FechaIni,
      FechaFin: this.InvCopy.value.FechaFin,
      InvType: this.InvCopy.value.InvType
    };

    this.blockUI.start(`Obteniendo documentos, espere por favor`);
    this.invList = [];
    this.invSer.GetInvList(inv).subscribe((data: any) => {
      if (data.result) {
        this.invList.length = 0;
        if (data.invList.length > 0) {
          this.invList = data.invList;
        } else { this.alertService.infoInfoAlert('No se encontraron facturas dentro de estos parámetros de búsqueda') }
        this.blockUI.stop(); // Stop blocking
        // console.log(this.invList);
      } else {
        this.alertService.errorAlert('Error al obtener las listas Facturas - error: ' + data.errorInfo.Message);
        this.blockUI.stop(); // Stop blocking
      }
    }, (error) => {
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      this.blockUI.stop(); // Stop blocking
    });
  }
  // envia a reimprimir la orden de venta
  // recive el DocEntry de la orden de venta
  printARInvoice(DocEntry: number, _oinvType:string ='') {
    this.blockUI.start('Generando la impresión...');
    let DocType = this.InvCopy.value.InvType == 5 && _oinvType != '' && _oinvType === "Credito" ? ReportType.InvoiceCreditCopy: this.InvCopy.value.InvType;

    this.repoSer.printReport(DocEntry,DocType)
      .subscribe((data: any) => {
        this.blockUI.stop();
        printJS({
          printable: data,
          type: 'pdf',
          base64: true
        })
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // llena los campos de la tabla de items con los campos parametriados
  GetParamsViewList() {
    this.paramsService.getParasmView()
      .subscribe((data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.viewParamTitles = data.Params.filter(param => {
            return param.type === 6;
          });
          this.ChargeParamstoView();
        } else {
          this.alertService.errorAlert('Error al cargar los parámetros de la pagina - ' + data.errorInfo.Message);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  // Carga los datos parametrizados en las variables
  ChargeParamstoView() {
    // parametrizacion del titulo
    let obj = this.viewParamTitles.filter(param => {
      return param.Name === 'T_print';
    });
    this.title = obj[0].Text;
  }

}
