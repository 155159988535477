import { Component, OnInit, ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { saveAs } from 'file-saver';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

const printJS = require('print-js');

// MODELOS
// RUTAS

// COMPONENTES

// SERVICIOS
import { ReportsService, AlertService, dailybalanceService, StorageService, CommonService } from '../../../services/index';

// Electron renderer service
import { NgbModal, ModalDismissReasons, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { PaydeskBalance } from 'src/app/models';
import { DatePipe } from '@angular/common';

// PIPES

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
  providers: [DatePipe]
})
export class BalanceComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('tabset') tabSet: NgbTabset;
  title: string;
  BForm: FormGroup; // nombre del formulario de facturas a reimprimir
  date: Date; // fecha actual
  nowDate1: any;
  nowDate2: any;
  userList: any[] = [];
  balanceList: any[] = [];
  USDList: any[] = [];
  COLList: any[] = [];

  cardCol: number;
  transCol: number;
  CashCol: number;
  CheckCol: number;
  TotalCol: number;
  BalanceCol: number;
  zoom: number;
  isLocked: boolean;

  cardDol: number;
  transDoL: number;
  CashDol: number;
  CheckDol: number;
  TotalDol: number;
  BalanceDol: number;

  viewTable: boolean;

  // modal de envio de Docuemntos
  closeResult: string;
  modalPay: any; // instancia de la modal de envio de documentos
  correoForm: FormGroup; // Formulario de correo
  AddCc: boolean = true;//Agregar copia correo
  AddCopiaMail: boolean = false;
  BtnOptionDocument: boolean = false; //Opcion del Documento
  whatsappForm: FormGroup; // Formulario de whatsapp
  pdfSrc: ArrayBuffer;
  base64Report: string;

  paydeskBalanceForm: FormGroup;

  constructor(private fbs: FormBuilder,
    private alertService: AlertService,
    private repoSer: ReportsService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private paydeskService: dailybalanceService,
    private storageService: StorageService,
    private datePipe: DatePipe,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.cardDol = 0;
    this.isLocked = true;
    this.zoom = 0.5;
    this.transDoL = 0;
    this.CashDol = 0;
    this.CheckDol = 0;
    this.TotalDol = 0;
    this.cardCol = 0;
    this.transCol = 0;
    this.CashCol = 0;
    this.CheckCol = 0;
    this.TotalCol = 0;
    this.BalanceCol = 0;
    this.BalanceDol = 0;
    this.viewTable = false;
    this.BtnOptionDocument = false;
    this.date = new Date();
    this.title = 'Cierre de caja por usuario y hora';
    this.BForm = this.fbs.group({
      UserName: [{ value: '', disabled: true }, Validators.required],
      UserCode: [{ value: '', disabled: true }, Validators.required],
      FechaIni: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      FechaFin: [''],
    });
    this.BtnOptionDocument = false;
    this.correoForm = this.fb.group({
      emailsend: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      subject: ['', Validators.required],
      message: [''],
      tags: [''],//this.fb.array([])
    });
    this.AddCopiaMail = false;
    this.whatsappForm = this.fb.group({
      numeroWhatsapp: ['', Validators.required],
      messageWhatsapp: ['', Validators.required]
    });

    this.paydeskBalanceForm = this.fb.group({
      Cash: ['', Validators.required],
      Cards: ['', Validators.required],
      Transfer: ['', Validators.required],
    });

    this.setLoggedUserName();
  }

  increaseZoom(): void {
    this.zoom = this.zoom + 0.1;
    this.zoom = +this.zoom.toFixed(1);
  }

  decreaseZoom(): void {
    if (this.zoom === 0.1) return;
    this.zoom = this.zoom - 0.1;
    this.zoom = +this.zoom.toFixed(1);
  }

  // pone los cierres de caja en la lista
  GetCurrList() {
    this.COLList.length = 0;
    this.USDList.length = 0;
    this.cardDol = 0;
    this.transDoL = 0;
    this.CashDol = 0;
    this.CheckDol = 0;
    this.TotalDol = 0;
    this.cardCol = 0;
    this.transCol = 0;
    this.CashCol = 0;
    this.CheckCol = 0;
    this.TotalCol = 0;
    this.BalanceCol = 0;
    this.BalanceDol = 0;
    this.viewTable = true;
    this.balanceList.forEach(element => {
      if (element.DocCur === 'COL') {
        this.COLList.push(element);
        this.cardCol += element.CreditSum;
        this.transCol += element.TrsfrSum;
        this.CashCol += element.CashSum;
        this.CheckCol += element.CheckSum;
        this.TotalCol += element.PayTotal;
        this.BalanceCol += element.Balance;
      }
      if (element.DocCur === 'USD') {
        this.USDList.push(element)
        this.cardDol += element.CredSumFC;
        this.transDoL += element.TrsfrSumFC;
        this.CashDol += element.CashSumFC;
        this.CheckDol += element.CheckSumFC;
        this.TotalDol += element.PayTotal;
        this.BalanceDol += element.Balance;
      }
    })
  }

  close() {
    // this.correoForm.disable();
    this.modalPay.close();
    this.AddCc = true;
  }

  get ced() {
    return this.correoForm.controls;
  }

  download() {
    this.blockUI.start('Procesando PDF, espere por favor...'); // Start blocking
    const BalanceModel = {
      User: this.BForm.value.UserCode,
      FIni: this.BForm.value.FechaIni,
      FFin: this.BForm.value.FechaFin,
      DBCode: null,
    };
    this.repoSer.printBalanceReport(BalanceModel)
      .subscribe((response: any) => {
        this.blockUI.stop(); // Stop blocking
        if (response.result) {
          let fileBase64 = atob(response.File);
          let length = fileBase64.length;
          let arrayBuffer = new ArrayBuffer(length);
          let uintArray = new Uint8Array(arrayBuffer);
          for (let i = 0; i < length; i++) {
            uintArray[i] = fileBase64.charCodeAt(i);
          }
          this.date = new Date();
          this.nowDate1 = `${this.date.getFullYear()}-${('0' + (this.date.getMonth() + 1)).slice(-2)}-${('0' + this.date.getDate()).slice(-2)}`;

          let pdfFile = new Blob([uintArray], { type: 'application/pdf' });
          let fileUrl = URL.createObjectURL(pdfFile);
          saveAs(pdfFile, `Reporte Cierre de Caja-${this.nowDate1}-PDF`);
        } else {
          this.alertService.errorAlert(`${response.errorInfo.Message}`);
        }
      }, (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorAlert(`${error}`);
      });

    return false;
  }

  deleteCc(index: number): void {
    this.AddCc = true;
    this.AddCopiaMail = false;
    this.correoForm.patchValue({ tags: "" });
  }

  get tags(): FormArray {
    return this.correoForm.get('tags') as FormArray;
  }

  addCorreo(): void {
    this.AddCopiaMail = true;
    //this.tags.push(new FormControl());
    this.AddCc = false;
  }
  SendWhatsappDocument() {
    /*
    const whatsappModal = {
      numeroWhatsapp: this.whatsappForm.value.numeroWhatsapp,
      messageWhatsapp: this.whatsappForm.value.messageWhatsapp,
      modelReport:  {
        User: this.BForm.value.UserCode,
        FIni: this.BForm.value.FechaIni,
        FFin: this.BForm.value.FechaFin,
        DBCode: null,
        sendMailModel :null
      }
    }
    this.repoSer.SendWhatsappDocument(whatsappModal)
    .subscribe(data=> {
      //if(data.result){}

    })
*/
  }

  SendDocument() {
    this.blockUI.start('Enviando Documento...'); // Start blocking
    const correoModel = {
      emailsend: this.correoForm.value.emailsend,
      subject: this.correoForm.value.subject,
      message: this.correoForm.value.message,
      EmailCC: this.correoForm.value.tags
    };
    const BalanceModel = {
      User: this.BForm.value.UserCode,
      FIni: this.BForm.value.FechaIni,
      FFin: this.BForm.value.FechaFin,
      DBCode: null,
      sendMailModel: correoModel
    };
    this.repoSer.sendDocument(BalanceModel)
      .subscribe((data: any) => {
        console.log(data);
        if (data.result) {
          this.alertService.successAlertHtml(`Documentos enviados por correo correctamente`);
          this.close();
          this.AddCopiaMail = false;
        } else {
          this.alertService.errorAlert(`Error al enviar documentos al correo: ${data.errorInfo.Message}`);
        }
        this.blockUI.stop(); // Stop blocking
      });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  //Funcion abrir modal enviar por correo
  sendOptionDocumento(content) {
    this.correoForm = this.fb.group({
      emailsend: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      subject: ['', Validators.required],
      message: [''],
      tags: [''],//this.fb.array([])
    });
    this.modalPay = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'Modal-sm' });
    this.modalPay.result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.correoForm = this.fb.group({
        emailsend: [''],
        subject: ['', Validators.required],
        message: [''],
        tags: [''],//this.fb.array([])
      });
    });
  }

  // se encarga de imprimir
  printReport() {
    if (!this.base64Report) return;

    printJS({
      printable: this.base64Report,
      type: 'pdf',
      base64: true
    });
    //   }
    // }, (error: any) => {
    //   this.blockUI.stop(); // Stop blocking
    //   this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    // });
  }

  displayPdfReport() {
    this.BtnOptionDocument = false;
    this.isLocked = true;
    this.blockUI.start('Obteniendo la impresión...'); // Start blocking
    const BalanceModel = {
      User: this.BForm.value.UserCode,
      FIni: this.BForm.value.FechaIni,
      FFin: this.BForm.value.FechaFin,
      DBCode: null,
    };
    this.repoSer.printBalanceReport(BalanceModel)
      .subscribe((data: any) => {
        this.blockUI.stop();
        this.BtnOptionDocument = true;
        this.isLocked = false;
        this.pdfSrc = this.base64ToArrayBuffer(data.File) as ArrayBuffer;
      }, (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  base64ToArrayBuffer(base64) {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  onTabChange($event: any) {
    if ($event.nextId === 'tabSearch') {
      this.paydeskBalanceForm.reset();
    } else {
      this.pdfSrc = null;
      this.base64Report = null;
      this.BtnOptionDocument = false;
    }
  }

  get formControls() {
    return this.paydeskBalanceForm.controls;
  }

  async onClickCreatePaydeskBalance() {
    if (this.paydeskBalanceForm.invalid) {
      const confirmationResult = await this.alertService.ConfirmationAlert('Confirmación', 'No has completado valores para el cierre de caja. ¿Continuar de todas formas?', 'Continuar');

      if (!confirmationResult) return;
    }

    let paydeskBalance = this.paydeskBalanceForm.value as PaydeskBalance;

    this.blockUI.start('Procesando...');

    this.paydeskService.PostPaydeskBalance(paydeskBalance)
      .pipe(first())
      .subscribe(response => {
        this.blockUI.stop();

        if (response.result) {
          this.alertService.successInfoAlert('Proceso finalizado exitosamente');
          this.paydeskBalanceForm.reset();
          this.tabSet.select('tabSearch');
          this.BtnOptionDocument = true;
          this.base64Report = response.File;
          this.pdfSrc = this.base64ToArrayBuffer(response.File) as ArrayBuffer;
        } else {
          this.alertService.errorAlert(response.errorInfo.Message);
        }
      }, err => {
        this.blockUI.stop();
        this.alertService.errorAlert(err);
      });
  }

  onClickSearchPaydeskBalance() {

    if (!this.BForm.get('UserName').value || !this.BForm.get('FechaIni').value) {
      this.alertService.infoInfoAlert('Campos requeridos con formato inválido');
      return;
    }

    this.blockUI.start('Procesando...');
    this.paydeskService.GetPaydeskBalance(this.BForm.get('FechaIni').value)
      .pipe(first())
      .subscribe(response => {
        this.blockUI.stop();

        if (response.result) {
          this.BtnOptionDocument = true;
          this.isLocked = false;
          this.base64Report = response.File;
          this.pdfSrc = this.base64ToArrayBuffer(response.File) as ArrayBuffer;
        } else {
          this.alertService.errorAlert(response.errorInfo.Message);
        }
      }, err => {
        this.blockUI.stop();
        this.alertService.errorAlert(err);
      });
  }

  onClickDownloadPdf() {
    if (!this.base64Report) return;

    this.commonService.downloadFile(this.base64Report, `Cierre de caja ${new Date().toISOString().split('T')[0]}`, 'application/pdf', 'pdf');
  }

  setLoggedUserName() {
    let session: any = this.storageService.getCurrentSession();
    if (!session) return;

    session = JSON.parse(session);

    this.BForm.get('UserName').setValue(session.UserName);
  }

}
