import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { AppConstants } from '../models';
import { BranchOfficessResponse, BaseResponse, BranchOfficeResponse, BranchOfficesSAPResponse } from '../models/responses';
import { BranchOfficeModel2 } from '../models/branch-office';
import { KeyValue } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BranchOfficeService {

  constructor(private http: HttpClient,
    private storage: StorageService) { }

  GetBrachOfficess() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<BranchOfficessResponse>(`${AppConstants.apiUrl}api/BranchOffice/GetBrachOffices`,
      { headers });
  }

  PostBrachOfficess(branchOffice: BranchOfficeModel2) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<BaseResponse>(`${AppConstants.apiUrl}api/BranchOffice/PostBrachOffice`, branchOffice,
      { headers });
  }

  PutBrachOfficess(branchOffice: BranchOfficeModel2) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.put<BaseResponse>(`${AppConstants.apiUrl}api/BranchOffice/PutBrachOffice`, branchOffice,
      { headers });
  }

  GetBranchOffice(userId: string, branchOfficeId = 0) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    let params = [{ Key: 1, Value: userId }, { Key: 2, Value: branchOfficeId }];

    return this.http.post<BranchOfficeResponse>(`${AppConstants.apiUrl}api/BranchOffice/GetBranchOffice`, params,
      { headers });
  }

  GetBranchOfficesSAP() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.get<BranchOfficesSAPResponse>(`${AppConstants.apiUrl}api/BranchOffice/GetBranchOfficesSAP`,
      { headers });
  }

}
