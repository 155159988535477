import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {PermsService} from "./perms.service";
import {AuthenticationService} from "./authentication.service";
import {filter, finalize} from "rxjs/operators";
import {StorageService} from "./storage.service";
import {LocalStorageVariables} from "../models/constantes";
import {formatDate} from "@angular/common";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Injectable({
  providedIn: 'root'
})
export class ApplicationTabsService {

  @BlockUI() blockUI: NgBlockUI;

  private _shouldLockWindow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _broadcastChannel: BroadcastChannel;

  private _thereAreMoreWindowsOpened: boolean = false;

  private _principalWindow: undefined | boolean;

  private currentUser: any;
  constructor(private permissionsService: PermsService,
              private localStorageService: StorageService,
              private authenticationService: AuthenticationService)
  {

    this._principalWindow = this.PrincipalWindow;

    this.InitializeBroadcastChannel();

    this.authenticationService.currentUser
      .pipe(
        filter(user => user)
      )
      .subscribe(user => {
      this.currentUser = user;

      this.CheckUserPermission();
    });
  }

  /**
   * Indicates if the current window is the principal (First window that was opened) window
   * @constructor
   */
  get PrincipalWindow(): boolean | undefined {
    let storedValuePrincipalWindow = sessionStorage.getItem("PrincipalWindow");

    if(storedValuePrincipalWindow)
    {
      return JSON.parse(storedValuePrincipalWindow) as boolean;
    }

    return undefined;
  }

  /**
   * Initialize the broadcast channel to establish the communication between application windows
   * @constructor
   * @private
   */
  private InitializeBroadcastChannel(): void
  {
    this._broadcastChannel = new BroadcastChannel("MultipleTabs");

    this._broadcastChannel.onmessage = (event) => {
      if(event.data === "__windowInitialized" )
      {
        /* If is undefined means that when the window was initialized do not receive a response from another window,
           so that means that this window is the principal
        */
        if(this._principalWindow === undefined)
        {
          this._principalWindow = true;

          sessionStorage.setItem("PrincipalWindow", "true");
        }

        this._thereAreMoreWindowsOpened = true;

        this._broadcastChannel.postMessage("__otherWindowHere");
      }
      else if(event.data === "__otherWindowHere" && !this._principalWindow)
      {
        if(this._principalWindow === undefined)
        {
          this._principalWindow = false

          sessionStorage.setItem("PrincipalWindow", "false");
        }

        this._thereAreMoreWindowsOpened = true;

        this.CheckUserPermission();
      }
    }

    this._broadcastChannel.postMessage("__windowInitialized");
  }




  /**
   * Check the permissions of the user to enable multiple windows opened
   * @constructor
   * @private
   */
  private CheckUserPermission(): void
  {
    if(!this.currentUser || !this._thereAreMoreWindowsOpened || this._principalWindow)
    {
      return;
    }

    this.blockUI.start();

    this.permissionsService.getPerms(this.currentUser.userId)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response: any) =>  {
          if(response.perms && response.perms.length)
          {
            if(response.perms.some(p => p.Name === "App_Windows_OpenMultiple" && p.Active))
            {
              this._shouldLockWindow.next(false)
            }
            else
            {
              this._shouldLockWindow.next(true)
            }
          }
        },
        error: (error) => {
          this._shouldLockWindow.next(true)
        }
      });
  }

  /**
   * Indicates if should lock the current window
   * @constructor
   */
  get ShouldLockWindow(): Observable<boolean>
  {
    return this._shouldLockWindow.asObservable();
  }
}
