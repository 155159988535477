import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentService, PermsService, AuthenticationService, BusinessPartnerService, AlertService, ReportsService, StorageService } from 'src/app/services';
import { IPaymentSearch, IPayment, IPrinter } from 'src/app/models';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { Subscription, Observable } from 'rxjs';
import { formatDate } from '@angular/common';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ReportType, PRINTERS_PER_REPORT } from 'src/app/enum/enum';
const printJS = require('print-js');

@Component({
  selector: 'app-print-inc-pay-copy',
  templateUrl: './print-inc-pay-copy.component.html',
  styleUrls: ['./print-inc-pay-copy.component.scss']
})
export class PrintIncPayCopyComponent implements OnInit {
  //VARBOX
  payments: IPayment[];
  bpNameList: string[] = []; // lista de los Códigos de clientes
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  currentUser: any; // variable para almacenar el usuario actual
  @BlockUI() blockUI: NgBlockUI;
  permisos: boolean = true;
  paymentSearchForm: FormGroup; // nombre del formulario de facturas a reimprimir
  paymentSearch: IPaymentSearch;
  constructor(
    private router: Router
    ,private documentService: DocumentService
    ,private fbs: FormBuilder
    ,private sPerm: PermsService
    ,private authenticationService: AuthenticationService
    ,private bpService: BusinessPartnerService
    ,private alertService: AlertService
    ,private reportsService: ReportsService
    ,private storageService: StorageService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.InitVariables();
    this.GetCustomers();
  }

  searchBPCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.bpNameList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  GetPayments(): void {
    this.blockUI.start(`Obteniendo documentos`);
    this.documentService.GetPayments(this.paymentSearch).subscribe(next => {
      this.blockUI.stop();
      if (next.result) {
        this.payments = next.Payments;
      }
      else {
        this.alertService.infoInfoAlert(`No se encontraron documentos`);
        console.log(next);
      }
    }, error => {
      this.blockUI.stop();
    });
  }

  InitVariables(): void {
    this.paymentSearch = null;
    const mDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.paymentSearchForm = this.fbs.group({
      TopDate: [mDate]
      ,BottomDate: [mDate]
      ,CardCode: ['']
    });
  }

  RequestData(): void {
    this.GetPayments();
    this.GetCustomers();
  }

  checkPermits() {
    this.sPerm.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        let permListtable: any = data.perms;
        data.perms.forEach(Perm => {
          if (Perm.Name === "V_Print") {
            this.permisos = Perm.Active;
          }
        });

      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }

  Search(): void {
    this.paymentSearch = this.paymentSearchForm.value as IPaymentSearch;
    console.log(this.paymentSearch.CardCode.split(' '));
    if (this.paymentSearch.CardCode.split(' ').length > 0) this.paymentSearch.CardCode = this.paymentSearch.CardCode.split(' ')[0];
    this.GetPayments();
    console.log(this.paymentSearch);
  }

  GetCustomers() {
    this.blockUI.start('Obteniendo clientes...');
    this.bpService.GetCustomers()
      .subscribe((data: any) => {
        this.blockUI.stop();
        if (data.result) {
          this.bpNameList = [];
          for (let businessPartner of data.BPS) {
            this.bpNameList.push(`${businessPartner.CardCode} ${businessPartner.CardName}`);
          }
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
        }

      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error getCustomers!!!, Error: ${error.error.Message}`);
      });
  }

  printPayment(_docEntry: number): void {
    this.blockUI.start('Generando la impresión...'); // Start blocking
    this.reportsService.printReport(_docEntry, ReportType.IncomingPaymentCopy)
      .subscribe((data: any) => {{
        this.blockUI.stop(); // Stop blocking
        printJS({
          printable: data,
          type: 'pdf',
          base64: true
        })
      }
      }, error => {
        this.blockUI.stop();
        console.log(error);
        this.alertService.errorInfoAlert(`Error al generar el reporte, Error: ${error}`);
      });
  }
}
