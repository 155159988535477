import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BranchOfficeModel2 } from '../models/branch-office';
import { FormGroup } from '@angular/forms';
import { IEvent,  KeyValue } from '../models';
import { AlertService } from './alert.service';
import {StorageService} from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  hasDocument: Subject<string>;
  offlineInformation: Subject<string>;
  eventManager: Subject<IEvent>;
  constructor(private alertService: AlertService,private storage: StorageService,) {
    this.hasDocument = new Subject();
    this.offlineInformation = new Subject();
    this.eventManager = new Subject();
  }

  private stringToArrayBuffer(toConvert: string) {
    let buf = new ArrayBuffer(toConvert.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != toConvert.length; ++i)
      view[i] = toConvert.charCodeAt(i) & 0xff;

    return buf;
  }

  downloadFile(
    base64File: string,
    fileName: string,
    blobType: string,
    fileExtension: string
  ) {
    let report = new Blob([this.stringToArrayBuffer(atob(base64File))], {
      type: blobType,
    });

    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(report);
    link.download = `${fileName}.${fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  isValidFile(file: File, validExtensions: string[]) {
    let fileExtension = this.getFileExtension(file);

    return validExtensions.some((x) => x === fileExtension);
  }

  getFileExtension(file: File): string {
    return file.name.split('.')[file.name.split('.').length - 1].toLowerCase();
  }

  setPaymentDefaultValues(
    branchOffice: BranchOfficeModel2,
    cashForm: FormGroup,
    creditCardForm: FormGroup,
    transferForm: FormGroup,
    checkForm: FormGroup
  ) {
    if (!branchOffice || Object.keys(branchOffice).length === 0) {
      this.alertService.infoInfoAlert("Ha surgido un problema en la lectura de la caché, cierre e inicie sessión.");
      return;
    }

    cashForm.get('AccountCash').setValue(branchOffice.CashAccount);
    creditCardForm.get('CreditCard').setValue(branchOffice.CreditCard);
    creditCardForm.get('OwnerIdNum').setValue(branchOffice.VoucherNum);
    transferForm.get('AccountTransf').setValue(branchOffice.TransferAccount);
    transferForm.get('Ref').setValue(branchOffice.VoucherNum);
  }

  roudToTwo(toRound: number) {
    let fixed = toRound.toFixed(2);
    return Number(fixed);
  }

  /**
   * Method to generate uniqueId
   * @constructor
   */
  GenerateDocumentUniqueID(): string {
    const USER_PREFIXID = JSON.parse(this.storage.getCurrentSession()).PrefixId;

    const DATE = new Date();

    const DAYS =
      DATE.getDate() < 10 ? "0" + DATE.getDate() : DATE.getDate().toString();
    const MONTS =
      DATE.getMonth() + 1 < 10
        ? "0" + (DATE.getMonth() + 1)
        : (DATE.getMonth() + 1).toString();
    const YEAR = DATE.getFullYear()
      .toString()
      .slice(
        DATE.getFullYear().toString().length - 2,
        DATE.getFullYear().toString().length
      );

    const HOURS =
      DATE.getHours() < 10 ? "0" + DATE.getHours() : DATE.getHours();
    const MINUTES =
      DATE.getMinutes() < 10 ? "0" + DATE.getMinutes() : DATE.getMinutes();
    const SECONDS =
      DATE.getSeconds() < 10 ? "0" + DATE.getSeconds() : DATE.getSeconds();

    return `${USER_PREFIXID + DAYS + MONTS + YEAR + HOURS + MINUTES + SECONDS
    }`;

  }


}
