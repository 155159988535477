import { Component, OnInit, ElementRef, ViewChild, Inject, ViewChildren, QueryList, } from '@angular/core';
import { AlertService } from '../../../services/alert.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemService, PermsService, AuthenticationService } from 'src/app/services';
import { debounceTime, map, distinctUntilChanged, filter } from 'rxjs/operators';
import { IItemModel } from '../../../models/i-item';
import { GoodsReceiptStockService } from '../../../services/goodsReceiptStockService';
import { StoreService } from '../../../services/store.service';
import { ILine } from '../../../models/i-line';
import { TaxService } from '../../../services/tax.service';
import swal from 'sweetalert2';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/platform-browser';
import { Renderer2 } from '@angular/core';

import { IPrice } from 'src/app/models/i-price';
@Component({
  selector: 'app-stock-goodissue',
  templateUrl: './stock-goodissue.component.html',
  styleUrls: ['./stock-goodissue.component.scss']
})
export class StockGoodissueComponent implements OnInit {


  @ViewChild('scrollMe') private myScrollContainer: ElementRef; // Se usa para que la tabla haga autoscroll
  @ViewChild('input1') inputEl: ElementRef; // Lo uso para mandarle el autofocus cuando se escanea el codigo de barras
  permisos = true; // Comprueba los permisos del usuario
  lines: ILine[]; // Representa la linea del producto que se ingresa
  itemsTypeaheadList: string[] = []; // lista de la concatenacion del Código con el nombre del item
  itemsList: IItemModel[]; // Contiene el nombre de los productos, preformateados desde el api y el codigo de estos
  taxesList: any; // Contiene la lista de impuestos registrados en el sistema
  @BlockUI() blockUI: NgBlockUI; // Usado para bloquear la interfaz de usuario
  currentUser: any; // variable para almacenar el usuario actual
  totalLines: number; // Guarda la sumatoria de todas las linea con (precio unitario * cantidad)
  item: IItemModel; // Model para el typeahead, cuando se lecciona un item de la lista este lo almacena
  UnitPrice: number;
  Quantity: number;
  SubTotal: number; // Guarda
  WareHouse: string; // Guarda el id del almacen del dropdown global
  unitPriceSelectBox: string; // Guarda el id del almacen del dropdown global
  Stores: any; // Guarda todos los almacenes de la empresa
  DiscountTotal: number;
  TaxesTotal: number;
  USTotal: number; // Total en dolares
  CRCTotal: number; // Total en colones
  barcodeModel: any; // Usando para sacar el codigo de barras de un producto, de la lista de codigos que este tiene
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  ListaPrecio: number; //Almacena lista precios
  QuantyList: number[] = [];
  x: number = 0;
  /**/
  priceListOld: IPrice[]; // Respaldo de la lista editada
  exitForm: FormGroup; // inputs salida de inventario

  isOnEditMode = false;

  currentIndex: number;

  @ViewChild('editQuantityId') editQuantityId: ElementRef; // Referencia al input de edicion de precio por linea, para poder setear el focus cuando se va a editar
  @ViewChild('editUnitPriceId') editUnitPriceyId: ElementRef; // Referencia al input de edicion de precio por linea, para poder setear el focus cuando se va a editar
  @ViewChild('editTotalLineId') editTotalLineId: ElementRef; // Referencia al input de edicion de precio por linea, para poder setear el focus cuando se va a editar

  fItemName = (x: { ItemName: string }) => x.ItemName; // Formateador para el nombre de los productos
  sItemName = (text$: Observable<string>) => text$.pipe( // Busca en el nombre de item la coincidencia por codigo de barras, nombre, codigo item
    debounceTime(5),
    distinctUntilChanged(),
    map(term => term === '' ? []
      : this.itemsList.filter(v => v.ItemName.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 3)))

  searchItemCode = (text$: Observable<string>) =>
    text$.pipe(
      //debounceTime(5),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.itemsTypeaheadList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  constructor(private modalService: NgbModal,
    private itemsService: ItemService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private sPerm: PermsService,
    private goodsReciptStockService: GoodsReceiptStockService,
    private storeService: StoreService,
    private fb: FormBuilder,
    private taxesService: TaxService,
    @Inject(DOCUMENT) private _document: Document,
    private renderer: Renderer2,
    private elRef: ElementRef) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.checkPermits(); // Verifica si el usuario tiene permisos para acceder a la pagina
    this.blockUI.start();
    this.InitVariables();
    this.blockUI.start('Obteniendo información, espere por favor...');

    this.exitForm = this.fb.group({
      Quantity: [''],
      ItemInfo: [''],
      unitPriceSelectBox: [''],
      WareHouse: ['']

    });

    // Obtiene la lista de almances de la compania
    this.storeService.getStoresv2().subscribe(response => {
      this.Stores = response.Stores;
      this.WareHouse = this.Stores[0].StoreCode;
    });
    // Obtiene la lista de impuestos registrados en el sistema
    this.taxesService.GetTaxes().subscribe(response => {
      this.taxesList = response.Taxes;
    }); // Consume los impuesto y los setea a dicha lista desde el api

    // Obtiene los items para ser usados en el typeahead
    this.itemsService.GetItems().subscribe(response => {
      this.itemsList = [];
      this.itemsTypeaheadList = response.ItemList.ItemCompleteName;
      for (let c = 0; c < response.ItemList.ItemCompleteName.length; c++) {
        const NAME = response.ItemList.ItemCompleteName[c];
        const ITEM_CODE = response.ItemList.ItemCode[c];
        const tmpItem = {} as IItemModel;
        tmpItem.ItemName = NAME;
        tmpItem.ItemCode = ITEM_CODE;
        this.itemsList.push(tmpItem);
      }
      this.blockUI.stop();
    });

    if (this.itemsService.hasLines) {
      this.lines = this.itemsService.GetLines(this);
      this.recalculate();
    } else {
      console.log(this.itemsService.GetLines(this));
    }
    //Obtener lista de Precios 
    this.goodsReciptStockService.GetAllPriceList().subscribe(response => {
      this.priceListOld = response.priceList;
      this.unitPriceSelectBox = response.priceList[9].ListNum;
      this.ListaPrecio = response.priceList[9].ListNum;

    });
    //this.unitPriceSelectBox.patchValue({ unitPriceSelectBox: this.priceListOld[0].ListName });
    // this.itemsService.GetPriceList().subscribe(response => {
    //   if (response.result) {
    //     this.priceListOld = [];
    //     //this.priceList = [];
    //     for (let c = 0; c < response.priceList.length; c++) {
    //       console.log('2')
    //       this.priceListOld.push({
    //         ListName: response.priceList[c].ListName,
    //         ListNum: response.priceList[c].ListNum,
    //         Price: 0,
    //       });
    //     }
    //    //this.priceList = this.priceListOld;
    //   }
    //   // console.log(response.priceList);
    // });
  }

  addItems(item: any): void {
    const ITEM_CODE = item.item.split(' ')[0];
    this.onThClick(ITEM_CODE);
    item.preventDefault();
    //  this.ItemInfo.reset();
    this.exitForm.patchValue({ ItemInfo: '' });
  }

  // Funcion que recalcula el precio total, subtotal, descuentos, impuestos, sobre la lista de lineas
  recalculate(): void {
    this.totalLines = +this.lines.reduce((a, b) => a + ((b.UnitPrice * b.Quantity) || 0), 0).toFixed(2);
    this.TaxesTotal = +this.lines.reduce((a, b) => a + ((b.UnitPrice * b.Quantity) * (b.Tax_Rate / 100) || 0), 0).toFixed(2);
    this.DiscountTotal = +this.lines.reduce((a, b) => a + ((b.UnitPrice * b.Quantity) * (b.Discount / 100) || 0), 0).toFixed(2);
    this.CRCTotal = +this.lines.reduce((a, b) => a +
      (((b.UnitPrice * b.Quantity) - ((b.UnitPrice * b.Quantity) * (b.Discount / 100))) +
        (((b.UnitPrice * b.Quantity) - ((b.UnitPrice * b.Quantity) * (b.Discount / 100))) * (b.Tax_Rate / 100)) || 0), 0).toFixed(2);
    if (this.lines.length > 0) {
      this.itemsService.hasLines = true;
      this.itemsService.UpdateLines(this.lines);
    } else {
      this.itemsService.hasLines = false;
      this.itemsService.UpdateLines([]);
    }
  }

  onFocusOutEvent(event: any, type: string) {
    switch (+type) {
      case 0:
        this.inputs.toArray()[+event].nativeElement.focus();
        break;
      case 1:
        this.unitPrices.toArray()[+event].nativeElement.focus();
        break;
    }
  }
  isOnEditMode_ = false;

  @ViewChildren('quantities') inputs: QueryList<ElementRef>;
  @ViewChildren('unitPrices') unitPrices: QueryList<ElementRef>;
  @ViewChildren('totalLine') totalLine: QueryList<ElementRef>;
  // (blur)="onFocusOutEvent(i, '0')"
  lostFocus(index: number, type: number) {
    console.log(index);
    switch (+type) {
      case 0:
        this.inputs.toArray()[+index].nativeElement.focus();
        break;
      case 1:
        this.unitPrices.toArray()[+index].nativeElement.focus();
        break;
      case 2:
        this.totalLine.toArray()[+index].nativeElement.focus();
        break;
    }
  }

  lostEditFocus(): void {
    console.log('lost on edit ->');
    this.isOnEditMode = false;
  }

  toggleEdition(index: string, type: string): void {
    console.log('click' + index);
    this.isOnEditMode = true;
  }

  onFocusOutEdit(i: string) {
    console.log('lost on edit');
    this.isOnEditMode = false;
  }

  // Saca un item de la lista de lineas basado en su indice
  // removeItem(index: number) {
  //   this.lines = this.lines.filter((x, i) => i !== index);
  //   this.recalculate();
  // }
  // Evento para actualizar todos los almacenes con el dropdown global
  onSelectBoxChange(event: any): void {
    for (let c = 0; c < this.lines.length; c++) {
      this.lines[c].WareHouse = event.target.value;
    }
  }
  // Evento para actualizar el almacen de cada linea, basado en su indice
  onSelectBoxLineChange(event: any, index: number): void {
    this.lines[index].WareHouse = event.target.value;
  }
  // Evento para actulizar el impuesto de cada producto, basado en su indice
  onSelectBoxLineChangeTax(event: any, index: number): void {
    const TAX_CODE = event.target.value;
    const TAX = this.taxesList.filter(x => x.TaxCode === TAX_CODE)[0];
    this.lines[index].TaxCode = TAX.TaxCode;
    this.lines[index].Tax_Rate = +TAX.TaxRate;
    this.recalculate();
    // console.log(event.target.value, this.taxesList.filter(x=> {x.TaxCode === event.target.value}));
  }
  // Evento para seleccionar un item del type ahead y cargarlo en la lista de lineas
  onThClick(_itemCode: string): void {
    // if (item.constructor.name.toString() === 'Object') {
    this.blockUI.start('Obteniendo información del item');
    this.itemsService.GetItemByItemCode(_itemCode, 1).subscribe(response => {
      if (response.result) {
        const LINE = {} as ILine;
        LINE.BarCode = response.Item.BarCode;
        LINE.ItemCode = response.Item.ItemCode;
        LINE.ItemName = response.Item.ItemName;
        LINE.Quantity = this.Quantity > 0 ? this.Quantity : 1;
        LINE.TaxCode = response.Item.TaxCode;
        LINE.UnitPrice = +(Number(response.Item.LastPurchasePrice)).toFixed(4);
        LINE.Discount = response.Item.Discount;
        LINE.Tax_Rate = response.Item.TaxRate;
        LINE.TaxRate = response.Item.TaxRate;
        LINE.WareHouse = this.WareHouse;
        LINE.TotalLine = response.Item.LastPurchasePrice * LINE.Quantity;

        if (this.lines.some(x => x.ItemCode === '----')) {
          this.lines[this.lines.findIndex(x => x.ItemCode === '----')] = LINE;
        }
        else {
          this.lines.push(LINE);
        }

        this.Quantity = 1;
        this.item = {} as IItemModel;
        setTimeout(() => this.inputEl.nativeElement.focus());
        this.recalculate();
        this.blockUI.stop();
      } else {
        this.blockUI.stop();
        this.alertService.errorAlert(response.Message);
      }
    });
    // }
  }
  // Evento para actualizar los diferentes datos de un item en la linea, segun su indice
  onKeyUp(i: number, event: any, field: string): void {
    if (event.target.value !== '') {
      switch (+field) {
        case 0:
          this.lines[i].Quantity = +event.target.value;
          break;
        case 1:
          this.lines[i].UnitPrice = +event.target.value;
          break;
        case 2:
          this.lines[i].Discount = +event.target.value;
          break;
        case 4:
          this.lines[i].UnitPrice = +event.target.value / this.lines[i].Quantity;
          break;
      }
      this.recalculate();
    }
  }
  // Crea la salida de mercancias
  createGoodsIssue(): void {
    if (this.lines.length === 0) {
      this.alertService.infoAlert('Debe ingregar un artículo al menos');
      return;
    }

    if (this.lines.some(x => x.ItemCode === '----')) {
      this.alertService.infoAlert('Existen líneas que no han sido completadas');
      return;
    }

    // if (!this.ListaPrecio) {
    //   this.alertService.infoAlert('Debe Seleccionar Lista de Precios');
    //   return;
    // }


    this.blockUI.start('Agregando salida de inventario');
    this.goodsReciptStockService.CreateGoodsIssueStock(
      this.lines, this.ListaPrecio
    ).subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.alertService.successAlertHtml(`Se ha creado correctamente la salida de inventario`);
        this.resetGUI();
      } else {
        this.alertService.errorAlert('Error al agregar la entrada: ' + response.errorInfo.Message);
      }
    });
  }

  // Resetea la intefaz usado por el boton de borrar campos
  resetGUI() {
    this.blockUI.start();
    this.InitVariables();
    this.blockUI.stop();

  }

  // Incializa las variables a un estado por defecto
  InitVariables() {
    this.lines = [];
    this.CRCTotal = 0.0;
    this.Quantity = 1;
    this.SubTotal = 0.0;
    this.totalLines = 0.0;
    this.DiscountTotal = 0.0;
    this.TaxesTotal = 0.0;
    this.USTotal = 0.0;
    this.CRCTotal = 0.0;
    this.itemsService.UpdateLines([]);
    this.itemsService.hasLines = false;
  }
  // Verifica si el usuario tiene permisos para acceder a la pagina
  checkPermits() {
    this.sPerm.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        data.perms.forEach(Perm => {
          if (Perm.Name === 'V_Inventory_Master') {
            this.permisos = Perm.Active;
          }
        });
      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }
  updateIndex(event: any): void {
    this.ListaPrecio = event;
    this.blockUI.start('Obteniendo precios de los Items');
    this.goodsReciptStockService.GetItemChangePrice(this.lines, this.ListaPrecio).subscribe(response => {
      if (response.result) {
        this.lines.forEach(i => {
          this.QuantyList.push(i.Quantity);
        });
        this.lines.length = 0;
        response.ItemList.forEach(item => {
          const LINE = {} as ILine;
          // LINE.BarCode = response.Item.BarCode;
          LINE.ItemCode = item.ItemCode;
          LINE.ItemName = item.ItemName;
          LINE.Quantity = this.QuantyList[this.x];
          LINE.TaxCode = item.TaxCode;
          LINE.UnitPrice = item.UnitPrice;
          LINE.Discount = item.Discount;
          LINE.Tax_Rate = item.TaxRate;
          LINE.WareHouse = this.WareHouse;
          this.x++;
          this.lines.push(LINE);
        });


        //LINE.WareHouse = this.WareHouse;

        // this.lines.push(LINE);
        //this.Quantity = 1;
        this.item = {} as IItemModel;
        setTimeout(() => this.inputEl.nativeElement.focus());
        this.recalculate();
        this.blockUI.stop();
      } else {
        this.blockUI.stop();
        this.alertService.errorAlert(response.Message);
      }
    });
  }

  setCurrentIndex(index: number): void {
    this.currentIndex = index;
  }

  addLine(): void {
    if (this.lines.some(x => x.ItemCode === '----')) {
      this.alertService.infoInfoAlert('Por favor complete la línea recién agregada');
      return;
    }
    const LINE = {} as ILine;
    LINE.BarCode = '';
    LINE.ItemCode = '----';
    LINE.ItemName = '';
    LINE.Quantity = 1;
    LINE.TaxCode = '13IVA';
    LINE.UnitPrice = 0;
    LINE.Discount = 0;
    LINE.Tax_Rate = 0;
    LINE.TaxRate = '';
    LINE.WareHouse = this.WareHouse;
    LINE.TotalLine = 0;
    let auxiliarList = this.lines;

    const lista = auxiliarList.slice(0, this.currentIndex);
    const listb = auxiliarList.slice(this.currentIndex, auxiliarList.length);

    lista.push(LINE);

    listb.forEach(x => lista.push(x));
    this.lines = lista;

    setTimeout(() => this.inputEl.nativeElement.focus());
  }

  upLine(): void {
    if (this.currentIndex > 0) {
      let auxiliarList = this.lines;

      const itemup = auxiliarList.slice(this.currentIndex, auxiliarList.length);
      const itemmove = auxiliarList.slice(this.currentIndex - 1, auxiliarList.length);

      auxiliarList[this.currentIndex - 1] = itemup[0];
      auxiliarList[this.currentIndex] = itemmove[0];

      this.lines = auxiliarList;
    }

  }

  downLine(): void {
    if ((this.currentIndex + 1) != this.lines.length) {
      let auxiliarList = this.lines;

      const itemup = auxiliarList.slice(this.currentIndex, auxiliarList.length);
      const itemmove = auxiliarList.slice(this.currentIndex + 1, auxiliarList.length);

      auxiliarList[this.currentIndex + 1] = itemup[0];
      auxiliarList[this.currentIndex] = itemmove[0];
      this.lines = auxiliarList;
    }
  }

  removeItem(): void {
    if (this.currentIndex !== -1) {
      this.lines.splice(this.currentIndex, 1);
      this.recalculate();
      this.currentIndex = -1;
      setTimeout(() => this.inputEl.nativeElement.focus());
    }
  }

}
