import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { dailybalanceService, SalesManService, AlertService, StorageService } from "../../../services/index";
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { first } from 'rxjs/operators';
import { CashflowReasonModel } from 'src/app/models';
import { CashflowModel } from 'src/app/models/i-cashflow';

@Component({
  selector: 'app-cashflow',
  templateUrl: './cashflow.component.html',
  styleUrls: ['./cashflow.component.scss']
})
export class CashflowComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  permisos: boolean = true;
  cashflowForm: FormGroup;
  userList: any[] = [];
  reasons: CashflowReasonModel[];

  constructor(
    private formBuilder: FormBuilder,
    private cashflowService: dailybalanceService,
    private alertService: AlertService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.cashflowForm = this.formBuilder.group({
      UserName: [{ value: '', disabled: true }, Validators.required],
      Type: ['1', Validators.required],
      Amount: ['', Validators.required],
      Reason: ['', Validators.required],
      Details: ['']
    });

    this.setLoggedUserName();
    this.getCashflowReasons();
  }

  initPage() {
    this.cashflowForm.reset({
      UserName: this.cashflowForm.get('UserName').value,
      Type: '1',
      Amount: '',
      Reason: '',
      Details: ''
    });
  }

  onClickCashflow() {
    if (!this.cashflowForm.get('UserName').value) return;

    let cashflow: CashflowModel = {
      Amount: Number(this.cashflowForm.get('Amount').value),
      CreationDate: new Date(),
      Details: this.cashflowForm.get('Details').value,
      Reason: this.cashflowForm.get('Reason').value,
      UserSignature: 0,
      Type: this.cashflowForm.get('Type').value
    };

    this.blockUI.start('Procesando...');
    this.cashflowService.PostCashflow(cashflow).pipe(first()).subscribe(x => {
      this.blockUI.stop();
      if (x.result) {
        this.initPage();
        this.alertService.successInfoAlert('Proceso finalizado exitosamente');
      } else {
        this.alertService.errorAlert(x.errorInfo.Message);
      }
    }, err => {
      this.blockUI.stop();
      this.alertService.errorAlert(err);
    });
  }

  onClickInitPage() {
    this.initPage();
  }

  getCashflowReasons() {
    this.reasons = [];
    this.cashflowService.GetCashflowReasons().pipe(first()).subscribe(x => {
      if (x.result) this.reasons = x.CashflowReasons;
      else this.alertService.errorAlert(x.errorInfo.Message);

      console.log(this.reasons);
      
    }, err => {
      this.alertService.errorAlert(err);
    });
  }

  get formControls() {
    return this.cashflowForm.controls;
  }

  setLoggedUserName() {
    let user: any = this.storageService.getCurrentSession();
    if (!user) return;
    user = JSON.parse(user);

    this.cashflowForm.get('UserName').setValue(user.UserName);
  }

}
